import { ToTalContribution, Total, StakePercent, FlexContainer, ValueOfTotal, TotalBox } from './styles';
import { defaultCurrency } from 'components/templates/Questionaries/constant';
import { commaSeparatedNumber } from 'utils/helperMethod';

interface TotalProps {
  totalContribution: number;
  totalPercentage: number;
  selectedCountryCurrency: string;
}

const TotalValues = ({ totalContribution, totalPercentage, selectedCountryCurrency }: TotalProps): JSX.Element => {
  return (
    <FlexContainer>
      <ToTalContribution>
        <Total>Total:</Total>

        <TotalBox isError={totalContribution === 0}>
          <div>
            <span> {selectedCountryCurrency == '' ? defaultCurrency : selectedCountryCurrency}</span>
          </div>
          <ValueOfTotal> {commaSeparatedNumber(totalContribution)}</ValueOfTotal>
        </TotalBox>
        <StakePercent isError={totalPercentage > 100 || totalPercentage < 100}>
          <span>{totalPercentage} %</span>
        </StakePercent>
      </ToTalContribution>
    </FlexContainer>
  );
};

export default TotalValues;
