import { TableHeadData } from './reportData';
import { TableHeadWrapper, TableHead, Last12Month, TableHeadStyleB, TableHeadStyleA } from './styles';

const DCFTableHead = ({ currencySymbol = '' }): JSX.Element => {
  return (
    <TableHeadWrapper>
      <TableHead TableHeadStyle={TableHeadStyleA}>
        <span> Detailed financial forecasts ( {currencySymbol} )</span>
      </TableHead>
      <Last12Month>
        <span> Last 12 months</span>
      </Last12Month>
      {TableHeadData.map(({ id, title }) => (
        <TableHead TableHeadStyle={TableHeadStyleB} key={id}>
          <span> {title}</span>
        </TableHead>
      ))}
    </TableHeadWrapper>
  );
};

export default DCFTableHead;
