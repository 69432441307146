import styled from 'styled-components';

export const Wrapper = styled.main`
  margin: 71px auto 71px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 978px;

  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      margin-top: 78px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 900px) {
    //this will be replaced in future
    margin: auto;
  }
  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      margin-top: 17px;
    }
  }
`;
export const CardMedia = styled.img`
  height: 418px;
  max-width: 418px;
  margin-bottom: 30px;
  border-radius: 100px;

  && {
    ${(props) => props.theme.breakpoints.down('lg')} {
      height: 330px;
      width: 330px;
      border-radius: 50px;
    }
  }
  && {
    ${(props) => props.theme.breakpoints.down('sm')} {
      height: 244px;
      width: 244px;
    }
  }
`;
export const CardBody = styled.p`
  max-width: 300px;
  text-align: center;
  margin: 12px auto 0 auto;
  font-size: 16px;
  font-weight: 400;

  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      font-size: 14px;
      height: 42px;
      max-width: 260px;
    }
  }
`;
export const StyledDiv = styled.div`
  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      max-width: 260px;
    }
  }
`;
export const GreenText = styled.span`
  color: ${({ theme }) => theme.palette.primary.main};
`;
