import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
interface StyleProps {
  modalWidth?: string;
  borderRadius?: string;
  height?: string;
  padding?: string;
  mobileWidth?: string;
  mobilePadding?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  responsiveModal: {
    width: ({ modalWidth }) => modalWidth,
    borderRadius: ({ borderRadius }) => borderRadius,
    height: ({ height }) => height,
    padding: ({ padding }) => padding,
    [theme.breakpoints.down('md')]: {
      width: ({ mobileWidth }) => mobileWidth,
      padding: ({ mobilePadding }) => mobilePadding,
    },
  },
}));
