import RegistrationForm from '../../organisms/RegistrationForm/RegistrationForm';
import NavBar from '../../organisms/NavBar/NavBar';
import Img from '../../../assets/registration-page-image.png';
import Title from '../../molecules/Title/Title';
import { Wrapper, Card, CardMedia, CardBody, StyledDiv, GreenText } from './styles';

const Registration: React.FC = () => {
  return (
    <>
      <NavBar />
      <Wrapper>
        <RegistrationForm />
        <Card>
          <CardMedia src={Img} alt="" />
          <StyledDiv>
            <Title titleStyle={{ fontSize: '24px' }}>
              Startup valuation <GreenText>made simple</GreenText>
            </Title>
          </StyledDiv>
          <CardBody>Calculate your startup valuation and generate professional reports</CardBody>
        </Card>
      </Wrapper>
    </>
  );
};

export default Registration;
