import { Box, Dialog } from '@material-ui/core';
import { StyledPaper, StyledCloseIcon } from './styles';

interface PropTypes {
  onClose: () => void;
  open: boolean;
  children: React.ReactNode;
}

const Modal: React.FC<PropTypes> = ({ onClose, open, children }) => {
  return (
    <Dialog open={open} onClose={onClose} PaperComponent={StyledPaper}>
      <StyledCloseIcon onClick={onClose} />
      <Box>{children}</Box>
    </Dialog>
  );
};

export default Modal;
