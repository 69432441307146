import React from 'react';
import { Box, Card, Divider, Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
type Elements = {
  title: string;
  text: string | number;
};

type PropTypes = {
  data: Elements[];
};

const CardContent: React.FC<PropTypes> = ({ data }) => {
  return (
    <div
      style={{
        padding: '23px 12px 46px 12px',
        width: '100%',
        height: '420px',
        overflow: 'hidden',
        borderRadius: '4px',
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
      }}
      className="tilesBoxShadow"
    >
      {!isEmpty(data) &&
        data.map(({ title, text }) => {
          return (
            <div style={{ padding: '12px 2px 15px 25px' }}>
              <h3
                style={{
                  fontSize: '16px',
                  lineHeight: '21px',
                  fontFamily: 'Prompt',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  color: '#9B9B9B',
                }}
              >
                {title}
              </h3>
              <div style={{ marginTop: '8px' }} />
              <h2
                style={{
                  fontSize: '26px',
                  lineHeight: '35px',
                  fontFamily: 'Prompt',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  color: '#171C1B',
                }}
              >
                {text}
              </h2>
              <Divider />
            </div>
          );
        })}
    </div>
  );
};

export default CardContent;
