import { Box, Typography } from '@material-ui/core';
import { subscriptionCost } from 'utils/constants';
import { useStyles, StyledBullet, StyledValuationImage } from '../styles';
import copy from '../copy.json';

const Advantages: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.advantagesContainer}>
      <StyledValuationImage />
      <Box className={classes.advantagesContent}>
        <Box mb={3}>
          <Typography className={classes.advantagesHeader}>
            {copy.advantages.title} <span className={classes.feeSpan}>£{subscriptionCost.toFixed(2)}</span>
          </Typography>
        </Box>
        <Box>
          {copy.advantages.list.map((advantageText, index) => (
            <Box key={index} display="flex">
              <StyledBullet />
              <Typography className={classes.advantagesListItem}>{advantageText}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Advantages;
