import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';

type DataSetType = {
  generalQuestions: any[];
  riskQuestions: Array<{ [key: string]: string | number }>;
  raisingAmount: Array<{ [key: string]: string | number }>;
  additionalInvesment: Array<{ [key: string]: string | number }>;
  revenueQuestions: any[];
};

export const QuestionValidation = (dataSet: DataSetType) => {
  if (
    isEmpty(dataSet) &&
    isEmpty(dataSet.generalQuestions) &&
    isEmpty(dataSet.riskQuestions) &&
    isEmpty(dataSet.raisingAmount) &&
    isEmpty(dataSet.additionalInvesment) &&
    isEmpty(dataSet.revenueQuestions)
  ) {
    return;
  }
  if (
    !isEmpty(dataSet) &&
    !isEmpty(dataSet.generalQuestions) &&
    !isEmpty(dataSet.riskQuestions) &&
    !isEmpty(dataSet.raisingAmount) &&
    !isEmpty(dataSet.additionalInvesment) &&
    !isEmpty(dataSet.revenueQuestions)
  ) {
    const generalSchema: any = [];
    const riskSchema: any = [];
    const generalQuestion = [
      ...dataSet.generalQuestions,
      ...dataSet.raisingAmount,
      ...dataSet.additionalInvesment,
      ...dataSet.revenueQuestions,
    ];
    generalQuestion.forEach((item) => {
      if (item.fieldType === 'dropdown' && item.fieldSymbol === null) {
        generalSchema[item.slug] = Yup.number().required('Please select an answer from the dropdown').nullable();
      }
      // validation rule for number input - ZERO NOT ALLOWED
      // exclude fields in which 0 is allowed:
      // - revenue_last_year
      // - no_board_members
      // - no_employees
      if (
        item.fieldType === 'number' &&
        item.fieldSymbol !== 'percentage' &&
        item.slug !== 'revenue_last_year' &&
        item.slug !== 'no_board_members' &&
        item.slug !== 'no_employees'
      ) {
        generalSchema[item.slug] = Yup.number()
          .positive()
          .min(1, 'Value can not be less than 1')
          .required('Please enter numbers only')
          .nullable();
      }

      // validation rule for revenue_last_year specifically
      if (item.fieldType === 'number' && item.fieldSymbol !== 'percentage' && item.slug === 'revenue_last_year') {
        generalSchema[item.slug] = Yup.number().required('Please enter numbers only').nullable();
      }

      // validation rule - negative numbers not allowed
      // include fields in which 0 is allowed:
      // - no_board_members
      // - no_employees
      if (
        item.fieldType === 'number' &&
        item.fieldSymbol !== 'percentage' &&
        (item.slug === 'no_board_members' || item.slug === 'no_employees')
      ) {
        generalSchema[item.slug] = Yup.number()
          .min(0, `${item.slug.replace('_', ' ')} can not be less than 0`)
          .required('Please enter numbers only')
          .nullable();
      }

      // validation rule for percentage
      // exclude average_growth - max percentage needs to be 200
      if (item.fieldType === 'number' && item.fieldSymbol === 'percentage' && item.slug !== 'average_growth') {
        generalSchema[item.slug] = Yup.number()
          .positive()
          .min(1, `${item.slug.replace('_', ' ')} can not be less than 1`)
          .max(100, 'Percentage can not be more than 100')
          .required('Please enter numbers only')
          .nullable();
      }

      // validation rule for average_growth specifically
      // min is 0
      // max is 200
      if (item.slug === 'average_growth') {
        generalSchema[item.slug] = Yup.number()
          .positive()
          .min(0, `${item.slug.replace('_', ' ')} can not be less than 0`)
          .required('Please enter numbers only')
          .max(200, 'Percentage can not be more than 200')
          .nullable();
      }
    });
    dataSet.riskQuestions.forEach((item) => {
      riskSchema[item.slug] = Yup.number().nullable();
    });

    return Yup.object().shape({
      general: Yup.object().shape({
        companyName: Yup.string().required('Please enter a valid company name').nullable(),
        companyWebsite: Yup.string()
          .matches(
            /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
            'Enter valid url!',
          )
          .nullable(),
        location: Yup.number().required('Please select an answer from the dropdown').nullable(),
        yearFounded: Yup.date()
          .max(new Date().getFullYear(), 'Year must be current year or less than current year')
          .required('Please enter a valid year')
          .min(new Date('1970').getFullYear(), 'Year must be current year more than 1970')
          .nullable(),
        industry: Yup.number().required('Please select an answer from the dropdown').nullable(),
        investmentStage: Yup.number().required('Please select an answer from the dropdown').nullable(),
        ...generalSchema,
      }),
      risk: Yup.object().shape({
        ...riskSchema,
      }),
    });
  }
};
