import { ReactChild, ReactChildren } from 'react';
import { ButtonWrapper } from './styles';
interface ButtonProps {
  children: ReactChild | ReactChildren;
  additionStyle?: { [key: string]: string | number };
  processing?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event: any) => void;
  buttonName?: string;
}

const defaultProps = {
  buttonName: '',
};

const FormButton = ({ children, disabled, type, additionStyle, onClick, buttonName }: ButtonProps): JSX.Element => {
  return (
    <ButtonWrapper data-name={buttonName} onClick={onClick} disabled={disabled} style={additionStyle} type={type}>
      {children}
    </ButtonWrapper>
  );
};

FormButton.defaultProps = defaultProps;

export default FormButton;
