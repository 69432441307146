import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme) => ({
  cardContainer: {
    padding: '23px 12px 46px 12px',
    width: '100%',
    height: '420px',
  },
  content: {
    padding: '12px 2px 15px 25px',
  },
  title: {
    fontSize: '16px',
    lineHeight: '21px',
  },
  text: {
    fontSize: '26px',
    lineHeight: '35px',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '22px',
    },
  },
}));
