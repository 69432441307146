import React, { useEffect, useState, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import CardColumnSection from 'components/organisms/CardColumsSection';
import { commaSeparatedNumber } from 'utils/helperMethod';
import customTheme from 'theme/customTheme';
import { RiskReportProps, ColumnsData } from './types';

const RiskReport: React.FC<RiskReportProps> = ({ currency, riskReportDetails }) => {
  const [riskValues, setRiskValues] = useState<ColumnsData[] | any>();

  const { riskFactorWeightedAmount } = riskReportDetails;

  useEffect(() => {
    if (isEmpty(riskReportDetails)) {
      return;
    }
    const { riskFactorValuations } = riskReportDetails;
    const riskData = riskFactorValuations.map((item) => ({ title: item.question, value: item.score }));
    setRiskValues(riskData);
  }, [riskReportDetails]);

  const getTotalValueCount = useMemo(() => {
    if (isEmpty(riskReportDetails)) {
      return null;
    }
    const { riskFactorValuations } = riskReportDetails;
    const count = riskFactorValuations.reduce(function (acc, obj) {
      return +acc + +obj.score;
    }, 0);

    return count;
  }, [riskReportDetails]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <h2
            style={{
              fontFamily: 'Prompt',
              fontWeight: 700,
              fontStyle: 'normal',
              color: '#171C1B',
              fontSize: '32px',
              lineHeight: '43px',
              wordBreak: 'break-word',
            }}
          >
            Risk factor summation method
          </h2>
        </div>
        <div style={{ marginTop: '16px' }}>
          <p
            style={{
              fontFamily: 'Mulish',
              fontWeight: 700,
              fontStyle: 'normal',
              color: '#171C1B',
              fontSize: '16px',
              lineHeight: '21px',
              wordBreak: 'break-word',
            }}
          >
            The company valuation according to
            <br /> the Risk factor summation method is&nbsp;
            <span style={{ color: customTheme.colors.MediumSpringGreen }}>
              {currency}
              {commaSeparatedNumber(Number(riskFactorWeightedAmount))}
            </span>
          </p>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
        <div
          style={{
            overflow: 'hidden',
            boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
            borderRadius: '4px',
            color: 'rgba(0, 0, 0, 0.87)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: '#fff',
            width: '49%',
            height: 'auto',
            minHeight: '538px',
          }}
          className="tilesBoxShadow"
        >
          <div style={{ padding: '25px 35px 17px 35px' }}>
            <p
              style={{
                fontFamily: 'Mulish',
                fontWeight: 700,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '16px',
                lineHeight: '21px',
                wordBreak: 'break-word',
              }}
            >
              <b>Risk Factor Summation method</b> uses a baseline valuation of the companies according to the location,
              industry, and stage of your company, which is then adjusted for 12 risk types by the risk levels which are
              estimated by the company’s management.
            </p>
            <div style={{ marginTop: '32px' }}>
              <h3
                style={{
                  fontFamily: 'Prompt',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '18px',
                  lineHeight: '21px',
                  wordBreak: 'break-word',
                }}
              >
                Method pros
              </h3>
              <div style={{ marginTop: '8px' }} />
              <div style={{ display: 'flex' }}>
                <span style={{ paddingRight: 10 }}>•</span>
                <p
                  style={{
                    fontFamily: 'Mulish',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    color: '#171C1B',
                    fontSize: '15px',
                    lineHeight: '21px',
                  }}
                >
                  Can be a good basis for your negotiations with the investor and discussing risk mitigation strategies
                  that the company has
                </p>
              </div>
            </div>
            <div style={{ marginTop: '16px' }}>
              <h3
                style={{
                  fontFamily: 'Prompt',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '18px',
                  lineHeight: '21px',
                  wordBreak: 'break-word',
                }}
              >
                Method cons
              </h3>
              <div style={{ marginTop: '8px' }} />
              <div style={{ display: 'flex' }}>
                <span style={{ paddingRight: 10 }}>•</span>
                <p
                  style={{
                    fontFamily: 'Mulish',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    color: '#171C1B',
                    fontSize: '15px',
                    lineHeight: '21px',
                  }}
                >
                  Highly subjective
                </p>
              </div>
            </div>
            <div style={{ marginTop: '40px' }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  overflow: 'hidden',
                  boxShadow: 'none',
                  borderRadius: '4px',
                  color: 'rgba(0, 0, 0, 0.87)',
                  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  backgroundColor: '#fff',
                  background: customTheme.colors.skyBlue,
                  width: '95%',
                  padding: 12,
                }}
              >
                <p
                  style={{
                    fontFamily: 'Mulish',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    color: '#171C1B',
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}
                >
                  Although original method has a fixed adjustment value (such as {currency}500,000), our algorithm uses
                  a different value depending on our estimates of valuations in your region, industry, stage of funding
                  or valuations provided by you in Valuation Parameters
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            overflow: 'hidden',
            boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
            borderRadius: '4px',
            color: 'rgba(0, 0, 0, 0.87)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: '#fff',
            width: '49%',
            height: 'auto',
            minHeight: '538px',
          }}
          className="tilesBoxShadow"
        >
          <div style={{ padding: '25px 35px 17px 35px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginTop: '24px' }}>
              <CardColumnSection
                firstTitle="Risk Factor"
                secondTitle="Risk Score"
                data={riskValues || []}
                totalValue={getTotalValueCount}
                mobileWidthFirstCol="65%"
                mobileWidthSecondCol="34%"
                widthFirstCol="70%"
                widthSecondCol="29%"
                isLastRowGreen
                isCenterlizeValue
              />
            </div>
          </div>
          <div style={{ display: 'flex', padding: '10px 35px 25px 35px' }}>
            <p
              style={{
                fontFamily: 'Mulish',
                fontWeight: 400,
                fontStyle: 'normal',
                fontSize: '11px',
                lineHeight: '15px',
                color: customTheme.colors.gray6,
              }}
            >
              Assigned factors:&nbsp;
              <span>
                <b>-2</b> Very high risk
              </span>
              &nbsp;&nbsp;
              <span>
                <b>-1</b> High risk
              </span>
              &nbsp;&nbsp;
              <span>
                <b>0</b> Average risk
              </span>
              &nbsp;&nbsp;
              <span>
                <b>1</b> Low risk
              </span>
              &nbsp;&nbsp;
              <span>
                <b>2</b> Very low risk
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskReport;
