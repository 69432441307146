import styled, { css } from 'styled-components';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { RiDeleteBin6Line, RiQuestionFill } from 'react-icons/ri';
import customTheme from 'theme/customTheme';
const {
  colors: { tintOfSilver, error, white, DarkBlack, gray98, lightGrey2 },
} = customTheme;

export const Wrapper = styled.section`
  margin: 63px 0;
  margin: auto;
`;
export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const PercentSymbol = styled.span`
  display: inline-block;
  padding: 0 25px 0 0;
`;
export const percentInputStyle = {
  height: '60px',
  width: '80px',
  backgroundColor: 'white',
  marginLeft: ' 10px ',
  borderRadius: '100px',
};
export const investorsInputStyle = { height: '60px', width: '242px', backgroundColor: 'white', borderRadius: '100px' };
export const ListItemBox = styled(FlexContainer)`
  justify-content: space-between;
  margin-bottom: 10px;

  @media (max-width: 510px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.secondaryFont};
  font-size: 21px;
  padding-bottom: 13px;
  font-weight: 700;
  border-bottom: 1px solid ${(props) => props.theme.colors.LigthGray};
  color: ${(props) => props.theme.colors.DarkBlack};
`;

export const Section = styled.section`
  margin: 30px 0;
  padding: 0px 0 30px 0px;
  border-bottom: 1px solid ${(props) => props.theme.colors.LigthGray};
`;

export const SectionTitle = styled.h4`
  font-family: ${(props) => props.theme.fontFamily.secondaryFont};
  font-size: 18px;
  margin: 30px 0 20px 0;
  font-weight: 700;

  @media (max-width: 510px) {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const Text = styled.span`
  display: inline-block;
  margin: -4px 10px 0 0;

  &::first-letter {
    text-transform: capitalize;
  }
  @media (max-width: 510px) {
    margin: 0 10px 13px 0;
  }
`;

export const QuestionIcon = styled(RiQuestionFill)`
  width: 21px;
  height: 21px;
  color: ${(props) => props.theme.colors.LigthGray};
  @media (max-width: 510px) {
    margin-top: -12px;
  }
`;

export const ToTalContribution = styled(FlexContainer)`
  justify-content: flex-end;
  width: 100%;
`;

export const Total = styled.span`
  margin-right: 15px;
  font-family: ${(props) => props.theme.colors.primaryFont};
  font-size: 15px;
  font-weight: 700;
`;

export const StakePercent = styled.div<{ isError?: boolean }>`
  height: 60px;
  border-radius: 100px;
  width: 80px;
  display: flex;
  background-color: ${white};
  margin-left: 10px;
  padding: 12px 12px 12px 15px;
  overflow: hidden;
  display: flex;
  margin-bottom: -7px;
  border: 1px solid ${({ isError }) => (isError ? error : tintOfSilver)};
  & > span {
    display: inline-block;
    margin: auto;
    font-weight: 700;
  }
`;

export const InputFieldWrapper = styled.div<{ width?: string }>`
  border-radius: ${(props) => props.theme.colors.primaryBorderRadius};
  border: 1px solid ${(props) => props.theme.colors.tintOfSilver};
  border-radius: 100px;
  background-color: ${white};
  height: 48px;
  overflow: hidden;
  width: 242px;

  width: ${(props) =>
    css`
      ${props.width}
    `};
  display: flex;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 28px;
    height: 50px;
    top: 0;
    right: 0px;
    background-color: ${white};
  }
  & > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${white};
    height: 100%;
    width: 48px;
    background-color: ${(props) => props.theme.colors.primaryColor};
    font-size: 16px;
    font-weight: 700;
  }
`;
export const InputIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${white};
  height: 100%;
  width: 48px;
  background-color: ${(props) => props.theme.colors.primaryColor};
  font-size: 16px;
  font-weight: 700;
`;
export const InputField = styled.input`
  width: 90%;
  height: 48px;
  border: none;
  outline: none;
  margin: 0 13px;
  font-size: 16px;
  font-weight: 400;
`;

export const LayoutFixed = styled.span`
  display: inline-block;
  height: 17.5px;
  width: 15.75px;
  margin-left: 12px;
`;
export const DeleteIcon = styled(RiDeleteBin6Line)`
  position: absolute;
  right: -30px;
  color: ${(props) => props.theme.colors.DimGray};
  height: 17.5px;
  width: 15.75px;
  cursor: pointer;
  > :hover {
    color: ${(props) => props.theme.colors.error};
  }

  @media (max-width: 510px) {
    position: static;
    margin-left: 8px;
  }
`;
export const buttonStyle = { width: '118px', fontSize: '14px', height: '43px', backgroundColor: ` ${DarkBlack}` };

export const inputFieldStyle = {
  margin: '25px 10px 25px 0',
  maxWidth: '324px',
  borderRadius: '50px',
  padding: '12px 25px',
  border: `1px solid ${tintOfSilver}`,
};

export const UserAddMoreBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 350px;
`;

export const ButtonBox = styled.div`
  position: relative;
  @media (max-width: 510px) {
    margin: 0 0 20px 0;
  }
`;

export const TotalBox = styled(InputFieldWrapper)<{ isError?: boolean }>`
  width: 242px;
  height: 60px;
  border-radius: 100px;
  border: 1px solid ${({ isError }) => (isError ? error : tintOfSilver)};
`;

export const ValueOfTotal = styled(FlexContainer)`
  font-size: 16px;
  padding: 0 10px;
  font-weight: 700;
  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      width: 100px;
    }
  }
`;

export const MenuWrapper = styled.div<{ open?: boolean }>`
  width: 200px;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.109804);
  border-radius: ${({ theme: { borderRadius } }) => borderRadius.secondaryBorder};
  padding: 20px;
  position: absolute;
  top: 30px;
  left: 120px;
  background: ${white};
  z-index: 200;
  display: ${({ open }) => (open ? 'block' : 'none')};
`;

export const MenuItem = styled.div<{ isAdded?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: ${gray98};
  }
`;

export const StyledSpan = styled.span<{ removeBorder?: any }>`
  border-bottom: ${(props) => (props.removeBorder ? '' : `0.5px solid ${lightGrey2}`)};
  display: inline-block;
  padding: 10px 0;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
`;

export const useStyles = makeStyles<Theme>((theme) => {
  return {
    addIcon: {
      cursor: 'pointer',
      '&:hover': {
        fill: `${theme.palette.primary.main}`,
      },
    },
  };
});
