import React from 'react';
import moment from 'moment';
import TransparentLogo from 'assets/transparentLogo.svg';
import CoverImage from 'assets/coverBackground.svg';
import { CoverSectionProps } from './types';

const CoverSection: React.FC<CoverSectionProps> = ({ name, date }) => {
  return (
    <div
      style={{
        backgroundColor: '#00caa6',
        width: '100%',
        minHeight: '600px',
        backgroundImage: `url(${CoverImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top',
        backgroundSize: 'contain',
      }}
    >
      <div
        style={{
          position: 'relative',
          minHeight: 'calc(600px - 30px)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <div style={{ margin: 0 }}>
          <h2
            style={{
              fontFamily: 'Prompt',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '55px',
              lineHeight: '65px',
              color: '#FFFFFF',
            }}
          >
            Valuation of {name}
          </h2>
          <h3
            style={{
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '23px',
              lineHeight: '35px',
              color: '#FFFFFF',
            }}
          >
            Generated on {moment(date).format('MMM Do YYYY')} at growthval.com
          </h3>
        </div>
      </div>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', padding: '12px 25px 14px 39px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '65%', alignItems: 'center' }}>
          <div
            style={{
              fontFamily: 'Mulish',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '16px',
              lineHeight: '27px',
              color: '#FFFFFF',
            }}
          >
            <p>See disclaimer at the end of the document</p>
          </div>
          <div>
            <img style={{ width: '200px', height: '48px' }} src={TransparentLogo} alt="growthval-logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoverSection;
