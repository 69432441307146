import styled, { css } from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { Typography, Box, SwipeableDrawer } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

const baseZIndex = 1000;

export const useStyles = makeStyles<Theme>((theme) => ({
  optionsDrawerPaper: {
    maxWidth: '220px',
    top: '70px',
    left: 'calc(100% - 240px)',
    borderRadius: '0 0 5px 5px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const mobileNavItemStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.LigthGray};
  cursor: pointer;
  height: 50px;
  width: 100%;
  padding: 0 20px;
  margin: 0;
`;

const navLinkStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  padding: 0 10px;
  margin: 0 10px;
  color: ${({ theme }) => theme.palette.common.white};
  border-bottom: 4px solid transparent;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobileNavItemStyle}
  }
`;

export const MobileUserName = styled.div`
  cursor: default;
  padding: 37px 20px 20px 20px;
  font-weight: 700;
`;

export const Wrapper = styled.nav`
  display: flex;
  position: fixed;
  top: 0;
  z-index: ${baseZIndex};
  background: ${({ theme }) => theme.colors.DarkBlack};
  padding: 0 22px;
  height: 70px;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 0 0 22px;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 100%;
`;

export const StyledNavItems = styled(Box)`
  display: flex;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    background: ${({ theme }) => theme.palette.common.white};
    z-index: ${baseZIndex};
    height: 100%;
    width: 90vw;
  }
`;

export const StyledNavLink = styled(NavLink)`
  ${navLinkStyle}

  &.active {
    border-bottom-color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    &.active {
      border-bottom: 1px solid ${({ theme }) => theme.colors.LigthGray};
    }
  }
`;

export const StyledNavLinkBtn = styled.div`
  ${navLinkStyle}
`;

export const StyledOptionBtn = styled.div`
  ${mobileNavItemStyle}
`;

export const StyledOptionLink = styled(NavLink)`
  ${mobileNavItemStyle}
`;

export const StyledLinkText = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.common.white};
    ${({ theme }) => theme.breakpoints.down('sm')} {
      color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;

export const DesktopBox = styled(Box)`
  && {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      display: none;
    }
  }
`;

export const NavItemsDrawer = styled(SwipeableDrawer)`
  && {
    display: none;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
`;

export const MobileBox = styled(Box)`
  && {
    display: none;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
`;
