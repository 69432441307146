import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import customTheme from 'theme/customTheme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 35px 0 35px 0;
`;

export const Centerlize = styled.div`
  width: 86%;
  margin: 0 auto;
  max-width: 1496px;
  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      width: 90%;
    }
  }
`;

export const useStyles = makeStyles<Theme>((theme) => {
  return {
    header: {
      fontSize: '26px',
      lineHeight: 1,
      wordBreak: 'break-word',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
      },
    },
    largeHeader: {
      fontSize: '32px',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px',
      },
    },
    marginBottom: {
      marginBottom: 30,
    },
    row: {
      minHeight: 40,
      display: 'flex',
      alignItems: 'center',
    },
    responsiveRow: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: '12px',
      },
    },
    leftBox: {
      width: '20%',
      minWidth: '130px',
    },
    rightBox: {
      width: '80%',
    },
    responsiveBox: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    boldText: {
      fontWeight: 700,
    },
    success: {
      color: customTheme.colors.primaryColor,
    },
    error: {
      color: customTheme.colors.error,
    },
    modalText: {
      fontWeight: 700,
      textAlign: 'center',
    },
    smallUp: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    inputContainer: {
      width: 285,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    submitContainer: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    submitBox: {
      marginRight: '20px',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        marginRight: 'auto',
        marginBottom: '10px',
      },
    },
    modalContent: {
      padding: 20,
      [theme.breakpoints.down('xs')]: {
        padding: '20px 0 0 0',
      },
    },
    modalActionPanel: {
      display: 'flex',
      justifyContent: 'space-between',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    cancelBtn: {
      [theme.breakpoints.down('xs')]: {
        order: 1,
      },
    },
  };
});
