import { ReactChild, ReactChildren, ReactNode } from 'react';
import { TitleBox } from './styles';
interface TitleProps {
  children: ReactChild | ReactChildren | ReactNode;
  titleStyle?: { [key: string]: string | number };
}
function Title({ children, titleStyle }: TitleProps): JSX.Element {
  return <TitleBox titleStyle={titleStyle}>{children}</TitleBox>;
}

export default Title;
