import React, { useState, useEffect } from 'react';
import { NoneEditableCell, EditableCell } from './styles';
import {
  commaSeparatedNumber,
  formatInputWithCommas,
  handleCommaNumberChange,
  COMMA_NEGATIVE_NUMBER_INPUT_REGEX,
} from 'utils/helperMethod';

interface TableData {
  id?: string;
  amt: number | string;
}

interface TableRowProps {
  cellData: TableData;
  removeBorder?: boolean;
  isEditable?: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  fontWeight?: string;
  fontColor?: string;
  makeBold?: boolean;
  cellStyle?: { [key: string]: string | number };
}

const TableCell = ({
  cellData,
  cellStyle,
  isEditable,
  removeBorder,
  makeBold,
  ...rest
}: TableRowProps): JSX.Element => {
  const { id, amt } = cellData;
  const [value, setValue] = useState<string | number>(amt || 0);
  const updateValue = (value: string | number): void =>
    handleCommaNumberChange(value, setValue, COMMA_NEGATIVE_NUMBER_INPUT_REGEX);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { target: { value: targetValue = '' } = {} } = event || {};
    updateValue(targetValue);
  };

  useEffect(() => {
    updateValue(amt);
  }, [amt]);

  if (!cellData) return <></>;

  return (
    <>
      {(() => {
        if (isEditable) {
          return (
            <EditableCell
              {...rest}
              type="text"
              value={formatInputWithCommas(value)}
              onChange={handleChange}
              cellAdditionalStyle={cellStyle}
              removeBorder={removeBorder}
              makeBold={makeBold}
              defaultValue={amt}
              key={id}
            />
          );
        }
        return (
          <NoneEditableCell
            {...rest}
            cellAdditionalStyle={cellStyle}
            removeBorder={removeBorder}
            makeBold={makeBold}
            key={id}
          >
            {commaSeparatedNumber(Number(amt))}
          </NoneEditableCell>
        );
      })()}
    </>
  );
};

export default TableCell;
