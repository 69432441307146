import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Box, Card, Typography } from '@material-ui/core';
import CardColumnSection from 'components/organisms/CardColumsSection';
import { commaSeparatedNumber } from 'utils/helperMethod';
import { BerkusReportProps, ColumnsData } from './types';
import { useStyles } from './styles';

const BerkusReport: React.FC<BerkusReportProps> = ({ currency, berkusDetails, companyName }) => {
  const classes = useStyles();
  const [berkusValues, setBerkusValues] = useState<ColumnsData[] | any>();

  useEffect(() => {
    if (isEmpty(berkusDetails)) {
      return;
    }
    const berkusData = berkusDetails.berkusCategoriesValuation.map((item) => ({
      title: item.name,
      value: `${currency}${commaSeparatedNumber(Number(item.categoryWeightedAmount))}`,
    }));

    setBerkusValues(berkusData);
  }, [berkusDetails]);

  const getTotalValueCount = () => {
    if (isEmpty(berkusDetails)) {
      return null;
    }

    const count = berkusDetails.berkusCategoriesValuation.reduce(function (acc, obj) {
      return +acc + +obj.categoryWeightedAmount;
    }, 0);

    if (count && typeof count !== 'number') return '';
    if (count && typeof count === 'number') {
      return commaSeparatedNumber(count);
    }
  };

  const { berkusWeightedAmount } = berkusDetails;
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" className={classes.scoreCardValue}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Berkus method
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="subtitle2" className={classes.cardSubText}>
            The company valuation according to the Berkus method is&nbsp;
            <span className={classes.greenText}>
              {currency}
              {commaSeparatedNumber(Number(berkusWeightedAmount))}
            </span>
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={3} className={classes.scoreCardsSection}>
        <Card className={`${classes.reportCard} ${classes.cardContainer}`}>
          <Box className={classes.cardTextBox}>
            <Typography variant="subtitle2" className={classes.cardSubText}>
              This method was developed by Dave Berkus to value companies at pre-revenue stage.
            </Typography>
            <Box mt={3} />
            <Typography variant="body1" className={classes.cardSubText}>
              It is based on assigning a value in 5 areas to reduce risks of the startups:
            </Typography>
            <Box mt={3} />
            <Box display="flex">
              <span className={classes.bullet}>•</span>
              <Typography variant="body1" className={classes.pointText}>
                Sound Idea (basic value)
              </Typography>
            </Box>
            <Box display="flex">
              <span className={classes.bullet}>•</span>
              <Typography variant="body1" className={classes.pointText}>
                Prototype (reducing technology risk)
              </Typography>
            </Box>
            <Box display="flex">
              <span className={classes.bullet}>•</span>
              <Typography variant="body1" className={classes.pointText}>
                Quality Management Team (reducing execution risk)
              </Typography>
            </Box>
            <Box display="flex">
              <span className={classes.bullet}>•</span>
              <Typography variant="body1" className={classes.pointText}>
                Strategic Relationships (reducing market risk)
              </Typography>
            </Box>
            <Box display="flex">
              <span className={classes.bullet}>•</span>
              <Typography variant="body1" className={classes.pointText}>
                Product Rollout and Sales (reducing production risk)
              </Typography>
            </Box>
            <Box mt={4}>
              <Typography variant="subtitle1" className={classes.pointHeading}>
                Method pros
              </Typography>
              <Box mt={1} />
              <Box display="flex">
                <span className={classes.bullet}>•</span>
                <Typography variant="body1" className={classes.pointText}>
                  Useful method to value pre-revenue startups.
                </Typography>
              </Box>
              <Box display="flex">
                <span className={classes.bullet}>•</span>
                <Typography variant="body1" className={classes.pointText}>
                  Can be a used to justify your startup valuation during negotiations.
                </Typography>
              </Box>
              <Box display="flex">
                <span className={classes.bullet}>•</span>
                <Typography variant="body1" className={classes.pointText}>
                  Identifies important factors that affect a startup
                </Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography variant="subtitle1" className={classes.pointHeading}>
                Method cons
              </Typography>
              <Box mt={1} />
              <Box display="flex">
                <span className={classes.bullet}>•</span>
                <Typography variant="body1" className={classes.pointText}>
                  Level of subjectivity
                </Typography>
              </Box>
              <Box display="flex">
                <span className={classes.bullet}>•</span>
                <Typography variant="body1" className={classes.pointText}>
                  Doesn’t include weights which are important for your sector
                </Typography>
              </Box>
            </Box>
          </Box>
        </Card>
        <Card className={`${classes.reportCard} ${classes.cardContainer}`}>
          <Box display="flex" flexDirection="column" className={classes.cardTextBox}>
            <Box mt={3}>
              <CardColumnSection
                firstTitle={companyName}
                secondTitle="Value Allocated"
                data={berkusValues || []}
                totalValue={`${currency}${getTotalValueCount()}`}
                mobileWidthFirstCol="55%"
                mobileWidthSecondCol="44%"
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="center">
            <Card className={classes.noteSection}>
              <Typography variant="body1" className={classes.notesText}>
                We ask a set of questions under each category to allocate value to the startup. Maximum valuation that
                can be assigned in each area is <b>{currency}500,000</b>, the startup’s valuation cannot be higher
                than&nbsp;
                <b>{currency}2,500,000</b>.
              </Typography>
            </Card>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default BerkusReport;
