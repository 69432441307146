import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  scorecardMethod: Yup.number()
    .max(100, 'Value can not exceed by 100')
    .positive('Please enter positive vlaue only')
    .nullable(),
  berkusMethod: Yup.number()
    .max(100, 'Value can not exceed by 100')
    .positive('Please enter positive vlaue only')
    .nullable(),
  riskFactorSummationMethod: Yup.number()
    .max(100, 'Value can not exceed by 100')
    .positive('Please enter positive vlaue only')
    .nullable(),
  vCMethod: Yup.number()
    .max(100, 'Value can not exceed by 100')
    .positive('Please enter positive vlaue only')
    .nullable(),
  dCFMethod: Yup.number()
    .max(100, 'Value can not exceed by 100')
    .positive('Please enter positive vlaue only')
    .nullable(),
  methodAverageValuation: Yup.number().positive('Please enter positive vlaue only').nullable(),
  returnRatePercentage: Yup.number()
    .max(100, 'Value can not exceed by 100')
    .positive('Please enter positive vlaue only')
    .nullable(),
  discountRatePercentage: Yup.number()
    .max(100, 'Value can not exceed by 100')
    .positive('Please enter positive vlaue only')
    .nullable(),
  growthRatePercentage: Yup.number()
    .max(100, 'Value can not exceed by 100')
    .positive('Please enter positive vlaue only')
    .nullable(),
  medianName: Yup.string().nullable(),
  medianRate: Yup.number().positive('Please enter positive value only').nullable(),
});
