import { Box } from '@material-ui/core';
import { Wrapper } from 'style/CommonStyle';
import { useStyles } from './styles';

interface PropTypes {
  marginTop?: number;
}

const FullscreenLoader: React.FC<PropTypes> = ({ marginTop = 70 }) => {
  const classes = useStyles({ marginTop });

  return (
    <Wrapper className={classes.container}>
      <Box className={classes.loader} />
    </Wrapper>
  );
};

export default FullscreenLoader;
