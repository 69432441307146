import { useHistory } from 'react-router-dom';
import { Wrapper } from 'style/CommonStyle';
import { Box } from '@material-ui/core';
import NavContainer from 'components/layout/NavContainer';
import { routesObj } from 'routes/routes';
import CompanyOverview from './CompanyOverview';
import ScoreCardReport from './ScoreCardReport';
import BerkusReport from './BerkusReport';
import RiskReport from './RiskReport';
import VcReport from './VCReport';
import ValuationSummary from './ValuationSummary';
import FinancialReport from './FinancialReport';
import { Container, Centerlize } from './styles';
import { ReportData } from './types';
import ValuationHeader from 'components/organisms/ValuationHeader';
import DCFReport from 'components/organisms/DCFReport';
import Disclaimer from 'components/molecules/Disclaimer';

interface PropTypes {
  reportDetails: ReportData | any;
  companyValuationId: number;
  parentCompanyValuationId: number;
  skipFinancialForecast: boolean;
}

const ValuationReport: React.FC<PropTypes> = ({
  companyValuationId,
  parentCompanyValuationId,
  skipFinancialForecast,
  reportDetails: {
    companyOverview = {},
    scorecardReport: scoreCardDetails = {},
    berkusReport: berkusDetails = {},
    riskFactorReport: riskReportDetails = {},
    vcReport: vcReportDetails = {},
    valuationSummaryReport: valuationSummaryDetails = {},
    financialReport: financialDetails = {},
    dcfReport = {},
    currency,
    pdfUrl,
  } = {},
}) => {
  const history = useHistory();
  const downloadReport = () => {};
  const onEditReport = () => {
    history.push({
      pathname: `${routesObj.questionaries.path}`,
      state: { companyValuationId, parentCompanyValuationId },
    });
  };
  return (
    <NavContainer>
      <Wrapper>
        <Container>
          <Centerlize>
            <ValuationHeader onEdit={onEditReport} onDownload={downloadReport} pdfUrl={pdfUrl} />
            <Box id="element-to-print">
              <Box mt={6}>
                <CompanyOverview details={companyOverview || {}} />
              </Box>
              <Box mt={6}>
                <FinancialReport financialDetails={financialDetails} currency={currency.symbol || ''} />
              </Box>
              <Box mt={6}>
                <ValuationSummary
                  valuationSummaryDetails={valuationSummaryDetails}
                  currency={currency.symbol || ''}
                  companyName={companyOverview.companyName || ''}
                />
              </Box>
              <Box mt={6}>
                <ScoreCardReport scoreCardDetails={scoreCardDetails} currency={currency.symbol || ''} />
              </Box>
              <Box mt={6}>
                <BerkusReport
                  berkusDetails={berkusDetails}
                  currency={currency.symbol || ''}
                  companyName={companyOverview.companyName || ''}
                />
              </Box>
              <Box mt={6}>
                <RiskReport riskReportDetails={riskReportDetails} currency={currency.symbol || ''} />
              </Box>
              <Box mt={6}>
                <VcReport
                  vcReportDetails={vcReportDetails}
                  currency={currency.symbol || ''}
                  companyValuationId={companyValuationId}
                  skipFinancialForecast={skipFinancialForecast}
                  parentCompanyValuationId={parentCompanyValuationId}
                />
              </Box>
              <DCFReport
                dcfReport={dcfReport}
                currencySymbol={currency.symbol || ''}
                companyValuationId={companyValuationId}
                parentCompanyValuationId={parentCompanyValuationId}
                skipFinancialForecast={skipFinancialForecast}
              />
              <Disclaimer />
            </Box>
          </Centerlize>
        </Container>
      </Wrapper>
    </NavContainer>
  );
};

export default ValuationReport;
