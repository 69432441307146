import styled, { css } from 'styled-components';
import customTheme from 'theme/customTheme';

export interface CommonStructure {
  [key: string]: string | number;
}
const {
  colors: { tintOfSilver, error, DimGray },
  fontFamily: { primaryFont },
} = customTheme;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InputLabel = styled.label<{ labelStyle: any }>`
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;

  ${({ labelStyle }) =>
    css`
      ${labelStyle}
    `};
`;

export const InputFieldBox = styled.div<{ additionalStyle?: CommonStructure; isError?: boolean }>`
  display: flex;
  border-radius: 25px;
  overflow: hidden;
  height: 46px;
  border: 1px solid ${tintOfSilver};

  ${({ additionalStyle }) =>
    css`
      ${additionalStyle};
    `};

  border: ${({ isError }) => isError && `1px solid ${error}`};
`;

export const InputFieldIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
`;
export const InputField = styled.input<{ placeholderStyle?: CommonStructure; fieldPadding?: string }>`
  width: 100%;
  background: none;
  outline: none;
  border: none;
  padding: 12px 25px;
  font-family: ${primaryFont};
  padding: ${({ fieldPadding }) => fieldPadding && fieldPadding};
  font-size: 16px;
  &::placeholder {
    color: ${DimGray};
    ${({ placeholderStyle }) =>
      css`
        ${placeholderStyle}
      `}
  }
`;

export const Error = styled.p<{ errorStyle: any }>`
  font-size: 11px;
  font-weight: 700;
  color: ${error};
  margin: 5px 0 0 0;
  ${(props) =>
    css`
      ${props.errorStyle}
    `}
`;
