import { ReactComponent as BurgerIcon } from 'assets/burger-icon-white.svg';
import { StyledButton } from './styles';

interface PropTypes {
  onClick: () => void;
}

const BurgerBtn: React.FC<PropTypes> = ({ onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <BurgerIcon />
    </StyledButton>
  );
};

export default BurgerBtn;
