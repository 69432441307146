import React, { ChangeEvent, useState, useEffect } from 'react';
import FormInput from 'components/molecules/FormInput/FormInput';
import FormTitle from 'components/molecules/Title/Title';
import FormError from 'components/molecules/formError/FormError';
import GrowthValLogo from 'components/molecules/GrowthValLogo/GrowthValLogo';
import Logo from '../../../assets/growthValLogo.svg';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Button from 'components/molecules/FormButton/FormButton';
import { FormWrapper, MarginAuto, FormContainer, InputWrapper, ForgetPassword, NotMemberSignUp } from './styles';
import { LoginUrl } from 'utils/ApiUrls';
import { routesObj } from 'routes/routes';
import axios from 'axios';

const LoginForm: React.FC = () => {
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const [loginError, setLoginError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const history = useHistory();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value.trim() });
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('click', handleSubmit);
    };
  }, []);
  const { password, email } = formValues;
  const checkFormValues = (): boolean => (!(password.length > 1) && !(email.length > 2)) || isProcessing;

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setIsProcessing(true);
      const response = await axios.post(LoginUrl, formValues);

      const {
        success,
        data: { accessToken, role, firstName, lastName, email },
      } = response.data;

      if (success) {
        setIsProcessing(false);
        const userInfo = { token: accessToken, role, firstName, lastName, email };
        localStorage.setItem('growthVal', JSON.stringify(userInfo));
        history.push(routesObj.dashboard.path);
      }
    } catch (error) {
      const { response } = error;
      if (response === undefined) {
        setLoginError('Error has occurred: please check you details');
        setIsProcessing(false);
        return;
      }

      if (response.status === 401) {
        setLoginError('Invalid username or password');
      } else if (response.status === 404) {
        setLoginError('User not found');
      } else if (response.status === 400) {
        setLoginError('Account not verified!!');
      } else {
        setLoginError('Please enter a valid input');
      }
      setIsProcessing(false);
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <MarginAuto>
        <GrowthValLogo logo={Logo} />
      </MarginAuto>

      <FormContainer>
        <FormTitle>Login</FormTitle>
        <InputWrapper>
          <FormInput
            label="Email:"
            type="text"
            name="email"
            value={email}
            placeholder="name@company.com"
            onChange={handleChange}
            inputFieldStyle={{ backgroundColor: 'none' }}
          />
        </InputWrapper>
        <InputWrapper>
          <ForgetPassword>
            <Link to="/forgot-password">Forgot password?</Link>{' '}
          </ForgetPassword>
          <FormInput
            label="Password:"
            type="password"
            name="password"
            placeholder="••••••••"
            placeholderStyle={{ 'font-weight': '900', fontSize: '16px', ' letter-spacing': '2px' }}
            value={password}
            onChange={handleChange}
          />
          {loginError && <FormError errorMsg={loginError} />}
        </InputWrapper>

        <Button buttonName="login" additionStyle={{ width: '100%' }} disabled={checkFormValues()}>
          {isProcessing ? 'processing...' : 'Log in'}
        </Button>
      </FormContainer>
      <NotMemberSignUp>
        Don’t have an account?<Link to="/registration"> Sign up here</Link>
      </NotMemberSignUp>
    </FormWrapper>
  );
};

export default LoginForm;
