import { ConfirmationBox, CardMedia, Title, StyledParagraph, SeeFullReportButton } from './styles';
import ResponsiveModal from 'components/organisms/ResponsiveModal';

import verificationImage from 'assets/verification-Image.png';

interface PropTypes {
  open: boolean;
  onClose: () => void;
  onActionClick: () => void;
}

const PaymentConfirmation: React.FC<PropTypes> = ({ open = false, onClose = () => null, onActionClick = () => {} }) => {
  return (
    <ResponsiveModal closeOnOverlayClick closeOnEsc showCloseIcon open={open} modalVisibility={onClose}>
      <ConfirmationBox>
        <CardMedia src={verificationImage} />
        <Title>Thank you for your payment</Title>
        <StyledParagraph>Click the button below to see the full version of the valuation report:</StyledParagraph>
        <SeeFullReportButton
          onClick={() => {
            onActionClick();
            onClose();
          }}
        >
          See the full report
        </SeeFullReportButton>
      </ConfirmationBox>
    </ResponsiveModal>
  );
};

export default PaymentConfirmation;
