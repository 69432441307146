import { AllInvestorsI } from './types';
import isEmpty from 'lodash/isEmpty';

const sumAllUseOfFundValues = (data: { [key: string]: AllInvestorsI }) => {
  return Object.values(data).reduce((acc, val) => {
    if (val.value === '') {
      return acc + 0;
    }
    if (/-/.test(val.value)) {
      return acc + parseFloat(val.value.replace(/-/g, ''));
    }
    return acc + parseFloat(val.value);
  }, 0);
};
export const computeValueAndPercent = (data: any) => {
  const useOfFundsValues = Object.values(data);
  const sumOfAllUseOfFun = sumAllUseOfFundValues(data);
  const list: any = {};

  useOfFundsValues.forEach(({ text, added, id, isRequired, value, isError, valuationQuestionId, ...rest }: any) => {
    let percentageValue;
    if (Number(value) === 0 || isEmpty(value)) {
      percentageValue = 0;
    }
    if (Number(value) > 0 && Number(value) !== 0 && !isEmpty(value)) {
      percentageValue = Math.round((parseFloat(value) / sumOfAllUseOfFun) * 100).toString();
    }

    list[text] = {
      added,
      text,
      id,
      value,
      isRequired,
      isError: isError,
      valuationQuestionId,
      percentageValue: percentageValue,
    };
  });
  return list;
};
