import { Box, Card, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { routesObj } from 'routes/routes';
import classnames from 'classnames';
import { ReactComponent as VCEquationImage } from 'assets/equation-vc.svg';
import { ReactComponent as WarningIcon } from 'assets/warning-icon-grey.svg';
import { commaSeparatedNumber } from 'utils/helperMethod';
import { useStyles } from '../styles';
import { vcReportProps, VcTableItemType } from '../types';
import copy from './copy.json';

const VcReport: React.FC<vcReportProps & { companyValuationId?: number; parentCompanyValuationId?: number }> = ({
  vcReportDetails: {
    companyYear5Amount,
    exitYear,
    investmentSize,
    investorShare,
    medianMultiple,
    medianName,
    postMoneyValuation,
    preMoneyValuation,
  },
  currency,
  companyValuationId,
  parentCompanyValuationId,
  skipFinancialForecast,
}) => {
  const classes = useStyles();
  const isVcAvailable = !skipFinancialForecast;

  const topTableData = ((isAvailable: boolean): VcTableItemType[] => [
    {
      title: 'The Multiple choosen is:',
      value: isAvailable ? medianName : '-',
    },
    {
      title: `Company’s ${medianName} in year 5:`,
      value: isAvailable ? `${currency}${commaSeparatedNumber(Number(companyYear5Amount))}` : '-',
    },
    {
      title: `${medianName} * Multiple:`,
      value: isAvailable ? `${medianMultiple}x` : '-',
    },
    {
      title: 'Exit Year:',
      value: isAvailable ? exitYear : '-',
    },
  ])(isVcAvailable);

  const bottomTableData = ((isAvailable: boolean): VcTableItemType[] => [
    {
      title: 'Post-Money Valuation',
      value: isAvailable ? `${currency}${commaSeparatedNumber(Number(postMoneyValuation))}` : '-',
    },
    {
      title: 'Investment size',
      value: isAvailable ? `${currency}${commaSeparatedNumber(Number(investmentSize))}` : '-',
    },
    {
      title: 'Pre-Money Valuation',
      value: isAvailable ? `${currency}${commaSeparatedNumber(Number(preMoneyValuation))}` : '-',
    },
    {
      title: 'Investor’s share',
      value: isAvailable ? `${investorShare}%` : '-',
    },
  ])(isVcAvailable);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" className={classes.scoreCardValue}>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          VC method
        </Typography>
        <Box mt={2}>
          {isVcAvailable ? (
            <Typography variant="subtitle2" className={classes.cardSubText}>
              The company valuation according to the VC method is&nbsp;
              <span className={classes.greenText}>
                {currency}
                {commaSeparatedNumber(Number(preMoneyValuation))}
              </span>
            </Typography>
          ) : (
            <Box display="flex" alignItems="center">
              <WarningIcon className={classes.spanIcon} />
              <Typography>
                <b>
                  To see the valuation according to the VC method, please{' '}
                  <Link
                    to={{
                      pathname: `${routesObj.questionaries.path}`,
                      state: { companyValuationId, parentCompanyValuationId },
                    }}
                    className={classes.greenText}
                  >
                    complete the Financial Forecasts
                  </Link>{' '}
                  section in the questionnaire.
                </b>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={3} className={classes.scoreCardsSection}>
        <Card className={`${classes.reportCard} ${classes.cardContainer}`}>
          <Box className={classes.cardTextBox}>
            <Typography variant="body1" className={classes.cardSubText}>
              <b>The VC Method</b> calculates a start-up's value by:
            </Typography>
            <Box mt={3} />
            {copy.descriptionList.map((listItemText, index) => (
              <Box display="flex" key={index}>
                <span className={classes.bullet}>{++index}.</span>
                <Typography variant="body1" className={classes.pointText}>
                  {listItemText}
                </Typography>
              </Box>
            ))}
            <Box mt={3} />
            <Typography variant="body1" className={classes.cardSubText}>
              Growthval calculates the valuation by using P/Revenues, EV/EBITDA, P/E multiples and the result is the
              median value of 3.
            </Typography>
            <Box mt={3} />
            {topTableData.map(({ title, value }, index) => {
              return (
                <Box display="flex" key={index} className={classes.vcDataBox}>
                  <Box width="60%" padding="0 18px" className={classes.vcDataText}>
                    <b>{title}</b>
                  </Box>
                  <Box width="40%" padding="0 18px" className={classes.vcDataText}>
                    {value}
                  </Box>
                </Box>
              );
            })}
            <Box mt={3} />
            {bottomTableData.map(({ title, value }, index) => {
              const rightItemClass = classnames(classes.vcDataItem, classes.vcDataText, {
                [classes.topLeftTile]: index === 0,
                [classes.bottomLeftTile]: index === (bottomTableData || []).length - 1,
              });

              const leftItemClass = classnames(classes.vcDataItem, classes.vcDataText, {
                [classes.topRightTile]: index === 0,
                [classes.bottomRightTile]: index === (bottomTableData || []).length - 1,
              });

              return (
                <Box display="flex" key={index}>
                  <Box width="60%" padding="8px 18px" className={rightItemClass}>
                    <b>{title}</b>
                  </Box>
                  <Box width="40%" padding="8px 18px" className={leftItemClass}>
                    {value}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Card>
        <Box display="flex" flexDirection="column" className={classes.columnContainer}>
          <Card className={`${classes.greenCard} ${classes.topCard}`}>
            <Box display="flex" justifyContent="center" className={classes.cardTextBox}>
              <VCEquationImage />
            </Box>
            <Box mt={2} />
          </Card>
          <Card className={classes.bottomCard}>
            <Box className={classes.cardTextBox}>
              <Typography variant="subtitle1" className={classes.pointHeading}>
                Method pros
              </Typography>
              <Box mt={1} />
              {copy.pros.map((prosText, index) => (
                <Box display="flex" key={index}>
                  <span className={classes.bullet}>•</span>
                  <Typography variant="body1" className={classes.pointText}>
                    {prosText}
                  </Typography>
                </Box>
              ))}
              <Box mt={4}>
                <Typography variant="subtitle1" className={classes.pointHeading}>
                  Method cons
                </Typography>
                <Box mt={1} />
                {copy.cons.map((consText, index) => (
                  <Box display="flex" key={index}>
                    <span className={classes.bullet}>•</span>
                    <Typography variant="body1" className={classes.pointText}>
                      {consText}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box mt={3} />
              <Box display="flex" justifyContent="center">
                <Card className={`${classes.noteSection} ${classes.riskNote}`}>
                  <Typography variant="body1" className={classes.notesText}>
                    Data Source for default multiples:&nbsp;
                    <span className={classes.greenText}>
                      <b>Damodaran database</b>
                    </span>
                    <br />
                    You can also select the multiple in settings - if you have accurate data on comparable companies
                    transaction multiples.
                  </Typography>
                </Card>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default VcReport;
