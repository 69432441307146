import * as Yup from 'yup';

export const passwordValidation = Yup.object().shape({
  password: Yup.string().required('Please enter password').min(8, 'Max password length should be 8'),
  confirmPassword: Yup.string()
    .required()
    .label('Confirm password')
    .test('passwords-match', 'Password & confirm password do not match', function (value) {
      return this.parent.password === value;
    }),
});
