import styled, { css } from 'styled-components';
import customTheme from 'theme/customTheme';
const {
  fontFamily: { primaryFont },
} = customTheme;

export const ButtonWrapper = styled.button<{ disabled?: boolean; style: any }>`
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50px;
  height: 46px;
  color: ${(props) => props.theme.colors.white};
  width: fit-content;
  background-color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${primaryFont};
  &:active {
    border: 2px solid ${(props) => props.theme.colors.white};
    transform: translateY(-1px);
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.DarkBlack};
  }
  ${({ style }) =>
    css`
      ${style}
    `}

  ${({ disabled }) =>
    disabled
      ? css`
          color: ${(props) => props.theme.colors.lighterGray};
          cursor: not-allowed;
        `
      : ''};
`;
