import React from 'react';
import CustomButton from 'components/molecules/button';
import CustomInput from 'components/molecules/FormInput';
import { CardDetails, FormSection, Title, SubTitle, Label } from 'style/CommonStyle';
import { Form } from './styles';

interface EmailValidationFormProps {
  submitForm: (e: any) => void;
  userEmail?: string;
  title: string;
  subTitle: string;
  readOnly?: boolean;
  btnText: string;
  helpText?: string;
  validateEmail?: (email: string) => void;
  isDisable?: boolean;
}

const defaultProps = {
  readOnly: false,
  userEmail: '',
  helpText: '',
  validateEmail: () => false,
  isDisable: true,
};

const EmailValidationForm: React.FC<EmailValidationFormProps> = ({
  title,
  subTitle,
  btnText,
  submitForm,
  readOnly,
  userEmail,
  validateEmail,
  helpText,
  isDisable,
}) => {
  return (
    <CardDetails>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      <FormSection>
        <Form onSubmit={submitForm}>
          <Label>Email:</Label>
          <CustomInput
            type="email"
            value={userEmail || ''}
            placeholder="name@company.com"
            readOnly={readOnly}
            handleOnChange={(value) => validateEmail && validateEmail(value)}
            helpText={helpText}
          />
          <CustomButton type="submit" isDisable={isDisable}>
            {btnText}
          </CustomButton>
        </Form>
      </FormSection>
    </CardDetails>
  );
};

EmailValidationForm.defaultProps = defaultProps;

export default EmailValidationForm;
