import { NavLinkType } from './types';
import { capitalize } from 'lodash';

const extractUserName = (storageData: any = {}): string =>
  ['firstName', 'lastName']
    .map((key) => {
      const name = storageData[key] || '';
      return capitalize(name);
    })
    .filter((name) => !!name)
    .join(' ');

export const getUserName = ({ email, ...storageData }: any = {}): string => extractUserName(storageData) || email;

export const createNavLinks = (links: NavLinkType[]): NavLinkType[] =>
  links.map((link: NavLinkType, index) => ({
    ...link,
    id: index,
  }));
