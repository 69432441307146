export const createDeepCopy = (arg = {}) => {
  return JSON.parse(JSON.stringify(arg));
};

const handleSingleValueData = (data = {}) => {
  const [amt = 0] = Object.values(data) || [];
  return { amt };
};

const handleRowData = (data = {}) => {
  const dataArray = Object.entries(data) || [];
  return dataArray.reduce((acc, [id, amt = 0]): any => [...acc, { id, amt }], []);
};

export const handleFinancialForecastResponse = ({ financialForecastQuestionsResponse = [] }: any = {}): any => {
  return financialForecastQuestionsResponse.map(({ slug, value }: any): any => {
    const isSingleValue = ['cash', 'borrowings'].some((singleValueSlug) => slug === singleValueSlug);
    const values = isSingleValue ? handleSingleValueData(value) : handleRowData(value);
    return { slug, values };
  });
};
