import MainNav from 'components/organisms/MainNav';
import { NavLinkType } from 'components/organisms/MainNav/types';
import { Wrapper } from './styles';

interface PropTypes {
  links?: NavLinkType[];
  options?: NavLinkType[];
  children: React.ReactNode;
}

const NavContainer: React.FC<PropTypes> = ({ links, options, children }) => (
  <Wrapper>
    <MainNav links={links} options={options} />
    {children}
  </Wrapper>
);

export default NavContainer;
