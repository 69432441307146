import styled from 'styled-components';
import customTheme from 'theme/customTheme';

const {
  colors: { white, reportSectionCardsShadow, gray6 },
  borderRadius: { reportSectionBorderRadius },
  fontFamily: { primaryFont },
} = customTheme;

export const DisclaimerBox = styled.section`
  margin-bottom: 287px;
  && {
    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-bottom: 82px;
    }
  }
`;
export const DisclaimerText = styled.div`
  font-family: ${primaryFont};
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  height: 338px;
  color: ${gray6};
  background: ${white};
  box-shadow: ${reportSectionCardsShadow};
  border-radius: ${reportSectionBorderRadius};
  padding: 40px 30px;
  font-size: 16px;
  margin-top: 21px;
  && {
    ${({ theme }) => theme.breakpoints.down('md')} {
      height: auto;
      padding: 35px 20px;
    }
  }

  & > p {
    margin-bottom: 24px;
  }
`;
