import styled from 'styled-components';

export const logoStyle = { margin: '0 auto 37px auto', width: '187px' };

export const RegistrationConfirmationBox = styled.section`
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TextBox = styled.div`
  height: 232px;
  width: 392px;
  border-radius: 8px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
  padding: 30px 43px;

  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      max-width: 335px;
      padding: 30px 10px;
    }
  }
`;
export const ParagraphBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 55%;
`;

export const StyledParagraph = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #171c1b;
  text-align: center;
`;
