import React, { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Box } from '@material-ui/core';
import CustomButton from 'components/molecules/button';
import CenterlizeBox from 'components/organisms/CenterlizedBox';
import FullscreenLoader from 'components/layout/Loading/ViewPortLoader';
import { CardDetails, Error, SubTitle } from 'style/CommonStyle';
import { MarketingEmailSubscription } from 'utils/ApiUrls';
import useLoadingContext from 'components/layout/Loading/useLoadingContext';
import { ResponseType, UnsubscribeProps } from './types';

const Unsubscribe: React.FC<UnsubscribeProps> = (value) => {
  const [serverError, setSeverError] = useState('');
  const [unsubscribeStatus, setUnsubscribeStatus] = useState(true);
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    emailMarketingSubscription(true);
  }, []);

  const emailMarketingSubscription = (subscriptionStatus: boolean) => {
    setLoading(true);
    const { userId, token } = value?.match?.params;

    axios
      .post<AxiosResponse>(MarketingEmailSubscription(userId), {
        unsubscribeToken: token,
        unsubscribeNotification: subscriptionStatus,
      })
      .then((res: ResponseType) => {
        if (res.status === 204) {
          setLoading(false);
        }
      })
      .catch((error) => {
        setSeverError(error.message);
      });
  };

  return (
    <FullscreenLoader>
      <CenterlizeBox>
        <CardDetails>
          <Box mt={2} />
          {unsubscribeStatus ? (
            <>
              <SubTitle>You've successfully been unsubscribed from the Growthval marketing channel messages.</SubTitle>
              <SubTitle>Don't mean to unsubscribe?</SubTitle>
              <Error>{serverError && serverError}</Error>
              <CustomButton
                type="button"
                onClick={() => {
                  emailMarketingSubscription(false);
                  setUnsubscribeStatus(false);
                }}
              >
                Resubscribe
              </CustomButton>
            </>
          ) : (
            <Box margin="69px auto" display="flex">
              <SubTitle>Thanks for resubscribing for marketing emails!</SubTitle>
            </Box>
          )}
        </CardDetails>
      </CenterlizeBox>
    </FullscreenLoader>
  );
};

export default Unsubscribe;
