import React, { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Bar } from 'react-chartjs-2';
import CardColumnSection from 'components/organisms/CardColumsSection';
import { commaSeparatedNumber } from 'utils/helperMethod';
import customTheme from 'theme/customTheme';
import { ScoreCardReportProps, GraphData, ColumnsData, WeightDataTypes } from './types';

const ScoreCardReport: React.FC<ScoreCardReportProps> = ({ scoreCardDetails, currency }) => {
  const [graphData, setGraphData] = useState<GraphData>();
  const [weights, setWeights] = useState<ColumnsData[] | any>();
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    const dataRange: number[] = [];
    const labels: string[] = [];
    const weightsData: WeightDataTypes[] = [];
    if (isEmpty(scoreCardDetails)) {
      return;
    }
    scoreCardDetails.scorecardCategoriesValuation.forEach((item) => {
      dataRange.push(Number(item.categoryWeightedImpact - 100));
      labels.push(item.name);
      weightsData.push({
        title: item.name,
        value: `${Number(item.defaultWeight)}%`,
      });
    });
    setWeights(weightsData);
    setLabels(labels);
    if (dataRange.length > 0) {
      setGraphData({
        labels,
        datasets: [
          {
            backgroundColor: '#00CAA6',
            data: dataRange,
          },
        ],
      });
    }
  }, [scoreCardDetails]);

  const callBarGraph = useCallback(() => {
    return (
      <Bar
        type="bar"
        data={graphData}
        options={{
          animations: {
            tension: {
              duration: 0,
            },
          },
          indexAxis: 'y',
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              grid: {
                display: false,
              },
              ticks: {
                autoSkip: false,
                font: {
                  size: 8,
                  family: 'Mulish',
                  weight: 400,
                },
                padding: 12,
                callback: function (value: number, index: any, ticks: any) {
                  if (/\s/.test(labels[index])) {
                    return labels[index].split('\n');
                  } else {
                    return labels[index];
                  }
                },
              },
            },
            x: {
              ticks: {
                callback: function (value: number) {
                  return `${value}%`;
                },
              },
            },
          },
        }}
      />
    );
  }, [graphData, labels]);

  const { scorecardWeightedAmount } = scoreCardDetails;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <h2
            style={{
              fontFamily: 'Prompt',
              fontWeight: 700,
              fontStyle: 'normal',
              color: '#171C1B',
              fontSize: '32px',
              lineHeight: '43px',
              wordBreak: 'break-word',
            }}
          >
            Scorecard method
          </h2>
        </div>
        <div style={{ marginTop: '16px' }}>
          <p
            style={{
              fontFamily: 'Mulish',
              fontWeight: 700,
              fontStyle: 'normal',
              color: '#171C1B',
              fontSize: '16px',
              lineHeight: '21px',
              wordBreak: 'break-word',
            }}
          >
            The company valuation according to the Scorecard method is&nbsp;
            <span style={{ color: customTheme.colors.MediumSpringGreen }}>
              {currency}
              {commaSeparatedNumber(Number(scorecardWeightedAmount))}
            </span>
          </p>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
        <div
          style={{
            overflow: 'hidden',
            boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
            borderRadius: '4px',
            color: 'rgba(0, 0, 0, 0.87)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: '#fff',
            width: '49%',
            height: 'auto',
            minHeight: '650px',
          }}
          className="tilesBoxShadow"
        >
          <div style={{ padding: '25px 35px 17px 35px' }}>
            <p
              style={{
                fontFamily: 'Mulish',
                fontWeight: 700,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '16px',
                lineHeight: '21px',
                wordBreak: 'break-word',
              }}
            >
              The scorecard valuation method, developed by angel investor Bill Payne, is used to value early-stage
              startups.
            </p>
            <div style={{ marginTop: '32px' }} />
            <p
              style={{
                fontFamily: 'Mulish',
                fontWeight: 400,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '16px',
                lineHeight: '21px',
                wordBreak: 'break-word',
              }}
            >
              Under this method, we compare your startup with similar startups in your location, industry, stage (where
              sufficient data is publicly available) by asking a set of questions under 6 main categories. Based on the
              answers, your company is assigned a score, which is used to calculate a normalized impact on the category
              score, considering the stage of your business.
            </p>
            <div style={{ marginTop: '32px' }} />
            <p
              style={{
                fontFamily: 'Mulish',
                fontWeight: 700,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '16px',
                lineHeight: '21px',
                wordBreak: 'break-word',
              }}
            >
              We then calculate factors in 6 categories by multiplying your company’s category score with the
              corresponding weights.&nbsp;
              <p
                style={{
                  fontFamily: 'Mulish',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '16px',
                  lineHeight: '21px',
                  wordBreak: 'break-word',
                }}
              >
                The sum of the factors is multiplied with our estimate of the average valuation of startups in your
                location, industry, stage to determine your startup’s valuation or with the valuation provided by the
                you in the Valuation Parameters.
              </p>
            </p>
            <div style={{ marginTop: '16px' }}>
              <h3
                style={{
                  fontFamily: 'Prompt',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '18px',
                  lineHeight: '21px',
                  wordBreak: 'break-word',
                }}
              >
                Method pros
              </h3>
              <div style={{ marginTop: '8px' }} />
              <div style={{ display: 'flex' }}>
                <span style={{ paddingRight: 10 }}>•</span>
                <p
                  style={{
                    fontFamily: 'Mulish',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    color: '#171C1B',
                    fontSize: '15px',
                    lineHeight: '21px',
                  }}
                >
                  Takes into account a wide range of qualitative aspects of startups
                </p>
              </div>
            </div>
            <div style={{ marginTop: '16px' }}>
              <h3
                style={{
                  fontFamily: 'Prompt',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '18px',
                  lineHeight: '21px',
                  wordBreak: 'break-word',
                }}
              >
                Method cons
              </h3>
              <div style={{ marginTop: '8px' }} />
              <div style={{ display: 'flex' }}>
                <span style={{ paddingRight: 10 }}>•</span>
                <p
                  style={{
                    fontFamily: 'Mulish',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    color: '#171C1B',
                    fontSize: '15px',
                    lineHeight: '21px',
                  }}
                >
                  Dependent on the availability and quality of baseline valuation data
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            overflow: 'hidden',
            boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
            borderRadius: '4px',
            color: 'rgba(0, 0, 0, 0.87)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: '#fff',
            width: '49%',
            height: 'auto',
            minHeight: '650px',
          }}
          className="tilesBoxShadow"
        >
          <div style={{ display: 'flex', flexDirection: 'column', padding: '25px 35px 17px 35px' }}>
            <p
              style={{
                fontFamily: 'Mulish',
                fontWeight: 700,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '15px',
                lineHeight: '21px',
              }}
            >
              The graph below represents a summary of how your startup scores compared to similar startup's scores:
            </p>
            <div style={{ marginBottom: '16px' }} />
            <div style={{ width: '100%' }}>{callBarGraph()}</div>
            <div style={{ marginTop: '24px' }}>
              <CardColumnSection
                firstTitle="Comparison category"
                secondTitle="Weight"
                data={weights || []}
                totalValue="100%"
                mobileWidthFirstCol="75%"
                mobileWidthSecondCol="24%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreCardReport;
