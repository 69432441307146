import { useState, ChangeEvent } from 'react';
import FormInput from 'components/molecules/FormInput/FormInput';
import { PaymentUrl } from 'utils/ApiUrls';
import usePaymentModal from 'components/templates/payment/usePaymentModal';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import PayButton from 'components/molecules/FormButton/FormButton';
import api from 'utils/api';
import { isValidPostCode, isEmpty } from './validationHelper';
import { subscriptionCost } from 'utils/constants';

import {
  FieldStyle,
  buttonStyle,
  errorStyle,
  PaymentFormWrapper,
  FormTitle,
  ErrorBox,
  DisplayOnWidth768,
  FlexContainer,
  FlexContainerWithMargin,
  InputFieldBox,
  CardNumberBox,
  ZipCodeInputBox,
  CardExpirationBox,
  CardCvcBox,
} from './styles';
import FormError from 'components/molecules/formError/FormError';
import { getLocalStorageItem } from 'utils/getLocalStorageItem';

const PaymentForm: React.FC = (): JSX.Element => {
  const { setSuccessModal } = usePaymentModal();
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string>('');
  const [billingDetails, setBillingDetails] = useState({
    firstName: '',
    lastName: '',
    zipCode: '',
  });

  const [formError, setFormError] = useState({
    firstNameError: '',
    lastNameError: '',
    zipCodeError: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  });

  const [cardNumberIsCompleted, setCardNumberIsCompleted] = useState(false);
  const [cardExpiryIsCompleted, setCardExpiryIsCompleted] = useState(false);
  const [cardCvcIsCompleted, setCardCvcIsCompleted] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setBillingDetails({ ...billingDetails, [name]: value.trim() });
  };

  // allow spaces for postcodes
  const handlePostcodeChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setBillingDetails({ ...billingDetails, [name]: value });
  };

  const { firstNameError, lastNameError, zipCodeError, cardCvc, cardNumber, cardExpiry } = formError;

  const checkFormInputs = (): boolean => {
    return !(
      firstName.length > 1 &&
      lastName.length > 1 &&
      cardCvcIsCompleted &&
      cardExpiryIsCompleted &&
      cardNumberIsCompleted
    );
  };

  const checkIfFormHasAnyError = () => {
    return (
      firstNameError.length > 0 ||
      lastNameError.length > 0 ||
      zipCodeError.length > 2 ||
      cardCvc.length > 3 ||
      cardExpiry.length > 2 ||
      cardNumber.length > 2
    );
  };

  const handleCardError = (e: any) => {
    setFormError({
      ...formError,
      [e.elementType]: e.error?.message.trim() || '',
    });
  };

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email } = getLocalStorageItem('growthVal');

    const Details = {
      email,
      name: `${firstName} ${lastName}`,
      address: {
        postal_code: zipCode,
      },
    };

    if (!stripe || !elements) return;
    setProcessing(true);

    try {
      const cardNumberElement = elements.getElement(CardNumberElement);
      if (!cardNumberElement) throw new Error('Oops! Something went wrong!');

      const { error: paymentError, paymentMethod: { id: paymentId = '' } = {} } =
        (await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumberElement,
          billing_details: Details,
        })) || {};

      if (paymentError || !paymentId) throw new Error('Oops! Something went wrong!');
      const { data: { success = false, data: { clientSecret = '' } = {} } = {} } = await api.post(PaymentUrl, {
        amount: subscriptionCost,
        paymentMethod: paymentId,
      });

      if (!success) throw new Error('Oops! Something went wrong!');
      const { error: { message: errorMessage = '' } = {} } =
        (await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardNumberElement,
          },
        })) || {};

      if (errorMessage) throw new Error(errorMessage);
      setSuccessModal(true);
    } catch (error) {
      const { message: errorMessage = '' } = error || {};
      setPaymentErrorMessage(errorMessage || 'Error occured while processing the payment');
    } finally {
      setProcessing(false);
    }
  };

  const { firstName, lastName, zipCode } = billingDetails;

  const validateZipCode = () => {
    if (isValidPostCode(zipCode)) {
      setFormError({ ...formError, zipCodeError: '' });
      return;
    }
    setFormError({ ...formError, zipCodeError: 'please enter a valid post code' });
  };

  return (
    <PaymentFormWrapper onSubmit={handleSubmit}>
      <FormTitle>Enter your credit card details:</FormTitle>
      <FlexContainer>
        <InputFieldBox>
          <FormInput
            type="text"
            placeholder="First name"
            onChange={handleChange}
            value={firstName}
            name="firstName"
            inputFieldStyle={FieldStyle}
            hideLabel={true}
            isError={firstNameError.length > 0}
            onBlur={() => {
              setFormError({ ...formError, firstNameError: isEmpty(firstName) });
            }}
          />
        </InputFieldBox>
        {firstNameError && <DisplayOnWidth768>{firstNameError} </DisplayOnWidth768>}
        <InputFieldBox>
          <FormInput
            type="text"
            placeholder="Last name"
            value={lastName}
            onChange={handleChange}
            name="lastName"
            inputFieldStyle={FieldStyle}
            hideLabel={true}
            isError={lastNameError.length > 0}
            onBlur={() => {
              setFormError({ ...formError, lastNameError: isEmpty(lastName) });
            }}
          />
        </InputFieldBox>
        {lastNameError && <DisplayOnWidth768>{lastNameError} </DisplayOnWidth768>}
      </FlexContainer>
      <ErrorBox>
        {<FormError errorStyle={errorStyle} errorMsg={firstNameError} />}
        {<FormError errorStyle={errorStyle} errorMsg={lastNameError} />}
      </ErrorBox>
      <div>
        <CardNumberBox cardNumberError={formError.cardNumber}>
          <CardNumberElement
            onChange={(e) => {
              handleCardError(e);
              setCardNumberIsCompleted(e.complete);
            }}
          />
        </CardNumberBox>
        {formError.cardNumber && <FormError errorMsg={formError.cardNumber} />}
      </div>

      <FlexContainerWithMargin>
        <CardExpirationBox cardExpiryError={formError.cardExpiry}>
          <CardExpiryElement
            onChange={(e) => {
              handleCardError(e);
              setCardExpiryIsCompleted(e.complete);
            }}
          />
        </CardExpirationBox>

        <CardCvcBox cardCvcError={formError.cardCvc}>
          <CardCvcElement
            onChange={(e) => {
              handleCardError(e);
              setCardCvcIsCompleted(e.complete);
            }}
          />
        </CardCvcBox>
        <ZipCodeInputBox>
          <FormInput
            type="text"
            placeholder="Postcode"
            name="zipCode"
            value={zipCode}
            inputFieldStyle={FieldStyle}
            onChange={handlePostcodeChange}
            hideLabel={true}
            isError={zipCodeError.length > 0}
            onBlur={validateZipCode}
          />
        </ZipCodeInputBox>
      </FlexContainerWithMargin>
      <PayButton
        additionStyle={buttonStyle}
        disabled={processing || !stripe || checkFormInputs() || checkIfFormHasAnyError()}
      >
        {processing ? 'processing...' : `Pay £${subscriptionCost.toFixed(2)}`}
      </PayButton>
      {paymentErrorMessage && <FormError errorMsg={paymentErrorMessage} errorStyle={{ textAlign: 'center' }} />}
      {formError.cardCvc && <FormError errorMsg={formError.cardCvc} />}
      {formError.cardExpiry && <FormError errorMsg={formError.cardExpiry} />}
      {zipCodeError && <FormError errorMsg={zipCodeError} />}
    </PaymentFormWrapper>
  );
};

export default PaymentForm;
