import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import CustomInput from 'components/molecules/FormInput';
import classnames from 'classnames';
import { useStyles } from './styles';
import { DesktopStagesProps } from './types';
import { toCamelCase, getWeightSum, notAllowNegativeValue } from './helper';

const DesktopViewTable: React.FC<DesktopStagesProps> = ({
  stagesData,
  setFieldValue,
  touched,
  errors,
  handleBlur,
  values,
  setTotalStagesWeight,
  noNegativeValue,
  setNegativeValue,
}) => {
  const classes = useStyles();
  const [updatingWeights, setUpdatingWeights] = useState(false);

  useEffect(() => {
    const { scorecardMethod, berkusMethod, riskFactorSummationMethod, vCMethod, dCFMethod } = values;
    const isUpdatingWeight =
      !isEmpty(scorecardMethod) ||
      !isEmpty(berkusMethod) ||
      !isEmpty(riskFactorSummationMethod) ||
      !isEmpty(vCMethod) ||
      !isEmpty(dCFMethod);
    setUpdatingWeights(isUpdatingWeight);
  }, [values]);

  const renderTotalUserInputWeights: any = useCallback(() => {
    const { scorecardMethod, berkusMethod, riskFactorSummationMethod, vCMethod, dCFMethod } = values;
    if (
      (scorecardMethod === null || scorecardMethod === '') &&
      (berkusMethod === null || berkusMethod === '') &&
      (riskFactorSummationMethod === null || riskFactorSummationMethod === '') &&
      (vCMethod === null || vCMethod === '') &&
      (dCFMethod === null || dCFMethod === '')
    ) {
      setTotalStagesWeight(0);
      return <Typography variant="subtitle2">-</Typography>;
    }
    const sum = getWeightSum(values);
    setTotalStagesWeight(sum);
    if (sum > 100) {
      return (
        <Typography variant="subtitle2" className={classes.sumError}>
          {sum}%
        </Typography>
      );
    }
    if (sum === 100) {
      return (
        <Typography variant="subtitle2" className={classes.sumGreen}>
          {sum}%
        </Typography>
      );
    }
    return (
      <Typography variant="subtitle2" className={classes.weightSumValue}>
        {sum}%
      </Typography>
    );
  }, [values]);

  const renderWeightInputFields = useMemo(() => {
    if (isEmpty(stagesData)) return <></>;

    const response = stagesData.map(({ valuationMethodName, valuationMethodWeights }, index) => {
      const inputName = toCamelCase(valuationMethodName);
      return (
        <Box display="flex" justifyContent="space-between" className={classes.weights} key={index}>
          <Box>
            <Typography variant="subtitle2" className={classes.weightTitle}>
              {valuationMethodName}
            </Typography>
          </Box>
          <Box display="flex">
            {!isEmpty(valuationMethodWeights) &&
              valuationMethodWeights.map(({ weightPercentage }, key) => {
                return (
                  <Box className={classes.weightValue} key={key}>
                    <Typography
                      variant="body1"
                      className={classnames({
                        [classes.grayText]: updatingWeights,
                        [classes.percentageText]: !updatingWeights,
                      })}
                    >
                      {weightPercentage}%
                    </Typography>
                  </Box>
                );
              })}
            <Box>
              <CustomInput
                name={inputName}
                type="number"
                borderRadius="0"
                marginTop="0"
                width="60px"
                height="37px"
                padding="11px 12px"
                value={values[inputName] || ''}
                handleOnChange={(data: string | number) => {
                  if (noNegativeValue) {
                    setFieldValue(inputName, data, false);
                  }
                }}
                onKeyPress={(e) => setNegativeValue(notAllowNegativeValue(e))}
              />
              &nbsp;&nbsp;%
            </Box>
          </Box>
        </Box>
      );
    });
    return response;
  }, [values, stagesData, setFieldValue, classes, updatingWeights, setNegativeValue, noNegativeValue]);
  return (
    <Box display="flex" flexDirection="column">
      <Card className={classes.stagesLabel}>
        <Box display="flex" justifyContent="flex-end">
          <Box className={classes.stagesLabelSection}>
            <Typography variant="subtitle2">Pre-seed</Typography>
          </Box>
          <Box className={classes.stagesLabelSection}>
            <Typography variant="subtitle2">Seed</Typography>
          </Box>
          <Box className={classes.stagesLabelSection}>
            <Typography variant="subtitle2">Series A</Typography>
          </Box>
          <Box className={classes.stagesLabelSection}>
            <Typography variant="subtitle2">Series B</Typography>
          </Box>
          <Box className={classes.stagesLabelSection}>
            <Typography variant="subtitle2">Method weights</Typography>
          </Box>
        </Box>
      </Card>
      <Card className={classes.valuationWeight}>
        {renderWeightInputFields}
        <Box display="flex" justifyContent="space-between" className={classes.weights}>
          <Box>
            <Typography variant="subtitle2" className={classes.weightTitle}>
              Total
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" width="61%">
            <Box className={classes.totalValue}>
              <Typography variant="subtitle2">100%</Typography>
            </Box>
            <Box className={classes.totalValue}>
              <Typography variant="subtitle2">100%</Typography>
            </Box>
            <Box className={classes.totalValue}>
              <Typography variant="subtitle2">100%</Typography>
            </Box>
            <Box className={classnames({ [classes.totalValue]: true, [classes.seriesB]: true })}>
              <Typography variant="subtitle2">100%</Typography>
            </Box>
            <Box width="60px" className={classes.totalCount}>
              {renderTotalUserInputWeights()}
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default DesktopViewTable;
