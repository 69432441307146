import { Typography } from '@material-ui/core';
import { BoldSpan } from '../styles';

interface PropTypes {
  text: string | string[];
}

const Text: React.FC<PropTypes> = ({ text }) => {
  // if a string array is passed, make first line bold
  if (Array.isArray(text)) {
    const [boldText, regularText] = text;
    return (
      <Typography align="center">
        <BoldSpan>{boldText}</BoldSpan> {regularText}
      </Typography>
    );
  }

  return <Typography align="center">{text}</Typography>;
};

export default Text;
