import { makeStyles, Theme } from '@material-ui/core/styles';

interface InputStyles {
  borderColor?: string;
  padding?: string;
  bgColor?: string;
  marginTop?: string;
  borderRadius?: string;
  width?: string;
  height?: string;
  mobileWidth?: string;
}

export const useStyles = makeStyles<Theme, InputStyles>((theme) => ({
  inputField: {
    border: ({ borderColor }) => `1px solid ${borderColor || '#C2C2C2'}`,
    borderRadius: ({ borderRadius }) => borderRadius,
    padding: ({ padding }) => padding,
    marginTop: ({ marginTop }) => marginTop,
    background: ({ bgColor }) => bgColor,
    width: ({ width }) => width,
    height: ({ height }) => height,
    [theme.breakpoints.down('md')]: {
      width: ({ mobileWidth }) => mobileWidth,
    },
  },
}));
