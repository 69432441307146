import { useEffect } from 'react';

export const COMMA_NUMBER_INPUT_REGEX = /^$|^\d(\d|,)*$/;
export const COMMA_NEGATIVE_NUMBER_INPUT_REGEX = /^$|^-?(\d|,)*$/;
export const COMMA_NUMBER_REGEX = /\B(?=(\d{3})+(?!\d))/g;

export const isValidEmail = (email: string): boolean => {
  if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
    return false;
  }
  return true;
};

export const commaSeparatedNumber = (data: number): string => {
  return data.toString().replace(COMMA_NUMBER_REGEX, ',');
};

export const getAmountString = (amount: string | number | null, currencySymbol: string): string => {
  const amountNumber = Number(amount);
  if (isNaN(amountNumber)) return '';

  const amountString = commaSeparatedNumber(amountNumber);
  return amountNumber >= 0 ? `${currencySymbol}${amountString}` : `-${currencySymbol}${amountString.slice(1)}`;
};

export const removeCommasFromNumber = (value: string | number): string => `${value}`.replace(/,/gi, '');

export const formatInputWithCommas = (inputValue: number | string): string => {
  // remove commas
  const value = removeCommasFromNumber(inputValue);

  // cut the decimal part
  const wholeValue = value.replace(/\.\d*/, '');

  // reaply commas
  return wholeValue.replace(COMMA_NUMBER_REGEX, '$&,');
};

export const handleCommaNumberChange = (
  value: string | number,
  callback: (newValue: number | string) => void,
  regex: RegExp = COMMA_NUMBER_INPUT_REGEX,
): void => {
  const stringValue = `${value}`;
  if (regex.test(stringValue)) {
    const newValue = removeCommasFromNumber(stringValue);
    return callback(newValue);
  }
};

export const logoutUser = () => {
  localStorage.removeItem('growthVal');
  window.location.assign('/');
};

export const calculateDCFGrowthRate = (lastYearRev: number, year5Rev: number): number => {
  if (year5Rev / lastYearRev === Infinity) {
    return 0;
  }
  return (year5Rev / lastYearRev) ** (1 / 5) - 1 || 0;
};

export const useGlobalClickHandler = (callback: () => void): void => {
  useEffect(() => {
    document.addEventListener('click', callback);
    return () => {
      document.removeEventListener('click', callback);
    };
  });
};
