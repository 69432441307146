interface validateFormDataArg {
  businessType: string;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
}

interface formErrorTypes {
  businessTypeError: string;
  firstNameError: string;
  lastNameError: string;
  passwordError: string;
  emailError: string;
}

export const validateFormData = (formFields: validateFormDataArg): formErrorTypes => {
  const formError = {
    businessTypeError: '',
    firstNameError: '',
    lastNameError: '',
    passwordError: '',
    emailError: '',
  };

  //The regular expression below will match a standard email address : user@domain.extension (coyote@acme.com)
  const emailRegEx = /^[^@&)(:;/?,][a-z0-9_%-.]+@{1}\w+\.[a-z0-9_%-.]+[^@&)(:;/?,.]$/i;

  if (formFields.businessType.trim().length === 0) {
    formError.businessTypeError = 'Please select a business category';
  }
  if (!emailRegEx.test(formFields.email.trim())) {
    formError.emailError = 'Please enter a valid email';
  }

  if (formFields.firstName.trim().length === 0) {
    formError.firstNameError = 'This field is required';
  }

  if (formFields.lastName.trim().length === 0) {
    formError.lastNameError = 'This field is required';
  }

  const trimedpassword = formFields.password.trim();
  if (trimedpassword.length <= 7) {
    formError.passwordError = 'Password should be at least 8 characters';
  }
  return formError;
};
