import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import customTheme from 'theme/customTheme';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CustomInput from 'components/molecules/FormInput';
import { useStyles } from './styles';
import { preSeed, seed, seriesA, seriesB } from './constants';
import { MobileStagesDataProps, WeightTypes } from './types';
import { sendMobileFieldData, getWeightSum, notAllowNegativeValue } from './helper';

const MobileViewTable: React.FC<MobileStagesDataProps> = ({
  mobileViewStagesData,
  setFieldValue,
  touched,
  errors,
  handleBlur,
  values,
  setNegativeValue,
  noNegativeValue,
  setTotalStagesWeight,
}) => {
  const classes = useStyles();
  const [currentStage, setCurrentStage] = useState(preSeed);

  const renderTotalUserInputWeights: any = useCallback(() => {
    const { scorecardMethod, berkusMethod, riskFactorSummationMethod, vCMethod, dCFMethod } = values;
    if (
      (scorecardMethod === null || scorecardMethod === '') &&
      (berkusMethod === null || berkusMethod === '') &&
      (riskFactorSummationMethod === null || riskFactorSummationMethod === '') &&
      (vCMethod === null || vCMethod === '') &&
      (dCFMethod === null || dCFMethod === '')
    ) {
      return <Typography variant="subtitle2">-</Typography>;
    }
    const sum = getWeightSum(values);
    setTotalStagesWeight(sum);
    if (sum > 100) {
      return (
        <Typography variant="subtitle2" className={classes.sumError}>
          {sum}%
        </Typography>
      );
    }
    if (sum === 100) {
      return (
        <Typography variant="subtitle2" className={classes.sumGreen}>
          {sum}%
        </Typography>
      );
    }
    return (
      <Typography variant="subtitle2" className={classes.weightSumValue}>
        {sum}%
      </Typography>
    );
  }, [values]);

  const getWeights = (data: WeightTypes[]) => {
    return data.map(({ name, weight }, index) => (
      <Box key={index} display="flex" justifyContent="space-between" mt={2} className={classes.mobileWeightSection}>
        <Box mt={1}>
          <Typography variant="subtitle2" className={classes.questionText}>
            {name}
          </Typography>
        </Box>
        <Box className={classes.mobileWeightValue}>
          <Typography variant="body1">{weight}%</Typography>
        </Box>
      </Box>
    ));
  };
  const renderWeightSection = () => {
    switch (currentStage) {
      case preSeed:
        return (
          <Box>
            <Box className={classes.industryStages} display="flex" justifyContent="center">
              <ArrowBackIcon
                style={{
                  color: Number(currentStage - 1) > preSeed ? customTheme.colors.white : customTheme.colors.ligthGreen,
                }}
                onClick={() => {
                  if (Number(currentStage - 1) > preSeed) {
                    setCurrentStage(Number(currentStage - 1));
                  }
                }}
              />
              <Box className={classes.stagesLabelSection}>
                <Typography variant="subtitle2">Pre-seed</Typography>
              </Box>
              <ArrowForwardIcon
                style={{ color: customTheme.colors.white }}
                onClick={() => {
                  if (Number(currentStage + 1) < seriesB) {
                    setCurrentStage(Number(currentStage + 1));
                  }
                }}
              />
            </Box>
            <Box>{getWeights(mobileViewStagesData[0]['Pre-seed'])}</Box>
          </Box>
        );
      case seed:
        return (
          <Box>
            <Box className={classes.industryStages} display="flex" justifyContent="center">
              <ArrowBackIcon
                style={{ color: customTheme.colors.white }}
                onClick={() => setCurrentStage(Number(currentStage - 1))}
              />
              <Box className={classes.stagesLabelSection}>
                <Typography variant="subtitle2">Seed</Typography>
              </Box>
              <ArrowForwardIcon
                style={{ color: customTheme.colors.white }}
                onClick={() => setCurrentStage(Number(currentStage + 1))}
              />
            </Box>
            <Box>{getWeights(mobileViewStagesData[1]['Seed'])}</Box>
          </Box>
        );
      case seriesA:
        return (
          <Box>
            <Box className={classes.industryStages} display="flex" justifyContent="center">
              <ArrowBackIcon
                style={{ color: customTheme.colors.white }}
                onClick={() => setCurrentStage(Number(currentStage - 1))}
              />
              <Box className={classes.stagesLabelSection}>
                <Typography variant="subtitle2">Series A</Typography>
              </Box>
              <ArrowForwardIcon
                style={{ color: customTheme.colors.white }}
                onClick={() => setCurrentStage(Number(currentStage + 1))}
              />
            </Box>
            <Box>{getWeights(mobileViewStagesData[2]['Series-A'])}</Box>
          </Box>
        );
      case seriesB:
        return (
          <Box>
            <Box className={classes.industryStages} display="flex" justifyContent="center">
              <ArrowBackIcon
                style={{ color: customTheme.colors.white }}
                onClick={() => {
                  if (Number(currentStage - 1) !== 0) {
                    setCurrentStage(Number(currentStage - 1));
                  }
                }}
              />
              <Box className={classes.stagesLabelSection}>
                <Typography variant="subtitle2">Series B</Typography>
              </Box>
              <ArrowForwardIcon
                style={{
                  color: Number(currentStage + 1) < seriesB ? customTheme.colors.white : customTheme.colors.ligthGreen,
                }}
                onClick={() => {
                  if (Number(currentStage + 1) < seriesB) {
                    setCurrentStage(Number(currentStage + 1));
                  }
                }}
              />
            </Box>
            <Box>{getWeights(mobileViewStagesData[3]['Series-B'])}</Box>
          </Box>
        );
      default:
        return;
    }
  };

  if (isEmpty(mobileViewStagesData)) return <></>;
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column">
        {renderWeightSection()}
        <Box display="flex" justifyContent="space-between" mt={2} className={classes.mobileWeightSection}>
          <Box mt={1}>
            <Typography variant="subtitle2" className={classes.questionText}>
              Total
            </Typography>
          </Box>
          <Box className={classes.mobileWeightValue}>
            <Typography variant="body1">100%</Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={4} display="flex" flexDirection="column">
        <Typography variant="subtitle1" className={classes.questionLabel}>
          Edit method weights
        </Typography>
        <>
          {!isEmpty(sendMobileFieldData()) &&
            sendMobileFieldData().map(({ name, label, id }) => {
              return (
                <Box
                  key={id}
                  display="flex"
                  justifyContent="space-between"
                  mt={2}
                  className={classes.mobileWeightSection}
                >
                  <Box mt={1}>
                    <Typography variant="subtitle2" className={classes.questionText}>
                      {label}
                    </Typography>
                  </Box>
                  <Box>
                    <CustomInput
                      name={name}
                      type="number"
                      borderRadius="0"
                      marginTop="0"
                      width="60px"
                      height="37px"
                      value={values[name] || ''}
                      handleOnChange={(data: string | number) => {
                        if (noNegativeValue) {
                          setFieldValue(name, data, false);
                        }
                      }}
                      handleBlur={handleBlur}
                      onKeyPress={(e) => setNegativeValue(notAllowNegativeValue(e))}
                    />
                    &nbsp;&nbsp;%
                  </Box>
                </Box>
              );
            })}
          <Box display="flex" justifyContent="space-between" mt={2} className={classes.mobileWeightSection}>
            <Box mt={1}>
              <Typography variant="subtitle2" className={classes.questionText}>
                Total
              </Typography>
            </Box>
            <Box>{renderTotalUserInputWeights()}</Box>
          </Box>
        </>
      </Box>
    </Box>
  );
};

export default MobileViewTable;
