import { Paper } from '@material-ui/core';
import { ReactComponent as CrossIcon } from 'assets/cross-icon-gray.svg';
import styled from 'styled-components';

export const StyledPaper = styled(Paper)`
  padding: 44px 20px 20px 20px;
  && {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      margin: 20px;
    }
  }
`;

export const StyledCloseIcon = styled(CrossIcon)`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  height: 24px;
  width: 24px;
  padding: 5px;
`;
