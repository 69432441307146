import { MethodWeightsTypes, StagesTypes, MobileInputFieldType, FormValuesPair } from './types';

export const getParseMobileData = (data: MethodWeightsTypes[]): StagesTypes[] => {
  const output: StagesTypes[] = data[0].valuationMethodWeights.map((val, index) => {
    const obj: StagesTypes = {};
    obj[val.companyStage?.name] = getValuationWeigths(index, data);
    return obj;
  });
  return output;
};

const getValuationWeigths = (index: number, data: MethodWeightsTypes[]) => {
  return data.map((val) => {
    return {
      name: val.valuationMethodName,
      weight: val.valuationMethodWeights[index].weightPercentage,
    };
  });
};

export const toCamelCase = (inputName: string): string => {
  return inputName
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const sendMobileFieldData = (): MobileInputFieldType => {
  return [
    {
      name: 'scorecardMethod',
      label: 'Scorecard Method',
      id: 1,
    },
    {
      name: 'berkusMethod',
      label: 'Berkus method',
      id: 2,
    },
    {
      name: 'riskFactorSummationMethod',
      label: 'Risk factor summation method',
      id: 3,
    },
    {
      name: 'vCMethod',
      label: 'VC method',
      id: 4,
    },
    {
      name: 'dCFMethod',
      label: 'DCF method',
      id: 5,
    },
  ];
};

export const getWeightSum = (values: FormValuesPair): number => {
  const { scorecardMethod, berkusMethod, riskFactorSummationMethod, vCMethod, dCFMethod } = values;
  const sum = Number(
    Number(scorecardMethod) +
      Number(berkusMethod) +
      Number(riskFactorSummationMethod) +
      Number(vCMethod) +
      Number(dCFMethod),
  );
  return sum;
};

export const notAllowNegativeValue = (event: any) => {
  if (event.key === '-') {
    event.preventDefault();
    return false;
  }
  return true;
};

export const parsePostData = (data: FormValuesPair): FormValuesPair => {
  const {
    scorecardMethod,
    berkusMethod,
    riskFactorSummationMethod,
    vCMethod,
    dCFMethod,
    methodAverageValuation,
    returnRatePercentage,
    discountRatePercentage,
    growthRatePercentage,
    medianName,
    medianRate,
  } = data;

  return {
    scorecardMethodWeight: scorecardMethod || null,
    berkusMethodWeight: berkusMethod || null,
    riskFactorMethodWeight: riskFactorSummationMethod || null,
    VCMethodWeight: vCMethod || null,
    DCFMethodWeight: dCFMethod || null,
    methodAverageValuation: methodAverageValuation || null,
    returnRatePercentage: returnRatePercentage || null,
    discountRatePercentage: discountRatePercentage || null,
    growthRatePercentage: growthRatePercentage || null,
    medianName: medianName || null,
    medianRate: medianRate || null,
  };
};
