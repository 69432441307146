import { makeStyles } from '@material-ui/styles';
import customTheme from 'theme/customTheme';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme) => ({
  title: {
    fontSize: '24px',
    lineHeight: '32px',
    [theme.breakpoints.down('md')]: {
      fontSize: '21px',
      lineHeight: '28px',
    },
  },
  titleText: {
    fontSize: '15px',
    lineHeight: '20px',
    color: customTheme.colors.gray6,
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  stagesLabel: {
    background: customTheme.colors.MediumSpringGreen,
    padding: '12px 2px',
  },
  stagesLabelSection: {
    margin: '2px 17px',
    '& h6': {
      fontSize: '14px',
      lineHeight: '21px',
      color: customTheme.colors.white,
      textAlign: 'center',
    },
    [theme.breakpoints.down('md')]: {
      padding: '3px 30px',
      width: '70%',
    },
  },
  valuationWeight: {
    background: 'transparent',
  },
  weights: {
    padding: '16px 34px 16px 6px',
    borderBottom: `1px solid ${customTheme.colors.LigthGray}`,
  },
  weightValue: {
    margin: '2px 52px 0px 3px',
    '& p': {
      textAlign: 'center',
      fontSize: '14px',
      lineHeight: '30px',
    },
  },
  weightTitle: {
    fontSize: '14px',
    lineHeight: '31px',
  },
  totalValue: {
    '& h6': {
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  summationMethod: {},
  questionLabel: {
    fontSize: '18px',
    lineHeight: '24px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  questionText: {
    fontSize: '15px',
    lineHeight: '22px',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
      lineHeight: '17px',
    },
  },
  select: {
    width: '100%',
    borderRadius: '47px',
    background: customTheme.colors.white,
    padding: '0px 23px',
  },
  dropdownText: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  multipleSelect: {
    width: '35%',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginTop: 14,
      width: '100%',
    },
  },
  btnSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelBtn: {
    '& button': {
      background: customTheme.colors.DarkBlack,
      padding: '12px 23px',
      '&:hover': {
        background: customTheme.colors.DarkBlack,
      },
    },
  },
  ctaText: {
    color: customTheme.colors.white,
    fontSize: '16px',
    lineHeight: '22px',
  },
  questionSection: {
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  questionInput: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginTop: 14,
      width: '100%',
    },
  },
  fieldTitleSection: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  mobileWeightSection: {
    [theme.breakpoints.down('md')]: {
      borderBottom: `1px solid ${customTheme.colors.LigthGray}`,
      padding: '3px 0px 8px 0px',
    },
  },
  industryStages: {
    background: customTheme.colors.MediumSpringGreen,
    padding: '12px 23px',
    borderRadius: '6px 6px 0 0',
  },
  mobileWeightValue: {
    margin: '2px 31px 0px 3px',
    '& p': {
      textAlign: 'center',
      fontSize: '14px',
      lineHeight: '30px',
    },
  },
  multipleInput: {
    display: 'flex',
    marginLeft: '177px',
    marginTop: '0',
    width: '66%',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: '100%',
    },
  },
  sumError: {
    fontSize: '13px',
    lineHeight: '17px',
    color: customTheme.colors.error,
  },
  weightSumValue: {
    fontSize: '13px',
    lineHeight: '17px',
  },
  sumGreen: {
    fontSize: '13px',
    lineHeight: '17px',
    color: customTheme.colors.MediumSpringGreen,
  },
  weightSumMsg: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  resetLink: {
    fontSize: '12px',
    lineHeight: '16px',
    color: customTheme.colors.DimGray,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  resetSection: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  percentageText: {
    fontSize: '14px',
    lineHeight: '21px',
  },
  grayText: {
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.colors.DimGray,
  },
  totalCount: {
    width: '63px',
    height: '37px',
    padding: '4px 0',
  },
  seriesB: {
    marginRight: '18px',
  },
}));
