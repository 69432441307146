import { commaSeparatedNumber } from 'utils/helperMethod';
export const makeBold = (arg: string): boolean => {
  return (
    /^Gross (profit)$/gi.test(arg) ||
    /^EBITDA$/gi.test(arg) ||
    /^EBIT$/gi.test(arg) ||
    /Net profit\/loss/gi.test(arg) ||
    /Free cash flow/gi.test(arg)
  );
};

export const formateNumber = (arg: any, symbol?: string) => {
  if (symbol !== undefined) {
    return /^-$/.test(arg) ? arg : `${symbol}${commaSeparatedNumber(arg)}`;
  }
  return /^-$/.test(arg) ? arg : `${commaSeparatedNumber(arg)}${'%'}`;
};
