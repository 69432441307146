import React from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import classnames from 'classnames';
import { useStyles } from './styles';
import isEmpty from 'lodash/isEmpty';

type DataPropsTypes = {
  title: string;
  value: number | string;
};

type PropTypes = {
  firstTitle: string;
  secondTitle: string;
  data: DataPropsTypes[];
  totalValue: string | number | null;
  mobileWidthFirstCol: string;
  mobileWidthSecondCol: string;
  isLastRowGreen?: boolean;
  widthSecondCol?: string;
  widthFirstCol?: string;
  isCenterlizeValue?: boolean;
};

const defaultProps = {
  isLastRowGreen: false,
  widthFirstCol: '50%',
  widthSecondCol: '49%',
  isCenterlizeValue: false,
};

const CardColumnSection: React.FC<PropTypes> = ({
  firstTitle,
  secondTitle,
  data,
  totalValue,
  isLastRowGreen,
  isCenterlizeValue,
  ...colStyles
}) => {
  const classes = useStyles(colStyles);
  return (
    <Box>
      <Box display="flex">
        <Card className={classes.headingCardOne}>
          <Box className={classes.contentPadding}>
            <Typography variant="subtitle2" className={classes.mainTitle}>
              {firstTitle}
            </Typography>
          </Box>
        </Card>
        <Card className={classes.headingCardTwo}>
          <Box className={classes.contentPadding}>
            <Typography
              variant="subtitle2"
              className={classnames({ [classes.mainTitle]: true, [classes.centerLizeValue]: isCenterlizeValue })}
            >
              {secondTitle}
            </Typography>
          </Box>
        </Card>
      </Box>
      {!isEmpty(data) &&
        data.map(({ title, value }) => {
          return (
            <Box display="flex">
              <Card className={classes.contentTitle}>
                <Box className={classes.contentPadding}>
                  <Typography variant="body1" className={classes.contentText}>
                    {title}
                  </Typography>
                </Box>
              </Card>
              <Card className={classes.contentValue}>
                <Box className={classes.contentPadding}>
                  <Typography
                    variant="body1"
                    className={classnames({
                      [classes.contentText]: true,
                      [classes.centerLizeValue]: isCenterlizeValue,
                    })}
                  >
                    {value}
                  </Typography>
                </Box>
              </Card>
            </Box>
          );
        })}
      <Box display="flex">
        <Card className={classnames({ [classes.contentTitle]: true, [classes.totalSection]: isLastRowGreen })}>
          <Box className={classes.contentPadding}>
            <Typography variant="subtitle2" className={classes.contentText}>
              Total
            </Typography>
          </Box>
        </Card>
        <Card className={classnames({ [classes.contentValue]: true, [classes.totalSection]: isLastRowGreen })}>
          <Box className={classes.contentPadding}>
            <Typography
              variant="subtitle2"
              className={classnames({ [classes.contentText]: true, [classes.centerLizeValue]: isCenterlizeValue })}
            >
              {totalValue}
            </Typography>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

CardColumnSection.defaultProps = defaultProps;

export default CardColumnSection;
