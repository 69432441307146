import React, { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import ReactTooltip from 'react-tooltip';
import isEmpty from 'lodash/isEmpty';
import Typography from '@material-ui/core/Typography';
import CustomInput from 'components/molecules/FormInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import QuestionMark from 'components/layout/QuestionMark';
import { formatInputWithCommas, handleCommaNumberChange } from 'utils/helperMethod';
import theme from 'theme/customTheme';
import { useStyles, Symbol, Currency } from './styles';

interface PropsValidation {
  currencySymbol: string;
  errors: any;
  setFieldValue: (field: string, data: string | number | any[], validate: boolean) => any;
  handleBlur: (e: any) => any;
  touched: any;
  revenueQuestions: any[];
  values: any;
}

const RevenueQuestions: React.FC<PropsValidation> = ({
  currencySymbol,
  errors,
  setFieldValue,
  handleBlur,
  touched,
  revenueQuestions,
  values,
}) => {
  const classes = useStyles();
  const [notAllowNegative, setNotAllowNegative] = useState<boolean>(false);

  const getCommaValueFromSlug = (slug: string): string => {
    const { general = {} } = values || {};
    const value = general[slug] || '';
    return formatInputWithCommas(`${value}`);
  };

  const notAllowNegativeValue = (event: any) => {
    if (event.key === '-') {
      event.preventDefault();
      setNotAllowNegative(false);
      return false;
    }
    setNotAllowNegative(true);
    return true;
  };

  if (isEmpty(revenueQuestions)) return <></>;
  return (
    <>
      {revenueQuestions.length > 0 &&
        revenueQuestions.map(({ question, tooltip, slug }, index) => {
          return (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              mt={2}
              mb={2}
              className={classes.generalContainer}
              key={index}
            >
              <Box
                display="flex"
                width="52%"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="baseline"
                mt={2}
                className={classes.questionTextSection}
              >
                <Box width="100%" display="flex">
                  <Box>
                    <Typography variant="subtitle2" className={classes.questionText}>
                      {question}
                    </Typography>
                  </Box>
                  {tooltip !== null && (
                    <>
                      <Box ml={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip="hover on me will keep the tooltip"
                          data-for={`tool-tip-${question}`}
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id={`tool-tip-${question}`} />
                    </>
                  )}
                </Box>
              </Box>
              <Box display="flex" width="40%" flexDirection="column" className={classes.fieldSection}>
                <CustomInput
                  type="text"
                  name={`general.${slug}`}
                  padding={'13px 0px 13px 54px'}
                  marginTop="0"
                  bgColor={theme.colors.white}
                  value={getCommaValueFromSlug(slug)}
                  inputProps={
                    <InputAdornment position="start">
                      <Symbol>
                        <Currency>{currencySymbol || '£'}</Currency>
                      </Symbol>
                    </InputAdornment>
                  }
                  placeholder="Enter amount"
                  isFullWidth={true}
                  readOnly={false}
                  handleBlur={handleBlur}
                  onKeyPress={(e) => {
                    notAllowNegativeValue(e);
                  }}
                  handleOnChange={(data: string | number) => {
                    handleCommaNumberChange(data, (newData) => {
                      setFieldValue(`general.${slug}`, newData, false);
                    });
                  }}
                  helpText={
                    !isEmpty(errors) && errors.general[slug] && touched.general[slug] ? errors.general[slug] : ''
                  }
                />
              </Box>
            </Box>
          );
        })}
      <Box marginTop="35px" />
      <Divider className={classes.horizontalDivider} />
    </>
  );
};

export default RevenueQuestions;
