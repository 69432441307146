export const questionData = {
  simpleQuestions: [
    {
      questionNo: 1,
      question: 'Company name',
      fieldType: 'text',
      slug: 'companyName',
      choices: [],
      isAmount: false,
      placeholder: 'Enter company name',
      readOnly: false,
      tooltip: null,
      questionType: 'simple',
      disableTrimming: true,
      fieldSymbol: null,
    },
    {
      questionNo: 2,
      question: 'Company website',
      slug: 'companyWebsite',
      fieldType: 'text',
      choices: [],
      isAmount: false,
      placeholder: 'www.example.com',
      readOnly: false,
      tooltip: null,
      questionType: 'simple',
      fieldSymbol: null,
    },
    {
      questionNo: 3,
      question: 'Location',
      slug: 'location',
      fieldType: 'dropdown',
      choices: [],
      isAmount: false,
      placeholder: 'Choose option',
      readOnly: false,
      tooltip: null,
      questionType: 'simple',
      fieldSymbol: null,
    },
    {
      questionNo: 4,
      question: 'Currency',
      slug: 'currency',
      fieldType: 'text',
      choices: [],
      isAmount: false,
      placeholder: '',
      readOnly: true,
      tooltip: null,
      questionType: 'simple',
      fieldSymbol: null,
    },
    {
      questionNo: 5,
      question: 'Year founded',
      slug: 'yearFounded',
      fieldType: 'number',
      choices: [],
      isAmount: false,
      placeholder: 'YYYY',
      readOnly: false,
      tooltip: null,
      questionType: 'simple',
      fieldSymbol: null,
    },
    {
      questionNo: 6,
      question: 'Industry',
      slug: 'industry',
      fieldType: 'dropdown',
      choices: [],
      isAmount: false,
      placeholder: 'Choose option',
      tooltip: null,
      questionType: 'simple',
      fieldSymbol: null,
    },
  ],
};

export const stagesData = [
  {
    question: 'Investment Stage',
    slug: 'investmentStage',
    fieldType: 'dropdown',
    isAmount: false,
    placeholder: 'Choose option',
    tooltip: null,
    questionType: 'simple',
  },
];
