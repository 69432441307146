import { useContext, createContext, useState, useEffect, SetStateAction } from 'react';
import { isEmpty } from 'lodash';
import { removeCommasFromNumber, calculateDCFGrowthRate } from 'utils/helperMethod';
import { RowType, SingleCellType, RowDataType } from './types';
import { initialVal, RowWidthSingleValue, initialRowData } from 'components/organisms/DCFinancial/DCFConstants';
import { DcfContextI } from './types';
import { createDeepCopy } from 'components/organisms/DCFinancial/DCFHelper';

interface DCFProvider {
  children: JSX.Element;
}

const stateSetterDefault = () => {};

export const DCFContext = createContext<DcfContextI>({
  setRevenues: stateSetterDefault,
  handleChange: stateSetterDefault,
  handleRowWithSingleField: stateSetterDefault,
  setGrossProfit: stateSetterDefault,
  calculateGrossProfit: stateSetterDefault,
  setCostOfGoodsSold: stateSetterDefault,
  setOperatingExpenses: stateSetterDefault,
  setEBITDA: stateSetterDefault,
  calculateEBITDA: stateSetterDefault,
  setEBIT: stateSetterDefault,
  calculateEBIT: stateSetterDefault,
  setDepreciationAndAmort: stateSetterDefault,
  setInterestExpenses: stateSetterDefault,
  setTaxes: stateSetterDefault,
  setNetProfitLoss: stateSetterDefault,
  setWorkingCapital: stateSetterDefault,
  setFreeCashFlow: stateSetterDefault,
  setCapitalExpenditure: stateSetterDefault,
  calculateNetProfitAndLoss: stateSetterDefault,
  calculateFreeCashFlow: stateSetterDefault,
  setCash: stateSetterDefault,
  setBorrowings: stateSetterDefault,
  setSkipFinancialForecast: stateSetterDefault,
  setLastYearRevenue: stateSetterDefault,
  setYear5Revenue: stateSetterDefault,
  DCFTableData: {
    revenues: initialVal,
    costOfGoodsSold: initialVal,
    grossProfit: initialVal,
    operatingExpenses: initialVal,
    EBITDA: initialVal,
    depreciationAndAmort: initialVal,
    EBIT: initialVal,
    interestExpenses: initialVal,
    taxes: initialVal,
    netProfitLoss: initialVal,
    workingCapital: initialVal,
    depAmor: initialVal,
    capitalExpenditure: initialVal,
    freeCashFlow: initialVal,
    cash: RowWidthSingleValue,
    borrowings: RowWidthSingleValue,
  },
  skipFinancialForecast: false,
  getRevenues: stateSetterDefault,

  setFinancialForecastQuestions: stateSetterDefault,
  setFinancialForecastData: stateSetterDefault,
  financialForecastQuestions: [],
});

const DCFProvider = ({ children }: DCFProvider) => {
  const year = 6;
  const [lastYearRevenue, setLastYearRevenue] = useState(0);
  const [Year5Revenue, setYear5Revenue] = useState(0);
  const [revenues, setRevenues] = useState<any>(initialVal);
  const [costOfGoodsSold, setCostOfGoodsSold] = useState<RowDataType>(initialVal);
  const [grossProfit, setGrossProfit] = useState<RowDataType>(initialVal);
  const [operatingExpenses, setOperatingExpenses] = useState<RowDataType>(initialVal);
  const [EBITDA, setEBITDA] = useState<RowDataType>(initialVal);
  const [depreciationAndAmort, setDepreciationAndAmort] = useState<RowDataType>(initialVal);
  const [EBIT, setEBIT] = useState<RowDataType>(initialVal);
  const [interestExpenses, setInterestExpenses] = useState<RowDataType>(initialVal);
  const [taxes, setTaxes] = useState<RowDataType>(initialVal);
  const [netProfitLoss, setNetProfitLoss] = useState<RowDataType>(initialVal);
  const [depAmor, setDepAmor] = useState<RowDataType>(initialVal);
  const [workingCapital, setWorkingCapital] = useState<RowDataType>(initialVal);
  const [freeCashFlow, setFreeCashFlow] = useState<RowDataType>(initialVal);
  const [capitalExpenditure, setCapitalExpenditure] = useState<RowDataType>(initialVal);
  const [cash, setCash] = useState(RowWidthSingleValue);
  const [borrowings, setBorrowings] = useState(RowWidthSingleValue);
  const [skipFinancialForecast, setSkipFinancialForecast] = useState(false);
  const [financialForecastQuestions, setFinancialForecastQuestions] = useState<any[]>([]);
  const [financialForecastData, setFinancialForecastData] = useState<any[]>([]);

  useEffect(() => {
    const rowData: RowDataType[] | any = [];
    if (financialForecastQuestions.length > 0) {
      financialForecastQuestions.forEach(
        ({ isHidden, question, valuationQuestionId, isMandatory, tooltip, slug }: any) => {
          const isSingleValue = ['cash', 'borrowings'].some((singleValueSlug) => singleValueSlug === slug);
          const { values: savedValues } = (financialForecastData || []).find((item) => item.slug === slug) || {};

          if (isHidden === false) {
            if (isSingleValue) {
              rowData.push({
                question: question,
                values: isEmpty(savedValues) ? { amt: 0 } : savedValues,
                valuationQuestionId: valuationQuestionId,
                isMandatory: isMandatory,
                tooltip: tooltip,
              });
            } else {
              rowData.push({
                question: question,
                values: isEmpty(savedValues) ? createDeepCopy(initialRowData) : savedValues,
                valuationQuestionId: valuationQuestionId,
                isMandatory: isMandatory,
                tooltip: tooltip,
              });
            }
          }
        },
      );
      setRevenues(rowData[0]);
      setCostOfGoodsSold(rowData[1]);
      setGrossProfit(rowData[2]);
      setOperatingExpenses(rowData[3]);
      setEBITDA(rowData[4]);
      setDepreciationAndAmort(rowData[5]);
      setEBIT(rowData[6]);
      setInterestExpenses(rowData[7]);
      setTaxes(rowData[8]);
      setNetProfitLoss(rowData[9]);
      setDepAmor(rowData[10]);
      setWorkingCapital(rowData[11]);
      setCapitalExpenditure(rowData[12]);
      setFreeCashFlow(rowData[13]);
      setCash(rowData[14]);
      setBorrowings(rowData[15]);
      getRevenues(lastYearRevenue, Year5Revenue);
    }
  }, [financialForecastQuestions, financialForecastData, Year5Revenue, lastYearRevenue]);

  // refresh calculations
  useEffect(() => {
    const newGrossProfit = calculateGrossProfit() as RowType[];
    const { values: newDepAmorValues = [] } = depreciationAndAmort;
    setGrossProfit((oldState: RowDataType): RowDataType => ({ ...oldState, values: newGrossProfit }));
    setDepAmor((oldState: RowDataType): RowDataType => ({ ...oldState, values: newDepAmorValues }));
    const newEBITDA = calculateEBITDA(newGrossProfit);
    const newEBIT = calculateEBIT(newEBITDA);
    const newProfitAndLoss = calculateNetProfitAndLoss(newEBIT);
    calculateFreeCashFlow(newProfitAndLoss);
  }, [
    revenues,
    costOfGoodsSold,
    operatingExpenses,
    depreciationAndAmort,
    interestExpenses,
    taxes,
    workingCapital,
    capitalExpenditure,
  ]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    cellData: RowType,
    setter: React.Dispatch<SetStateAction<RowDataType>>,
  ) => {
    const { target: { value: targetValue = '0' } = {} } = event || {};
    const newAmt = removeCommasFromNumber(targetValue);
    const newItem = { id: cellData.id, amt: Number(newAmt) || 0 };
    setter(
      (oldState: RowDataType): RowDataType => ({
        ...oldState,
        values: oldState.values.map((oldItem: RowType) => (newItem.id === oldItem.id ? newItem : oldItem)),
      }),
    );
  };

  const handleRowWithSingleField = (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<SetStateAction<SingleCellType>>,
  ): void => {
    const { target: { value: targetValue = '0' } = {} } = event || {};
    const value = removeCommasFromNumber(targetValue);
    setter(
      (oldState: SingleCellType): SingleCellType => ({
        ...oldState,
        values: { amt: Number(value) || 0 },
      }),
    );
  };

  const getRevenues = (lastYearRev: number, year5Rev: number): void => {
    const growthRate = calculateDCFGrowthRate(lastYearRev, year5Rev);
    const revenue = {
      values: [
        {
          id: 'last12Month',
          amt: lastYearRev,
        },
      ],
    };
    for (let i = 0; i < 5; i++) {
      revenue.values.push({
        id: `year${i + 1}`,
        amt: i === 4 ? year5Rev : Math.round(revenue.values[i].amt * (1 + growthRate)),
      });
    }
    setRevenues((rev: any) => ({ ...rev, values: revenue.values }));
  };

  const calculateGrossProfit = () => {
    if (!revenues) {
      return;
    }

    const calculatedGrossProfits = [];
    for (let i = 0; i <= year; i++) {
      if (revenues.values[i] && costOfGoodsSold.values[i]) {
        calculatedGrossProfits.push({
          id: `${i === 0 ? 'last12Month' : 'year' + i}`,
          amt: revenues.values[i].amt - costOfGoodsSold.values[i].amt,
        });
      }
    }
    return calculatedGrossProfits;
  };

  const calculateEBITDA = (grossProfitValues: RowType[]): RowType[] => {
    //EBITDA=(GrossProfit)-(OperatingExpenses)
    const calculatedEBITDA: RowType[] = [];
    for (let i = 0; i <= year; i++) {
      if (grossProfitValues[i] && operatingExpenses.values[i]) {
        calculatedEBITDA.push({
          id: `${i === 0 ? 'last12Month' : 'year' + i}`,
          amt: grossProfitValues[i].amt - operatingExpenses.values[i].amt,
        });
      }
    }
    setEBITDA((oldEBITDA) => ({ ...oldEBITDA, values: calculatedEBITDA }));
    return calculatedEBITDA;
  };

  const calculateEBIT = (EBITDAValues: RowType[]): RowType[] => {
    //EBIT=(EBITDA)-(DepreciationAndAmortization)
    const calculatedEBIT: RowType[] = [];
    for (let i = 0; i <= year; i++) {
      if (EBITDAValues[i] && depreciationAndAmort.values[i]) {
        calculatedEBIT.push({
          id: `${i === 0 ? 'last12Month' : 'year' + i}`,
          amt: EBITDAValues[i].amt - depreciationAndAmort.values[i].amt,
        });
      }
    }
    setEBIT((oldEBIT) => ({
      ...oldEBIT,
      values: calculatedEBIT,
    }));

    return calculatedEBIT;
  };

  const calculateNetProfitAndLoss = (EBITValues: RowType[]): RowType[] => {
    //NetProfitLoss=(EBIT)-((Taxes)+(InterestExpenses))
    const taxesValues = taxes.values;
    const interestExpensesValues = interestExpenses.values;
    const calculatedNetProfitLoss: RowType[] = [];
    for (let i = 0; i <= year; i++) {
      if (taxesValues[i] && EBITValues[i] && interestExpensesValues[i]) {
        calculatedNetProfitLoss.push({
          id: `${i === 0 ? 'last12Month' : 'year' + i}`,
          amt: EBITValues[i].amt - (taxesValues[i].amt + interestExpensesValues[i].amt),
        });
      }
    }
    setNetProfitLoss((oldProfitAndLoss) => ({ ...oldProfitAndLoss, values: calculatedNetProfitLoss }));
    return calculatedNetProfitLoss;
  };

  const calculateFreeCashFlow = (netProfitLossValues: RowType[]): void => {
    const calculatedFreeCashFlow = [];
    for (let i = 0; i <= year; i++) {
      if (netProfitLossValues[i] && depreciationAndAmort.values[i] && workingCapital.values[i]) {
        calculatedFreeCashFlow.push({
          id: `${i === 0 ? 'last12Month' : 'year' + i}`,
          amt:
            netProfitLossValues[i].amt +
            (depreciationAndAmort.values[i].amt - (workingCapital.values[i].amt + capitalExpenditure.values[i].amt)),
        });
      }
    }
    setFreeCashFlow({ ...freeCashFlow, values: calculatedFreeCashFlow });
  };

  const data = {
    handleChange,
    handleRowWithSingleField,
    setRevenues,
    setGrossProfit,
    setCostOfGoodsSold,
    setOperatingExpenses,
    setEBITDA,
    setEBIT,
    setDepreciationAndAmort,
    setInterestExpenses,
    setTaxes,
    setWorkingCapital,
    setFreeCashFlow,
    setCapitalExpenditure,
    setCash,
    setBorrowings,
    setNetProfitLoss,
    calculateNetProfitAndLoss,
    calculateFreeCashFlow,
    calculateEBITDA,
    calculateEBIT,
    calculateGrossProfit,
    getRevenues,
    DCFTableData: {
      revenues,
      costOfGoodsSold,
      grossProfit,
      operatingExpenses,
      EBITDA,
      depreciationAndAmort,
      EBIT,
      interestExpenses,
      taxes,
      netProfitLoss,
      workingCapital,
      depAmor,
      capitalExpenditure,
      freeCashFlow,
      cash,
      borrowings,
    },
    setSkipFinancialForecast,
    skipFinancialForecast,
    setFinancialForecastQuestions,
    financialForecastQuestions,
    setFinancialForecastData,
    setLastYearRevenue,
    setYear5Revenue,
  };

  return <DCFContext.Provider value={data}>{children}</DCFContext.Provider>;
};

export default DCFProvider;

export const useDCFContext = () => {
  const context = useContext(DCFContext);
  return context;
};
