import NavContainer from 'components/layout/NavContainer';
import FullscreenLoader from 'components/molecules/FullscreenLoader';
import useLoadingContext from './useLoadingContext';

interface PropTypes {
  children: any;
}

const Loading: React.FC<PropTypes> = ({ children }) => {
  const { loading } = useLoadingContext();
  if (!loading) return children;

  return (
    <NavContainer>
      <FullscreenLoader />
    </NavContainer>
  );
};

export default Loading;
