export const PREPARE_FINANCIAL_DATA = 'PREPARE_FINANCIAL_DATA';
export const SET_PAYMENT_MODAL = 'SET_PAYMENT_MODAL';
export const SET_SUCCESS_MODAL = 'SET_SUCCESS_MODAL';
export const GET_REQUIRED_FIELD = 'GET_REQUIRED_FIELD';
export const GET_USE_OF_FUND_REQUIRED_FIELD = 'GET_USE_OF_FUND_REQUIRED_FIELD';
export const ADD_NEW_ITEM_FROM_INVESTORS_MENU = 'ADD_NEW_ITEM_FROM_INVESTORS_MENU';
export const MODIFIED_INVESTORS_VALUE = 'MODIFIED_INVESTORS_VALUE';
export const DELETE_INVESTOR = 'DELETE_INVESTOR';
export const ADD_NEW_INVESTOR_FROM_INPUT = 'ADD_NEW_INVESTOR_FROM_INPUT';
export const UPDATE_INVESTOR_PERCENT = 'UPDATE_INVESTOR_PERCENT';
export const ADD_NEW_PLAN_USE_OF_FUND = 'ADD_NEW_PLAN_USE_OF_FUND';
export const REMOVE_USE_OF_FUND = 'REMOVE_USE_OF_FUND';
export const ADD_NEW_UES_OF_FUND_FROM_INPUT = 'ADD_NEW_UES_OF_FUND_FROM_INPUT';
export const MODIFIED_USE_OF_FUND_VALUE = 'MODIFIED_USE_OF_FUND_VALUE';
export const UPDATE_PERCENT_IN_USE_OF_FUND_SECTION = 'UPDATE_PERCENT_IN_USE_OF_FUND_SECTION';
export const GET_INVESTORS_QUESTION_PREFILLED = 'GET_INVESTORS_QUESTION_PREFILLED';
export const GET_USE_OF_FUND_PREFILLED = 'GET_USE_OF_FUND_PREFILLED';
