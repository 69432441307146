import styled from 'styled-components';

export const Card = styled.section`
  background: ${(props) => props.theme.colors.white};
  width: 30%;
  height: auto;
  min-height: 232px;
  border-radius: 8px;
  && {
    ${(props) => props.theme.breakpoints.down('sm')} {
      width: 90%;
    }
  }
`;

export const VerificationWrapper = styled.div`
  background: ${(props) => props.theme.colors.Gray90};
`;

export const MarginAuto = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
`;
