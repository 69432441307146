import { makeStyles, Theme } from '@material-ui/core/';

export const useStyles = makeStyles<Theme>((theme) => ({
  reportSection: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  buttonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  heading: {
    fontSize: '36px',
    lineHeight: '48px',
    wordBreak: 'break-word',
    [theme.breakpoints.down('md')]: {
      fontSize: '26px',
      lineHeight: '35px',
    },
  },
  subHeading: {
    fontSize: '16px',
    lineHeight: '21px',
    wordBreak: 'break-word',
  },
  editButton: {
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginRigth: 0,
      '& button': {
        fontSize: '12px',
        wordBreak: 'break-word',
      },
    },
  },
  pdfBtn: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      '& button': {
        marginTop: 10,
        fontSize: '12px',
      },
    },
  },
}));
