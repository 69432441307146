import { methodCons, methodPros } from './reportData';
import TableCell from './reportTableCell';
import { getAmountString } from 'utils/helperMethod';
import { RowTitle } from 'components/organisms/DCFinancial/styles';
import HorizontalScroll from 'components/molecules/HorizontalScroll';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStyles } from 'components/templates/ValuationReport/styles';
import { Typography } from '@material-ui/core';
import { makeBold, formateNumber } from './DCFReportHelper';
import { routesObj } from 'routes/routes';
import { ReactComponent as WarningIcon } from 'assets/warning-icon-grey.svg';
import { DCFReportProps } from './DCFReportTypes';
import DCFFormula from 'assets/dcf_formula.svg';
import LeftCardTable from './LeftCardTable';
import TableHead from './TableHead';
import {
  DCFReportWrapper,
  CardWrapper,
  RightCard,
  Paragraph,
  LeftText,
  GreenText,
  TitleBox,
  BoldText,
  Subtitle,
  List,
  CardFooter,
  LeftCard,
  FormulaBox,
  EquityValue,
  FormulaBoxFooter,
  DCFReportTableRow,
  ReportDCFSection,
} from './styles';

const handleForecastValue = (value: number | string, fieldType: string, currencySymbol: string): string => {
  const stringValue = `${value}`;

  if (stringValue === '-') return stringValue;
  if (!stringValue || isNaN(Number(stringValue))) return '-';
  if (fieldType === 'percentage') return `${stringValue}%`;
  return getAmountString(stringValue, currencySymbol);
};

const DCFReport = ({
  dcfReport,
  currencySymbol,
  skipFinancialForecast,
  companyValuationId,
  parentCompanyValuationId,
}: DCFReportProps): JSX.Element => {
  const classes = useStyles();
  if (dcfReport === undefined) {
    return <></>;
  }

  return (
    <DCFReportWrapper>
      <TitleBox>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          DCF method
        </Typography>
        {!skipFinancialForecast ? (
          <LeftText>
            The company valuation according to the DCF method is{' '}
            <GreenText>{formateNumber(dcfReport.equityValue, currencySymbol)}</GreenText>
          </LeftText>
        ) : (
          <Box display="flex" alignItems="center">
            <WarningIcon className={classes.spanIcon} />
            <Typography>
              <b>
                To see the valuation according to the VC method, please{' '}
                <Link
                  to={{
                    pathname: `${routesObj.questionaries.path}`,
                    state: { companyValuationId, parentCompanyValuationId },
                  }}
                  className={classes.greenText}
                >
                  complete the Financial Forecasts
                </Link>{' '}
                section in the questionnaire.
              </b>
            </Typography>
          </Box>
        )}
      </TitleBox>

      <CardWrapper>
        <RightCard>
          <Paragraph>
            <BoldText> Discounted Cash Flow method</BoldText> estimates the value of the Business today based on its
            projected future cash flows (you can find detailed financial forecasts on the next page).{' '}
          </Paragraph>
          <Paragraph>
            <BoldText> Enterprise Value of the Company (EV) </BoldText> is the sum of present values of free cash flows
            from period 1 to 5, and Terminal Value (TV). <BoldText>Terminal Value of the Company (TV)</BoldText> is the
            discounted cash flows of the company from year 6 into perpetuity.
            <BoldText> Discount rate (r) </BoldText> used was based on average expected rates of return for VC
            investors* (depending on the stage of the funding ) or entered in parameters by the user. Long-term growth
            rate is the market growth rate or entered by the user.
          </Paragraph>

          <Subtitle>Method pros</Subtitle>
          <List>
            {methodPros.map((item) => (
              <li key={item.id}>{item.prop}</li>
            ))}
          </List>
          <Subtitle>Method cons</Subtitle>
          <List>
            {methodCons.map((item) => (
              <li key={item.id}>{item.prop}</li>
            ))}
          </List>

          <CardFooter>
            *Damodaran, A. (2009). Valuing Young, Start-Up and Growth Companies: Estimation Issues and Valuation
            Challenges{' '}
          </CardFooter>
        </RightCard>

        <LeftCard>
          <FormulaBox>
            <img src={DCFFormula} alt="" />
            <EquityValue>
              Equity Value = <span> EV + Cash</span> - Borrowings
            </EquityValue>
            <FormulaBoxFooter>
              <span> EV - Enterprise Value</span> <span>FCF - Free Cash Flow</span> TV - Terminal Value of the Business{' '}
              <br />
              <span>r - discount rate</span> g - long term growth rate
            </FormulaBoxFooter>
          </FormulaBox>
          <LeftCardTable dcfReport={dcfReport} currencySymbol={currencySymbol} />
        </LeftCard>
      </CardWrapper>

      {dcfReport.financialForecast.length > 0 && (
        <HorizontalScroll>
          <ReportDCFSection>
            <TableHead currencySymbol={currencySymbol} />
            <section>
              {dcfReport.financialForecast.map(({ question, value, fieldType }) => {
                return (
                  <DCFReportTableRow>
                    <RowTitle
                      makeTextBold={makeBold(question)}
                      style={{ margin: '1px', background: '#F7F7F7', width: '26.5%' }}
                    >
                      {question}
                    </RowTitle>
                    {Object.values(value).map((item: any, idx) => {
                      return (
                        <TableCell
                          key={idx}
                          cellData={handleForecastValue(item, fieldType, currencySymbol as string)}
                          fontWeight={makeBold(question) ? 700 : 400}
                          style={{
                            margin: '1px',
                            background: '#F7F7F7',
                            width: '12.9%',
                            padding: '0 0 0 10px',
                            lineHeight: '37px',
                          }}
                          removeBorder
                        />
                      );
                    })}
                  </DCFReportTableRow>
                );
              })}
            </section>
          </ReportDCFSection>
        </HorizontalScroll>
      )}
    </DCFReportWrapper>
  );
};

export default DCFReport;
