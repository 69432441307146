import React from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useStyles } from './styles';
import customTheme from 'theme/customTheme';

type ModalProps = {
  open: boolean;
  modalVisibility: (value: boolean) => void;
  showCloseIcon: boolean;
  closeOnEsc: boolean;
  closeOnOverlayClick: boolean;
  modalWidth?: string;
  borderRadius?: string;
  height?: string;
  padding?: string;
  mobileWidth?: string;
  mobilePadding?: string;
};

const defaultProps = {
  modalWidth: '100%',
  borderRadius: '10px',
  height: 'auto',
  padding: '2.2rem',
  mobileWidth: '88%',
  mobilePadding: '37px 19px',
};

const closeIcon = (
  <svg fill={customTheme.colors.gray6} width="28" height="28" viewBox="0 0 36 36" data-testid="close-icon">
    <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"></path>
  </svg>
);

const ResponsivePopup: React.FC<ModalProps> = ({
  children,
  open,
  modalVisibility,
  showCloseIcon,
  closeOnEsc,
  closeOnOverlayClick,
  ...Modalstyles
}) => {
  const classes = useStyles(Modalstyles);
  return (
    <Modal
      open={open}
      closeOnEsc={closeOnEsc}
      closeOnOverlayClick={closeOnOverlayClick}
      classNames={{ modal: classes.responsiveModal }}
      onClose={() => modalVisibility(false)}
      styles={{ closeButton: { cursor: 'pointer' } }}
      closeIcon={showCloseIcon ? closeIcon : ' '}
      center
    >
      {children}
    </Modal>
  );
};

ResponsivePopup.defaultProps = defaultProps;

export default ResponsivePopup;
