import { useContext } from 'react';
import LoadingContext from './LoadingContext';
import { LoadingContextType } from './types';

const useLoadingContext = (): LoadingContextType => {
  const { loading, setLoading } = useContext(LoadingContext);
  return { loading, setLoading };
};

export default useLoadingContext;
