import styled from 'styled-components';
import customTheme from 'theme/customTheme';
import { ReactComponent as CheckIcon } from 'assets/check-icon-green.svg';
import { ReactComponent as ValuationImage } from 'assets/valuation-image.svg';
import { ReactComponent as LockIcon } from 'assets/lock-icon-white.svg';
import { makeStyles, Theme } from '@material-ui/core/';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 35px 0 35px 0;
`;

export const Centerlize = styled.div`
  width: 86%;
  margin: 0 auto;
  max-width: 1496px;
  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      width: 90%;
    }
  }
`;

export const StyledBullet = styled(CheckIcon)`
  min-height: 10px;
  min-width: 14px;
  margin: 7px 12px 0 0;
`;

export const StyledValuationImage = styled(ValuationImage)`
  position: absolute;
  top: 40px;
  right: 50px;
  height: 100%;
  width: 100%;
  max-width: 400px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    top: 20px;
    right: 0;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    position: static;
    margin: 0 auto 20px auto;
  }
`;

export const StyledLockIcon = styled(LockIcon)`
  min-width: 18px;
  min-height: 18px;
  margin-right: 10px;
`;

export const useStyles = makeStyles<Theme>((theme) => {
  return {
    greenBox: {
      display: 'flex',
      background: theme.palette.primary.main,
      borderRadius: customTheme.borderRadius.reportSectionBorderRadius,
      padding: '30px 0',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: '20px 0',
      },
    },
    greenBoxText: {
      color: theme.palette.common.white,
      lineHeight: 1.35,
    },
    summaryItem: {
      padding: '0 10px 0 30px',
      '&:first-child': {
        borderRight: '1px solid rgba(255, 255, 255, 0.5)',
      },
      [theme.breakpoints.down('md')]: {
        padding: '0 10px 0 20px',
        '&:first-child': {
          border: 'none',
          marginBottom: 20,
        },
      },
    },
    summaryTitle: {
      fontSize: '16px',
      marginBottom: 4,
      [theme.breakpoints.down('md')]: {
        fontSize: '12px',
      },
    },
    summaryValue: {
      fontSize: '32px',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px',
      },
    },
    shadow: {
      boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.08)',
    },
    shadowContainer: {
      borderRadius: customTheme.borderRadius.reportSectionBorderRadius,
    },
    advantagesContainer: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: customTheme.borderRadius.reportSectionBorderRadius,
      background: theme.palette.common.white,
      padding: '60px 400px 60px 60px',
      [theme.breakpoints.down('sm')]: {
        padding: '30px 40px 30px 35px;',
        borderRadius: `${customTheme.borderRadius.reportSectionBorderRadius} ${customTheme.borderRadius.reportSectionBorderRadius} 0 0`,
      },
    },
    advantagesContent: {
      maxWidth: 525,
    },
    advantagesHeader: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: 1.35,
      [theme.breakpoints.down('md')]: {
        fontSize: '18px',
      },
    },
    feeSpan: {
      color: theme.palette.primary.main,
    },
    advantagesListItem: {
      fontWeight: 700,
      color: customTheme.colors.gray6,
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
    overviewContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '40px 70px 40px 60px',
      [theme.breakpoints.down('md')]: {
        padding: '30px 40px 45px 40px',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    overviewContent: {
      maxWidth: 580,
    },
    overviewHeader: {
      fontWeight: 700,
      lineheight: 1.35,
      marginBottom: 10,
    },
    overviewListContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    overvievListItem: {
      lineHeight: 1.35,
      color: customTheme.colors.gray6,
      margin: '0 30px 0 0',
      [theme.breakpoints.down('sm')]: {
        margin: '0 0 10px 0',
      },
    },
    overviewBtnContainer: {
      minWidth: 250,
      marginLeft: 20,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginLeft: 0,
      },
    },
  };
});
