import styled from 'styled-components';

export const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 21px;

  cursor: pointer;
`;
