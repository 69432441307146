import customTheme from 'theme/customTheme';

export const summaryPotentialDeal = [
  {
    name: 'Pre-money valuation',
    value: 'preMoneyValuation',
    isAmount: true,
  },
  {
    name: 'Funds invested',
    value: 'fundsInvested',
    isAmount: true,
  },
  {
    name: 'Post-money valuation',
    value: 'postMoneyValuation',
    isAmount: true,
  },
  {
    name: 'Investor share',
    value: 'investorShare',
    isAmount: false,
  },
];

export const summaryWeights: Array<{ name: string; value: string }> = [
  {
    name: 'Scorecard Method',
    value: 'scorecardMethodWeight',
  },
  {
    name: 'Dave Berkus',
    value: 'berkusMethodWeight',
  },
  {
    name: 'Risk Factor',
    value: 'riskFactorMethodWeight',
  },
  {
    name: 'VC Method',
    value: 'vcMethodWeight',
  },
  {
    name: 'DCF',
    value: 'dcfMethodWeight',
  },
];

export const tweleveYearsData = [
  {
    name: 'Revenues',
    value: 'revenues',
  },
  {
    name: 'Net Profit/Loss',
    value: 'incomeLoss',
  },
  {
    name: 'Current cash balance',
    value: 'currentCashBalance',
  },
];

export const financialBarLegendData = [
  {
    colorCode: customTheme.colors.MediumSpringGreen,
    label: 'Revenues',
  },
  {
    colorCode: customTheme.colors.DarkBlack,
    label: 'Gross Profit',
  },
  {
    colorCode: customTheme.colors.blue1,
    label: 'Gross Profit margin',
  },
];

export const financialYears5Data = [
  {
    name: 'Gross Profit',
    value: 'grossProfitResponse',
  },
  {
    name: 'EBITDA',
    value: 'EBITDA',
  },
  {
    name: 'NET income',
    value: 'netIncome',
  },
];

export const financialYears5DataPercentage = [
  {
    name: 'GROSS PROFIT MARGIN',
    value: 'grossProfitMargin',
  },
  {
    name: 'EBITDA MARGIN',
    value: 'EBITDAMargin',
  },
  {
    name: 'NET PROFIT MARGIN',
    value: 'netIncomeMargin',
  },
];
