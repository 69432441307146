import { ReactChild, ReactChildren } from 'react';
import { InputWrapper, InputLabel, InputFieldBox, InputFieldIcon, InputField, Error } from './formInputStyles';

export interface CommonStructure {
  [key: string]: string | number;
}
interface FormInputProps {
  label?: string;
  type: string;
  inputFieldStyle?: CommonStructure;
  inputWidth?: unknown;
  labelStyle?: CommonStructure;
  hideLabel?: boolean;
  placeholderStyle?: CommonStructure;
  placeholder?: string;
  name?: string;
  value?: string;
  isError?: boolean;
  inputFieldErrorMsg?: string;
  defaultValue?: string;
  inputFieldErrorStyle?: CommonStructure;
  inputIcon?: ReactChild | ReactChildren;
  iconPosition?: 'start' | 'end';
  padding?: string;
  onKeyUp?: (e: any) => void;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onKeyDown?: (e: any) => void;
}

const FormInput = ({
  label,
  type,
  value,
  placeholder,
  labelStyle,
  inputWidth,
  hideLabel,
  name,
  isError,
  inputFieldStyle,
  placeholderStyle,
  inputFieldErrorStyle,
  inputFieldErrorMsg = '',
  inputIcon,
  padding,
  defaultValue,
  iconPosition = 'start',
  ...rest
}: FormInputProps) => {
  return (
    <InputWrapper style={{ width: `${inputWidth}` }}>
      {!hideLabel && <InputLabel labelStyle={labelStyle}>{label}</InputLabel>}
      <InputFieldBox additionalStyle={inputFieldStyle} isError={isError}>
        {/^start$/.test(iconPosition) && <InputFieldIcon> {inputIcon}</InputFieldIcon>}
        <InputField
          name={name}
          value={value}
          type={type}
          placeholder={placeholder}
          placeholderStyle={placeholderStyle}
          fieldPadding={padding}
          {...rest}
          defaultValue={defaultValue}
        />
        {/^end$/.test(iconPosition) && <InputFieldIcon> {inputIcon}</InputFieldIcon>}
      </InputFieldBox>
      {inputFieldErrorMsg.length > 0 && (
        <Error errorStyle={inputFieldErrorStyle}>{inputFieldErrorMsg || isError}</Error>
      )}
    </InputWrapper>
  );
};

export default FormInput;
