import GrowthValLogo from 'components/molecules/GrowthValLogo/GrowthValLogo';
import Logo from 'assets/growthValLogo.svg';
import { Link } from 'react-router-dom';
import { NavbarWrapper, SpanWithMargin, LoginButton } from './styles';

const NavBar: React.FC = () => {
  return (
    <>
      <NavbarWrapper>
        <Link to="/">
          <GrowthValLogo logo={Logo} />
        </Link>
        <div>
          <SpanWithMargin>Already have an account?</SpanWithMargin>
          <Link to="/login">
            <LoginButton>Log in here</LoginButton>
          </Link>
        </div>
      </NavbarWrapper>
    </>
  );
};

export default NavBar;
