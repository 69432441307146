import { makeStyles } from '@material-ui/styles';
import customTheme from 'theme/customTheme';
import { Theme } from '@material-ui/core';

type StyleProps = {
  mobileWidthFirstCol: string;
  mobileWidthSecondCol: string;
  widthFirstCol?: string;
  widthSecondCol?: string;
};

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  dividerElement: {
    background: customTheme.colors.white,
  },
  headingCardOne: {
    width: ({ widthFirstCol }) => widthFirstCol,
    background: customTheme.colors.MediumSpringGreen,
    borderRadius: '5px 0 0 0',
    padding: '8px 18px',
    [theme.breakpoints.down('md')]: {
      width: ({ mobileWidthFirstCol }) => mobileWidthFirstCol,
      padding: '8px 10px',
    },
  },
  headingCardTwo: {
    width: ({ widthSecondCol }) => widthSecondCol,
    background: customTheme.colors.MediumSpringGreen,
    borderRadius: '0 5px 0 0',
    padding: '8px 18px',
    marginLeft: '1px',
    [theme.breakpoints.down('md')]: {
      width: ({ mobileWidthSecondCol }) => mobileWidthSecondCol,
      padding: '8px 10px',
    },
  },
  contentTitle: {
    width: ({ widthFirstCol }) => widthFirstCol,
    background: customTheme.colors.gray5,
    padding: '8px 18px',
    borderBottom: `1px solid ${customTheme.colors.white}`,
    borderRadius: 0,
    [theme.breakpoints.down('md')]: {
      width: ({ mobileWidthFirstCol }) => mobileWidthFirstCol,
      padding: '8px 10px',
    },
  },
  contentValue: {
    width: ({ widthSecondCol }) => widthSecondCol,
    background: customTheme.colors.gray5,
    padding: '8px 18px',
    marginLeft: '1px',
    borderBottom: `1px solid ${customTheme.colors.white}`,
    borderRadius: 0,
    [theme.breakpoints.down('md')]: {
      width: ({ mobileWidthSecondCol }) => mobileWidthSecondCol,
      padding: '8px 10px',
    },
  },
  mainTitle: {
    fontSize: '14px',
    lineHeight: '12px',
    color: customTheme.colors.white,
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  contentText: {
    fontSize: '14px',
    lineHeight: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  contentPadding: {
    padding: '0',
  },
  totalSection: {
    background: customTheme.colors.MediumSpringGreen,
    '& h6': {
      color: customTheme.colors.white,
    },
  },
  centerLizeValue: {
    textAlign: 'center',
  },
}));
