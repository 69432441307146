import React, { useEffect, useState } from 'react';
import MUIBUTTON from '@material-ui/core/Button';
import { useStyles } from './styles';
import customTheme from 'theme/customTheme';

interface ButtonProps {
  type?: string;
  bgColor?: string;
  textColor?: string;
  fontSize?: string;
  onClick?: (e?: any) => void;
  isDisable?: boolean;
  startIcon?: any;
  marginTop?: string;
  hoverColor?: string;
  id?: string;
}

const defaultProps = {
  isDisable: false,
  onClick: () => false,
  startIcon: '',
  type: 'button',
  marginTop: '41px',
  hoverColor: customTheme.colors.DarkBlack,
  id: '',
};

const Button: React.FC<ButtonProps> = ({ children, onClick, id, type, isDisable, startIcon, ...rest }) => {
  const classes = useStyles(rest);
  const [isDisableBtn, setIsBtnDisable] = useState<boolean>(false);

  useEffect(() => {
    if (isDisable !== undefined) setIsBtnDisable(isDisable);
  }, [isDisable]);
  return (
    <MUIBUTTON
      type="submit"
      className={classes.formButton}
      onClick={onClick}
      disabled={isDisableBtn}
      startIcon={startIcon}
      id={id}
    >
      {children}
    </MUIBUTTON>
  );
};

Button.defaultProps = defaultProps;
export default Button;
