import styled, { css } from 'styled-components';
import customTheme from 'theme/customTheme';

const {
  colors: { primaryColor, error },
} = customTheme;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DCFWrapper = styled.section`
  padding: 35px 0;
  color: ${({ theme }) => theme.colors.DarkBlack};
  font-family: ${(props) => props.theme.fontFamily.primaryFont};
`;

export const Title = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.secondaryFont};
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 24px;
`;

export const StyledParagraph = styled.p`
  margin-bottom: 35px;
`;

export const OptionToSkipSection = styled(FlexContainer)`
  margin-bottom: 12px;
`;

export const CheckBox = styled.input`
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

export const Label = styled.label`
  font-family: ${(props) => props.theme.fontFamily.primaryFont};
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  margin-left: 10px;
`;

export const EffectOFSkipping = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primaryFont};
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray6};
  margin-bottom: 25px;
`;

export const StyledH3 = styled.h3`
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: ${error};
  margin-bottom: 19px;
`;

export const Table = styled.section`
  width: 100%;
  min-width: 900px;
  border-radius: 5px 5px 0px 0px;
  overflow: hidden;
`;

export const RowTitleText = styled.span`
  display: inline-block;
  margin-right: 5px;
`;

export const TableRow = styled(FlexContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.LigthGray};
`;

export const RowWithOneData = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.LigthGray};
`;

export const TableHeadWrapper = styled.section<{ bg?: string; height?: string }>`
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  background-color: ${({ bg }) => (bg ? bg : primaryColor)};
  height: ${({ height }) => (height ? height : '28px')};
`;

export const TableHead = styled(FlexContainer)<{
  TableHeadStyle?: { [key: string]: string | number };
}>`
  color: ${({ theme }) => theme.colors.white};
  font-size: 13px;
  font-weight: 700;
  ${({ TableHeadStyle }) =>
    css`
      ${TableHeadStyle}
    `}
`;

export const RowTitle = styled.div<{
  makeTextBold?: boolean;
  style?: { [key: string]: string | number };
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  width: 25%;
  font-weight: ${({ makeTextBold }) => (makeTextBold ? '700' : '400')};
  ${({ style }) =>
    css`
      ${style}
    `}
`;
export const Currency = styled.div`
  padding-left: 10px;
  text-align: left;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  width: calc(25% - 10px);
`;

export const THead = styled.div<{ width?: string; mobileWidth?: boolean; padding?: string }>`
  margin: 10px 10px 10px 0;
  padding: 8px 15px 8px 8px;
  text-align: left;
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  width: ${({ width }) => (width ? width : '12%')};
  padding: ${({ padding }) => (padding ? padding : '8px 15px 8px 8px')};
  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      padding-left: 0px;
    }
  }
`;

export const Last12Month = styled.div`
  font-size: 13px;
  font-weight: 700;
  color: #fff;
  width: 12%;
  && {
    ${({ theme }) => theme.breakpoints.down('md')} {
      width: 18%;
      margin-left: -39px;
    }
  }
`;
