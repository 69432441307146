const customTheme = {
  colors: {
    white: '#FFFFFF',
    Gray90: '#e5e5e5',
    Gray95: '#f2f2f2',
    DarkBlack: '#171c1b',
    LightBlack: '#171c1b',
    MediumSpringGreen: '#00caa6',
    LigthGray: '#CCCCCC',
    DimGray: '#9B9B9B',
    lightGrey2: '#d5d5d5',
    tintOfSilver: '#c2c2c2',
    lighterGray: '#ddd',
    error: '#E05959',
    primaryColor: '#00CAA6',
    gray5: '#F7F7F7',
    gray6: '#555555',
    gray98: '#fafafa',
    skyBlue: 'rgba(0, 202, 166, 0.1)',
    ligthGreen: '#80E5D3',
    transparentBlack: 'rgba(0, 0, 0, 0.6)',
    reportSectionCardsShadow: '0px 0px 25px rgba(0, 0, 0, 0.08)',
    borderBottomGray: '#DDDDDD',
    blue1: '#7293A0',
  },
  fontFamily: {
    primaryFont: '"Mulish", sans-serif',
    secondaryFont: '"Prompt", sans-serif',
  },
  borderRadius: {
    primaryBorderRadius: '50px',
    secondaryBorder: '8px;',
    reportSectionBorderRadius: '5px',
  },
  size: {
    mobileS: '335px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  },
};

export default customTheme;
