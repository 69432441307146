import React, { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import ReactTooltip from 'react-tooltip';
import isEmpty from 'lodash/isEmpty';
import Typography from '@material-ui/core/Typography';
import { Select, MenuItem } from '@material-ui/core';
import CustomInput from 'components/molecules/FormInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import QuestionMark from 'components/layout/QuestionMark';
import theme from 'theme/customTheme';
import { Error } from 'style/CommonStyle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles, Symbol, Currency } from './styles';
import { stagesData } from './data';
import { CompanyStages, Choices } from './types';

interface PropsValidation {
  currencySymbol: string;
  errors: any;
  setFieldValue: (field: string, data: string | number | any[], validate: boolean) => any;
  handleBlur: (e: any) => any;
  touched: any;
  additionalInvestment: any[];
  values: any;
}

const AdditionalInvestment: React.FC<PropsValidation> = ({
  currencySymbol,
  errors,
  setFieldValue,
  handleBlur,
  touched,
  additionalInvestment,
  values,
}) => {
  const classes = useStyles();

  if (isEmpty(additionalInvestment)) return <></>;
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mt={2}
        mb={3}
        className={classes.generalContainer}
      >
        <Box
          display="flex"
          width="52%"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="baseline"
          mt={2}
          className={classes.questionTextSection}
        >
          <Box width="100%" display="flex">
            <Box>
              <Typography variant="subtitle2" className={classes.questionText}>
                {additionalInvestment[0].question}
              </Typography>
            </Box>
            {additionalInvestment[0].tooltip !== null && (
              <>
                <Box ml={1}>
                  <QuestionMark
                    bgFillColor="#CCCCCC"
                    symbolFillColor="#ffffff"
                    data-tip="hover on me will keep the tooltip"
                    data-for={`tool-tip-${additionalInvestment[0].question}`}
                  />
                </Box>
                <ReactTooltip delayHide={1000} effect="solid" id={`tool-tip-${additionalInvestment[0].question}`} />
              </>
            )}
          </Box>
        </Box>
        <Box display="flex" width="40%" flexDirection="column" className={classes.fieldSection}>
          {additionalInvestment[0].fieldType === 'dropdown' && (
            <>
              <Select
                error={
                  !isEmpty(errors) &&
                  errors.general[additionalInvestment[0].slug] &&
                  touched.general[additionalInvestment[0].slug]
                }
                name={`general.${additionalInvestment[0].slug}`}
                variant="outlined"
                className={classes.select}
                IconComponent={ExpandMoreIcon}
                value={
                  (!isEmpty(values) && !isEmpty(values.general) && values.general[additionalInvestment[0].slug]) ||
                  'Choose answer'
                }
                onChange={(e: any) => setFieldValue(`general.${additionalInvestment[0].slug}`, e.target.value, false)}
                onBlur={handleBlur}
              >
                <MenuItem value="Choose answer">
                  <Typography variant="body2" className={classes.dropdownText}>
                    Choose answer
                  </Typography>
                </MenuItem>

                {additionalInvestment[0].choices.length > 0 &&
                  additionalInvestment[0].choices.map((item: Choices) => {
                    return <MenuItem value={item.value}>{item.text}</MenuItem>;
                  })}
              </Select>
              {!isEmpty(errors) &&
                errors.general[additionalInvestment[0].slug] &&
                touched.general[additionalInvestment[0].slug] && (
                  <Error>{errors.general[additionalInvestment[0].slug]}</Error>
                )}
            </>
          )}
        </Box>
      </Box>
      <Box marginTop="35px" />
      <Divider className={classes.horizontalDivider} />
    </>
  );
};

export default AdditionalInvestment;
