import { FC, useState } from 'react';
import { Box } from '@material-ui/core';
import GrowthValLogo from 'components/molecules/GrowthValLogo/GrowthValLogo';
import BurgerBtn from 'components/molecules/BurgerBtn';
import Logo from 'assets/growthVal-logo-white.svg';
import { getLocalStorageItem } from 'utils/getLocalStorageItem';
import { logoutUser } from 'utils/helperMethod';
import NavItem from './components/NavItem';
import UserSettings from './components/UserSettings';
import { NavLinkType } from './types';
import { createNavLinks, getUserName } from './helpers';
import { Wrapper, StyledLink, MobileUserName, StyledNavItems, DesktopBox, MobileBox, NavItemsDrawer } from './styles';

interface PropTypes {
  links?: NavLinkType[];
  options?: NavLinkType[];
}

const defaultLinks: NavLinkType[] = [
  {
    to: '/questionnaires',
    text: 'Create valuation',
  },
  {
    to: `/report`,
    text: 'Report',
  },
];

const defaultOptions = [
  {
    text: 'Settings',
    to: '/settings',
  },
  {
    text: 'Logout',
    onClick: logoutUser,
  },
];

const MainNav: FC<PropTypes> = ({ links = defaultLinks, options }) => {
  const [isDrawerOpen, setDrawer] = useState<boolean>(false);
  const storageData = getLocalStorageItem('growthVal');
  const userName = getUserName(storageData);

  const userSettings = options ? createNavLinks(options) : createNavLinks(defaultOptions);
  const navLinks = createNavLinks(links);
  const mobileLinks = createNavLinks([...navLinks, ...userSettings]);

  return (
    <Wrapper>
      <StyledLink to="/dashboard">
        <GrowthValLogo logo={Logo} />
      </StyledLink>
      <Box display="flex" justifyContent="space-between" width="100%">
        <NavItemsDrawer
          anchor="right"
          onClose={() => setDrawer(false)}
          onOpen={() => setDrawer(true)}
          open={isDrawerOpen}
        >
          <StyledNavItems>
            {!!userName && <MobileUserName>{userName}</MobileUserName>}
            {mobileLinks.map((item: NavLinkType) => (
              <NavItem {...item} key={item.id} />
            ))}
          </StyledNavItems>
        </NavItemsDrawer>

        <DesktopBox display="flex" justifyContent="space-between" width="100%">
          <StyledNavItems>
            {navLinks.map((item: NavLinkType) => (
              <NavItem {...item} key={item.id} />
            ))}
          </StyledNavItems>
          <UserSettings userName={userName} options={userSettings} />
        </DesktopBox>
      </Box>

      <MobileBox>
        {/* Hides burger button for desktop */}
        <BurgerBtn onClick={() => setDrawer((state) => !state)} />
      </MobileBox>
    </Wrapper>
  );
};

export default MainNav;
