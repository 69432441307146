import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { Bar } from 'react-chartjs-2';
import CardColumnSection from 'components/organisms/CardColumsSection';
import { commaSeparatedNumber } from 'utils/helperMethod';
import { ScoreCardReportProps, GraphData, ColumnsData, WeightDataTypes } from './types';
import { useStyles } from './styles';

const ScoreCardReport: React.FC<ScoreCardReportProps> = ({ scoreCardDetails, currency }) => {
  const classes = useStyles();
  const [graphData, setGraphData] = useState<GraphData>();
  const [weights, setWeights] = useState<ColumnsData[] | any>();
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    const dataRange: number[] = [];
    const labels: string[] = [];
    const weightsData: WeightDataTypes[] = [];
    if (isEmpty(scoreCardDetails)) {
      return;
    }
    scoreCardDetails.scorecardCategoriesValuation.forEach((item) => {
      dataRange.push(Number(item.categoryWeightedImpact - 100));
      labels.push(item.name);
      weightsData.push({
        title: item.name,
        value: `${Number(item.defaultWeight)}%`,
      });
    });
    setWeights(weightsData);
    setLabels(labels);
    if (dataRange.length > 0) {
      setGraphData({
        labels,
        datasets: [
          {
            backgroundColor: '#00CAA6',
            data: dataRange,
          },
        ],
      });
    }
  }, [scoreCardDetails]);

  const callBarGraph = useCallback(() => {
    return (
      <Bar
        type="bar"
        data={graphData}
        options={{
          indexAxis: 'y',
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              grid: {
                display: false,
              },
              ticks: {
                autoSkip: false,
                font: {
                  size: window.matchMedia('(max-width: 767px)').matches ? '6px' : '10px',
                  family: 'Mulish',
                  weight: 400,
                },
                padding: 12,
                callback: function (value: number, index: any, ticks: any) {
                  if (/\s/.test(labels[index])) {
                    return labels[index].split('\n');
                  } else {
                    return labels[index];
                  }
                },
              },
            },
            x: {
              ticks: {
                callback: function (value: number) {
                  return `${value}%`;
                },
              },
            },
          },
        }}
      />
    );
  }, [graphData, labels]);

  const { scorecardWeightedAmount } = scoreCardDetails;
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" className={classes.scoreCardValue}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Scorecard method
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="subtitle2" className={classes.cardSubText}>
            The company valuation according to the Scorecard method is&nbsp;
            <span className={classes.greenText}>
              {currency}
              {commaSeparatedNumber(Number(scorecardWeightedAmount))}
            </span>
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={3} className={classes.scoreCardsSection}>
        <Card className={classes.reportCard}>
          <Box className={classes.cardTextBox}>
            <Typography variant="subtitle2" className={classes.cardSubText}>
              The scorecard valuation method, developed by angel investor Bill Payne, is used to value early-stage
              startups.
            </Typography>
            <Box mt={4} />
            <Typography variant="body1" className={classes.cardSubText}>
              Under this method, we compare your startup with similar startups in your location, industry, stage (where
              sufficient data is publicly available) by asking a set of questions under 6 main categories. Based on the
              answers, your company is assigned a score, which is used to calculate a normalized impact on the category
              score, considering the stage of your business.
            </Typography>
            <Box mt={4} />
            <Typography variant="subtitle2" className={classes.cardSubText}>
              We then calculate factors in 6 categories by multiplying your company’s category score with the
              corresponding weights.&nbsp;
              <Typography variant="body1" className={classes.cardSubText}>
                The sum of the factors is multiplied with our estimate of the average valuation of startups in your
                location, industry, stage to determine your startup’s valuation or with the valuation provided by the
                you in the Valuation Parameters.
              </Typography>
            </Typography>
            <Box mt={2}>
              <Typography variant="subtitle1" className={classes.pointHeading}>
                Method pros
              </Typography>
              <Box mt={1} />
              <Box display="flex">
                <span className={classes.bullet}>•</span>
                <Typography variant="body1" className={classes.pointText}>
                  Takes into account a wide range of qualitative aspects of startups
                </Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography variant="subtitle1" className={classes.pointHeading}>
                Method cons
              </Typography>
              <Box mt={1} />
              <Box display="flex">
                <span className={classes.bullet}>•</span>
                <Typography variant="body1" className={classes.pointText}>
                  Dependent on the availability and quality of baseline valuation data
                </Typography>
              </Box>
            </Box>
          </Box>
        </Card>
        <Card className={classes.reportCard}>
          <Box display="flex" flexDirection="column" className={classes.cardTextBox}>
            <Typography variant="subtitle2" className={classes.pointText}>
              The graph below represents a summary of how your startup scores compared to similar startup's scores:
            </Typography>
            <Box mb={2} />
            <Box>{callBarGraph()}</Box>
            <Box mt={3}>
              <CardColumnSection
                firstTitle="Comparison category"
                secondTitle="Weight"
                data={weights || []}
                totalValue="100%"
                mobileWidthFirstCol="75%"
                mobileWidthSecondCol="24%"
              />
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default ScoreCardReport;
