import styled, { css } from 'styled-components';
export const LogoWrapper = styled.div<{ logoStyle: any }>`
  height: 31px;
  width: 158px;

  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      height: 26px;
      width: 151px;
    }
  }
  ${(props) =>
    css`
      ${props.logoStyle}
    `}
  & > img {
    width: 100%;
    height: 100%;
  }
`;
