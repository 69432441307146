import React, { useCallback, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import CenterLizedBox from 'components/organisms/CenterlizedBox';
import { isValidEmail } from 'utils/helperMethod';
import { ForgotPasswordLink } from 'utils/ApiUrls';
import EmailValidationForm from 'components/organisms/EmailValidationForm';
import { CardDetails, Title, SubTitle, OuterBox } from 'style/CommonStyle';

type ForgotPasswordResponse = {
  status: number;
};

export type ErrorResponse = {
  response: {
    data: {
      status: number;
      error: {
        message: string;
      };
    };
  };
};
const ForgotPassword: React.FC = () => {
  const [isEmailVerified, setEmailVerified] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const renderLayout = useCallback(() => {
    const submitAction = (e: any) => {
      e.preventDefault();
      axios
        .post<AxiosResponse>(ForgotPasswordLink, {
          email,
        })
        .then((res: ForgotPasswordResponse) => {
          if (res.status === 201) {
            setEmailVerified(true);
          }
        })
        .catch((error: ErrorResponse) => {
          const {
            response: {
              data: { status, error: responseError },
            },
          } = error;
          if (status === 404 && responseError?.message) {
            setErrorMessage('This email account is not valid');
          }
        });
    };

    const validateEmail = (value: string) => {
      setEmail(value);
      setErrorMessage('');
      if (!isValidEmail(email)) {
        setErrorMessage('Please enter valid email');
      }
    };

    if (isEmailVerified) {
      return (
        <OuterBox>
          <CardDetails>
            <Title>Check your email</Title>
            <SubTitle>We have sent you an email with a link to reset your password.</SubTitle>
          </CardDetails>
        </OuterBox>
      );
    }
    if (!isEmailVerified) {
      return (
        <EmailValidationForm
          title="Password recovery"
          subTitle="Enter your email address and we will send you a link to recover your password"
          btnText="Send me the link"
          readOnly={false}
          submitForm={submitAction}
          userEmail={email}
          helpText={errorMessage}
          validateEmail={(value) => validateEmail(value)}
          isDisable={errorMessage !== '' || email === ''}
        />
      );
    }
  }, [isEmailVerified, errorMessage, email]);
  return <CenterLizedBox showLogin>{renderLayout()}</CenterLizedBox>;
};

export default ForgotPassword;
