import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Formik, Form, Field, FormikProps } from 'formik';
import CustomInput from 'components/molecules/FormInput';
import CenterLizedBox from 'components/organisms/CenterlizedBox';
import CustomButton from 'components/molecules/button';
import { ResetPasswordRequest } from 'utils/ApiUrls';
import { CardDetails, FormSection, Label, OuterBox, SubTitle, Title, Error } from 'style/CommonStyle';
import { passwordValidation } from './validationSchema';
import { FieldSection, FormStyle } from './styles';

interface FormValues {
  password: string;
  confirmPassword: string;
}
type ParamsValue = { passwordToken: string };

interface ResetPasswordProps extends RouteComponentProps<ParamsValue> {
  value: {
    match: {
      params: ParamsValue;
    };
  };
}

type ResetPasswordResponse = { status: number };
type ErrorResponse = {
  response: {
    data: {
      status: number;
      error: {
        message: string;
      };
    };
  };
};

const initialValues: FormValues = { password: '', confirmPassword: '' };

const ResetPassword: React.FC<ResetPasswordProps> = ({ match }) => {
  const [success, setSuccess] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const submitHandler = (values: FormValues) => {
    if (values) {
      const data = { password: values.password };
      const token = match?.params?.passwordToken;
      axios
        .post<AxiosResponse>(ResetPasswordRequest(token), data)
        .then((res: ResetPasswordResponse) => {
          if (res.status === 204) {
            setSuccess(true);
          }
        })
        .catch((error: ErrorResponse) => {
          const {
            response: {
              data: { status, error: responseError },
            },
          } = error;
          if (status === 404 && responseError?.message) {
            setServerError('Something went wrong!');
          }
        });
    }
  };
  const renderLayout = () => {
    if (success) {
      return (
        <OuterBox>
          <CardDetails>
            <Title>Password changed</Title>
            <SubTitle>Your password has been successfully changed.</SubTitle>
            <Link to="/">
              <CustomButton type="button">Go to Login </CustomButton>
            </Link>
          </CardDetails>
        </OuterBox>
      );
    }
    return (
      <CardDetails>
        <Title>Password recovery</Title>
        <SubTitle>Set up your new password:</SubTitle>
        <FormSection>
          <Formik initialValues={initialValues} validationSchema={passwordValidation} onSubmit={submitHandler}>
            {({ setFieldValue, errors, handleBlur, touched, values }) => {
              const isDisableBtn =
                !isEmpty(errors) || (!isEmpty(values) && isEmpty(values.password) && isEmpty(values.confirmPassword));
              return (
                <Form>
                  <FormStyle>
                    <FieldSection>
                      <Label>Password:</Label>
                      <Field
                        name="password"
                        type="password"
                        value={(!isEmpty(values) && values.password) || ''}
                        component={CustomInput}
                        handleOnChange={(password: string) => setFieldValue('password', password, false)}
                        placeholder="••••••••"
                        handleBlur={handleBlur}
                        isError={!isEmpty(errors) && errors.password && errors.password}
                        helpText={!isEmpty(errors) && errors.password ? errors.password : ''}
                      />
                    </FieldSection>
                    <FieldSection>
                      <Label>Confirm password:</Label>
                      <Field
                        name="confirmPassword"
                        type="password"
                        value={(!isEmpty(values) && values.confirmPassword) || ''}
                        handleOnChange={(confirmPassword: string) => {
                          setFieldValue('confirmPassword', confirmPassword, false);
                        }}
                        component={CustomInput}
                        handleBlur={handleBlur}
                        placeholder="••••••••"
                        isError={!isEmpty(errors) && errors.confirmPassword && errors.confirmPassword}
                        helpText={!isEmpty(errors) && errors.confirmPassword ? errors.confirmPassword : ''}
                      />
                    </FieldSection>
                    {serverError && <Error>{serverError}</Error>}
                    <CustomButton type="submit" isDisable={isDisableBtn}>
                      Update password
                    </CustomButton>
                  </FormStyle>
                </Form>
              );
            }}
          </Formik>
        </FormSection>
      </CardDetails>
    );
  };
  return <CenterLizedBox>{renderLayout()}</CenterLizedBox>;
};

export default ResetPassword;
