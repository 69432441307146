import FullscreenLoader from 'components/molecules/FullscreenLoader';
import useLoadingContext from './useLoadingContext';

interface PropTypes {
  children: any;
}

const ViewPortLoader: React.FC<PropTypes> = ({ children }) => {
  const { loading } = useLoadingContext();
  if (!loading) return children;

  return <FullscreenLoader />;
};

export default ViewPortLoader;
