import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Bar, Pie } from 'react-chartjs-2';
import { Box, Typography, Card, Divider } from '@material-ui/core';
import { getAmountString, commaSeparatedNumber } from 'utils/helperMethod';
import { useStyles } from './styles';
import { FinancialReportProps, Graph, LegendsType, FinancialBarGraph } from './types';
import { getColorPalette, pieChartOptions, FinancialBarConfig } from './helper';
import { tweleveYearsData, financialBarLegendData, financialYears5Data, financialYears5DataPercentage } from './data';
import customTheme from 'theme/customTheme';

const FinancialReport: React.FC<FinancialReportProps> = ({ currency, financialDetails }) => {
  const classes = useStyles();
  const [pieChartData, setPieChart] = useState<Graph>();
  const [pieChartLegends, setPieChartLegends] = useState<LegendsType>([]);
  const [barGraphData, setBarGraphData] = useState<FinancialBarGraph>();

  useEffect(() => {
    const { currentShareHolders, yearsFinancialData } = financialDetails;
    const pieChartLabels: Array<string> = [];
    const pieChartRange: Array<string> = [];
    const legendsPieChart: Array<{ label: string; colorCode: string }> = [];

    if (!isEmpty(currentShareHolders)) {
      currentShareHolders.forEach(({ name, fundsPercentage, value }, index) => {
        pieChartLabels.push(name);
        pieChartRange.push(fundsPercentage);
        legendsPieChart.push({
          label: `${name}(${getAmountString(value, currency)})`,
          colorCode: getColorPalette(currentShareHolders.length)[index],
        });
      });
      setPieChartLegends(legendsPieChart);
      setPieChart({
        labels: pieChartLabels,
        datasets: [
          {
            data: pieChartRange,
            backgroundColor: getColorPalette(pieChartRange.length),
          },
        ],
      });
    }
    if (!isEmpty(yearsFinancialData)) {
      const barDataRange = yearsFinancialData.map(({ question, value }) => {
        return {
          [question]: Object.keys(value)
            .filter((key) => key !== 'last12Month')
            .map((key) => value[key]),
        };
      });
      const barThickness = window.matchMedia('(max-width: 767px)').matches ? 15 : 30;
      setBarGraphData({
        labels: ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'],
        datasets: [
          {
            type: 'bar',
            label: 'Revenue',
            fill: false,
            data:
              !isEmpty(barDataRange) && !isEmpty(barDataRange[0])
                ? barDataRange[0][yearsFinancialData[0].question]
                : [0],
            backgroundColor: customTheme.colors.MediumSpringGreen,
            yAxisID: 'y',
            barThickness: barThickness,
          },
          {
            type: 'bar',
            label: 'Gross Profit',
            fill: false,
            data:
              !isEmpty(barDataRange) && !isEmpty(barDataRange[1])
                ? barDataRange[1][yearsFinancialData[1].question]
                : [0],
            backgroundColor: customTheme.colors.DarkBlack,
            yAxisID: 'y',
            barThickness: barThickness,
          },
          {
            type: 'line',
            fill: false,
            label: 'Gross Profit margin',
            data:
              !isEmpty(barDataRange) && !isEmpty(barDataRange[2])
                ? barDataRange[2][yearsFinancialData[2].question]
                : [0],
            backgroundColor: customTheme.colors.blue1,
            yAxisID: 'y1',
            barThickness: barThickness,
          },
        ],
      });
    }
  }, [financialDetails, currency]);

  if (isEmpty(financialDetails)) return <></>;

  const { last12MonthsData, year5Data } = financialDetails;
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" className={classes.scoreCardValue}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Financials
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={3} className={classes.scoreCardsSection}>
        <Card className={classes.financialPieChartSection}>
          <Box p="20px 30px 0 30px">
            <Typography variant="subtitle1" className={classes.pointHeading}>
              Last 12 months
            </Typography>
          </Box>
          <Box mt={1} mb={2} />
          <Divider />
          <Box display="flex" flexDirection="column" mt={1} className={`${classes.financialCard}`}>
            {!isEmpty(last12MonthsData) &&
              tweleveYearsData.map(({ name, value }) => {
                const amount = last12MonthsData[value];
                return (
                  <Box display="flex" className={classes.listDetails} justifyContent="space-between">
                    <Typography variant="subtitle2" className={classes.labelText}>
                      {name}
                    </Typography>
                    <Box mr={0}>
                      <Typography variant="subtitle2" className={classes.labelText}>
                        {amount !== '-' ? `${currency}${commaSeparatedNumber(Number(amount))}` : '-'}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            <Box mt={2} mr={1} marginLeft="6px" display="flex" justifyContent="space-between">
              <Typography variant="subtitle2" className={classes.labelText}>
                Money invested in the company
              </Typography>
              <Box ml={4} />
              <Typography variant="subtitle2" className={classes.labelText}>
                {currency}
                {commaSeparatedNumber(Number(last12MonthsData.moneyInvestedInCompany))}
              </Typography>
            </Box>
          </Box>
          <Box p="20px 30px 0 30px">
            <Typography variant="subtitle1" className={classes.pointHeading}>
              Current shareholders
            </Typography>
          </Box>
          <Box mt={1} mb={2} />
          <Divider />
          <Box
            width="100%"
            display="flex"
            p="0 30px 0 30px"
            flexDirection="column-reverse"
            className={classes.chartSection}
          >
            <Box className={classes.financialPieChartMargin}>
              <Pie type="pie" data={pieChartData} options={pieChartOptions} />
            </Box>
            <Box>
              <Box mt={2} display="flex" flexWrap="wrap" width="100%">
                {!isEmpty(pieChartLegends) &&
                  pieChartLegends.map(({ label, colorCode }) => {
                    return (
                      <Box display="flex" className={classes.financialPieLegend}>
                        <span style={{ background: colorCode }} className={classes.square}></span>
                        <Typography variant="body1" className={classes.legendLabel}>
                          {label}
                        </Typography>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Box>
        </Card>
        <Card className={classes.financialBarSection}>
          <Box p="20px 30px 0 30px">
            <Typography variant="subtitle1" className={classes.pointHeading}>
              Forecast financials
            </Typography>
          </Box>
          <Box mt={1} mb={2} />
          <Divider />
          <Box display="flex" flexDirection="column" className={classes.financialCard}>
            <Box mb={2}>
              <Box display="flex" width="100%" justifyContent="center">
                {!isEmpty(financialBarLegendData) &&
                  financialBarLegendData.map(({ label, colorCode }) => {
                    return (
                      <Box display="flex" className={classes.financialBarLegend}>
                        <span style={{ background: colorCode }} className={classes.square}></span>
                        <Typography variant="body1" className={classes.legendLabel}>
                          {label}
                        </Typography>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
            <Box className={classes.financialBarGraph}>
              <Bar type="bar" data={barGraphData} options={FinancialBarConfig(currency)} />
            </Box>
          </Box>
          <Box p="20px 30px 0 30px">
            <Typography variant="subtitle1" className={classes.pointHeading}>
              In Year 5
            </Typography>
          </Box>
          <Box mt={1} mb={2} />
          <Divider />
          <Box display="flex" className={`${classes.financialCard} ${classes.forecastData}`}>
            <Box display="flex" flexDirection="column" width="30%" className={classes.amountData}>
              {!isEmpty(year5Data) &&
                financialYears5Data.map(({ name, value }) => {
                  const amount = year5Data[value];
                  return (
                    <Box display="flex" className={classes.listDetails} justifyContent="space-between">
                      <Typography variant="subtitle2" className={classes.labelText}>
                        {name}
                      </Typography>
                      <Box mr={0}>
                        <Typography variant="subtitle2" className={classes.labelText}>
                          {amount !== '-' && amount !== null ? getAmountString(amount, currency) : '-'}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
            <Box width="60%" className={classes.forecastStats} display="flex" justifyContent="space-between">
              {!isEmpty(year5Data) &&
                financialYears5DataPercentage.map(({ name, value }) => {
                  return (
                    <Box display="flex" flexDirection="column" className={classes.percentageSection}>
                      <Box maxHeight="37px" mb={2}>
                        <Typography variant="caption" className={classes.grayText}>
                          {name}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="subtitle1" className={classes.greenPercentageText}>
                          {year5Data[value] !== null && year5Data[value] !== '-' ? `${year5Data[value]}%` : '-'}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default FinancialReport;
