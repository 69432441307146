import { useEffect } from 'react';
import HorizontalScroll from 'components/molecules/HorizontalScroll';
import Box from '@material-ui/core/Box';
import TableCell from 'components/molecules/tableCell/TableCell';
import ReactTooltip from 'react-tooltip';
import QuestionMark from 'components/layout/QuestionMark';
import { defaultCurrency } from 'components/templates/Questionaries/constant';
import { TableHeadData } from './DCFConstants';
import { QuestionIcon } from 'components/icons/QuestionMarkIcon';
import { useDCFContext } from 'context/DCFContext/DCFContext';
import { DCFTableProps, RowType } from './types';
import TableHead from 'components/organisms/DCFReport/TableHead';
import SkipDCF from './SkipDCF';

import {
  DCFWrapper,
  Table,
  TableHeadWrapper,
  TableRow,
  RowTitle,
  RowTitleText,
  RowWithOneData,
  THead,
  Currency,
  StyledH3,
  Last12Month,
} from './styles';

const DCFTable = ({ selectedCountryCurrency, lastYearRevenue, revenueInFiveYear }: DCFTableProps) => {
  const {
    handleChange,
    handleRowWithSingleField,
    setRevenues,
    setGrossProfit,
    setCostOfGoodsSold,
    setOperatingExpenses,
    setEBITDA,
    setEBIT,
    setDepreciationAndAmort,
    setInterestExpenses,
    setTaxes,
    setWorkingCapital,
    setFreeCashFlow,
    setCapitalExpenditure,
    setCash,
    setBorrowings,
    setNetProfitLoss,
    calculateNetProfitAndLoss,
    calculateFreeCashFlow,
    calculateEBITDA,
    calculateEBIT,
    getRevenues,
    calculateGrossProfit,
    DCFTableData,
    setSkipFinancialForecast,
    skipFinancialForecast,
    setLastYearRevenue,
    setYear5Revenue,
  } = useDCFContext();

  const {
    revenues,
    costOfGoodsSold,
    grossProfit,
    operatingExpenses,
    EBITDA,
    depreciationAndAmort,
    EBIT,
    interestExpenses,
    taxes,
    netProfitLoss,
    workingCapital,
    depAmor,
    capitalExpenditure,
    freeCashFlow,
    cash,
    borrowings,
  } = DCFTableData;

  useEffect(() => {
    setLastYearRevenue(lastYearRevenue);
    setYear5Revenue(revenueInFiveYear);
    getRevenues(lastYearRevenue, revenueInFiveYear);
  }, [lastYearRevenue, revenueInFiveYear]);

  return (
    <DCFWrapper>
      <SkipDCF />
      {!skipFinancialForecast && (
        <>
          <StyledH3>Please fill in all the fields in the table below: </StyledH3>
          <HorizontalScroll>
            <Table>
              <TableHeadWrapper height="50px">
                <Currency>
                  Currency ({selectedCountryCurrency == '' ? defaultCurrency : selectedCountryCurrency})
                </Currency>
                {TableHeadData.map(({ id, title }) => (
                  <THead key={id}>{title}</THead>
                ))}
              </TableHeadWrapper>
              <TableRow>
                <RowTitle>
                  <RowTitleText>{revenues.question}</RowTitleText>
                  {revenues.tooltip !== null && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={revenues.tooltip}
                          data-for="tool-tip-revenues"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-revenues" />
                    </>
                  )}
                </RowTitle>
                {revenues.values.map((cellValue: RowType, idx: number, array: RowType[]) => {
                  const isEditable = idx >= 1 && idx <= array.length - 2;
                  const styles = {
                    fontSize: '14px',
                    ...(!isEditable ? { color: '#9B9B9B' } : {}),
                  };

                  return (
                    <TableCell
                      cellStyle={styles}
                      cellData={cellValue}
                      isEditable={isEditable}
                      onBlur={(e) => handleChange(e, cellValue, setRevenues)}
                    />
                  );
                })}
              </TableRow>
              <TableRow>
                <RowTitle>
                  <RowTitleText>{costOfGoodsSold.question}</RowTitleText>
                  {costOfGoodsSold.tooltip !== null && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={costOfGoodsSold.tooltip}
                          data-for="tool-tip-costOfGoodsSold"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-costOfGoodsSold" />
                    </>
                  )}
                </RowTitle>
                {costOfGoodsSold.values.map((data, idx) => (
                  <TableCell cellData={data} isEditable onBlur={(e) => handleChange(e, data, setCostOfGoodsSold)} />
                ))}
              </TableRow>

              <TableRow>
                <RowTitle makeTextBold>
                  <RowTitleText> {grossProfit.question}</RowTitleText>
                  {grossProfit.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={grossProfit.tooltip}
                          data-for="tool-tip-grossProfit"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-grossProfit" />
                    </>
                  )}
                </RowTitle>
                {grossProfit.values.map((data, idx) => (
                  <TableCell cellData={data} removeBorder makeBold />
                ))}
              </TableRow>

              <TableRow>
                <RowTitle>
                  <RowTitleText> {operatingExpenses.question}</RowTitleText>
                  {operatingExpenses.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={operatingExpenses.tooltip}
                          data-for="tool-tip-operatingExpenses"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-operatingExpenses" />
                    </>
                  )}
                </RowTitle>
                {operatingExpenses.values.map((cellData, idx) => (
                  <TableCell
                    cellData={cellData}
                    isEditable
                    onBlur={(e) => handleChange(e, cellData, setOperatingExpenses)}
                  />
                ))}
              </TableRow>
              <TableRow>
                <RowTitle makeTextBold>
                  <RowTitleText> {EBITDA.question}</RowTitleText>
                  {EBITDA.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={EBITDA.tooltip}
                          data-for="tool-tip-EBITDA"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-EBITDA" />
                    </>
                  )}
                </RowTitle>
                {EBITDA.values.map((data, idx) => (
                  <TableCell removeBorder makeBold cellData={data} />
                ))}
              </TableRow>

              <TableRow>
                <RowTitle>
                  <RowTitleText> {depreciationAndAmort.question}</RowTitleText>
                  {depreciationAndAmort.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={depreciationAndAmort.tooltip}
                          data-for="tool-tip-depreciationAndAmort"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-depreciationAndAmort" />
                    </>
                  )}
                </RowTitle>
                {depreciationAndAmort.values.map((cellValue, idx) => (
                  <TableCell
                    cellData={cellValue}
                    isEditable
                    onBlur={(e) => handleChange(e, cellValue, setDepreciationAndAmort)}
                  />
                ))}
              </TableRow>
              <TableRow>
                <RowTitle makeTextBold>
                  <RowTitleText> {EBIT.question} </RowTitleText>
                  {EBIT.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={EBIT.tooltip}
                          data-for="tool-tip-EBIT"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-EBIT" />
                    </>
                  )}
                </RowTitle>
                {EBIT.values.map((cellValue, idx) => (
                  <TableCell removeBorder makeBold cellData={cellValue} />
                ))}
              </TableRow>

              <TableRow>
                <RowTitle>
                  <RowTitleText>{interestExpenses.question}</RowTitleText>
                  {interestExpenses.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={interestExpenses.tooltip}
                          data-for="tool-tip-interestExpenses"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-interestExpenses" />
                    </>
                  )}
                </RowTitle>
                {interestExpenses.values.map((cellValue, idx) => (
                  <TableCell
                    cellData={cellValue}
                    isEditable
                    onBlur={(e) => handleChange(e, cellValue, setInterestExpenses)}
                  />
                ))}
              </TableRow>

              <TableRow>
                <RowTitle>
                  <RowTitleText>{taxes.question}</RowTitleText>
                  {taxes.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={taxes.tooltip}
                          data-for="tool-tip-taxes"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-taxes" />
                    </>
                  )}
                </RowTitle>
                {taxes.values.map((cellValue, idx) => (
                  <TableCell cellData={cellValue} isEditable onBlur={(e) => handleChange(e, cellValue, setTaxes)} />
                ))}
              </TableRow>
              <TableRow>
                <RowTitle makeTextBold>
                  <RowTitleText>{netProfitLoss.question}</RowTitleText>
                  {netProfitLoss.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={netProfitLoss.tooltip}
                          data-for="tool-tip-netProfitLoss"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-netProfitLoss" />
                    </>
                  )}
                </RowTitle>
                {netProfitLoss.values.map((data, idx) => (
                  <TableCell removeBorder makeBold cellData={data} />
                ))}
              </TableRow>
              <TableRow>
                <RowTitle>
                  <RowTitleText>{depreciationAndAmort.question}</RowTitleText>
                  {depreciationAndAmort.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={depreciationAndAmort.tooltip}
                          data-for="tool-tip-depreciationAndAmort"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-depreciationAndAmort" />
                    </>
                  )}
                </RowTitle>
                {depAmor.values.map((data, idx) => (
                  <TableCell cellData={data} removeBorder />
                ))}
              </TableRow>
              <TableRow>
                <RowTitle>
                  <RowTitleText> {workingCapital.question}</RowTitleText>
                  {workingCapital.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={workingCapital.tooltip}
                          data-for="tool-tip-workingCapital"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-workingCapital" />
                    </>
                  )}
                </RowTitle>
                {workingCapital.values.map((cellValue, idx) => (
                  <TableCell
                    cellData={cellValue}
                    isEditable
                    onBlur={(e) => handleChange(e, cellValue, setWorkingCapital)}
                  />
                ))}
              </TableRow>
              <TableRow>
                <RowTitle>
                  <RowTitleText>{capitalExpenditure.question}</RowTitleText>
                  {capitalExpenditure.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={capitalExpenditure.tooltip}
                          data-for="tool-tip-capitalExpenditure"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-capitalExpenditure" />
                    </>
                  )}
                </RowTitle>
                {capitalExpenditure.values.map((cellValue, idx) => (
                  <TableCell
                    cellData={cellValue}
                    isEditable
                    onBlur={(e) => handleChange(e, cellValue, setCapitalExpenditure)}
                  />
                ))}
              </TableRow>
              <TableRow>
                <RowTitle makeTextBold>
                  <RowTitleText>{freeCashFlow.question}</RowTitleText>
                  {freeCashFlow.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={freeCashFlow.tooltip}
                          data-for="tool-tip-freeCashFlow"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-freeCashFlow" />
                    </>
                  )}
                </RowTitle>
                {freeCashFlow.values.map((data, idx) => (
                  <TableCell cellData={data} removeBorder makeBold />
                ))}
              </TableRow>
              <RowWithOneData>
                <RowTitle>
                  <RowTitleText>{cash.question}</RowTitleText>
                  {cash.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={cash.tooltip}
                          data-for="tool-tip-cash"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-cash" />
                    </>
                  )}
                </RowTitle>
                <TableCell cellData={cash.values} onBlur={(e) => handleRowWithSingleField(e, setCash)} isEditable />
              </RowWithOneData>
              <RowWithOneData>
                <RowTitle>
                  <RowTitleText>{borrowings.question}</RowTitleText>
                  {borrowings.tooltip && (
                    <>
                      <Box ml={1} mt={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={borrowings.tooltip}
                          data-for="tool-tip-borrowings"
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-borrowings" />
                    </>
                  )}
                </RowTitle>
                <TableCell
                  cellData={borrowings.values}
                  isEditable
                  onBlur={(e) => handleRowWithSingleField(e, setBorrowings)}
                />
              </RowWithOneData>
            </Table>
          </HorizontalScroll>
        </>
      )}
    </DCFWrapper>
  );
};
export default DCFTable;
