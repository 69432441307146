import styled, { css } from 'styled-components';
import customTheme from 'theme/customTheme';

const {
  colors: { white, reportSectionCardsShadow, skyBlue, primaryColor, DarkBlack, gray5 },
  borderRadius: { reportSectionBorderRadius },
  fontFamily: { secondaryFont },
} = customTheme;

export const DCFReportWrapper = styled.section`
  margin: 42px 0;
  color: ${DarkBlack};
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardWrapper = styled(FlexContainer)`
  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      flex-direction: column;
    }
  }
`;

export const RightCard = styled.section`
  width: 49%;
  background: ${white};
  box-shadow: ${reportSectionCardsShadow};
  border-radius: ${reportSectionBorderRadius};
  padding: 20px 30px;
  font-size: 16px;
  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      width: 100%;
      margin-bottom: 12px;
      padding: 20px 25px;
      font-size: 14px;
    }
  }
`;

export const Paragraph = styled.p`
  margin-bottom: 20px;
  line-height: 21px;
`;
export const LeftText = styled.span`
  font-weight: 700;
  line-height: 21px;
  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      margin-top: 16px;
    }
  }
`;

export const GreenText = styled.span`
  color: ${primaryColor};
`;
export const TitleBox = styled(FlexContainer)`
  margin-bottom: 20px;
  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      flex-direction: column;
    }
  }
`;

export const BoldText = styled.span`
  font-size: 14px;
  font-weight: 700;
`;
export const Subtitle = styled.h3`
  font-family: ${secondaryFont};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 6px;
`;

export const List = styled.ul`
  margin-bottom: 15px;
  list-style-type: disc;
  margin-bottom: 15px;
  margin-left: 20px;
`;

export const CardFooter = styled.div`
  background: ${skyBlue};
  padding: 10px 20px;
  line-height: 21px;
  font-size: 14px;
  font-weight: 400;
`;
export const LeftCard = styled.section`
  width: 49%;
  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      width: 100%;
    }
  }
`;
export const FormulaBox = styled.section`
  border-radius: ${reportSectionBorderRadius};
  height: 193px;
  margin-bottom: 24px;
  background-color: ${primaryColor};
  text-align: center;
  & > img {
    max-width: 395px; // for fall back
    width: 73%;
    margin: 15px 0 7px 0;
  }
`;

export const EquityValue = styled.div`
  font-family: ${secondaryFont};
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  border-bottom: 1px solid #ffffff;
  width: 92%;
  padding-bottom: 15px;
  margin: auto;
  & > span {
    color: #fff;
  }
`;
export const FormulaBoxFooter = styled.div`
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  margin-top: 10px;
  line-height: 16.2px;
  & > span {
    display: inline-block;
    margin-right: 10px;
  }
`;

export const Table = styled.section`
  padding: 20px 30px;
  font-size: 14px;
  box-shadow: ${reportSectionCardsShadow};
  background: ${white};

  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      padding: 20px 25px;
      font-size: 10px;
    }
  }
`;
export const TableRow = styled(FlexContainer)<{ addExtraMargin?: boolean; border?: string }>`
  margin: 0.5px 0px;
  margin-bottom: ${({ addExtraMargin }) => addExtraMargin && '15px'};
  border-radius: ${({ border }) => `${border}`};
`;

export const cellStyleA = {
  width: '60%',
  margin: '0px',
  'padding-left': '20px',
  height: 'auto',
  border: '0.2px solid #fff',
  'border-left': 'none',
};

export const ReportDCFSection = styled.div`
  margin: 42px 0;
  padding: 10px 20px;
  background: ${white};
  box-shadow: ${reportSectionCardsShadow};
  border-radius: ${reportSectionBorderRadius};
  width: 100%;
  min-width: 950px; //At this width the scroll should appear
`;

export const TableHeadWrapper = styled.section<{ bg?: string; height?: string }>`
  display: flex;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  background-color: #fff;
  height: 28px;
`;

export const TableHead = styled(FlexContainer)<{ TableHeadStyle?: { [key: string]: string | number } }>`
  color: ${({ theme }) => theme.colors.white};
  font-size: 13px;
  font-weight: 700;
  align-items: center;
  color: #fff;
  padding-left: 10px;
  margin-right: 1px;
  height: 28px;
  ${({ TableHeadStyle }) =>
    css`
      ${TableHeadStyle}
    `};
`;
export const Last12Month = styled(TableHead)`
  font-size: 13px;
  font-weight: 700;
  align-items: center;
  color: #fff;
  padding-left: 10px;
  margin-right: 1px;
  height: 28px;
  width: 12.9%;
  background-color: #171c1b;
  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      width: 15%;
    }
  }
`;
export const TableHeadStyleA = {
  width: '26.4%',
  'background-color': '#00CAA6',
  paddingLeft: '20px',
};
export const TableHeadStyleB = {
  width: '12.9%',
  'background-color': '#171C1B',
};

export const RowTitle = styled.div<{
  makeTextBold?: boolean;
  style?: { [key: string]: string | number };
}>`
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 26.38%;
  font-weight: ${({ makeTextBold }) => (makeTextBold ? '700' : '400')};
  ${({ style }) =>
    css`
      ${style}
    `}
`;
export const DCFReportTableRow = styled.section`
  display: flex;
`;
