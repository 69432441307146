import React from 'react';
import Box from '@material-ui/core/Box';
import GrowthValLogo from 'components/molecules/GrowthValLogo/GrowthValLogo';
import Logo from 'assets/growthValLogo.svg';
import { Link } from 'react-router-dom';
import { SubTitle, HyperLinkText } from 'style/CommonStyle';
import { VerificationWrapper, MarginAuto, Card } from './styles';

type CenterlizeBoxProps = {
  showLogin?: boolean;
  showLogo?: boolean;
};

const defaultProps = {
  showLogin: false,
  showLogo: true,
};
const CenterlizeBox: React.FC<CenterlizeBoxProps> = ({ children, showLogin, showLogo }) => {
  return (
    <VerificationWrapper>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
        {showLogo && (
          <MarginAuto>
            <GrowthValLogo logo={Logo} />
          </MarginAuto>
        )}
        <Card>{children}</Card>
        {showLogin && (
          <SubTitle>
            Go back to{' '}
            <Link to="/">
              <HyperLinkText>Login</HyperLinkText>
            </Link>
          </SubTitle>
        )}
      </Box>
    </VerificationWrapper>
  );
};

CenterlizeBox.defaultProps = defaultProps;
export default CenterlizeBox;
