import { NavLinkType } from '../types';
import { StyledNavLink, StyledNavLinkBtn, StyledLinkText } from '../styles';

const NavItem: React.FC<NavLinkType> = ({ to = '', text = '', onClick = () => null }) => {
  if (to) {
    return (
      <StyledNavLink to={to || '/dashboard'} onClick={onClick}>
        <StyledLinkText>{text}</StyledLinkText>
      </StyledNavLink>
    );
  }

  return (
    <StyledNavLinkBtn onClick={onClick}>
      <StyledLinkText>{text}</StyledLinkText>
    </StyledNavLinkBtn>
  );
};

export default NavItem;
