import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import useScrollHandler from './useScrollHandler';
import { useStyles } from './styles';
import { HashItem } from './types';

interface PropTypes {
  hashList?: HashItem[];
}

const defaultHashItems = [
  {
    name: ' General questions',
    id: 'generalQuestions',
  },
  {
    name: 'Risks',
    id: 'risks',
  },
  {
    name: 'Financials',
    id: 'financials',
  },
];

const HashNav: React.FC<PropTypes> = ({ hashList = defaultHashItems }) => {
  const classes = useStyles();
  const [currentLink, setCurrentLink] = useState((hashList[0] || {}).id || '');
  useScrollHandler(hashList, currentLink, setCurrentLink);

  return (
    <Box className={classes.sideNavBar} display="flex" flexDirection="column">
      {hashList.map(({ name, id }) => (
        <a href={`#${id}`} className={classes.sideBarLinks} onClick={() => setCurrentLink(id)}>
          <Typography variant={currentLink === id ? 'subtitle2' : 'body1'} className={classes.linkText}>
            {name}
          </Typography>
        </a>
      ))}
    </Box>
  );
};

export default HashNav;
