import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import customTheme from 'theme/customTheme';
import { Bar, Pie } from 'react-chartjs-2';
import { commaSeparatedNumber, getAmountString } from 'utils/helperMethod';
import { ValuationSummaryProps, Graph, LegendsType } from './types';
import { summaryPotentialDeal, summaryWeights } from './data';
import { getColorPalette, verticalBarConfig, pieChartOptions } from './helper';

const ValuationSummary: React.FC<ValuationSummaryProps> = ({ currency, valuationSummaryDetails, companyName }) => {
  const [barGraph, setBarGraph] = useState<Graph>();
  const [pieChartData, setPieChart] = useState<Graph>();
  const [pieChartLegends, setPieChartLegends] = useState<LegendsType>([]);

  useEffect(() => {
    const { valuationMethodsWeights, funds } = valuationSummaryDetails;
    const dataRange: Array<number> = [];
    const labels: Array<string> = ['Scorecard', 'Berkus', 'Risk Factor', 'VC', 'DCF'];
    const pieChartLabels: Array<string> = [];
    const pieChartRange: Array<string> = [];
    const legendsPieChart: Array<{ label: string; colorCode: string }> = [];

    if (!isEmpty(funds)) {
      funds.forEach(({ name, fundsPercentage }, index) => {
        pieChartLabels.push(name);
        pieChartRange.push(fundsPercentage);
        legendsPieChart.push({
          label: `${name}(${parseInt(fundsPercentage)}%)`,
          colorCode: getColorPalette(funds.length)[index],
        });
      });

      setPieChartLegends(legendsPieChart);
      setPieChart({
        labels: pieChartLabels,
        datasets: [
          {
            data: pieChartRange,
            backgroundColor: getColorPalette(pieChartRange.length),
          },
        ],
      });
    }

    if (!isEmpty(valuationMethodsWeights)) {
      valuationMethodsWeights.forEach(({ value }) => {
        dataRange.push(value);
      });
      setBarGraph({
        labels,
        datasets: [
          {
            backgroundColor: customTheme.colors.MediumSpringGreen,
            data: dataRange,
          },
        ],
      });
    }
  }, [valuationSummaryDetails]);

  const callBarGraph = useMemo(() => {
    return <Bar type="bar" data={barGraph} options={verticalBarConfig(currency)} />;
  }, [barGraph, currency]);

  if (isEmpty(valuationSummaryDetails)) return <></>;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <h2
            style={{
              fontFamily: 'Prompt',
              fontWeight: 700,
              fontStyle: 'normal',
              color: '#171C1B',
              fontSize: '32px',
              lineHeight: '43px',
              wordBreak: 'break-word',
            }}
          >
            Valuation summary
          </h2>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '24px',
        }}
      >
        <div style={{ minHeight: '538px', display: 'flex', flexDirection: 'column', width: '49%', height: 'auto' }}>
          <div
            style={{
              overflow: 'hidden',
              boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
              borderRadius: '4px',
              color: 'rgba(0, 0, 0, 0.87)',
              transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              background: '#00CAA6',
              marginBottom: '24px',
            }}
            className="tilesBoxShadow"
          >
            <div style={{ padding: '25px 35px 17px 35px', display: 'flex', justifyContent: 'flex-start' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <h2
                    style={{
                      fontFamily: 'Prompt',
                      fontWeight: 700,
                      fontStyle: 'normal',
                      fontSize: '16px',
                      lineHeight: '22px',
                      color: customTheme.colors.white,
                    }}
                  >
                    THE VALUATION RANGE OF {companyName.toUpperCase()}
                  </h2>
                  <p
                    style={{
                      fontFamily: 'Prompt',
                      fontWeight: 700,
                      fontStyle: 'normal',
                      fontSize: '32px',
                      lineHeight: '43px',
                      color: customTheme.colors.white,
                    }}
                  >
                    {currency}
                    {commaSeparatedNumber(Number(valuationSummaryDetails.valuationMinRange))}&nbsp;-&nbsp;
                    {currency}
                    {commaSeparatedNumber(Number(valuationSummaryDetails.valuationMaxRange))}
                  </p>
                </div>
                <div style={{ marginTop: '16px' }}>
                  <h2
                    style={{
                      fontFamily: 'Prompt',
                      fontWeight: 700,
                      fontStyle: 'normal',
                      fontSize: '16px',
                      lineHeight: '22px',
                      color: customTheme.colors.white,
                    }}
                  >
                    THE WEIGHTED VALUATION OF {companyName.toUpperCase()}
                  </h2>
                  <p
                    style={{
                      fontFamily: 'Prompt',
                      fontWeight: 700,
                      fontStyle: 'normal',
                      fontSize: '32px',
                      lineHeight: '43px',
                      color: customTheme.colors.white,
                    }}
                  >
                    {currency}
                    {commaSeparatedNumber(Number(valuationSummaryDetails.weightedValuation))}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '16px' }} />
          </div>
          <div
            style={{
              overflow: 'hidden',
              boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
              borderRadius: '4px',
              color: 'rgba(0, 0, 0, 0.87)',
              transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              backgroundColor: '#fff',
              minHeight: '500px',
            }}
            className="tilesBoxShadow"
          >
            <div style={{ padding: '25px 35px 17px 35px' }}>
              <div style={{ display: 'flex' }}>
                <div>
                  <h3
                    style={{
                      fontSize: '16px',
                      lineHeight: '21px',
                      wordBreak: 'break-word',
                      fontFamily: 'Prompt',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      color: '#9B9B9B',
                    }}
                  >
                    CURRENTLY RAISING
                  </h3>
                  <p
                    style={{
                      fontSize: '25px',
                      lineHeight: '35px',
                      fontFamily: 'Prompt',
                      fontWeight: 700,
                      fontStyle: 'normal',
                      color: '#171C1B',
                    }}
                  >
                    {currency}
                    {commaSeparatedNumber(Number(valuationSummaryDetails.currentlyRaising))}
                  </p>
                </div>
                <div style={{ marginLeft: '32px' }}>
                  <h3
                    style={{
                      fontSize: '16px',
                      lineHeight: '21px',
                      wordBreak: 'break-word',
                      fontFamily: 'Prompt',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      color: '#9B9B9B',
                    }}
                  >
                    INVESTMENT STAGE
                  </h3>
                  <p
                    style={{
                      fontSize: '25px',
                      lineHeight: '35px',
                      fontFamily: 'Prompt',
                      fontWeight: 700,
                      fontStyle: 'normal',
                      color: '#171C1B',
                    }}
                  >
                    {valuationSummaryDetails.investmentStage}
                  </p>
                </div>
              </div>
              <div style={{ marginTop: '50px' }} />
              <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ width: '40%' }}>
                  <Pie type="pie" data={pieChartData} options={pieChartOptions} />
                </div>
                <div style={{ width: '60%', marginLeft: '32px' }}>
                  <div style={{ borderBottom: `1px solid ${customTheme.colors.borderBottomGray}` }}>
                    <p
                      style={{
                        fontSize: '25px',
                        lineHeight: '35px',
                        fontFamily: 'Prompt',
                        fontWeight: 700,
                        fontStyle: 'normal',
                        color: '#171C1B',
                      }}
                    >
                      Planned use of funds
                    </p>
                  </div>
                  <div style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                    {!isEmpty(pieChartLegends) &&
                      pieChartLegends.map(({ label, colorCode }) => {
                        return (
                          <div style={{ display: 'flex', padding: '2px 17px 8px 2px' }}>
                            <span
                              style={{
                                background: colorCode,
                                width: 12,
                                height: 12,
                                borderRadius: '1px',
                                marginRight: '8px',
                              }}
                            ></span>
                            <p
                              style={{
                                fontFamily: 'Mulish',
                                fontWeight: 400,
                                fontStyle: 'normal',
                                fontSize: '12px',
                                lineHeight: '12px',
                                color: customTheme.colors.gray6,
                              }}
                            >
                              {label}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            overflow: 'hidden',
            boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
            borderRadius: '4px',
            color: 'rgba(0, 0, 0, 0.87)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: '#fff',
            width: '49%',
            height: 'auto',
            minHeight: '538px',
          }}
          className="tilesBoxShadow"
        >
          <div style={{ padding: '25px 35px 17px 35px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '100%' }}>{callBarGraph}</div>
            <div style={{ display: 'flex', marginTop: '40px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '48px', width: '59%' }}>
                <h3
                  style={{
                    fontSize: '18px',
                    lineHeight: '21px',
                    wordBreak: 'break-word',
                    fontFamily: 'Prompt',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    color: '#171C1B',
                  }}
                >
                  Potential deal:
                </h3>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
                  {!isEmpty(valuationSummaryDetails.potentialDeal) &&
                    summaryPotentialDeal.map(({ name, value, isAmount }) => {
                      const amount = valuationSummaryDetails.potentialDeal[value];
                      return (
                        <div
                          style={{
                            display: 'flex',
                            borderBottom: `1px solid ${customTheme.colors.borderBottomGray}`,
                            padding: '8px 5px',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h4
                            style={{
                              fontSize: '14px',
                              lineHeight: '18px',
                              fontFamily: 'Mulish',
                              fontWeight: 700,
                              fontStyle: 'normal',
                              color: '#171C1B',
                            }}
                          >
                            {name}
                          </h4>
                          <div style={{ marginRight: '8px' }}>
                            <h4
                              style={{
                                fontSize: '14px',
                                lineHeight: '18px',
                                fontFamily: 'Mulish',
                                fontWeight: 700,
                                fontStyle: 'normal',
                                color: '#171C1B',
                              }}
                            >
                              {isAmount
                                ? amount !== '-' && amount !== null
                                  ? getAmountString(amount, currency)
                                  : '-'
                                : amount !== '-' && amount !== null
                                ? `${amount}%`
                                : '-'}
                            </h4>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div style={{ display: 'flex', width: '35%', flexDirection: 'column', marginLeft: '25px' }}>
                <h3
                  style={{
                    fontSize: '18px',
                    lineHeight: '21px',
                    wordBreak: 'break-word',
                    fontFamily: 'Prompt',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    color: '#171C1B',
                  }}
                >
                  The weights
                </h3>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
                  {!isEmpty(valuationSummaryDetails.weights) &&
                    summaryWeights.map(({ name, value }) => {
                      const weightVal = valuationSummaryDetails.weights[value];
                      return (
                        <div
                          style={{
                            display: 'flex',
                            borderBottom: `1px solid ${customTheme.colors.borderBottomGray}`,
                            padding: '8px 5px',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h4
                            style={{
                              fontSize: '14px',
                              lineHeight: '18px',
                              fontFamily: 'Mulish',
                              fontWeight: 700,
                              fontStyle: 'normal',
                              color: '#171C1B',
                            }}
                          >
                            {name}
                          </h4>
                          <div style={{ marginRight: '0px' }}>
                            <h4
                              style={{
                                fontSize: '14px',
                                lineHeight: '18px',
                                fontFamily: 'Mulish',
                                fontWeight: 700,
                                fontStyle: 'normal',
                                color: '#171C1B',
                              }}
                            >
                              {weightVal !== '-' ? `${weightVal}%` : '-'}
                            </h4>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuationSummary;
