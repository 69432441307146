import { SET_PAYMENT_MODAL, SET_SUCCESS_MODAL } from '../types';

type StateType = {
  isPaymentModalOpen: boolean;
  isSuccessModalOpen: boolean;
};

type ActionType = {
  type: string;
  payload: boolean;
};

const initialState = {
  isPaymentModalOpen: false,
  isSuccessModalOpen: false,
};

const paymentModalReducer = (state: StateType = initialState, { type, payload }: ActionType): StateType => {
  switch (type) {
    case SET_PAYMENT_MODAL: {
      return {
        ...state,
        isPaymentModalOpen: payload,
      };
    }

    case SET_SUCCESS_MODAL: {
      return {
        isPaymentModalOpen: false,
        isSuccessModalOpen: payload,
      };
    }

    default:
      return state;
  }
};

export default paymentModalReducer;
