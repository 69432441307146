import { useEffect } from 'react';
import useLoadingContext from 'components/layout/Loading/useLoadingContext';
import Loading from 'components/layout/Loading';
import { valuationApi } from './api';
import useValuationData from './useValuationData';
import usePaymentModal from '../payment/usePaymentModal';

import FreeValuation from 'components/templates/FreeValuation';
import ValuationReport from 'components/templates/ValuationReport';
import NavContainer from 'components/layout/NavContainer';
import DashboardContent from 'components/organisms/DashboardContent';

const Valuation: React.FC = () => {
  const [
    { paidReport, reportDetails, companyValuationId, parentCompanyValuationId, skipFinancialForecast },
    setValuationData,
  ] = useValuationData();
  const { isSuccessModalOpen } = usePaymentModal();
  const { setLoading } = useLoadingContext();

  useEffect(() => {
    // refresh valuation report after payment is done
    if (isSuccessModalOpen === false) {
      valuationApi.get({
        onRequest: () => setLoading(true),
        onSuccess: (data) => setValuationData(data),
        onFinally: () => setLoading(false),
      });
    }
  }, [isSuccessModalOpen]);

  return (
    <Loading>
      {paidReport === true && (
        <ValuationReport
          reportDetails={reportDetails}
          companyValuationId={companyValuationId}
          parentCompanyValuationId={parentCompanyValuationId}
          skipFinancialForecast={skipFinancialForecast}
        />
      )}
      {paidReport === false && <FreeValuation reportDetails={reportDetails} />}
      {paidReport !== true && paidReport !== false && (
        <NavContainer>
          <DashboardContent
            grayLogo
            title="You don’t have any valuation reports yet."
            text={[
              'Create a business valuation report in minutes.',
              'Provide information about your startup and download a valuation report.',
            ]}
            linkText="Create Valuation Report"
            linkTo="/questionnaires"
          />
        </NavContainer>
      )}
    </Loading>
  );
};

export default Valuation;
