import React, { useEffect, useMemo, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import isEmpty from 'lodash/isEmpty';
import { AxiosResponse } from 'axios';
import api from 'utils/api';
import Typography from '@material-ui/core/Typography';
import { Formik, Form } from 'formik';
import QuestionMarkIcon from 'components/icons/QuestionMarkIcon';
import { Wrapper } from 'style/CommonStyle';
import Button from '@material-ui/core/Button';
import SettingsIcon from '@material-ui/icons/Settings';
import {
  GetCompanyStages,
  GetCountries,
  GetIndustries,
  GetValuationQuestions,
  SaveValuationsData,
  GetValuationAnswers,
} from 'utils/ApiUrls';
import { getLocalStorageItem } from 'utils/getLocalStorageItem';
import useLoadingContext from 'components/layout/Loading/useLoadingContext';
import Loading from 'components/layout/Loading';
import GeneralQuestions from './GeneralQuestions';
import RiskQuestions from './RiskQuestions';
import InvestmentStage from './InvestmentStage';
import AdditionalInvesment from './AdditionalInvestment';
import RevenueQuestions from './RevenueQuestions';
import ValuationParameters from 'components/organisms/ValuationParameters';
import { questionData } from './data';
import { preparePostData, handleCountriesResponseData } from './helper';
import { CenterlizeContent, QuestionContainer, QuestionSection, useStyles, ButtonBox } from './styles';
import { Error } from 'style/CommonStyle';
import { routesObj } from 'routes/routes';
import FinancialQuestion from 'components/organisms/FinancialQuestion/FinancialQuestion';
import { handleFinancialForecastResponse } from 'components/organisms/DCFinancial/DCFHelper';
import DCFTable from 'components/organisms/DCFinancial/DCFTable';
import FormButton from 'components/molecules/FormButton/FormButton';
import {
  CountriesType,
  Countries,
  IndustriesType,
  Industries,
  ValuationDataTypes,
  CompanyStagesTypes,
  CompanyStages,
  SaveValuationResponseType,
  QuestionProps,
  KeyPair,
  ValuationIdType,
  ValuationAnswerResponse,
  QuestionAnswersType,
  FinacalDataType,
} from './types';
import { GET_USE_OF_FUND_PREFILLED, GET_INVESTORS_QUESTION_PREFILLED } from 'redux/types';
import { QuestionValidation } from './validation';
import Confirmation from './Confirmation';
import { useSelector, useDispatch } from 'react-redux';
import NavContainer from 'components/layout/NavContainer';
import HashNav from 'components/organisms/HashNav';
import { defaultCurrency } from './constant';
import { useDCFContext } from 'context/DCFContext/DCFContext';
import { useLocation } from 'react-router-dom';
import { StateI, AllInvestorsI } from 'components/organisms/FinancialQuestion/types';
import customTheme from 'theme/customTheme';

const formValues = {};

const QuestionsSection: React.FC<QuestionProps> = ({ history }) => {
  const classes = useStyles();
  const { setLoading } = useLoadingContext();
  const [currencySymbol, setCurrencySymbol] = useState<string>('');
  const [countries, setCountries] = useState<Countries[]>([]);
  const [industries, setIndustries] = useState<Industries[]>([]);
  const [questions, setGeneralQuestions] = useState<any>(null);
  const [initialValues, setInitialValues] = useState<any>(formValues);
  const [companyStages, setCompanyStages] = useState<CompanyStages[]>([]);
  const [currencyId, setCurrencyId] = useState<number>();
  const [showProcessing, setProcessing] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');
  const [financialQuestions, setFinancialQuestions] = useState<any>();
  const [raisingAmountQuestion, setRaisingAmountQuestions] = useState<any>(null);
  const [riskFactorQuestions, setRiskFactorQuestion] = useState<any>(null);
  const [additionalInvestment, setAdditionalInvestment] = useState<any>(null);
  const [revenueQuestions, setRevenueQuestions] = useState<any>();
  const [isActiveSubscriber, setIsActiveSubscriber] = useState(false);
  const [investorsOtherId, setInvestorsOtherId] = useState<number | null>(null);
  const [fundsOtherId, setFundsOtherId] = useState<number | null>(null);
  const [isValidInvestorsCount, setInvestorsCountValid] = useState(false);
  const [setValidFundsCount, setFundsCountValid] = useState(false);
  const { token: accessToken } = getLocalStorageItem('growthVal');
  const valuationParamRef = useRef<HTMLButtonElement>();

  const {
    DCFTableData,
    skipFinancialForecast,
    financialForecastQuestions,
    setFinancialForecastQuestions,
    setFinancialForecastData,
    setSkipFinancialForecast,
  } = useDCFContext();
  const { allInvestors, useOFFunds } = useSelector((state: StateI) => state);
  const dispatch = useDispatch();

  const location = useLocation() || [];
  const state = location.state as ValuationIdType;

  useEffect(() => {
    getCountries();
    getIndustries();
    getGeneralQuestions();
    getCompanyStages();
  }, []);

  useEffect(() => {
    return () => {
      setSkipFinancialForecast(false);
    };
  }, []);

  useEffect(() => {
    const getValuationDetails = (id: number) => {
      setLoading(true);
      api
        .get<AxiosResponse>(GetValuationAnswers(id))
        .then((res: ValuationAnswerResponse) => {
          const {
            data: { status, data },
          } = res;

          if (status === 200) {
            parseValuationAnswer(data);
            setSkipFinancialForecast(data.skipFinancialForecast);
            const newFinancialForecastData = handleFinancialForecastResponse(data);
            setFinancialForecastData(newFinancialForecastData);
            parseFinancialData(data);
          }
        })
        .finally(() => setLoading(false));
    };

    if (!isEmpty(state) && state.companyValuationId) {
      getValuationDetails(state.companyValuationId);
    }
  }, [state, accessToken]);

  const parseFinancialData = (data: QuestionAnswersType) => {
    const { financialInvestorQuestionsResponse, financialFundsQuestionsResponse } = data.financialQuestions;
    const getPrefillData = (argument: any): AllInvestorsI => {
      const list: any = {};
      argument.forEach(({ question, value, fundsPercentage, valuationQuestionId, questionHeading, ...rest }: any) => {
        list[questionHeading !== null ? questionHeading : question] = {
          added: true,
          text: questionHeading !== null ? questionHeading : question,
          value,
          isError: false,
          valuationQuestionId,
          percentageValue: (fundsPercentage || '').replace(/\.\d*/, ''),
        };
      });
      return list;
    };

    dispatch({
      type: GET_INVESTORS_QUESTION_PREFILLED,
      payload: getPrefillData(financialInvestorQuestionsResponse),
    });

    dispatch({
      type: GET_USE_OF_FUND_PREFILLED,
      payload: getPrefillData(financialFundsQuestionsResponse),
    });
  };

  const parseValuationAnswer = (answersData: any) => {
    if (
      isEmpty(answersData) &&
      isEmpty(answersData.generalQuestionsResponse) &&
      isEmpty(answersData.riskQuestionsResponse) &&
      isEmpty(answersData.revenueQuestionsResponse) &&
      isEmpty(answersData.raisingAmountQuestionsResponse) &&
      isEmpty(answersData.additionalInvestmentQuestionsResponse)
    )
      return;

    if (
      !isEmpty(answersData) &&
      !isEmpty(answersData.generalQuestionsResponse) &&
      !isEmpty(answersData.riskQuestionsResponse) &&
      !isEmpty(answersData.revenueQuestionsResponse) &&
      !isEmpty(answersData.raisingAmountQuestionsResponse) &&
      !isEmpty(answersData.additionalInvestmentQuestionsResponse)
    ) {
      const keyDetails: {
        general: KeyPair;
        risk: KeyPair;
      } = {
        general: {
          companyName: answersData.companyName || null,
          companyWebsite: answersData.companyWebsite || null,
          location: answersData.countryId || null,
          yearFounded: answersData.yearFounded || null,
          industry: answersData.industryId || null,
          investmentStage: answersData.companyStageId || null,
        },
        risk: {},
      };
      answersData.generalQuestionsResponse.forEach((item: KeyPair) => {
        keyDetails.general[item.slug] = item.value;
      });

      answersData.revenueQuestionsResponse.forEach((item: KeyPair) => {
        keyDetails.general[item.slug] = item.value;
      });
      answersData.raisingAmountQuestionsResponse.forEach((item: KeyPair) => {
        keyDetails.general[item.slug] = item.value;
      });
      answersData.additionalInvestmentQuestionsResponse.forEach((item: KeyPair) => {
        keyDetails.general[item.slug] = item.value;
      });
      answersData.riskQuestionsResponse.forEach((item: KeyPair) => {
        keyDetails.risk[item.slug] = item.value;
      });
      setInitialValues((prevValues: KeyPair) => {
        return { ...prevValues, ...keyDetails };
      });
      handleCurrency(answersData.location, valuationParamRef);
    }
  };

  useEffect(() => {
    if (
      questions &&
      questions.generalQuestions &&
      riskFactorQuestions &&
      raisingAmountQuestion &&
      additionalInvestment &&
      revenueQuestions
    ) {
      const keyDetails: {
        general: KeyPair;
        risk: KeyPair;
      } = {
        general: {
          companyName: null,
          companyWebsite: null,
          location: null,
          yearFounded: null,
          industry: null,
          investmentStage: null,
        },
        risk: {},
      };
      questions.generalQuestions.forEach((item: KeyPair) => {
        keyDetails.general[item.slug] = null;
      });

      raisingAmountQuestion.forEach((item: KeyPair) => {
        keyDetails.general[item.slug] = null;
      });
      additionalInvestment.forEach((item: KeyPair) => {
        keyDetails.general[item.slug] = null;
      });
      revenueQuestions.forEach((item: KeyPair) => {
        keyDetails.general[item.slug] = null;
      });
      riskFactorQuestions.forEach((item: KeyPair) => {
        keyDetails.risk[item.slug] = 3;
      });

      setInitialValues((prevValues: KeyPair) => {
        return { ...prevValues, ...keyDetails };
      });
    }
  }, [questions, riskFactorQuestions, revenueQuestions, raisingAmountQuestion, additionalInvestment]);

  const getCountries = () => {
    api.get<AxiosResponse>(GetCountries).then((res: CountriesType) => {
      const {
        data: { status, data },
      } = res;
      if (status === 200 && data.length > 0) {
        const countries = handleCountriesResponseData(data);
        setCountries(countries);
      }
    });
  };

  const getIndustries = () => {
    api.get<AxiosResponse>(GetIndustries).then((res: IndustriesType) => {
      const {
        data: { status, data },
      } = res;
      if (status === 200 && data.length > 0) {
        const industries = data.map((item) => ({ ...item, value: item.industryId, text: item.name }));
        setIndustries(industries);
      }
    });
  };

  const getGeneralQuestions = () => {
    setLoading(true);
    api
      .get<AxiosResponse>(GetValuationQuestions)
      .then((res: ValuationDataTypes) => {
        const {
          data: { status, data },
        } = res;
        if (status === 200) {
          if (data.financialQuestions) {
            setFinancialQuestions(data.financialQuestions);
            setFinancalOtherIds(data.financialQuestions);
          }

          if (data.generalQuestions.length > 0) {
            setFinancialForecastQuestions(data.financialForecastQuestions);
            const general = data.generalQuestions.map((item, index) => ({
              ...item,
              questionNo: Number(index + 7),
              isAmount: item.fieldType === 'number',
              readOnly: false,
              placeholder: item.fieldType === 'number' ? 'Enter amount' : 'Choose answer',
            }));

            setGeneralQuestions({ ...questionData, generalQuestions: general });
          }
          if (data.riskQuestions.length > 0) {
            setRiskFactorQuestion(data.riskQuestions);
          }
          if (!isEmpty(data.raisingAmountQuestions)) {
            setRaisingAmountQuestions(data.raisingAmountQuestions);
          }
          if (!isEmpty(data.additionalInvestmentQuestions)) {
            setAdditionalInvestment(data.additionalInvestmentQuestions);
          }
          if (!isEmpty(data.revenueQuestions)) {
            setRevenueQuestions(data.revenueQuestions);
          }
          setIsActiveSubscriber(data.isActiveUser);
        }
      })
      .finally(() => setLoading(false));
  };

  const setFinancalOtherIds = (data: FinacalDataType) => {
    if (!isEmpty(data)) {
      const funds = data.financialFundsQuestions.find((item) => item.question === 'Others');
      const investors = data.financialInvestorQuestions.find((item) => item.question === 'Others');
      if (investors && investors.valuationQuestionId !== undefined) {
        setInvestorsOtherId(investors.valuationQuestionId);
      }
      if (funds && funds.valuationQuestionId !== undefined) {
        setFundsOtherId(funds.valuationQuestionId);
      }
    }
  };

  const getCompanyStages = () => {
    api.get<AxiosResponse>(GetCompanyStages).then((res: CompanyStagesTypes) => {
      const {
        data: { status, data },
      } = res;
      if (status === 200 && data.length > 0) {
        const stages = data.map((item) => ({ ...item, value: item.companyStageId, text: item.name }));
        setCompanyStages(stages);
      }
    });
  };
  const handleCurrency = (location: number | string, inputRefs: any) => {
    if (location !== 'Choose option') {
      const selectCountry = countries.find(({ countryId }) => countryId === location);
      if (inputRefs && inputRefs.current && selectCountry) {
        const {
          currency: { currencyCode, symbol },
          currencyId,
        } = selectCountry;
        setCurrencySymbol(symbol);
        setCurrencyId(currencyId);
        inputRefs.current.onChange(`${currencyCode} ${symbol}`);
      }
    }
  };

  const submitHandler = (values: KeyPair) => {
    const isEmptyInvestment = Object.entries(allInvestors).some(([, keyValue]) => keyValue.value === '');
    const isEmptyFunds = Object.entries(useOFFunds).some(([, keyValue]) => keyValue.value === '');
    if (!isValidInvestorsCount || !setValidFundsCount || isEmptyInvestment || isEmptyFunds) {
      return;
    }

    setProcessing(true);
    const postData = preparePostData(
      values,
      questions.generalQuestions,
      raisingAmountQuestion,
      additionalInvestment,
      revenueQuestions,
      riskFactorQuestions,
      DCFTableData,
      skipFinancialForecast,
      investorsOtherId,
      fundsOtherId,
    );

    const finalData = { ...postData, currencyId };
    let bodyParams = finalData;
    if (!isEmpty(state) && state.parentCompanyValuationId) {
      bodyParams = { ...finalData, parentCompanyValuationId: state.parentCompanyValuationId };
    }
    if (finalData) {
      api
        .post<AxiosResponse>(SaveValuationsData, bodyParams)
        .then((res: SaveValuationResponseType) => {
          const {
            data: { status, data },
          } = res;
          if (status === 201 && data) {
            history.push(`${routesObj.ReportSection.path}`);
          }
        })
        .catch((error) => {
          setServerError(error.message);
        })
        .finally(() => {
          setTimeout(() => {
            setProcessing(false);
          }, 1000);
        });
    }
  };

  const renderConfirmation = useMemo(() => {
    if (showProcessing) {
      return <Confirmation isOpen={showProcessing} />;
    }
    return false;
  }, [showProcessing]);

  return (
    <Loading>
      <NavContainer>
        <Wrapper>
          <QuestionSection>
            <Box>
              <HashNav />
            </Box>
            <QuestionContainer>
              <CenterlizeContent>
                <Box>
                  <Box id="generalQuestions" />
                  <Typography variant="subtitle1" className={classes.heading}>
                    Create Valuation
                  </Typography>
                  <Box mt={2} />
                  <Typography variant="body1" className={classes.subTitle}>
                    Please answer the questions and provide financial information to generate a valuation report.
                  </Typography>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={QuestionValidation({
                      ...questions,
                      raisingAmount: raisingAmountQuestion,
                      revenueQuestions: revenueQuestions,
                      riskQuestions: riskFactorQuestions,
                      additionalInvesment: additionalInvestment,
                    })}
                    onSubmit={submitHandler}
                    enableReinitialize
                  >
                    {({ setFieldValue, errors, handleBlur, touched, values }) => (
                      <Form>
                        <Box mt={4}>
                          <GeneralQuestions
                            handleCurrency={handleCurrency}
                            currencySymbol={currencySymbol}
                            countries={countries}
                            industries={industries}
                            generalQuestions={questions}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            handleBlur={handleBlur}
                            touched={touched}
                            values={values}
                          />
                        </Box>
                        <Box id="risks" />
                        <Box mt={10} mb={10}>
                          <RiskQuestions
                            riskQuestions={riskFactorQuestions}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            values={values}
                          />
                          <Box id="financials" />
                        </Box>
                        <Box>
                          {financialQuestions && (
                            <FinancialQuestion
                              financialQuestions={financialQuestions}
                              selectedCountryCurrency={currencySymbol}
                              setInvestorsCountValid={setInvestorsCountValid}
                              setValidTotalFunds={setFundsCountValid}
                              revenueQuestions={
                                <Box mt={5}>
                                  <InvestmentStage
                                    setFieldValue={setFieldValue}
                                    stages={companyStages}
                                    currencySymbol={currencySymbol}
                                    errors={errors}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    raisingAmountQuestion={raisingAmountQuestion || []}
                                    values={values}
                                  />
                                </Box>
                              }
                              additionalInvesment={
                                <Box mt={3}>
                                  <AdditionalInvesment
                                    setFieldValue={setFieldValue}
                                    currencySymbol={currencySymbol}
                                    errors={errors}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    additionalInvestment={additionalInvestment || []}
                                    values={values}
                                  />
                                </Box>
                              }
                              revenueQuestionsList={
                                <Box mt={3}>
                                  <RevenueQuestions
                                    setFieldValue={setFieldValue}
                                    currencySymbol={currencySymbol}
                                    errors={errors}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    revenueQuestions={revenueQuestions || []}
                                    values={values}
                                  />
                                </Box>
                              }
                            />
                          )}
                        </Box>
                        {financialForecastQuestions.length > 0 && (
                          <DCFTable
                            selectedCountryCurrency={currencySymbol}
                            lastYearRevenue={values.general && values.general.revenue_last_year}
                            revenueInFiveYear={values.general && values.general.forecast_revenues}
                          />
                        )}

                        <ButtonBox>
                          <Box display="flex" alignItems="center" className={classes.btnContainer}>
                            <Box className={classes.valuationCTA}>
                              <Button
                                onClick={() => {
                                  valuationParamRef &&
                                    isActiveSubscriber &&
                                    valuationParamRef.current &&
                                    valuationParamRef.current.click();
                                }}
                                className={classes.valuationBtn}
                                style={{
                                  background: isActiveSubscriber
                                    ? customTheme.colors.DarkBlack
                                    : customTheme.colors.LigthGray,
                                }}
                                startIcon={<SettingsIcon />}
                                disabled={!isActiveSubscriber}
                              >
                                <Typography variant="subtitle2">Edit valuation parameters</Typography>
                              </Button>
                              <Box ml={1} mt={1}>
                                <QuestionMarkIcon
                                  data-tip="This option is available in the full (paid) version of the report."
                                  data-for="tool-tip-edit"
                                />
                              </Box>
                            </Box>

                            <Box className={classes.btnContainer}>
                              <Box mb={1}>{serverError.length > 0 && <Error>{serverError}</Error>}</Box>
                              <FormButton additionStyle={{ width: '250px' }} type="submit">
                                Create report
                              </FormButton>
                            </Box>
                          </Box>
                        </ButtonBox>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </CenterlizeContent>
            </QuestionContainer>
            <ValuationParameters ref={valuationParamRef} currency={currencySymbol || defaultCurrency} />
            {renderConfirmation}
          </QuestionSection>
        </Wrapper>
      </NavContainer>
    </Loading>
  );
};
export default QuestionsSection;
