import React from 'react';
import Box from '@material-ui/core/Box';
import isEmpty from 'lodash/isEmpty';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ReactTooltip from 'react-tooltip';
import QuestionMark from 'components/layout/QuestionMark';
import { Error } from 'style/CommonStyle';
import { riskLabels } from './riskData';
import { useStyles, RiskSlider } from './styles';

type RiskProps = {
  riskQuestions: any[];
  touched: any;
  setFieldValue: (field: string, data: string | number | any[], validate: boolean) => any;
  errors: any;
  values: any;
};

const RiskQuestions: React.FC<RiskProps> = ({ riskQuestions, errors, setFieldValue, touched, values }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="subtitle1" className={classes.questionHeading}>
        Risks
      </Typography>
      <Box mt={2} />
      <Typography variant="body1" className={classes.subTitle}>
        Please select the level of risk from very low to very high for each category of risk in comparison to a similar
        company in your location, industry and stage.
      </Typography>
      <Box mt={4} />
      <Box display="flex" justifyContent="flex-end" m={0} p={0} className={classes.sliderLabels}>
        {riskLabels &&
          riskLabels.map(({ label, value }) => {
            return (
              <Box className={classes.sliderLabelTitle}>
                <Typography variant="body2" className={classes.riskLabels}>
                  <span data-value={value} key={value}>
                    {label}
                  </span>
                </Typography>
              </Box>
            );
          })}
      </Box>
      <Divider className={classes.horizontalDivider} />
      <Box display="flex" flexDirection="column" className={classes.riskSlider}>
        {!isEmpty(riskQuestions) &&
          riskQuestions.map(({ valuationQuestionId, question, slug, tooltip }, index) => {
            const isError = !isEmpty(errors) && !isEmpty(errors.risk) && errors.risk[slug] && touched.risk[slug];
            return (
              <Box display="flex" mt={5} className={classes.sliderContainer} key={index}>
                <Box width="44%" className={classes.sliderTitle} display="flex">
                  <Typography variant="subtitle2" className={classes.questionText}>
                    {question}
                  </Typography>
                  {tooltip !== null && (
                    <>
                      <Box marginLeft="21px" mt={0}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip={tooltip}
                          data-for={`tool-tip-${valuationQuestionId}`}
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id={`tool-tip-${valuationQuestionId}`} />
                    </>
                  )}
                </Box>
                <Box width="56%" className={classes.sliderSection}>
                  <RiskSlider
                    min={1}
                    max={5}
                    aria-labelledby="discrete-slider-custom"
                    step={1}
                    valueLabelDisplay="off"
                    value={(!isEmpty(values) && !isEmpty(values.risk) && values.risk[slug]) || 3}
                    name={`risk.${slug}`}
                    onChange={(e, value) => setFieldValue(`risk.${slug}`, value, false)}
                  />
                  <Box>{isError && <Error>{errors.risk[slug]}</Error>}</Box>
                </Box>
              </Box>
            );
          })}
      </Box>
    </>
  );
};

export default RiskQuestions;
