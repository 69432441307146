import Title from 'components/molecules/Title/Title';
import GrowthValLogo from 'components/molecules/GrowthValLogo/GrowthValLogo';
import logo from '../../../assets/growthValLogo.svg';

import { logoStyle, StyledParagraph, ParagraphBox, TextBox, RegistrationConfirmationBox } from './styles';

const RegistrationConfirmation = (): JSX.Element => {
  return (
    <RegistrationConfirmationBox>
      <GrowthValLogo logo={logo} logoStyle={logoStyle} />
      <TextBox>
        <Title titleStyle={{ fontSize: '26px' }}>Check your email</Title>
        <ParagraphBox>
          <StyledParagraph>Please check your email to confirm your account.</StyledParagraph>
          <StyledParagraph>
            If you haven't received our email in 15 minutes, please check your spam folder.
          </StyledParagraph>
        </ParagraphBox>
      </TextBox>
    </RegistrationConfirmationBox>
  );
};

export default RegistrationConfirmation;
