import { RiQuestionFill } from 'react-icons/ri';
import styled from 'styled-components';

export const QuestionIcon = styled(RiQuestionFill)`
  width: 21px;
  height: 21px;
  color: ${(props) => props.theme.colors.LigthGray};
  @media (max-width: 510px) {
    margin-top: -12px;
  }
`;
interface QuestionMarkIconProps {
  iconColor?: string;
}
const QuestionMarkIcon = ({ iconColor, ...rest }: QuestionMarkIconProps) => {
  return <QuestionIcon {...rest} />;
};

export default QuestionMarkIcon;
