import styled from 'styled-components';
import customTheme from 'theme/customTheme';

const {
  colors: { tintOfSilver, error },
} = customTheme;

export const FieldStyle = {
  border: `1px solid ${tintOfSilver}`,
  height: '48px',
};

export const buttonStyle = { width: '100%', margin: '20px 0 0px 0' };
export const errorStyle = { width: ' 48.9%' };

export const PaymentFormWrapper = styled.form`
  padding: 40px 80px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 20px 40px;
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 20px 0;
  }
`;

export const FormTitle = styled.div`
  font-family: 'Prompt';
  font-size: 24px;
  margin-bottom: 37px;
  font-weight: 700;
  text-align: center;

  @media (max-width: 375px) {
    font-size: 21px;
    font-weight: 700;
    line-height: 28px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const ErrorBox = styled(FlexContainer)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const DisplayOnWidth768 = styled.p`
  font-size: 11px;
  font-weight: 700;
  color: ${error};
  width: 48.9%;
  display: none;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    width: 100%;
    display: block;
  }
`;
export const FlexContainerWithMargin = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0 0 0;
`;
export const InputFieldBox = styled.div`
  width: 48.9%;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;
export const ShearedStyle = styled.div`
  border: 1px solid ${(props) => props.theme.colors.tintOfSilver};
  height: 48px;
  border-radius: 50px;
  padding: 12px 25px;
  font-size: 16px;
`;

export const CardNumberBox = styled(ShearedStyle)<{ cardNumberError: string }>`
  margin: 10px 0 0 0;

  border: ${({ cardNumberError }) => cardNumberError.length > 0 && ' 1px solid #E05959'};
  @media (max-width: 768px) {
    margin: 0 0 10px 0;
  }
`;

export const ZipCodeInputBox = styled.div`
  width: 31.914%;
  @media (max-width: 768px) {
    width: 49.5%;
    margin-bottom: 10px;
  }
`;

export const CardCvcBox = styled(ShearedStyle)<{ cardCvcError: string }>`
  width: 31.914%;
  border: ${({ cardCvcError }) => cardCvcError.length > 0 && ' 1px solid #E05959'};
  @media (max-width: 768px) {
    width: 49.5%;
    margin-bottom: 10px;
  }
`;

export const CardExpirationBox = styled(ShearedStyle)<{ cardExpiryError: string }>`
  width: 31.914%;
  border: ${({ cardExpiryError }) => cardExpiryError.length > 0 && ' 1px solid #E05959'};
  @media (max-width: 768px) {
    width: 49.5%;
    margin-bottom: 10px;
  }
`;
