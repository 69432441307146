import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import ResponsivePopup from 'components/organisms/ResponsiveModal';
import { AxiosResponse } from 'axios';
import api from 'utils/api';
import { formatInputWithCommas, handleCommaNumberChange } from 'utils/helperMethod';
import isEmpty from 'lodash/isEmpty';
import { Box, Typography, Select, MenuItem, Button } from '@material-ui/core';
import CustomInput from 'components/molecules/FormInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import ReactTooltip from 'react-tooltip';
import QuestionMark from 'components/layout/QuestionMark';
import customTheme from 'theme/customTheme';
import { GetValuationMethodWeights, SaveValuationParamas } from 'utils/ApiUrls';
import { Formik, Form } from 'formik';
import SubmitButton from 'components/molecules/button';
import { Currency, Error, Symbol } from 'style/CommonStyle';
import { getLocalStorageItem } from 'utils/getLocalStorageItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormValuesPair, ValuationParamsType } from './types';
import { useStyles } from './styles';
import MobileViewTable from './MobileViewTable';
import DesktopViewTable from './DesktopViewTable';
import { ValuationResultType, StagesTypes, SaveValuationParamsReponse } from './types';
import { getParseMobileData, notAllowNegativeValue, parsePostData } from './helper';
import { validationSchema } from './validationSchema';

type ValuationProps = {
  currency: string;
  ref: any;
};

const ValuationoParameters: React.FC<ValuationProps> = forwardRef(({ currency }, ref) => {
  const [showModal, setModalVisibility] = useState(false);
  const [initialValues, setInitialValues] = useState<FormValuesPair>({
    scorecardMethod: null,
    berkusMethod: null,
    riskFactorSummationMethod: null,
    vCMethod: null,
    dCFMethod: null,
    methodAverageValuation: null,
    returnRatePercentage: null,
    discountRatePercentage: null,
    growthRatePercentage: null,
    medianName: null,
    medianRate: null,
  });
  const [valuationParams, setValuationParams] = useState<ValuationResultType>();
  const [totalStagesWeight, setTotalStagesWeight] = useState(0);
  const [serverError, setError] = useState('');
  const [noNegativeValue, setNegativeValue] = useState(true);
  const [mobileViewStagesData, setMobileViewStagesData] = useState<StagesTypes[]>([]);
  const [isMobile, setMobileView] = useState(window.matchMedia('(max-width: 767px)').matches);
  const classes = useStyles();
  const accessToken = getLocalStorageItem('growthVal').token;

  useImperativeHandle(ref, () => ({
    click() {
      setModalVisibility(true);
    },
  }));

  useEffect(() => {
    setError('');
  }, [showModal]);

  useEffect(() => {
    window.addEventListener('resize', mobileResolution);

    return () => {
      window.removeEventListener('resize', mobileResolution);
    };
  }, []);

  const mobileResolution = () => {
    setMobileView(window.matchMedia('(max-width: 767px)').matches);
  };

  useEffect(() => {
    const getValuationParameters = () => {
      api.get<AxiosResponse>(GetValuationMethodWeights).then((res: ValuationParamsType) => {
        const {
          data: { status, data },
        } = res;
        if (status === 200 && data) {
          const mobileViewStagesData = getParseMobileData(data.valuationMethodWeights);
          setValuationParams(data);
          setMobileViewStagesData(mobileViewStagesData);
          if (data?.valuationParameter) {
            const {
              scorecardMethodWeight,
              berkusMethodWeight,
              riskFactorMethodWeight,
              VCMethodWeight,
              DCFMethodWeight,
              methodAverageValuation,
              returnRatePercentage,
              discountRatePercentage,
              growthRatePercentage,
              medianName,
              medianRate,
            } = data.valuationParameter;
            const updateInitialValues = {
              scorecardMethod: scorecardMethodWeight || null,
              berkusMethod: berkusMethodWeight || null,
              riskFactorSummationMethod: riskFactorMethodWeight || null,
              vCMethod: VCMethodWeight || null,
              dCFMethod: DCFMethodWeight || null,
              methodAverageValuation: methodAverageValuation || null,
              returnRatePercentage: returnRatePercentage || null,
              discountRatePercentage: discountRatePercentage || null,
              growthRatePercentage: growthRatePercentage || null,
              medianName: medianName || null,
              medianRate: medianRate || null,
            };
            setInitialValues(updateInitialValues);
            setTotalStagesWeight(
              Number(
                Number(scorecardMethodWeight) +
                  Number(berkusMethodWeight) +
                  Number(riskFactorMethodWeight) +
                  Number(VCMethodWeight) +
                  Number(DCFMethodWeight),
              ),
            );
          }
        }
      });
    };
    if (showModal) getValuationParameters();
  }, [showModal, serverError, accessToken]);

  const submitHandler = (values: FormValuesPair) => {
    if (!values) {
      return;
    }
    const parseData = parsePostData(values);
    api
      .post<AxiosResponse>(SaveValuationParamas, parseData, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': `${accessToken}`,
        },
      })
      .then((res: SaveValuationParamsReponse) => {
        const { status } = res;
        if (status === 204) {
          setModalVisibility(false);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <ResponsivePopup
      closeOnOverlayClick
      closeOnEsc
      open={showModal}
      showCloseIcon={true}
      modalVisibility={() => setModalVisibility(false)}
    >
      <Box>
        <Box>
          <Typography variant="subtitle1" className={classes.title}>
            Edit Valuation Parameters
          </Typography>
          <Box mt={1} />
          <Typography variant="body1" className={classes.titleText}>
            We use the weights below as default values to calculate weighted valuation of your company. If you have
            skipped the Financial Forecasts part - we will use equal weights for Scorecard, Berkus, and Risk Factor
            Summation methods.
            <br /> You can also enter your own assumptions. They should sum up to 100%.
          </Typography>
        </Box>
        <Box mt={4} />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
          enableReinitialize
        >
          {({ setFieldValue, errors, handleBlur, touched, values }) => {
            const isError = !isEmpty(errors);
            let isInvalidDiscountAndGrowthRate = false;
            if (
              !isEmpty(values) &&
              values.discountRatePercentage !== null &&
              values.discountRatePercentage !== '' &&
              values.growthRatePercentage !== null &&
              values.growthRatePercentage !== ''
            ) {
              isInvalidDiscountAndGrowthRate =
                values['discountRatePercentage'] < values['growthRatePercentage'] ||
                values['discountRatePercentage'] === values['growthRatePercentage'];
            }

            return (
              <Form>
                <Box>
                  <Box>
                    {isMobile ? (
                      <MobileViewTable
                        mobileViewStagesData={mobileViewStagesData || []}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        values={values}
                        setTotalStagesWeight={setTotalStagesWeight}
                        setNegativeValue={setNegativeValue}
                        noNegativeValue={noNegativeValue}
                      />
                    ) : (
                      <DesktopViewTable
                        stagesData={valuationParams?.valuationMethodWeights || []}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        handleBlur={handleBlur}
                        values={values}
                        setTotalStagesWeight={setTotalStagesWeight}
                        setNegativeValue={setNegativeValue}
                        noNegativeValue={noNegativeValue}
                      />
                    )}
                  </Box>
                  <Box display="flex" justifyContent="flex-end" mt={2} className={classes.resetSection}>
                    <Box>
                      <Typography variant="body1" className={classes.weightSumMsg}>
                        The weights total must sum up to 100%
                      </Typography>
                    </Box>
                    <Box
                      ml={1}
                      onClick={() => {
                        setFieldValue('scorecardMethod', null);
                        setFieldValue('berkusMethod', null);
                        setFieldValue('vCMethod', null);
                        setFieldValue('dCFMethod', null);
                        setFieldValue('riskFactorSummationMethod', null);
                        setTotalStagesWeight(0);
                      }}
                    >
                      <Typography variant="body1" className={classes.resetLink}>
                        Reset to default
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={6} className={classes.summationMethod} display="flex" flexDirection="column">
                    <Typography variant="subtitle1" className={classes.questionLabel}>
                      Scorecard and Risk Factor Summation Method Assumptions:
                    </Typography>
                    <Box mt={2} />
                    <Typography variant="body1" className={classes.questionText}>
                      Please enter a valuation of a comparable company in your country, industry, stage of funding:
                    </Typography>
                    <Box mt={2}>
                      <CustomInput
                        type="text"
                        name="methodAverageValuation"
                        padding="13px 0px 13px 54px"
                        marginTop="0"
                        bgColor={customTheme.colors.white}
                        inputProps={
                          <InputAdornment position="start">
                            <Symbol>
                              <Currency>{currency}</Currency>
                            </Symbol>
                          </InputAdornment>
                        }
                        width="40%"
                        placeholder="Enter amount"
                        mobileWidth="100%"
                        value={formatInputWithCommas(values['methodAverageValuation'] || '')}
                        handleOnChange={(data: string) =>
                          handleCommaNumberChange(data, (newData) => {
                            setFieldValue('methodAverageValuation', newData, false);
                          })
                        }
                        handleBlur={handleBlur}
                        onKeyPress={(e) => setNegativeValue(notAllowNegativeValue(e))}
                      />
                      <Box>{isError && <Error>{errors['methodAverageValuation']}</Error>}</Box>
                    </Box>
                  </Box>
                  <Box mt={4} display="flex" flexDirection="column">
                    <Typography variant="subtitle1" className={classes.questionLabel}>
                      VC Method Assumptions:
                    </Typography>
                    <Box display="flex" mt={3} className={classes.questionSection}>
                      <Box mt={2} display="flex" alignItems="center">
                        <Typography variant="subtitle2" className={classes.questionText}>
                          Rate of return
                        </Typography>
                        <Box>
                          <Box ml={1} mt={1}>
                            <QuestionMark
                              bgFillColor="#CCCCCC"
                              symbolFillColor="#ffffff"
                              data-tip="Return on investment anticipated by the investors"
                              data-for="tool-tip-returnRate"
                            />
                          </Box>
                          <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-returnRate" />
                        </Box>
                      </Box>
                      <Box ml={11} className={classes.questionInput}>
                        <CustomInput
                          type="number"
                          name="returnRatePercentage"
                          padding="13px 0px 13px 54px"
                          marginTop="0"
                          bgColor={customTheme.colors.white}
                          inputProps={
                            <InputAdornment position="start">
                              <Symbol>
                                <Currency>%</Currency>
                              </Symbol>
                            </InputAdornment>
                          }
                          width="60%"
                          handleBlur={handleBlur}
                          value={values['returnRatePercentage'] || ''}
                          handleOnChange={(data: string | number) => {
                            if (noNegativeValue) {
                              setFieldValue('returnRatePercentage', data, false);
                            }
                          }}
                          onKeyPress={(e) => setNegativeValue(notAllowNegativeValue(e))}
                        />
                        <Box>{isError && <Error>{errors['returnRatePercentage']}</Error>}</Box>
                      </Box>
                    </Box>
                    <Box mt={2} />
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body1" className={classes.questionText}>
                        Please choose and enter exit multiple that you want to use for calculations:
                      </Typography>
                      <Box mt={4} display="flex" className={classes.questionSection}>
                        <Box mt={2} display="flex" alignItems="center">
                          <Typography variant="subtitle2" className={classes.questionText}>
                            Multiple
                          </Typography>
                        </Box>
                        <Box ml={14} className={classes.multipleSelect}>
                          <Select
                            variant="outlined"
                            className={classes.select}
                            IconComponent={ExpandMoreIcon}
                            value={
                              !isEmpty(values) && values.medianName !== null ? values.medianName : 'Net income multiple'
                            }
                            name="medianName"
                            onChange={(e: any) => setFieldValue('medianName', e.target.value, false)}
                          >
                            {!isEmpty(valuationParams) &&
                              valuationParams?.medianMultiple.map((item) => {
                                return (
                                  <MenuItem value={item} key={item}>
                                    <Typography variant="body1" className={classes.dropdownText}>
                                      {item}
                                    </Typography>
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <Box>{isError && <Error>{errors['medianName']}</Error>}</Box>
                        </Box>
                      </Box>
                      <Box className={classes.multipleInput}>
                        <CustomInput
                          type="number"
                          name="medianRate"
                          padding="13px 13px 13px 29px"
                          marginTop="0"
                          bgColor={customTheme.colors.white}
                          width="20%"
                          mobileWidth="40%"
                          handleBlur={handleBlur}
                          value={values['medianRate'] || ''}
                          handleOnChange={(data: string | number) => {
                            if (noNegativeValue) {
                              setFieldValue('medianRate', data, false);
                            }
                          }}
                          onKeyPress={(e) => setNegativeValue(notAllowNegativeValue(e))}
                        />
                        <Box>{isError && <Error>{errors['medianRate']}</Error>}</Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box mt={4} display="flex" flexDirection="column">
                    <Typography variant="subtitle1" className={classes.questionLabel}>
                      DCF Method Assumptions:
                    </Typography>
                    <Box display="flex" mt={3} className={classes.questionSection}>
                      <Box display="flex" alignItems="center" mt={2} width="30%" className={classes.fieldTitleSection}>
                        <Typography variant="subtitle2" className={classes.questionText}>
                          Discount rate
                        </Typography>
                        <Box>
                          <Box ml={1} mt={1}>
                            <QuestionMark
                              bgFillColor="#CCCCCC"
                              symbolFillColor="#ffffff"
                              data-tip="The rate by which to discount projected future cash flows (usually wacc)."
                              data-for="tool-tip-discount"
                            />
                          </Box>
                          <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-discount" />
                        </Box>
                      </Box>
                      <Box width="40%" className={classes.questionInput}>
                        <CustomInput
                          type="number"
                          name="discountRatePercentage"
                          padding="13px 0px 13px 54px"
                          marginTop="0"
                          bgColor={customTheme.colors.white}
                          inputProps={
                            <InputAdornment position="start">
                              <Symbol>
                                <Currency>%</Currency>
                              </Symbol>
                            </InputAdornment>
                          }
                          width="60%"
                          handleBlur={handleBlur}
                          value={values['discountRatePercentage'] || ''}
                          handleOnChange={(data: string | number) => {
                            if (noNegativeValue) {
                              setFieldValue('discountRatePercentage', data, false);
                            }
                          }}
                          onKeyPress={(e) => setNegativeValue(notAllowNegativeValue(e))}
                        />
                        <Box>{isError && <Error>{errors['discountRatePercentage']}</Error>}</Box>
                      </Box>
                    </Box>
                    <Box>
                      {isInvalidDiscountAndGrowthRate && (
                        <Error>Discount rate should be higher than long-term growth rate</Error>
                      )}
                    </Box>
                    <Box display="flex" mt={3} className={classes.questionSection}>
                      <Box mt={2} display="flex" alignItems="center" width="30%" className={classes.fieldTitleSection}>
                        <Typography variant="subtitle2">Long-term growth rate</Typography>
                        <Box>
                          <Box ml={1} mt={1}>
                            <QuestionMark
                              bgFillColor="#CCCCCC"
                              symbolFillColor="#ffffff"
                              data-tip="The rate at which the company's free cash flows are assumed to grow after year 5"
                              data-for="tool-tip-growthRate"
                            />
                          </Box>
                          <ReactTooltip delayHide={1000} effect="solid" id="tool-tip-growthRate" />
                        </Box>
                      </Box>
                      <Box width="40%" className={classes.questionInput}>
                        <CustomInput
                          type="number"
                          name="growthRatePercentage"
                          padding="13px 0px 13px 54px"
                          marginTop="0"
                          bgColor={customTheme.colors.white}
                          inputProps={
                            <InputAdornment position="start">
                              <Symbol>
                                <Currency>%</Currency>
                              </Symbol>
                            </InputAdornment>
                          }
                          width="60%"
                          handleBlur={handleBlur}
                          value={values['growthRatePercentage'] || ''}
                          handleOnChange={(data: string | number) => {
                            if (noNegativeValue) {
                              setFieldValue('growthRatePercentage', data, false);
                            }
                          }}
                          onKeyPress={(e) => setNegativeValue(notAllowNegativeValue(e))}
                        />
                        <Box>{isError && <Error>{errors['growthRatePercentage']}</Error>}</Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.btnSection} mt={10}>
                    <Box className={classes.cancelBtn}>
                      <Button onClick={() => setModalVisibility(false)}>
                        <Typography variant="subtitle2" className={classes.ctaText}>
                          Cancel
                        </Typography>
                      </Button>
                    </Box>
                    <Box>
                      <SubmitButton
                        isDisable={
                          !isEmpty(errors) ||
                          totalStagesWeight > 100 ||
                          (totalStagesWeight < 100 && totalStagesWeight !== 0) ||
                          isInvalidDiscountAndGrowthRate
                        }
                        bgColor={customTheme.colors.MediumSpringGreen}
                        marginTop="0"
                      >
                        Save changes
                      </SubmitButton>
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" mr={2}>
                    {serverError && <Error>{serverError}</Error>}
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </ResponsivePopup>
  );
});

export default ValuationoParameters;
