import NavContainer from 'components/layout/NavContainer';
import { Box } from '@material-ui/core';
import { Wrapper } from 'style/CommonStyle';
import ValuationHeader from 'components/organisms/ValuationHeader';
import Payment from 'components/templates/payment';
import usePaymentModal from 'components/templates/payment/usePaymentModal';
import { Summary, Advantages, Overview } from './components';
import { Container, Centerlize, useStyles } from './styles';
import { ReportDetails } from './types';

interface PropTypes {
  reportDetails?: ReportDetails;
}

const FreeValuation: React.FC<PropTypes> = ({
  reportDetails: {
    companyName = '',
    currency: { symbol: currency = '' } = {},
    valuationMaxRange = 0,
    valuationMinRange = 0,
    weightedValuation = 0,
  } = {},
}) => {
  const { setPaymentModal } = usePaymentModal();
  const classes = useStyles();

  return (
    <>
      <NavContainer>
        <Wrapper>
          <Container>
            <Centerlize>
              <ValuationHeader />
              <Box mt={6}>
                <Summary
                  valuationRange={[valuationMinRange, valuationMaxRange]}
                  weightedValuation={weightedValuation}
                  currency={currency}
                  companyName={companyName}
                />
              </Box>
              <Box mt={4}>
                <Box className={`${classes.shadowContainer} ${classes.shadow}`}>
                  <Advantages />
                  <Overview onUnlock={() => setPaymentModal(true)} />
                </Box>
              </Box>
            </Centerlize>
          </Container>
        </Wrapper>
      </NavContainer>
      <Payment />
    </>
  );
};

export default FreeValuation;
