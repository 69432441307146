import { ChangeEvent, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import Button from 'components/molecules/button';
import Box from '@material-ui/core/Box';
import FormError from 'components/molecules/formError/FormError';
import TotalValues from './TotalValues';
import {
  addNewItemFromInvestorMenu,
  modifiedInvestorValue,
  deleteInvestorFromList,
  upDateInvestorPercent,
  addNewPlanUseOfFundFromMenu,
  removePlanUseOfFund,
  addNewItemFromUseOfFundInput,
  addNewItemFromInvestorsInput,
  modifyUseOfFunValue,
} from 'redux/actions/FinancialQuestionAction';
import AddMoreField from './AddMoreField';
import { defaultCurrency } from 'components/templates/Questionaries/constant';
import FormInput from 'components/molecules/FormInput/FormInput';
import { StateI, AllInvestorsI } from './types';
import { removeCommasFromNumber, formatInputWithCommas, handleCommaNumberChange } from 'utils/helperMethod';
import {
  Wrapper,
  Title,
  Text,
  SectionTitle,
  ButtonBox,
  buttonStyle,
  Section,
  StakePercent,
  percentInputStyle,
  investorsInputStyle,
  DeleteIcon,
  FlexContainer,
  ListItemBox,
  MenuWrapper,
  MenuItem,
  StyledSpan,
  InputIcon,
  PercentSymbol,
} from './styles';
import customTheme from 'theme/customTheme';
import { useSelector, useDispatch } from 'react-redux';
import { GET_REQUIRED_FIELD, GET_USE_OF_FUND_REQUIRED_FIELD } from 'redux/types';
import React from 'react';

const FinancialQuestion = ({
  financialQuestions,
  selectedCountryCurrency,
  revenueQuestions,
  additionalInvesment,
  revenueQuestionsList,
  setInvestorsCountValid,
  setValidTotalFunds,
}: any): JSX.Element => {
  const [totalInvestorsContribution, setTotalInvestorsContribution] = useState(0);
  const [showCustomInputField, setShowCustomInputField] = useState(false);
  const [showInputFieldTwo, setShowInputFieldTwo] = useState(false);
  const [useOfFunTotalValue, setUseOfFunTotalValue] = useState(0);
  const [useOfFunTotalPercent, setUseOfFunTotalPercent] = useState(0);
  const [currentOtherValuationId, setCurrentOtherValuationId] = useState(null);
  const [open, setOpen] = useState(false);
  const [openA, setOpenA] = useState(false);
  const [totalInvestorsPercent, setTotalInvestorsPercent] = useState(0);
  const [_useOfFunds, _setUseOfFunds] = useState<{ [key: string]: AllInvestorsI }>({});
  const { financialInvestorQuestions, financialFundsQuestions } = financialQuestions;
  const { allInvestors, useOFFunds } = useSelector((state: StateI) => state);
  const dispatch = useDispatch();

  const getValue = (event: ChangeEvent<HTMLInputElement>): string => {
    const { target: { value = '' } = {} } = event || {};
    return removeCommasFromNumber(value);
  };

  const getRequiredField = (arg: any) => {
    const requiredField = arg.find((el: any) => el.isMandatory === true);
    return {
      [requiredField.question]: {
        text: requiredField.question,
        valuationQuestionId: requiredField.valuationQuestionId,
        added: true,
        value: '',
        percentageValue: '',
        isRequired: false,
      },
    };
  };

  useEffect(() => {
    window.addEventListener('click', handleDropdownEvent, true);
    return () => {
      window.removeEventListener('click', handleDropdownEvent, true);
    };
  }, []);

  const handleDropdownEvent = (e: any) => {
    if (e.target.id !== 'currentInvestment' && e.target.id !== 'planUseOfFunds') {
      setOpen(false);
      setOpenA(false);
    }
  };

  useEffect(() => {
    const totalFunds = computeTotalPercent(useOFFunds);
    setUseOfFunTotalPercent(totalFunds);
    setUseOfFunTotalValue(sumAllUseOfFundValues(useOFFunds));
    setValidTotalFunds(Number(totalFunds) === 100);
  }, [useOFFunds]);

  useEffect(() => {
    dispatch({
      type: GET_REQUIRED_FIELD,
      payload: getRequiredField(financialInvestorQuestions),
    });
    dispatch({
      type: GET_USE_OF_FUND_REQUIRED_FIELD,
      payload: getRequiredField(financialFundsQuestions),
    });
  }, [financialQuestions]);

  useEffect(() => {
    setTotalInvestorsContribution(
      Object.values(allInvestors).reduce((acc, val) => {
        if (val.value === '') {
          return acc + 0;
        }
        if (/-/.test(val.value)) {
          return acc + parseFloat(val.value.replace(/-/g, ''));
        }
        return acc + parseFloat(val.value);
      }, 0),
    );
    const totalPercent = Object.values(allInvestors).reduce((acc, element) => {
      if (element.percentageValue === '') {
        return acc + 0;
      }
      if (/-/.test(element.percentageValue)) {
        return acc + parseFloat(element.percentageValue.replace(/-/g, ''));
      }
      return acc + parseFloat(element.percentageValue);
    }, 0);
    const isValidInvestement = Object.entries(allInvestors).every(([, keyValue]) => {
      return !isEmpty(keyValue.percentageValue);
    });
    setTotalInvestorsPercent(totalPercent);
    setInvestorsCountValid(totalPercent === 100 && isValidInvestement && totalInvestorsContribution !== 0);
  }, [allInvestors, totalInvestorsContribution]);

  const handleMenu = (arg: boolean) => {
    return !arg;
  };

  const computeTotalPercent = (data = useOFFunds) => {
    const total = Object.values(data).reduce((acc, val) => {
      if (val.percentageValue === '') {
        return acc + 0;
      }
      return acc + parseFloat(val.percentageValue);
    }, 0);

    if (Number.isNaN(total)) {
      return 0;
    }
    return total === 99 || total > 100 ? 100 : total;
  };

  const sumAllUseOfFundValues = (data = useOFFunds) => {
    return Object.values(data).reduce((acc, val) => {
      if (val.value === '') {
        return acc + 0;
      }
      if (/-/.test(val.value)) {
        return acc + parseFloat(val.value.replace(/-/g, ''));
      }
      return acc + parseFloat(val.value);
    }, 0);
  };

  const addNewInvestorFromInput = (inputValue: string) => {
    dispatch(addNewItemFromInvestorsInput(inputValue, currentOtherValuationId));
  };

  const addNewPlanUseOfFundFromInput = (inputValue: string) => {
    dispatch(addNewItemFromUseOfFundInput(inputValue, currentOtherValuationId));
  };

  return (
    <Wrapper>
      <Title>Financials</Title>
      <Box marginTop="30px" />
      {revenueQuestionsList}
      <SectionTitle>Current investors & their equity stake</SectionTitle>
      {Object.entries(allInvestors).map(([key, keyValue]) => {
        return (
          <ListItemBox>
            <FlexContainer>
              <Text>{key}</Text>
            </FlexContainer>
            <FlexContainer>
              <FormInput
                type="text"
                placeholder="Enter amount"
                value={formatInputWithCommas(keyValue.value)}
                onChange={(e) => {
                  const targetValue = getValue(e);
                  handleCommaNumberChange(targetValue, (value) => {
                    dispatch(modifiedInvestorValue({ [key]: { ...keyValue, value } }));
                  });
                }}
                inputIcon={
                  <InputIcon>
                    <span> {selectedCountryCurrency == '' ? defaultCurrency : selectedCountryCurrency}</span>
                  </InputIcon>
                }
                padding="12px"
                isError={isEmpty(keyValue.value)}
                inputFieldStyle={investorsInputStyle}
              />
              <FormInput
                type="number"
                inputFieldStyle={percentInputStyle}
                padding="10px 0 12px 10px"
                isError={parseFloat(keyValue.percentageValue) > 100 || isEmpty(keyValue.percentageValue)}
                value={keyValue.percentageValue}
                onChange={(e: any) => {
                  const { value } = e.target;
                  if (/^\d{0,3}$/.test(`${value}`)) {
                    dispatch(upDateInvestorPercent({ [key]: { ...keyValue, percentageValue: e.target.value } }));
                  }
                }}
                inputIcon={<PercentSymbol>%</PercentSymbol>}
                iconPosition="end"
              />
              {!(key.toLowerCase() === 'Founders'.toLowerCase()) && (
                <DeleteIcon
                  onClick={() => {
                    dispatch(deleteInvestorFromList(key));
                    setTotalInvestorsContribution(
                      Object.values(allInvestors).reduce((acc, val) => {
                        return acc + parseFloat(val.value);
                      }, 0),
                    );
                  }}
                />
              )}
            </FlexContainer>
          </ListItemBox>
        );
      })}
      <Section>
        <div style={{ width: 'fit-content' }}>
          {showCustomInputField && (
            <AddMoreField
              addNewItemFromInputField={addNewInvestorFromInput}
              setShowCustomInputField={setShowCustomInputField}
            />
          )}
        </div>
        {Object.values(allInvestors).length < 10 && (
          <ButtonBox>
            <Button
              bgColor={customTheme.colors.LightBlack}
              hoverColor={customTheme.colors.primaryColor}
              marginTop="0"
              type="button"
              onClick={(e: Event) => {
                e.preventDefault();
                Object.values(allInvestors).length < 10 && setOpen(true);
              }}
              id="currentInvestment"
            >
              + Add more
            </Button>
            <MenuWrapper open={open}>
              {financialQuestions.financialInvestorQuestions.map((el: any) => {
                if (el.question.toLowerCase() !== 'Founders'.toLowerCase()) {
                  return (
                    <MenuItem
                      key={el.id}
                      onClick={() => {
                        if (/Other/i.test(el.question)) {
                          setShowCustomInputField((prevState: boolean) => !prevState);
                          setCurrentOtherValuationId(el.valuationQuestionId);
                          return;
                        }
                        const newList = Object.values(allInvestors);
                        if (newList.length >= 10) {
                          return;
                        }
                        const foundInvestors = newList.filter((item) => {
                          return item.text === el.question;
                        });
                        if (foundInvestors.length > 0) {
                          return;
                        }
                        const newItem = {
                          text: el.question,
                          valuationQuestionId: el.valuationQuestionId,
                          added: true,
                          value: '',
                          percentageValue: '',
                          isRequired: false,
                          isError: false,
                        };
                        dispatch(addNewItemFromInvestorMenu({ [el.question]: newItem }));
                        setTotalInvestorsContribution(
                          Object.values(allInvestors).reduce((acc, val) => {
                            return acc + parseFloat(val.value);
                          }, 0),
                        );
                      }}
                    >
                      <StyledSpan>{el.question}</StyledSpan>
                    </MenuItem>
                  );
                }
              })}
            </MenuWrapper>
          </ButtonBox>
        )}

        <TotalValues
          totalContribution={totalInvestorsContribution}
          totalPercentage={totalInvestorsPercent}
          selectedCountryCurrency={selectedCountryCurrency}
        />
      </Section>
      {revenueQuestions}

      <Section>
        <SectionTitle>Planned use of funds</SectionTitle>
        {Object.entries(useOFFunds).map(([key, keyValue], index) => {
          return (
            <ListItemBox>
              <FlexContainer>
                <Text>{key}</Text>
              </FlexContainer>

              <FlexContainer>
                <FormInput
                  type="text"
                  placeholder="Enter amount"
                  value={formatInputWithCommas(keyValue.value)}
                  isError={keyValue.isError}
                  onChange={(e) => {
                    const targetValue = getValue(e);
                    handleCommaNumberChange(targetValue, (value) => {
                      dispatch(
                        modifyUseOfFunValue({
                          [key]: { ...keyValue, value },
                        }),
                      );
                    });
                  }}
                  inputIcon={
                    <InputIcon>
                      <span> {selectedCountryCurrency == '' ? defaultCurrency : selectedCountryCurrency}</span>
                    </InputIcon>
                  }
                  padding="12px"
                  inputFieldStyle={investorsInputStyle}
                />

                <StakePercent>
                  <span>{keyValue.percentageValue} %</span>
                </StakePercent>
                {!/Product development/i.test(keyValue.text) && (
                  <DeleteIcon
                    onClick={(e) => {
                      dispatch(removePlanUseOfFund(key));
                    }}
                  />
                )}
              </FlexContainer>
            </ListItemBox>
          );
        })}
        <div style={{ width: 'fit-content' }}>
          {showInputFieldTwo && (
            <AddMoreField
              addNewItemFromInputField={addNewPlanUseOfFundFromInput}
              setShowCustomInputField={setShowInputFieldTwo}
            />
          )}
        </div>
        {Object.values(useOFFunds).length < 10 && (
          <ButtonBox>
            <Button
              bgColor={customTheme.colors.LightBlack}
              hoverColor={customTheme.colors.primaryColor}
              marginTop="0"
              type="button"
              onClick={(e: Event) => {
                e.preventDefault();
                Object.values(useOFFunds).length < 10 && setOpenA(true);
              }}
              id="planUseOfFunds"
            >
              + Add more
            </Button>
            <MenuWrapper open={openA}>
              {financialQuestions.financialFundsQuestions.map((el: any) => {
                if (el.question.toLowerCase() !== 'Product development'.toLowerCase()) {
                  return (
                    <MenuItem
                      key={el.id}
                      onClick={() => {
                        if (/Other/i.test(el.question)) {
                          setShowInputFieldTwo((prevState: boolean) => !prevState);
                          setCurrentOtherValuationId(el.valuationQuestionId);
                          return;
                        }
                        const newList = Object.values(useOFFunds);
                        if (newList.length >= 10) {
                          return;
                        }
                        const foundItem = newList.filter((item) => {
                          return item.text === el.question;
                        });
                        if (foundItem.length > 0) {
                          return;
                        }
                        const newItem = {
                          text: el.question,
                          valuationQuestionId: el.valuationQuestionId,
                          added: true,
                          value: '',
                          percentageValue: '',
                          isRequired: false,
                          isError: false,
                        };
                        dispatch(addNewPlanUseOfFundFromMenu({ [el.question]: newItem }));
                      }}
                    >
                      <StyledSpan>{el.question}</StyledSpan>
                    </MenuItem>
                  );
                }
              })}
            </MenuWrapper>
          </ButtonBox>
        )}

        <TotalValues
          totalContribution={useOfFunTotalValue}
          totalPercentage={useOfFunTotalPercent}
          selectedCountryCurrency={selectedCountryCurrency}
        />
      </Section>
      {additionalInvesment}
    </Wrapper>
  );
};

export default FinancialQuestion;
