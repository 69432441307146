import { isEmpty } from 'lodash';
import { Route, Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { getLocalStorageItem } from '../../utils/getLocalStorageItem';

interface PrivateRouteProps {
  Component: any;
  path: string;
}

interface DecodedJWT {
  data: { email: string; role: string; userId: number };
  exp: number;
  iat: number;
}

const isAuthenticated = (): boolean => {
  const deserilizeUserInfo = getLocalStorageItem('growthVal');
  if (isEmpty(deserilizeUserInfo)) {
    return false;
  }

  const decoded: DecodedJWT = jwt_decode(deserilizeUserInfo.token);
  if (Math.floor(Date.now() / 1000) > decoded.exp) {
    return false;
  }
  return true;
};

const PrivateRoute = ({ Component, path, ...rest }: PrivateRouteProps): JSX.Element => {
  return (
    <Route
      path={path}
      exact={path === '/'}
      {...rest}
      render={(props) => {
        if (isAuthenticated()) {
          return <Component {...props} />;
        }

        const { location } = props;
        return <Redirect to={{ pathname: '/', state: { from: location } }} />;
      }}
    />
  );
};

export default PrivateRoute;
