import { useState, useEffect } from 'react';
import api from 'utils/api';
import { GetPdfReportData } from 'utils/ApiUrls';
import { PdfResponseType, ReportData } from './types';

const useFetchPdfReport = (userId: string, token: string): any => {
  const [pdfReportData, setPdfReportData] = useState<ReportData>();

  // this interceptor should inherit headers from the global instance
  // we are just intercepting it twice to add params so it should be O.K.
  // but please check, just to be sure
  api.interceptors.request.use((config) => {
    config.params = { pdfToken: token };
    return config;
  });

  useEffect(() => {
    pdfReportApi.get({
      onSuccess: (data) => setPdfReportData(data),
    });
  }, []);

  const pdfReportApi = {
    get: async ({
      onRequest = () => null,
      onSuccess = () => null,
      onFailure = () => null,
      onFinally = () => null,
    }: {
      onRequest?: () => void;
      onSuccess?: (data: ReportData) => void;
      onFailure?: (message: string) => void;
      onFinally?: () => void;
    } = {}): Promise<void> => {
      onRequest();
      try {
        const { data: { data = {} } = {} } = (await api.get<PdfResponseType>(GetPdfReportData(userId))) || {};
        onSuccess(data as ReportData);
      } catch ({ response: { data: { error: { message = '' } = {} } = {} } = {} }) {
        onFailure(message || 'Oops, something went wrong');
      } finally {
        onFinally();
      }
    },
  };

  return pdfReportData;
};

export default useFetchPdfReport;
