import React, { useState } from 'react';
import ResponsivePopup from 'components/organisms/ResponsiveModal';
import GrowthValLogo from 'components/molecules/GrowthValLogo/GrowthValLogo';
import Box from '@material-ui/core/Box';
import Logo from 'assets/logoSymbol.svg';
import Typography from '@material-ui/core/Typography';
import CenterlizeBox from 'components/organisms/CenterlizedBox';
import { useStyles } from './styles';

type ConfirmationProps = {
  isOpen: boolean;
};

const Confirmation: React.FC<ConfirmationProps> = ({ isOpen }) => {
  const [showModal, setModalVisibility] = useState(isOpen);
  const classes = useStyles();
  return (
    <ResponsivePopup
      closeOnOverlayClick
      closeOnEsc={false}
      open={showModal}
      showCloseIcon={false}
      modalVisibility={() => setModalVisibility(false)}
      modalWidth="29%"
      padding="1.2rem"
      mobilePadding="12px 10px"
    >
      <Box>
        <Box display="flex" flexDirection="column" alignItems="center" p={7}>
          <GrowthValLogo logo={Logo} />
          <Box mt={2}>
            <Typography variant="subtitle2" className={classes.confirmationHeading}>
              Please wait, we are generating your valuation report...
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="body2" className={classes.confirmationText}>
              This can take up to 2 minutes
            </Typography>
          </Box>
        </Box>
      </Box>
    </ResponsivePopup>
  );
};

export default Confirmation;
