import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Bar, Pie } from 'react-chartjs-2';
import { Divider } from '@material-ui/core';
import { getAmountString, commaSeparatedNumber } from 'utils/helperMethod';
import { FinancialReportProps, Graph, LegendsType, FinancialBarGraph } from './types';
import { getColorPalette, pieChartOptions, FinancialBarConfig } from './helper';
import { tweleveYearsData, financialBarLegendData, financialYears5Data, financialYears5DataPercentage } from './data';
import customTheme from 'theme/customTheme';

const FinancialReport: React.FC<FinancialReportProps> = ({ currency, financialDetails }) => {
  const [pieChartData, setPieChart] = useState<Graph>();
  const [pieChartLegends, setPieChartLegends] = useState<LegendsType>([]);
  const [barGraphData, setBarGraphData] = useState<FinancialBarGraph>();

  useEffect(() => {
    const { currentShareHolders, yearsFinancialData } = financialDetails;
    const pieChartLabels: Array<string> = [];
    const pieChartRange: Array<string> = [];
    const legendsPieChart: Array<{ label: string; colorCode: string }> = [];
    if (!isEmpty(currentShareHolders)) {
      currentShareHolders.forEach(({ name, fundsPercentage, value }, index) => {
        pieChartLabels.push(name);
        pieChartRange.push(fundsPercentage);
        legendsPieChart.push({
          label: `${name}(${getAmountString(value, currency)})`,
          colorCode: getColorPalette(currentShareHolders.length)[index],
        });
      });

      setPieChartLegends(legendsPieChart);
      setPieChart({
        labels: pieChartLabels,
        datasets: [
          {
            data: pieChartRange,
            backgroundColor: getColorPalette(pieChartRange.length),
          },
        ],
      });
    }

    if (!isEmpty(yearsFinancialData)) {
      const barDataRange = yearsFinancialData.map(({ question, value }) => {
        return {
          [question]: Object.keys(value)
            .filter((key) => key !== 'last12Month')
            .map((key) => value[key]),
        };
      });

      const barThickness = window.matchMedia('(max-width: 767px)').matches ? 15 : 30;
      setBarGraphData({
        labels: ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'],
        datasets: [
          {
            type: 'bar',
            label: 'Revenue',
            fill: false,
            data: barDataRange[0][yearsFinancialData[0].question],
            backgroundColor: customTheme.colors.MediumSpringGreen,
            yAxisID: 'y',
            barThickness: barThickness,
          },
          {
            type: 'bar',
            label: 'Gross Profit',
            fill: false,
            data: barDataRange[1][yearsFinancialData[1].question],
            backgroundColor: customTheme.colors.DarkBlack,
            yAxisID: 'y',
            barThickness: barThickness,
          },
          {
            type: 'line',
            fill: false,
            label: 'Gross Profit margin',
            data: barDataRange[2][yearsFinancialData[2].question],
            backgroundColor: customTheme.colors.blue1,
            yAxisID: 'y1',
            barThickness: barThickness,
          },
        ],
      });
    }
  }, [financialDetails, currency]);

  if (isEmpty(financialDetails)) return <></>;

  const { last12MonthsData, year5Data } = financialDetails;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <h2
            style={{
              fontSize: '32px',
              lineHeight: '43px',
              wordBreak: 'break-word',
              color: customTheme.colors.DarkBlack,
              fontStyle: 'normal',
              fontFamily: 'Prompt',
              fontWeight: 700,
            }}
          >
            Financials
          </h2>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
        <div
          style={{
            background: customTheme.colors.white,
            minHeight: '539px',
            width: '32%',
            overflow: 'hidden',
            boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
            borderRadius: '4px',
            color: 'rgba(0, 0, 0, 0.87)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: '#fff',
          }}
          className="tilesBoxShadow"
        >
          <div style={{ padding: '20px 30px 0 30px' }}>
            <h3
              style={{
                fontSize: '18px',
                lineHeight: '21px',
                wordBreak: 'break-word',
                fontFamily: 'Prompt',
                fontWeight: 700,
                fontStyle: 'normal',
                color: '#171C1B',
              }}
            >
              Last 12 months
            </h3>
          </div>
          <div style={{ marginTop: '8px', marginBottom: '16px' }} />
          <Divider />
          <div style={{ padding: '20px 30px 37px 30px', display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
            {!isEmpty(last12MonthsData) &&
              tweleveYearsData.map(({ name, value }) => {
                const amount = last12MonthsData[value];
                return (
                  <div
                    style={{
                      display: 'flex',
                      borderBottom: `1px solid ${customTheme.colors.borderBottomGray}`,
                      padding: '8px 5px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p
                      style={{
                        fontFamily: 'Mulish',
                        fontWeight: 700,
                        fontStyle: 'normal',
                        color: '#171C1B',
                        fontSize: '14px',
                        lineHeight: '18px',
                      }}
                    >
                      {name}
                    </p>
                    <div style={{ marginRight: '0px' }}>
                      <p
                        style={{
                          fontSize: '14px',
                          lineHeight: '18px',
                          fontFamily: 'Mulish',
                          fontWeight: 700,
                          fontStyle: 'normal',
                          color: '#171C1B',
                        }}
                      >
                        {amount !== '-' ? `${currency}${commaSeparatedNumber(Number(amount))}` : '-'}
                      </p>
                    </div>
                  </div>
                );
              })}
            <div
              style={{
                marginTop: '16px',
                marginLeft: '6px',
                display: 'flex',
                marginRight: '8px',
                justifyContent: 'space-between',
              }}
            >
              <p
                style={{
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontFamily: 'Mulish',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  color: '#171C1B',
                }}
              >
                Money invested in the company
              </p>
              <div style={{ marginLeft: '32px' }} />
              <p
                style={{
                  fontSize: '14px',
                  lineHeight: '18px',
                  fontFamily: 'Mulish',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  color: '#171C1B',
                }}
              >
                {currency}
                {commaSeparatedNumber(Number(last12MonthsData.moneyInvestedInCompany))}
              </p>
            </div>
          </div>
          <div style={{ padding: '20px 30px 0 30px' }}>
            <h2
              style={{
                fontFamily: 'Prompt',
                fontWeight: 700,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '18px',
                lineHeight: '21px',
                wordBreak: 'break-word',
              }}
            >
              Current shareholders
            </h2>
          </div>
          <div style={{ marginTop: '8px', marginBottom: '16px' }} />
          <Divider />
          <div style={{ width: '100%', display: 'flex', padding: '0 30px 0 30px', flexDirection: 'column-reverse' }}>
            <div style={{ width: '70%', display: 'flex', margin: '12px auto' }}>
              <Pie type="pie" data={pieChartData} options={pieChartOptions} />
            </div>
            <div>
              <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', marginTop: '16px' }}>
                {!isEmpty(pieChartLegends) &&
                  pieChartLegends.map(({ label, colorCode }) => {
                    return (
                      <div style={{ display: 'flex', padding: '2px 20px 10px 4px' }}>
                        <span
                          style={{
                            background: colorCode,
                            width: 12,
                            height: 12,
                            borderRadius: '1px',
                            marginRight: '8px',
                          }}
                        ></span>
                        <p
                          style={{
                            fontFamily: 'Mulish',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontSize: '12px',
                            lineHeight: '12px',
                            color: customTheme.colors.gray6,
                          }}
                        >
                          {label}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            overflow: 'hidden',
            boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
            borderRadius: '4px',
            color: 'rgba(0, 0, 0, 0.87)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: '#fff',
            width: '66%',
            background: customTheme.colors.white,
            minHeight: '590px',
          }}
          className="tilesBoxShadow"
        >
          <div style={{ padding: '20px 30px 0 30px' }}>
            <h2
              style={{
                fontFamily: 'Prompt',
                fontWeight: 700,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '18px',
                lineHeight: '21px',
                wordBreak: 'break-word',
              }}
            >
              Forecast financials
            </h2>
          </div>
          <div style={{ marginTop: '8px', marginBottom: '16px' }} />
          <Divider />
          <div style={{ padding: '20px 30px 37px 30px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: '16px' }}>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                {!isEmpty(financialBarLegendData) &&
                  financialBarLegendData.map(({ label, colorCode }) => {
                    return (
                      <div style={{ padding: '2px 10px 10px 4px', display: 'flex' }}>
                        <span
                          style={{
                            background: colorCode,
                            width: 12,
                            height: 12,
                            borderRadius: '1px',
                            marginRight: '8px',
                          }}
                        ></span>
                        <p
                          style={{
                            fontFamily: 'Mulish',
                            fontWeight: 400,
                            fontStyle: 'normal',
                            fontSize: '12px',
                            lineHeight: '12px',
                            color: customTheme.colors.gray6,
                          }}
                        >
                          {label}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <Bar type="bar" data={barGraphData} options={FinancialBarConfig(currency)} />
            </div>
          </div>
          <div style={{ padding: '20px 30px 0 30px' }}>
            <h2
              style={{
                fontFamily: 'Prompt',
                fontWeight: 700,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '18px',
                lineHeight: '21px',
                wordBreak: 'break-word',
              }}
            >
              In Year 5
            </h2>
          </div>
          <div style={{ marginTop: '8px', marginBottom: '16px' }} />
          <Divider />
          <div style={{ display: 'flex', padding: '20px 30px 37px 30px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '30%',
              }}
            >
              {!isEmpty(year5Data) &&
                financialYears5Data.map(({ name, value }) => {
                  const amount = year5Data[value];
                  return (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottom: `1px solid ${customTheme.colors.borderBottomGray}`,
                        padding: '8px 5px',
                      }}
                    >
                      <p
                        style={{
                          fontSize: '14px',
                          lineHeight: '18px',
                          fontFamily: 'Mulish',
                          fontWeight: 700,
                          fontStyle: 'normal',
                          color: '#171C1B',
                        }}
                      >
                        {name}
                      </p>
                      <div style={{ marginRight: 0 }}>
                        <p
                          style={{
                            fontSize: '14px',
                            lineHeight: '18px',
                            fontFamily: 'Mulish',
                            fontWeight: 700,
                            fontStyle: 'normal',
                            color: '#171C1B',
                          }}
                        >
                          {amount !== '-' && amount !== null ? getAmountString(amount, currency) : '-'}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div
              style={{ marginLeft: '56px', width: '60%', display: 'flex', justifyContent: 'space-between' }}
              className="profitPc"
            >
              {!isEmpty(year5Data) &&
                financialYears5DataPercentage.map(({ name, value }) => {
                  return (
                    <div style={{ maxWidth: '25%', display: 'flex', flexDirection: 'column' }}>
                      <div className="marginProfit">
                        <h4
                          style={{
                            fontFamily: 'Prompt',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            color: '#9B9B9B',
                            fontSize: '14px',
                            lineHeight: '16px',
                          }}
                        >
                          {name}
                        </h4>
                      </div>
                      <div>
                        <h4
                          style={{
                            fontSize: '38px',
                            lineHeight: '48px',
                            color: customTheme.colors.MediumSpringGreen,
                            fontFamily: 'Prompt',
                            fontWeight: 700,
                            fontStyle: 'normal',
                          }}
                        >
                          {year5Data[value] !== null && year5Data[value] !== '-' ? `${year5Data[value]}%` : '-'}
                        </h4>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialReport;
