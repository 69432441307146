import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../styles';

interface PropTypes {
  message?: string;
  type?: string;
  children: React.ReactNode;
}

const SubmitSection: React.FC<PropTypes> = ({ message, type, children }) => {
  const classes = useStyles();
  const messageClass = classnames(classes.boldText, {
    [classes.success]: type === 'SUCCESS',
    [classes.error]: type === 'ERROR',
  });

  return (
    <Box display="flex">
      <Box className={`${classes.leftBox} ${classes.smallUp}`} />
      <Box className={classes.submitContainer}>
        <Box className={classes.submitBox}>{children}</Box>
        <Typography className={messageClass} align="left">
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default SubmitSection;
