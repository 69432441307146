import {
  GET_USE_OF_FUND_REQUIRED_FIELD,
  GET_REQUIRED_FIELD,
  ADD_NEW_ITEM_FROM_INVESTORS_MENU,
  MODIFIED_INVESTORS_VALUE,
  DELETE_INVESTOR,
  ADD_NEW_INVESTOR_FROM_INPUT,
  UPDATE_INVESTOR_PERCENT,
  ADD_NEW_PLAN_USE_OF_FUND,
  REMOVE_USE_OF_FUND,
  ADD_NEW_UES_OF_FUND_FROM_INPUT,
  MODIFIED_USE_OF_FUND_VALUE,
  GET_USE_OF_FUND_PREFILLED,
  GET_INVESTORS_QUESTION_PREFILLED,
} from '../types';
import { computeValueAndPercent } from 'components/organisms/FinancialQuestion/financialQuestionHelper';

export const allInvestorsReducer = (state = {}, action: any) => {
  switch (action.type) {
    case GET_REQUIRED_FIELD:
      return action.payload;
    case ADD_NEW_ITEM_FROM_INVESTORS_MENU:
      return action.payload;
    case MODIFIED_INVESTORS_VALUE:
      return action.payload;
    case DELETE_INVESTOR:
      return action.payload;
    case ADD_NEW_INVESTOR_FROM_INPUT:
      return action.payload;
    case UPDATE_INVESTOR_PERCENT:
      return action.payload;
    case GET_INVESTORS_QUESTION_PREFILLED:
      return action.payload;
    default:
      return state;
  }
};

export const useOfFundsReducer = (state = {}, action: any) => {
  switch (action.type) {
    case GET_USE_OF_FUND_REQUIRED_FIELD:
      return action.payload;
    case ADD_NEW_PLAN_USE_OF_FUND:
      return computeValueAndPercent(action.payload);
    case REMOVE_USE_OF_FUND:
      return computeValueAndPercent(action.payload);
    case ADD_NEW_UES_OF_FUND_FROM_INPUT:
      return computeValueAndPercent(action.payload);
    case MODIFIED_USE_OF_FUND_VALUE:
      return computeValueAndPercent(action.payload);
    case GET_USE_OF_FUND_PREFILLED:
      return action.payload;
    default:
      return state;
  }
};
