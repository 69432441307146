import Registration from '../components/templates/Registration';
import LoginForm from '../components/templates/Login';
import DashBoard from '../components/templates/dashboard/DashBoard';
import AccountVerification from '../components/templates/AccountVerification';
import ForgotPassword from '../components/templates/ForgotPassword';
import ResetPassword from '../components/templates/ResetPassword';
import QuestionsSection from '../components/templates/Questionaries';
import RegistrationConfirmation from '../components/templates/RegistrationConfirmation';
import Valuation from 'components/templates/Valuation';
import Settings from 'components/templates/Settings';
import PdfReport from 'components/templates/PdfReport';
import Unsubscribe from 'components/templates/Unsubscribe';

export const routesObj: {
  [key: string]: {
    id: number;
    path: string;
    component: any;
    isProtected?: boolean;
    page?: string;
  };
} = {
  home: {
    id: 1,
    path: '/',
    component: LoginForm,
  },
  registration: {
    id: 2,
    path: '/registration',
    component: Registration,
  },
  login: {
    id: 3,
    path: '/login',
    component: LoginForm,
  },
  accountVerification: {
    id: 4,
    path: '/account-verification/:verificationToken',
    component: AccountVerification,
  },
  forgotPassword: {
    id: 5,
    path: '/forgot-password',
    component: ForgotPassword,
  },
  resetPassword: {
    id: 6,
    path: '/reset-password/:passwordToken',
    component: ResetPassword,
  },
  registrationConfirmation: {
    id: 7,
    path: '/registration-confirmation',
    component: RegistrationConfirmation,
  },
  dashboard: {
    id: 8,
    path: '/dashboard',
    component: DashBoard,
    isProtected: true,
  },
  questionaries: {
    id: 9,
    path: '/questionnaires',
    component: QuestionsSection,
    isProtected: true,
  },
  ReportSection: {
    id: 10,
    path: '/report',
    component: Valuation,
    isProtected: true,
  },
  settings: {
    id: 11,
    path: '/settings',
    component: Settings,
    isProtected: true,
  },
  pdfReport: {
    id: 12,
    path: '/pdfReport/:userId/:token',
    component: PdfReport,
  },
  unsubscribe: {
    id: 12,
    path: '/unsubscribe/:userId/:token',
    component: Unsubscribe,
  },
};

export const routes = Object.values(routesObj);
