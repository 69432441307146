import styled from 'styled-components';

type TitleProps = {
  marginTop?: string;
  textAlign?: string;
};

type SubTitleProps = {
  textAlign?: string;
};

export const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 15px 13px;
`;

export const Title = styled.h1<TitleProps>`
  text-align: ${(props) => props.textAlign || 'center'};
  font-size: 26px;
  line-height: 35px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.DarkBlack};
  font-family: 'Prompt';
  font-style: normal;
  margin-bottom: 12px;
  margin-top: ${(props) => props.marginTop || '15px'};
  word-break: 'break-word';
`;

export const SubTitle = styled.p<SubTitleProps>`
  text-align: ${(props) => props.textAlign || 'center'};
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.DarkBlack};
  font-style: normal;
  font-family: 'Mulish';
  margin-bottom: 10px;
  margin-top: 10px;
  word-break: 'break-word';
`;

export const OuterBox = styled.div`
  padding: 58px 20px;
`;

export const LinkPath = styled.span`
  color: ${(props) => props.theme.colors.MediumSpringGreen};
`;

export const Error = styled.p`
  color: ${(props) => props.theme.colors.error};
  font-size: 14px;
  font-family: Mulish;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  margin: 12px 0;
`;

export const FormSection = styled.div`
  width: 100%;
  padding: 20px 0;
`;

export const Label = styled.label`
  text-align: left;
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.DarkBlack};
  font-style: normal;
  font-family: 'Mulish';
  word-break: 'break-word';
  margin-left: 5px;
`;

export const BoxFullWidth = styled.div`
  width: 100%;
  padding: 20px 0;
`;

export const Wrapper = styled.div`
  background: ${(props) => props.theme.colors.gray5};
`;

export const HyperLinkText = styled.span`
  color: ${(props) => props.theme.colors.MediumSpringGreen};
  font-weight: bold;
`;

export const Symbol = styled.div`
  background: ${(props) => props.theme.colors.MediumSpringGreen};
  border-radius: 50px 0 0 50px;
  width: 43px;
  height: 58px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 15px 25px;
`;

export const Currency = styled.span`
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  font-size: 16px;
  line-height: 30px;
  font-family: Mulish;
  font-weight: bold;
`;
