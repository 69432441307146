import { DisclaimerBox, DisclaimerText } from './styles';
import { useStyles } from 'components/templates/ValuationReport/styles';
import { Typography } from '@material-ui/core';

const Disclaimer = () => {
  const classes = useStyles();
  return (
    <DisclaimerBox>
      <Typography variant="subtitle1" className={classes.sectionTitle}>
        Disclaimer
      </Typography>
      <DisclaimerText>
        <p>
          {' '}
          Subject to Clause 15.1 of the Terms & Condition that you agreed to, we exclude all implied conditions,
          warranties, representations or other terms that may apply to our site and/or platform or any content on it.
          You assume sole responsibility for results obtained from your use of the platform and services (including the
          reports) and for conclusions drawn from such use. We shall have no liability for any damage caused by errors
          or omissions in any information provided to us by you in connection with the services. By using our site
          and/or platform, you acknowledge that we are not certified valuers. The content on our site and/or platform is
          provided for general information only and primarily to assist businesses, founders and investors to discover
          the value of a business through using our software which creates an automated valuation report. It is not
          intended to amount to advice on which you should rely. You must obtain professional or specialist advice
          before taking, or refraining from, any action on the basis of the content on our site and/or platform.
          Although we make reasonable efforts to update the information on our site and/or platform, we make no
          representations, warranties or guarantees, whether express or implied, that the content on our site and/or
          platform is accurate, complete or up to date.
        </p>

        <p>
          {' '}
          Please see the full Terms & Conditions{' '}
          <a
            href="https://www.growthval.com/terms-conditions"
            target="_blank"
            rel="noreferrer"
            className={classes.hyperLink}
          >
            here
          </a>
        </p>
      </DisclaimerText>
    </DisclaimerBox>
  );
};

export default Disclaimer;
