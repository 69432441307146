export const riskLabels = [
  {
    id: 1,
    label: 'Very low',
    value: 2,
  },
  {
    id: 2,
    label: 'Low',
    value: 1,
  },
  {
    id: 3,
    label: 'Neutral',
    value: 0,
  },
  {
    id: 4,
    label: 'High',
    value: -1,
  },
  {
    id: 5,
    label: 'Very high',
    value: -2,
  },
];
