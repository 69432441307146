import { ChangeEvent, useState } from 'react';
import { validateFormData } from './validationForm';
import { SignUpUrl } from 'utils/ApiUrls';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const initFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  businessType: '',
};

const useRegistration = () => {
  const [formValues, setFormValues] = useState(initFormValues);

  const [formError, setFormError] = useState({
    businessTypeError: '',
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    passwordError: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [registrating, setRegistrating] = useState(false);
  const history = useHistory();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, type, value } = e.target;

    if (type === 'radio') {
      setFormValues({ ...formValues, businessType: value });
      return;
    }
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const error = validateFormData(formValues);

    setFormError(error);
    if (
      error.emailError.length ||
      error.firstNameError.length ||
      error.passwordError.length ||
      error.lastNameError.length ||
      error.businessTypeError.length
    ) {
      return;
    }

    try {
      setRegistrating(true);
      const response = await axios.post(SignUpUrl, formValues);

      if (response.data.success) {
        history.push('/registration-confirmation');
        setRegistrating(false);
      }
    } catch (error) {
      const { response } = error;

      if (response === undefined) {
        setErrorMessage('ERR_INTERNET_DISCONNECTED');
        setRegistrating(false);
        return;
      }

      if (response.status === 422) {
        setErrorMessage('Please check you details');
      } else if (response.status === 409) {
        setErrorMessage('Email already exists!!');
      } else {
        setErrorMessage(error.message);
      }
      setRegistrating(false);
    }
  };

  return {
    formValues,
    handleChange,
    handleSubmit,
    registrating,
    formError,
    errorMessage,
    setFormValues,
  };
};
export default useRegistration;
