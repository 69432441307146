const ApiUrl: string = process.env.REACT_APP_API_URL || 'https://production-growthval-api.herokuapp.com/';

export const EmailConfirmation = (confirmationCode: string): string => `${ApiUrl}users/${confirmationCode}/validate`;
export const ResendEmailVerificationLink = (userId: number): string =>
  `${ApiUrl}users/${userId}/resendVerificationEmail`;
export const ForgotPasswordLink = `${ApiUrl}users/forgotPassword`;
export const ResetPasswordRequest = (token: string): string => `${ApiUrl}users/${token}/resetPassword`;
export const VerifyUserAccount = (userId: number): string => `${ApiUrl}users/${userId}/verifyAccount`;
export const users = `${ApiUrl}users`;
export const SignUpUrl = `${ApiUrl}users/signup`;
export const settings = `${ApiUrl}users/settings`;
export const changePassword = `${ApiUrl}users/changePassword`;
export const LoginUrl = `${ApiUrl}login`;
export const PaymentUrl = `${ApiUrl}stripe/paymentIntent`;
export const GetCountries = `${ApiUrl}countries`;
export const GetIndustries = `${ApiUrl}industries`;
export const GetValuationQuestions = `${ApiUrl}valuationQuestions`;
export const GetCompanyStages = `${ApiUrl}companyStages`;
export const SaveValuationsData = `${ApiUrl}valuationQuestionResponses`;
export const GetReportData = `${ApiUrl}valuationReport`;
export const GetValuationMethodWeights = `${ApiUrl}valuationMethodWeights`;
export const SaveValuationParamas = `${ApiUrl}valuationParameters`;
export const GetValuationAnswers = (valuationId: number): string =>
  `${ApiUrl}valuationQuestionResponses/${valuationId}`;
export const GetPdfReportData = (userId: string): string => `${ApiUrl}valuationReport/${userId}`;
export const MarketingEmailSubscription = (userId: string): string => `${ApiUrl}users/${userId}/unsubscribeEmails`;
