export const methodCons = [
  {
    prop: 'Rarely used to value startups by VCs and Angel Investors',
    id: 1,
  },
  {
    prop: 'Highly sensitive on future cash flows, which are uncertain for startups',
    id: 2,
  },
  {
    prop: 'Highly dependent on long term growth rate and discount rate assumptions',
    id: 3,
  },
];

export const methodPros = [
  {
    prop: 'In-depth analysis of the businesses’ financials',
    id: 1,
  },
];

export const leftCardData = [
  {
    title: 'Discount rate',
    value: 60,
  },
  {
    title: 'Long-term growth rate',
    value: 701,
  },
  {
    title: 'Sum of discounted FCF (Years 1 to 5)',
    value: 890,
  },
  {
    title: 'Discounted FCF in Terminal Period',
    value: 900,
  },
  {
    title: 'Enterprise Value',
    value: 600,
  },
  {
    title: 'Plus Cash',
    value: 400,
  },
  {
    title: 'Less Borrowings',
    value: 690,
  },
  {
    title: 'Equity Value',
    value: 605238,
  },
];

export const TableHeadData = [
  {
    id: 3,
    title: 'Year 1',
  },
  {
    id: 4,
    title: 'Year 2',
  },
  {
    id: 5,
    title: 'Year 3',
  },
  {
    id: 6,
    title: 'Year 4',
  },
  {
    id: 7,
    title: 'Year 5',
  },
];
