import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { routes } from './routes/routes';
import ContainerWithBg from './components/layout/ContainerWithBg';
import PrivateRoute from './routes/privateRoute/PrivateRoute';
import LoadingProvider from 'components/layout/Loading/LoadingProvider';

import './style/global.css'; // this style will be removed, it is just here for experimental purpose

const App: React.FC = () => {
  return (
    <>
      <header></header>
      <ContainerWithBg>
        <main>
          <BrowserRouter>
            <LoadingProvider>
              <Switch>
                {routes.map(({ path, isProtected, component }) =>
                  isProtected ? (
                    <PrivateRoute path={path} key={path} Component={component} />
                  ) : (
                    <Route path={path} key={path} exact={path === '/'} component={component} />
                  ),
                )}
              </Switch>
            </LoadingProvider>
          </BrowserRouter>
        </main>
      </ContainerWithBg>
      <footer></footer>
    </>
  );
};
export default App;
