import {
  ADD_NEW_ITEM_FROM_INVESTORS_MENU,
  MODIFIED_INVESTORS_VALUE,
  DELETE_INVESTOR,
  ADD_NEW_INVESTOR_FROM_INPUT,
  UPDATE_INVESTOR_PERCENT,
  ADD_NEW_PLAN_USE_OF_FUND,
  REMOVE_USE_OF_FUND,
  ADD_NEW_UES_OF_FUND_FROM_INPUT,
  MODIFIED_USE_OF_FUND_VALUE,
  UPDATE_PERCENT_IN_USE_OF_FUND_SECTION,
} from '../types';
import { AllInvestorsI } from 'components/organisms/FinancialQuestion/types';
import deepClone from 'lodash/cloneDeep';

export const getRequiredField = (arg: any) => {
  return (dispatch: any) => {
    dispatch({
      type: arg.type,
      payload: arg.payload,
    });
  };
};

export const addNewItemFromInvestorMenu = (arg: { [key: string]: AllInvestorsI }) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: ADD_NEW_ITEM_FROM_INVESTORS_MENU,
      payload: { ...getState().allInvestors, ...arg },
    });
  };
};

export const modifiedInvestorValue = (arg: { [key: string]: AllInvestorsI }) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: MODIFIED_INVESTORS_VALUE,
      payload: { ...getState().allInvestors, ...arg },
    });
  };
};

export const deleteInvestorFromList = (arg: string) => {
  return (dispatch: any, getState: any) => {
    const allInvestors = deepClone(getState().allInvestors);
    delete allInvestors[arg];
    dispatch({
      type: DELETE_INVESTOR,
      payload: allInvestors,
    });
  };
};

export const addNewItemFromInvestorsInput = (inputValue: string, currentOtherValuationId: number | null) => {
  return (dispatch: any, getState: any) => {
    const allInvestors = deepClone(getState().allInvestors);
    const newList = Object.values(allInvestors);
    if (newList.length >= 10) {
      dispatch({
        type: ADD_NEW_INVESTOR_FROM_INPUT,
        payload: allInvestors,
      });
      return;
    }

    const duplicates = newList.filter((item: any) => {
      return item.text === inputValue;
    });
    if (duplicates.length > 0) {
      dispatch({
        type: ADD_NEW_INVESTOR_FROM_INPUT,
        payload: allInvestors,
      });
      return;
    }

    dispatch({
      type: ADD_NEW_INVESTOR_FROM_INPUT,
      payload: {
        ...allInvestors,
        [inputValue]: {
          text: inputValue,
          valuationQuestionId: currentOtherValuationId,
          added: true,
          value: '',
          percentageValue: '',
          isRequired: false,
          isError: false,
        },
      },
    });
  };
};

export const upDateInvestorPercent = (arg: { [key: string]: AllInvestorsI }) => {
  return (dispatch: any, getState: any) => {
    const allInvestors = deepClone(getState().allInvestors);

    dispatch({
      type: UPDATE_INVESTOR_PERCENT,
      payload: { ...allInvestors, ...arg },
    });
  };
};

export const addNewPlanUseOfFundFromMenu = (arg: any) => {
  return (dispatch: any, getState: any) => {
    const useOFFunds = deepClone(getState().useOFFunds);
    dispatch({
      type: ADD_NEW_PLAN_USE_OF_FUND,
      payload: { ...useOFFunds, ...arg },
    });
  };
};

export const removePlanUseOfFund = (arg: string) => {
  return (dispatch: any, getState: any) => {
    const useOFFunds = deepClone(getState().useOFFunds);
    delete useOFFunds[arg];
    dispatch({
      type: REMOVE_USE_OF_FUND,
      payload: useOFFunds,
    });
  };
};

export const addNewItemFromUseOfFundInput = (inputValue: string, currentOtherValuationId: number | null) => {
  return (dispatch: any, getState: any) => {
    const useOFFunds = deepClone(getState().useOFFunds);
    const newList = Object.values(useOFFunds);
    if (newList.length >= 10) {
      dispatch({
        type: ADD_NEW_UES_OF_FUND_FROM_INPUT,
        payload: useOFFunds,
      });
      return;
    }
    const duplicates = newList.filter((item: any) => {
      return item.text === inputValue;
    });

    if (duplicates.length > 0) {
      dispatch({
        type: ADD_NEW_UES_OF_FUND_FROM_INPUT,
        payload: useOFFunds,
      });
      return;
    }
    dispatch({
      type: ADD_NEW_UES_OF_FUND_FROM_INPUT,
      payload: {
        ...useOFFunds,
        [inputValue]: {
          text: inputValue,
          valuationQuestionId: currentOtherValuationId,
          added: true,
          value: '',
          percentageValue: '',
          isRequired: false,
          isError: false,
        },
      },
    });
  };
};

export const modifyUseOfFunValue = (arg: any) => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: MODIFIED_USE_OF_FUND_VALUE,
      payload: { ...getState().useOFFunds, ...arg },
    });
  };
};

export const updatePerCentAndValue = (arg: any) => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_PERCENT_IN_USE_OF_FUND_SECTION,
      payload: arg,
    });
  };
};
