import styled, { css } from 'styled-components';
import customTheme from 'theme/customTheme';
const {
  colors: { tintOfSilver, white, LigthGray, DarkBlack, primaryColor },
} = customTheme;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NoneEditableCell = styled.div<{
  removeBorder?: boolean;
  fontWeight?: number;
  cellStyle?: { [key: string]: string | number };
  addBg?: boolean;
  borderRadius?: string;
}>`
  padding: 8px;
  height: 37px;
  width: 10.7%;
  overflow: hidden;
  margin: 10px 10px 10px 0;
  border: 1px solid ${tintOfSilver};
  color: ${DarkBlack};
  font-size: 13px;
  font-weight: ${({ fontWeight = 400 }) => fontWeight && fontWeight};
  background: rgb(247, 247, 247);
  ${({ cellStyle }) =>
    css`
      ${cellStyle}
    `}

  border-radius: ${({ borderRadius }) => `${borderRadius}`};

  overflow: hidden;
  ${({ addBg }) =>
    addBg &&
    css`
      background-color: ${primaryColor};
      color: ${white};
    `}

  border: ${({ removeBorder }) => (removeBorder ? 'none' : `1px solid ${tintOfSilver}`)};
`;

export const EditableCell = styled.input<{
  removeBorder?: boolean;
  fontWeight?: number;
  style?: { [key: string]: string | number };
}>`
  padding: 8px;
  height: 37px;
  width: 10.7%;
  margin: 10px 10px 10px 0;
  border: 1px solid ${tintOfSilver};
  cursor: pointer;
  overflow: hidden;
  background: ${white};
  color: ${DarkBlack};
  font-weight: ${({ fontWeight = 400 }) => fontWeight && fontWeight};
  font-size: 13px;
  ${({ style }) =>
    css`
      ${style}
    `}

  border: ${({ removeBorder }) => (removeBorder ? 'none' : `1px solid ${tintOfSilver}`)};
`;

export const CellDataBox = styled(FlexContainer)`
  justify-content: space-between;
  border-bottom: 1px solid ${LigthGray};
`;
