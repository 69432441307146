import { KeyPair } from './types';
import store from 'redux/store';

export const handleCountriesResponseData = (countriesData: any[]): any[] => {
  const countries = countriesData.map((item) => ({ ...item, value: item.countryId, text: item.name }));

  return countries;
};

export const getQuestionId = (data: any[], inputSlug: string): number => {
  const result = data.find((item) => item.slug === inputSlug);
  return result && result.valuationQuestionId;
};

export const preparePostData = (
  data: KeyPair,
  question: any[],
  raisingAmountQuestion: any[],
  additionalInvestment: any[],
  revenueQuestions: any[],
  riskQuestions: any[],
  DCFData: any,
  skipFinancialForecast: boolean,
  investorOtherId: number | null,
  fundsOtherId: number | null,
): any => {
  const {
    general: { investmentStage, location, industry, companyName, companyWebsite, yearFounded, ...generalQuestion },
    risk,
  } = data;
  const postData = {
    companyStageId: investmentStage,
    countryId: location,
    industryId: industry,
    companyName,
    companyWebsite,
    yearFounded,
  };

  const generalQuestionsList = [...question, ...raisingAmountQuestion, ...additionalInvestment, ...revenueQuestions];

  const generalResponse = Object.entries(generalQuestion).map(([key, value]) => {
    return { valuationQuestionId: getQuestionId(generalQuestionsList, key), value: Number(value) };
  });
  const riskResponse = Object.entries(risk).map(([key, value]) => {
    return { valuationQuestionId: getQuestionId(riskQuestions, key), value: Number(value) };
  });

  const prepareDCFData = (DCFData: any) => {
    if (DCFData === undefined) {
      return [];
    }
    return Object.values(DCFData).map((el: any) => {
      return {
        valuationQuestionId: el.valuationQuestionId,
        value:
          el.values.length > 0
            ? el.values.reduce((acc: any, item: any) => ((acc[item.id] = item.amt), acc), {})
            : { last12Month: el.values.amt },
      };
    });
  };
  const prepareFinancialData = () => {
    const financialData = [
      ...Object.values(Object.values(store.getState().allInvestors)),
      ...Object.values(Object.values(store.getState().useOFFunds)),
    ];
    return financialData.map((el: any) => {
      if (el.valuationQuestionId === investorOtherId || el.valuationQuestionId === fundsOtherId) {
        return {
          valuationQuestionId: el.valuationQuestionId,
          value: el.value === '' ? 0 : parseFloat(el.value),
          questionText: el.text,
          fundsPercentage: el.percentageValue == '' ? 0 : parseFloat(el.percentageValue),
        };
      }
      return {
        valuationQuestionId: el.valuationQuestionId,
        value: el.value === '' ? 0 : parseFloat(el.value),
        fundsPercentage: el.percentageValue == '' ? 0 : parseFloat(el.percentageValue),
      };
    });
  };
  return {
    ...postData,
    generalResponse,
    financialResponse: prepareFinancialData(),
    riskResponse,
    dcfResponse: prepareDCFData(DCFData),
    skipFinancialForecast: skipFinancialForecast,
  };
};
