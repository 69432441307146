import { useHistory } from 'react-router-dom';
import PaymentForm from 'components/organisms/PaymentForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import usePaymentModal from './usePaymentModal';
import ResponsiveModal from 'components/organisms/ResponsiveModal';
import PaymentConfirmation from 'components/molecules/PaymentConfirmation';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY || '');
const Payment: React.FC = (): JSX.Element => {
  const history = useHistory();
  const { isPaymentModalOpen, isSuccessModalOpen, setPaymentModal, setSuccessModal } = usePaymentModal();

  return (
    <>
      <Elements stripe={stripePromise}>
        <ResponsiveModal
          closeOnOverlayClick
          closeOnEsc
          showCloseIcon
          open={isPaymentModalOpen}
          modalVisibility={() => setPaymentModal(false)}
        >
          <PaymentForm />
        </ResponsiveModal>
      </Elements>
      <PaymentConfirmation
        open={isSuccessModalOpen}
        onClose={() => setSuccessModal(false)}
        onActionClick={() => history.push('/report')}
      />
    </>
  );
};

export default Payment;
