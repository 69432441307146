import styled, { css } from 'styled-components';

interface FormErrorProps {
  errorStyle?: { [key: string]: string | number };
  fontSize?: string;
  color?: string;
  margin?: string;
  errorMsg: string | undefined;
}
function FormError({ errorMsg, errorStyle }: FormErrorProps): JSX.Element {
  return <Error errorStyle={errorStyle}>{errorMsg}</Error>;
}

export default FormError;

const Error = styled.p<{ errorStyle?: { [key: string]: string | number } }>`
  font-size: 11px;
  font-weight: 700;
  color: #f91515;
  margin: 5px 0 0 0;
  ${(props) =>
    css`
      ${props.errorStyle}
    `}
`;
