import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import customTheme from 'theme/customTheme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 35px 0 35px 0;
`;

export const Centerlize = styled.div`
  width: 86%;
  margin: 0 auto;
  max-width: 1496px;
  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      width: 90%;
    }
  }
`;

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  heading: {
    fontSize: '36px',
    lineHeight: '48px',
    wordBreak: 'break-word',
    [theme.breakpoints.down('md')]: {
      fontSize: '26px',
      lineHeight: '35px',
    },
  },
  subHeading: {
    fontSize: '16px',
    lineHeight: '21px',
    wordBreak: 'break-word',
  },
  cardHeader: {
    background: customTheme.colors.DarkBlack,
    padding: '23px 20px',
  },
  linkHeading: {
    fontSize: '32px',
    lineHeight: '43px',
    wordBreak: 'break-word',
    color: customTheme.colors.white,
    [theme.breakpoints.down('md')]: {
      fontSize: '21px',
      lineHeight: '28px',
    },
  },
  linkText: {
    fontSize: '16px',
    lineHeight: '21px',
    color: customTheme.colors.MediumSpringGreen,
    wordBreak: 'break-word',
  },
  sectionTitle: {
    fontSize: '32px',
    lineHeight: '43px',
    wordBreak: 'break-word',
    [theme.breakpoints.down('md')]: {
      fontSize: '21px',
      lineHeight: '28px',
    },
  },
  cardContent: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  card: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: '19px',
    },
  },
  greenText: {
    color: customTheme.colors.MediumSpringGreen,
  },
  cardSubText: {
    fontSize: '16px',
    lineHeight: '21px',
    wordBreak: 'break-word',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  reportCard: {
    width: '49%',
    height: 'auto',
    minHeight: '650px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: 12,
    },
  },
  pointHeading: {
    fontSize: '18px',
    lineHeight: '21px',
    wordBreak: 'break-word',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  cardTextBox: {
    padding: '25px 35px 17px 35px',
    [theme.breakpoints.down('md')]: {
      padding: '25px 20px',
    },
  },
  pointText: {
    fontSize: '15px',
    lineHeight: '21px',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
      lineHeight: '21px',
    },
  },
  bullet: {
    paddingRight: 10,
  },
  scoreCardValue: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  scoreCardsSection: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  noteSection: {
    background: customTheme.colors.skyBlue,
    width: '85%',
    padding: 12,
  },
  notesText: {
    fontSize: '14px',
    lineHeight: '21px',
  },
  cardContainer: {
    minHeight: '538px',
  },
  riskNote: {
    width: '95%',
  },
  scores: {
    fontSize: '11px',
    lineHeight: '15px',
    color: customTheme.colors.gray6,
  },
  scoreSection: {
    padding: '10px 35px 25px 35px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      textAlign: 'center',
      padding: '5px 35px 25px 35px',
    },
  },
  columnContainer: {
    width: '49%',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  topCard: {
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '12px',
    },
  },
  bottomCard: {
    paddingBottom: '12px',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      marginBottom: '12px',
    },
  },
  greenCard: {
    background: theme.palette.primary.main,
  },
  spanIcon: {
    minWidth: '19px',
    marginRight: '10px',
  },
  vcDataBox: {
    [theme.breakpoints.down('md')]: {
      '& > div': {
        marginBottom: '8px',
      },
      '&:last-child': {
        background: 'violet',
        paddingBottom: 0,
      },
    },
  },
  vcDataItem: {
    background: customTheme.colors.gray5,
    border: `1px solid ${theme.palette.common.white}`,
  },
  vcDataText: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  summaryWeights: {
    marginLeft: '25px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0,
      marginTop: '38px',
    },
  },
  listDetails: {
    borderBottom: `1px solid ${customTheme.colors.borderBottomGray}`,
    padding: '8px 5px',
  },
  labelText: {
    fontSize: '14px',
    lineHeight: '18px',
  },
  greenBoxTitle: {
    fontSize: '16px',
    lineHeight: '22px',
    color: customTheme.colors.white,
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
  },
  greenBoxText: {
    fontSize: '32px',
    lineHeight: '43px',
    color: customTheme.colors.white,
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
  },
  mediumSize: {
    fontSize: '25px',
    lineHeight: '35px',
    [theme.breakpoints.down('md')]: {
      fontSize: '21px',
    },
  },
  topLeftTile: {
    borderRadius: '5px 0 0 0',
  },
  topRightTile: {
    borderRadius: '0 5px 0 0',
  },
  bottomRightTile: {
    borderRadius: '0 0 5px 0',
  },
  bottomLeftTile: {
    borderRadius: '0 0 0 5px',
  },
  legendLabel: {
    fontSize: '12px',
    lineHeight: '12px',
    color: customTheme.colors.gray6,
    [theme.breakpoints.down('md')]: {
      fontSize: '11px',
    },
  },
  square: {
    width: 12,
    height: 12,
    borderRadius: '1px',
    marginRight: '8px',
    [theme.breakpoints.down('md')]: {
      width: 10,
      height: 10,
      marginRight: 5,
      marginLeft: 5,
    },
  },
  PielegendSection: {
    padding: '2px 17px 8px 2px',
  },
  chartSection: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  fundsLabel: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: '27px',
    },
  },
  labelHeading: {
    borderBottom: `1px solid ${customTheme.colors.borderBottomGray}`,
  },
  summaryStats: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: '11px',
    },
  },
  potentialDealSection: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  dealsDataSection: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  financialPieChartSection: {
    background: customTheme.colors.white,
    minHeight: '539px',
    width: '32%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: '17px',
    },
  },
  financialBarSection: {
    width: '66%',
    background: customTheme.colors.white,
    minHeight: '590px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  financialCard: {
    padding: '20px 30px 37px 30px',
    [theme.breakpoints.down('md')]: {
      padding: '20px 18px',
    },
  },
  financialPieLegend: {
    padding: '2px 20px 10px 4px',
    [theme.breakpoints.down('md')]: {
      padding: '2px 7px 10px 1px',
    },
  },
  financialPieChartMargin: {
    width: '70%',
    display: 'flex',
    margin: '12px auto',
  },
  financialBarLegend: {
    padding: '2px 10px 10px 4px',
    [theme.breakpoints.down('md')]: {
      padding: '2px 1px 10px 4px',
    },
  },
  grayText: {
    fontSize: '14px',
    lineHeight: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px',
      lineHeight: '8px',
    },
  },
  greenPercentageText: {
    fontSize: '38px',
    lineHeight: '48px',
    color: customTheme.colors.MediumSpringGreen,
    [theme.breakpoints.down('md')]: {
      fontSize: '30px',
      lineHeight: '36px',
    },
  },
  forecastStats: {
    marginLeft: '56px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '3px',
      width: '100%',
      marginTop: '16px',
    },
  },
  percentageSection: {
    maxWidth: '25%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '28%',
    },
  },
  forecastData: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  amountData: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  hyperLink: {
    color: customTheme.colors.MediumSpringGreen,
  },
}));
