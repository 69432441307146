import React, { useEffect } from 'react';
import { HashItem } from './types';

type HashElement = {
  id: string;
  range: [min: number, max: number];
};

type IntermediaryElement = {
  htmlItem: HTMLElement;
  id: string;
};

// executes once per mount
const getHTMLElements = (list: HashItem[]): HashElement[] =>
  list
    .map(
      ({ id }: { id: string }): IntermediaryElement => ({
        htmlItem: document.querySelector<HTMLElement>(`#${id}`) || ({} as HTMLElement),
        id,
      }),
    )
    .map((item: IntermediaryElement, index: number, array: IntermediaryElement[]) => {
      const min = index === 0 ? 0 : item.htmlItem.offsetTop - 1;
      const max = index === array.length - 1 ? Infinity : array[++index].htmlItem.offsetTop - 1;
      return { id: item.id, range: [min, max] };
    });

const useScrollHandler = (
  links: HashItem[] = [],
  oldId: string,
  setNewId: React.Dispatch<React.SetStateAction<string>>,
): void => {
  // executes once per mount
  const elements = getHTMLElements(links);

  // executes on scroll event
  const handleScroll = (): void => {
    const { id: newId = '' } =
      elements.find(({ range }: HashElement) => window.scrollY >= range[0] && window.scrollY <= range[1]) || {};

    // executes once per id change
    if (newId !== oldId) return setNewId(newId);
  };

  useEffect(() => {
    handleScroll();
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [elements]);
};

export default useScrollHandler;
