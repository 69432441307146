import api from 'utils/api';
import { GetReportData } from 'utils/ApiUrls';
import { ResponseType, ReportData } from 'components/templates/ValuationReport/types';

export const valuationApi = {
  get: async ({
    onRequest = () => null,
    onSuccess = () => null,
    onFailure = () => null,
    onFinally = () => null,
  }: {
    onRequest?: () => void;
    onSuccess?: (data: ReportData) => void;
    onFailure?: (message: string) => void;
    onFinally?: () => void;
  } = {}): Promise<void> => {
    onRequest();
    try {
      const { data: { data = {} } = {} } = (await api.get<ResponseType>(GetReportData)) || {};
      onSuccess(data as ReportData);
    } catch ({ response: { data: { error: { message = '' } = {} } = {} } = {} }) {
      onFailure(message || 'Oops, something went wrong');
    } finally {
      onFinally();
    }
  },
};
