import { useDispatch, useSelector } from 'react-redux';
import { SET_PAYMENT_MODAL, SET_SUCCESS_MODAL } from 'redux/types';

type PaymentStoreType = {
  paymentModal: {
    isPaymentModalOpen: boolean;
    isSuccessModalOpen: boolean;
  };
};

type PaymentModalHandles = {
  isPaymentModalOpen: boolean;
  isSuccessModalOpen: boolean;
  setPaymentModal: (payload: boolean) => void;
  setSuccessModal: (payload: boolean) => void;
};

const usePaymentModal = (): PaymentModalHandles => {
  const dispatch = useDispatch();

  const setPaymentModal = (payload: boolean) =>
    dispatch({
      type: SET_PAYMENT_MODAL,
      payload,
    });

  const setSuccessModal = (payload: boolean) => {
    dispatch({
      type: SET_SUCCESS_MODAL,
      payload,
    });
  };

  const { isPaymentModalOpen, isSuccessModalOpen } = useSelector(({ paymentModal }: PaymentStoreType) => paymentModal);
  return { isPaymentModalOpen, isSuccessModalOpen, setPaymentModal, setSuccessModal };
};

export default usePaymentModal;
