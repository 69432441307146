import { Box, Typography } from '@material-ui/core';
import Button from 'components/molecules/button';
import { useStyles, StyledLockIcon } from '../styles';
import copy from '../copy.json';

interface PropTypes {
  onUnlock: () => void;
}

const Overview: React.FC<PropTypes> = ({ onUnlock = () => null }) => {
  const classes = useStyles();

  return (
    <Box className={classes.overviewContainer}>
      <Box className={classes.overviewContent}>
        <Box>
          <Typography className={classes.overviewHeader}>{copy.features.title}</Typography>
          <Box className={classes.overviewListContainer}>
            {copy.features.list.map((featureText, index) => (
              <Typography className={classes.overvievListItem} key={index}>
                {featureText}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography className={classes.overviewHeader}>{copy.overview.title}</Typography>
          </Box>
          <Box className={classes.overviewListContainer}>
            {copy.overview.list.map((overviewText, index) => (
              <Typography className={classes.overvievListItem} key={index}>
                {overviewText}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
      <Box className={classes.overviewBtnContainer}>
        <Button marginTop="0" onClick={onUnlock}>
          <Box display="flex" alignItems="center">
            <StyledLockIcon />
            Unlock the full version
            <br />
            of the report now
          </Box>
        </Button>
      </Box>
    </Box>
  );
};

export default Overview;
