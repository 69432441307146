import { methodCons, methodPros } from 'components/organisms/DCFReport/reportData';
import TableCell from 'components/organisms/DCFReport/reportTableCell';
import { RowTitle } from 'components/organisms/DCFinancial/styles';
import HorizontalScroll from 'components/molecules/HorizontalScroll';
import { makeBold, formateNumber } from 'components/organisms/DCFReport/DCFReportHelper';
import { DCFReportProps } from 'components/organisms/DCFReport/DCFReportTypes';
import DCFFormula from 'assets/dcf_formula.svg';
import { ReactComponent as WarningIcon } from 'assets/warning-icon-grey.svg';
import { getAmountString } from 'utils/helperMethod';
import { Link } from 'react-router-dom';
import { routesObj } from 'routes/routes';
import { Typography, Box } from '@material-ui/core';
import LeftCardTable from 'components/organisms/DCFReport/LeftCardTable';
import TableHead from 'components/organisms/DCFReport/TableHead';
import {
  Paragraph,
  BoldText,
  Subtitle,
  List,
  CardFooter,
  FormulaBox,
  EquityValue,
  FormulaBoxFooter,
  DCFReportTableRow,
  ReportDCFSection,
} from 'components/organisms/DCFReport/styles';
import customTheme from 'theme/customTheme';

const handleForecastValue = (value: number | string, fieldType: string, currencySymbol: string): string => {
  const stringValue = `${value}`;

  if (stringValue === '-') return stringValue;
  if (!stringValue || isNaN(Number(stringValue))) return '-';
  if (fieldType === 'percentage') return `${stringValue}%`;
  return getAmountString(stringValue, currencySymbol);
};

const DCFReport = ({
  dcfReport,
  currencySymbol,
  skipFinancialForecast,
  companyValuationId,
  parentCompanyValuationId,
}: DCFReportProps): JSX.Element => {
  if (dcfReport === undefined) {
    return <></>;
  }

  return (
    <section style={{ margin: '42px 0', color: customTheme.colors.DarkBlack }}>
      <div className="pagebreak">
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <h2
            style={{
              fontSize: '32px',
              lineHeight: '43px',
              fontFamily: 'Prompt',
              fontWeight: 700,
              fontStyle: 'normal',
              color: '#171C1B',
            }}
          >
            DCF method
          </h2>
          {!skipFinancialForecast ? (
            <p
              style={{
                fontFamily: 'Mulish',
                fontWeight: 700,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '16px',
                lineHeight: '21px',
                wordBreak: 'break-word',
              }}
            >
              The company valuation according to the DCF method is{' '}
              <span style={{ color: customTheme.colors.MediumSpringGreen }}>
                {formateNumber(dcfReport.equityValue, currencySymbol)}
              </span>
            </p>
          ) : (
            <Box display="flex" alignItems="center">
              <WarningIcon style={{ minWidth: '19px', marginRight: '10px' }} />
              <Typography>
                <b>
                  To see the valuation according to the VC method, please{' '}
                  <Link
                    to={{
                      pathname: `${routesObj.questionaries.path}`,
                      state: { companyValuationId, parentCompanyValuationId },
                    }}
                    style={{ color: customTheme.colors.MediumSpringGreen }}
                  >
                    complete the Financial Forecasts
                  </Link>{' '}
                  section in the questionnaire.
                </b>
              </Typography>
            </Box>
          )}
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <section
            style={{
              width: '49%',
              background: customTheme.colors.white,
              boxShadow: customTheme.colors.reportSectionCardsShadow,
              borderRadius: customTheme.borderRadius.reportSectionBorderRadius,
              padding: '20px 30px',
              fontSize: '16px',
            }}
            className="tilesBoxShadow"
          >
            <Paragraph>
              <BoldText> Discounted Cash Flow method</BoldText> estimates the value of the Business today based on its
              projected future cash flows (you can find detailed financial forecasts on the next page).{' '}
            </Paragraph>
            <Paragraph>
              <BoldText> Enterprise Value of the Company (EV) </BoldText> is the sum of present values of free cash
              flows from period 1 to 5, and Terminal Value (TV). <BoldText>Terminal Value of the Company (TV)</BoldText>{' '}
              is the discounted cash flows of the company from year 6 into perpetuity.
              <BoldText> Discount rate (r) </BoldText> used was based on average expected rates of return for VC
              investors* (depending on the stage of the funding ) or entered in parameters by the user. Long-term growth
              rate is the market growth rate or entered by the user.
            </Paragraph>

            <Subtitle>Method pros</Subtitle>
            <List>
              {methodPros.map((item) => (
                <li key={item.id}>{item.prop}</li>
              ))}
            </List>
            <Subtitle>Method cons</Subtitle>
            <List>
              {methodCons.map((item) => (
                <li key={item.id}>{item.prop}</li>
              ))}
            </List>

            <CardFooter>
              *Damodaran, A. (2009). Valuing Young, Start-Up and Growth Companies: Estimation Issues and Valuation
              Challenges{' '}
            </CardFooter>
          </section>

          <section style={{ width: '49%' }}>
            <FormulaBox>
              <img src={DCFFormula} alt="" />
              <EquityValue>
                Equity Value = <span> EV + Cash</span> - Borrowings
              </EquityValue>
              <FormulaBoxFooter>
                <span> EV - Enterprise Value</span> <span>FCF - Free Cash Flow</span> TV - Terminal Value of the
                Business <br />
                <span>r - discount rate</span> g - long term growth rate
              </FormulaBoxFooter>
            </FormulaBox>
            <LeftCardTable dcfReport={dcfReport} currencySymbol={currencySymbol} />
          </section>
        </div>
      </div>

      <div className="pagebreak">
        <div style={{ marginTop: '48px' }}>
          {dcfReport.financialForecast.length > 0 && (
            <HorizontalScroll>
              <ReportDCFSection>
                <TableHead currencySymbol={currencySymbol} />
                <section>
                  {dcfReport.financialForecast.map(({ question, value, fieldType }) => {
                    return (
                      <DCFReportTableRow>
                        <RowTitle
                          makeTextBold={makeBold(question)}
                          style={{ margin: '1px', background: '#F7F7F7', width: '26.5%' }}
                        >
                          {question}
                        </RowTitle>
                        {Object.values(value).map((item: any, idx) => {
                          return (
                            <TableCell
                              key={idx}
                              cellData={handleForecastValue(item, fieldType, currencySymbol as string)}
                              fontWeight={makeBold(question) ? 700 : 400}
                              style={{
                                margin: '1px',
                                background: '#F7F7F7',
                                width: '12.9%',
                                padding: '0 0 0 10px',
                                lineHeight: '37px',
                              }}
                              removeBorder
                            />
                          );
                        })}
                      </DCFReportTableRow>
                    );
                  })}
                </section>
              </ReportDCFSection>
            </HorizontalScroll>
          )}
        </div>
      </div>
    </section>
  );
};

export default DCFReport;
