import styled, { css } from 'styled-components';
import customTheme from 'theme/customTheme';
const {
  colors: { tintOfSilver, primaryColor, LightBlack },
  fontFamily: { primaryFont },
} = customTheme;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const cellStyles = css`
  height: 37px;
  width: 12%;
  margin: 10px 10px 10px 0;
  padding: 8px 15px 8px 8px;
  font-size: 14px;
  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      height: 32px;
    }
  }
`;

export const NoneEditableCell = styled.div<{
  removeBorder?: boolean;
  makeBold?: boolean;
  cellAdditionalStyle?: { [key: string]: string | number };
}>`
  ${cellStyles}
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.tintOfSilver};
  border: ${({ removeBorder }) => (removeBorder ? 'none' : `1px solid ${tintOfSilver}`)};
  font-weight: ${({ makeBold }) => (makeBold ? '700' : '400')};
  font-family: ${primaryFont};
  color: ${LightBlack};
  ${({ cellAdditionalStyle }) =>
    css`
      ${cellAdditionalStyle}
    `};
`;

export const EditableCell = styled.input<{
  removeBorder?: boolean;
  makeBold?: boolean;
  cellAdditionalStyle?: { [key: string]: string | number };
}>`
  ${cellStyles}
  border: 1px solid ${({ theme }) => theme.colors.tintOfSilver};
  cursor: pointer;
  overflow: hidden;
  font-family: ${primaryFont};
  color: ${LightBlack};
  background: ${({ theme }) => theme.colors.white};
  border: ${({ removeBorder }) => (removeBorder ? 'none' : `1px solid ${tintOfSilver}`)};
  font-weight: ${({ makeBold }) => (makeBold ? '700' : '400')};
  font-size: 14px;
  line-height: 21px;
  ${({ cellAdditionalStyle }) =>
    css`
      ${cellAdditionalStyle}
    `};
  &:focus {
    outline: 1px solid ${primaryColor};
  }
`;

export const CellDataBox = styled(FlexContainer)`
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.LigthGray}; ;
`;
