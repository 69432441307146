import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { ReactComponent as Chevron } from 'assets/chevron-white.svg';

export const StyledButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding: 0 10px;
`;

export const StyledText = styled(Typography)`
  padding: 0 20px;
  && {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const StyledChevron = styled(Chevron)`
  transition: transform 0.2s;
  &.open {
    transform: rotate(180deg);
  }
`;
