import React, { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import ReactTooltip from 'react-tooltip';
import isEmpty from 'lodash/isEmpty';
import { formatInputWithCommas, removeCommasFromNumber, handleCommaNumberChange } from 'utils/helperMethod';
import Typography from '@material-ui/core/Typography';
import { Select, MenuItem } from '@material-ui/core';
import CustomInput from 'components/molecules/FormInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import QuestionMark from 'components/layout/QuestionMark';
import theme from 'theme/customTheme';
import { Error } from 'style/CommonStyle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles, Symbol, Currency } from './styles';
import { stagesData } from './data';
import { CompanyStages, Choices } from './types';

interface PropsValidation {
  stages: CompanyStages[];
  currencySymbol: string;
  errors: any;
  setFieldValue: (field: string, data: string | number | any[], validate: boolean) => any;
  handleBlur: (e: any) => any;
  touched: any;
  raisingAmountQuestion: any[];
  values: any;
}

const InvestmentStage: React.FC<PropsValidation> = ({
  stages,
  currencySymbol,
  errors,
  setFieldValue,
  handleBlur,
  touched,
  raisingAmountQuestion,
  values,
}) => {
  const classes = useStyles();
  const [notAllowNegative, setNotAllowNegative] = useState<boolean>(false);
  const [{ slug: raisingSlug = '' } = {}] = raisingAmountQuestion || [];
  const getCommaValueFromSlug = (slug: string): string => {
    const { general = {} } = values || {};
    const value = general[slug] || '';
    return formatInputWithCommas(`${value}`);
  };

  const notAllowNegativeValue = (event: any) => {
    if (event.key === '-') {
      event.preventDefault();
      setNotAllowNegative(false);
      return false;
    }
    setNotAllowNegative(true);
    return true;
  };
  if (isEmpty(raisingAmountQuestion)) return <></>;
  return (
    <>
      {stagesData.length > 0 &&
        stagesData.map(({ fieldType, question, tooltip, placeholder, slug }, index) => {
          return (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              mt={2}
              mb={2}
              className={classes.generalContainer}
              key={index}
            >
              <Box
                display="flex"
                width="52%"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="baseline"
                mt={2}
                className={classes.questionTextSection}
              >
                <Box width="100%" display="flex">
                  <Box>
                    <Typography variant="subtitle2" className={classes.questionText}>
                      {question}
                    </Typography>
                  </Box>
                  {tooltip !== null && (
                    <>
                      <Box ml={1}>
                        <QuestionMark
                          bgFillColor="#CCCCCC"
                          symbolFillColor="#ffffff"
                          data-tip="hover on me will keep the tooltip"
                          data-for={`tool-tip-${question}`}
                        />
                      </Box>
                      <ReactTooltip delayHide={1000} effect="solid" id={`tool-tip-${question}`} />
                    </>
                  )}
                </Box>
              </Box>
              <Box display="flex" width="40%" flexDirection="column" className={classes.fieldSection}>
                {fieldType === 'dropdown' && (
                  <>
                    <Select
                      error={!isEmpty(errors) && errors.general[slug] && touched.general[slug]}
                      name={`general.${slug}`}
                      variant="outlined"
                      className={classes.select}
                      IconComponent={ExpandMoreIcon}
                      value={(!isEmpty(values) && !isEmpty(values.general) && values.general[slug]) || 'Choose answer'}
                      onChange={(e: any) => setFieldValue(`general.${slug}`, e.target.value, false)}
                      onBlur={handleBlur}
                    >
                      <MenuItem value="Choose answer">
                        <Typography variant="body2" className={classes.dropdownText}>
                          Choose answer
                        </Typography>
                      </MenuItem>

                      {stages.length > 0 &&
                        stages.map((item: Choices) => {
                          return <MenuItem value={item.value}>{item.text}</MenuItem>;
                        })}
                    </Select>
                    {!isEmpty(errors) && errors.general[slug] && touched.general[slug] && (
                      <Error>{errors.general[slug]}</Error>
                    )}
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      {!isEmpty(raisingAmountQuestion) && !isEmpty(raisingAmountQuestion[0]) && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={2}
          mb={2}
          className={classes.generalContainer}
        >
          <Box
            display="flex"
            width="52%"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="baseline"
            mt={2}
            className={classes.questionTextSection}
          >
            <Box width="100%" display="flex">
              <Box>
                <Typography variant="subtitle2" className={classes.questionText}>
                  {raisingAmountQuestion[0].question}
                </Typography>
              </Box>
              {raisingAmountQuestion[0].tooltip !== null && (
                <>
                  <Box ml={1}>
                    <QuestionMark
                      bgFillColor="#CCCCCC"
                      symbolFillColor="#ffffff"
                      data-tip="hover on me will keep the tooltip"
                      data-for={`tool-tip-${raisingAmountQuestion[0].question}`}
                    />
                  </Box>
                  <ReactTooltip delayHide={1000} effect="solid" id={`tool-tip-${raisingAmountQuestion[0].question}`} />
                </>
              )}
            </Box>
          </Box>
          <Box display="flex" width="40%" flexDirection="column" className={classes.fieldSection}>
            <CustomInput
              type="text"
              name={`general.${raisingAmountQuestion[0].slug}`}
              padding={'13px 0px 13px 54px'}
              marginTop="0"
              bgColor={theme.colors.white}
              value={getCommaValueFromSlug(raisingSlug)}
              inputProps={
                <InputAdornment position="start">
                  <Symbol>
                    <Currency>{currencySymbol || '£'}</Currency>
                  </Symbol>
                </InputAdornment>
              }
              placeholder="Enter amount"
              isFullWidth={true}
              readOnly={false}
              handleBlur={handleBlur}
              onKeyPress={(e) => {
                notAllowNegativeValue(e);
              }}
              handleOnChange={(data: string | number) => {
                handleCommaNumberChange(data, (newData) => {
                  setFieldValue(`general.${raisingSlug}`, newData, false);
                });
              }}
              helpText={
                !isEmpty(errors) &&
                errors.general[raisingAmountQuestion[0].slug] &&
                touched.general[raisingAmountQuestion[0].slug]
                  ? errors.general[raisingAmountQuestion[0].slug]
                  : ''
              }
            />
          </Box>
        </Box>
      )}
      <Box marginTop="35px" />
      <Divider className={classes.horizontalDivider} />
    </>
  );
};

export default InvestmentStage;
