import { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Logo from 'assets/logoSymbol.svg';
import GrayLogo from 'assets/logo-symbol-gray.svg';
import Button from 'components/molecules/button';
import { Text } from './components';

import { Wrapper, Centerlize, useStyles } from './styles';

interface PropTypes {
  title: string;
  text: string | string[];
  linkText: string;
  linkTo: string;
  grayLogo?: boolean;
}

const DashboardContent: FC<PropTypes> = ({ title, text, linkText, linkTo, grayLogo }) => {
  const history = useHistory();
  const classes = useStyles();
  const LogoImage = grayLogo ? GrayLogo : Logo;

  return (
    <Wrapper>
      <Centerlize>
        <Box className={classes.logoBox}>
          <img className={classes.logoImg} src={LogoImage || ''} alt="GrowthVal logo" />
        </Box>
        <Box className={classes.titleBox}>
          <Typography variant="subtitle1" className={classes.title}>
            {title}
          </Typography>
        </Box>
        <Box className={classes.textContainer}>
          <Text text={text} />
        </Box>
        <Button marginTop="0" onClick={() => history.push(linkTo)}>
          {linkText}
        </Button>
      </Centerlize>
    </Wrapper>
  );
};

export default DashboardContent;
