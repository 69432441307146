import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import customTheme from 'theme/customTheme';
import { Bar, Pie } from 'react-chartjs-2';
import { Box, Typography, Card } from '@material-ui/core';
import { commaSeparatedNumber, getAmountString } from 'utils/helperMethod';
import { useStyles } from './styles';
import { ValuationSummaryProps, Graph, LegendsType } from './types';
import { summaryPotentialDeal, summaryWeights } from './data';
import { getColorPalette, verticalBarConfig, pieChartOptions } from './helper';

const ValuationSummary: React.FC<ValuationSummaryProps> = ({ currency, valuationSummaryDetails, companyName }) => {
  const classes = useStyles();
  const [barGraph, setBarGraph] = useState<Graph>();
  const [pieChartData, setPieChart] = useState<Graph>();
  const [pieChartLegends, setPieChartLegends] = useState<LegendsType>([]);

  useEffect(() => {
    const { valuationMethodsWeights, funds } = valuationSummaryDetails;
    const dataRange: Array<number> = [];
    const labels: Array<string> = ['Scorecard', 'Berkus', 'Risk Factor', 'VC', 'DCF'];
    const pieChartLabels: Array<string> = [];
    const pieChartRange: Array<string> = [];
    const legendsPieChart: Array<{ label: string; colorCode: string }> = [];

    if (!isEmpty(funds)) {
      funds.forEach(({ name, fundsPercentage }, index) => {
        pieChartLabels.push(name);
        pieChartRange.push(fundsPercentage);
        legendsPieChart.push({
          label: `${name}(${parseInt(fundsPercentage)}%)`,
          colorCode: getColorPalette(funds.length)[index],
        });
      });

      setPieChartLegends(legendsPieChart);
      setPieChart({
        labels: pieChartLabels,
        datasets: [
          {
            data: pieChartRange,
            backgroundColor: getColorPalette(pieChartRange.length),
          },
        ],
      });
    }

    if (!isEmpty(valuationMethodsWeights)) {
      valuationMethodsWeights.forEach(({ value }) => {
        dataRange.push(value);
      });

      setBarGraph({
        labels,
        datasets: [
          {
            backgroundColor: customTheme.colors.MediumSpringGreen,
            data: dataRange,
          },
        ],
      });
    }
  }, [valuationSummaryDetails]);

  const callBarGraph = useMemo(() => {
    return <Bar type="bar" data={barGraph} options={verticalBarConfig(currency)} />;
  }, [barGraph, currency]);

  if (isEmpty(valuationSummaryDetails)) return <></>;
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" className={classes.scoreCardValue}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Valuation summary
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={3} className={classes.scoreCardsSection}>
        <Box display="flex" flexDirection="column" className={`${classes.cardContainer} ${classes.reportCard}`}>
          <Card className={`${classes.greenCard} ${classes.topCard}`}>
            <Box display="flex" justifyContent="flex-start" className={classes.cardTextBox}>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography variant="subtitle1" className={classes.greenBoxTitle}>
                    THE VALUATION RANGE OF {companyName.toUpperCase()}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.greenBoxText}>
                    {currency}
                    {commaSeparatedNumber(Number(valuationSummaryDetails.valuationMinRange))}&nbsp;-&nbsp;
                    {currency}
                    {commaSeparatedNumber(Number(valuationSummaryDetails.valuationMaxRange))}
                  </Typography>
                </Box>
                <Box mt={2}>
                  <Typography variant="subtitle1" className={classes.greenBoxTitle}>
                    THE WEIGHTED VALUATION OF {companyName.toUpperCase()}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.greenBoxText}>
                    {currency}
                    {commaSeparatedNumber(Number(valuationSummaryDetails.weightedValuation))}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box mt={2} />
          </Card>
          <Card>
            <Box className={classes.cardTextBox}>
              <Box display="flex">
                <Box>
                  <Typography variant="caption" className={classes.subHeading}>
                    CURRENTLY RAISING
                  </Typography>
                  <Typography variant="subtitle1" className={classes.mediumSize}>
                    {currency}
                    {commaSeparatedNumber(Number(valuationSummaryDetails.currentlyRaising))}
                  </Typography>
                </Box>
                <Box ml={4}>
                  <Typography variant="caption" className={classes.subHeading}>
                    INVESTMENT STAGE
                  </Typography>
                  <Typography variant="subtitle1" className={classes.mediumSize}>
                    {valuationSummaryDetails.investmentStage}
                  </Typography>
                </Box>
              </Box>
              <Box mt={4} />
              <Box width="100%" display="flex" className={classes.chartSection}>
                <Box width="40%">
                  <Pie type="pie" data={pieChartData} options={pieChartOptions} />
                </Box>
                <Box width="60%" ml={4} className={classes.fundsLabel}>
                  <Box className={classes.labelHeading}>
                    <Typography variant="subtitle1" className={classes.mediumSize}>
                      Planned use of funds
                    </Typography>
                  </Box>
                  <Box mt={2} display="flex" flexWrap="wrap" width="100%">
                    {!isEmpty(pieChartLegends) &&
                      pieChartLegends.map(({ label, colorCode }) => {
                        return (
                          <Box display="flex" className={classes.PielegendSection}>
                            <span style={{ background: colorCode }} className={classes.square}></span>
                            <Typography variant="body1" className={classes.legendLabel}>
                              {label}
                            </Typography>
                          </Box>
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
        <Card className={`${classes.reportCard} ${classes.cardContainer}`}>
          <Box display="flex" flexDirection="column" className={classes.cardTextBox}>
            {callBarGraph}
            <Box display="flex" mt={5} className={classes.summaryStats}>
              <Box display="flex" flexDirection="column" mt={6} width="59%" className={classes.dealsDataSection}>
                <Typography variant="subtitle1" className={classes.pointHeading}>
                  Potential deal:
                </Typography>
                <Box display="flex" flexDirection="column" mt={1} className={classes.potentialDealSection}>
                  {!isEmpty(valuationSummaryDetails.potentialDeal) &&
                    summaryPotentialDeal.map(({ name, value, isAmount }) => {
                      const amount = valuationSummaryDetails.potentialDeal[value];
                      return (
                        <Box display="flex" className={classes.listDetails} justifyContent="space-between">
                          <Typography variant="subtitle2" className={classes.labelText}>
                            {name}
                          </Typography>
                          <Box mr={1}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                              {isAmount
                                ? amount !== '-' && amount !== null
                                  ? getAmountString(amount, currency)
                                  : '-'
                                : amount !== '-' && amount !== null
                                ? `${amount}%`
                                : '-'}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
              <Box display="flex" width="35%" flexDirection="column" className={classes.summaryWeights}>
                <Typography variant="subtitle1" className={classes.pointHeading}>
                  The weights
                </Typography>
                <Box display="flex" flexDirection="column" mt={1}>
                  {!isEmpty(valuationSummaryDetails.weights) &&
                    summaryWeights.map(({ name, value }) => {
                      const weightVal = valuationSummaryDetails.weights[value];
                      return (
                        <Box display="flex" className={classes.listDetails} justifyContent="space-between">
                          <Typography variant="body1" className={classes.labelText}>
                            {name}
                          </Typography>
                          <Box mr={0}>
                            <Typography variant="body1" className={classes.labelText}>
                              {weightVal !== '-' ? `${weightVal}%` : '-'}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default ValuationSummary;
