import { createDeepCopy } from './DCFHelper';
export const TableHeadData = [
  {
    id: 2,
    title: 'Last 12 months',
  },
  {
    id: 3,
    title: 'Year 1',
  },
  {
    id: 4,
    title: 'Year 2',
  },
  {
    id: 5,
    title: 'Year 3',
  },
  {
    id: 6,
    title: 'Year 4',
  },
  {
    id: 7,
    title: 'Year 5',
  },
];

export const initialRowData = [
  {
    id: 'last12Month',
    amt: 0,
  },
  {
    id: 'year1',
    amt: 0,
  },
  {
    id: 'year2',
    amt: 0,
  },
  {
    id: 'year3',
    amt: 0,
  },
  {
    id: 'year4',
    amt: 0,
  },
  {
    id: 'year5',
    amt: 0,
  },
];

export const initialVal = {
  question: '',
  values: createDeepCopy(initialRowData),
  valuationQuestionId: 0,
  isMandatory: false,
  tooltip: '',
};
export const RowWidthSingleValue = {
  question: '',
  values: { amt: 0 },
  valuationQuestionId: '',
  isMandatory: '',
  tooltip: '',
};
