const UK_POSTCODE_REGEX = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i;

export const isNumberOnly = (stingArgument: string) => {
  // /^[0-9]+$/ the proceeding regex, return true if the string contain number only
  return /^[0-9]+$/.test(stingArgument);
};

export const isValidPostCode = (string: string): boolean => {
  if (string !== '') {
    return UK_POSTCODE_REGEX.test(string);
  }
  return true;
};

export const isEmpty = (argument: string): string => {
  if (argument.length === 0) {
    return 'This field is required';
  }
  return '';
};
