import { NoneEditableCell } from './styles';
import { ReactChild, ReactChildren } from 'react';
interface TableData {
  last12Month: string;
  year1: string;
  year2: string;
  year3: string;
  year4: string;
  year5: string;
}
interface TableCellProps {
  removeBorder?: boolean;
  rowData?: TableData;
  fontWeight?: number;
  style?: { [key: string]: string | number };
  cellData?: number | string;
  children?: ReactChild | ReactChildren | any;
  addBg?: boolean;
  borderRadius?: string;
}
const DCFTableRowValues = ({
  cellData,
  removeBorder,
  fontWeight,
  style,
  children,
  addBg,
  borderRadius,
}: TableCellProps): JSX.Element => {
  return (
    <NoneEditableCell
      style={style}
      removeBorder={removeBorder}
      fontWeight={fontWeight}
      addBg={addBg}
      borderRadius={borderRadius}
    >
      {cellData || children}
    </NoneEditableCell>
  );
};

export default DCFTableRowValues;
