import { FC } from 'react';
import classNames from 'classnames';
import { ReactComponent as UserIcon } from 'assets/user-icon-white.svg';
import { StyledButton, StyledText, StyledChevron } from './styles';

interface PropTypes {
  onClick: () => void;
  userName: string;
  open: boolean;
}

const UserSettingsBtn: FC<PropTypes> = ({ onClick, userName, open }) => {
  const className = classNames({ open });

  return (
    <StyledButton onClick={onClick}>
      <UserIcon />
      <StyledText>{userName || 'User Settings'}</StyledText>
      <StyledChevron className={className} />
    </StyledButton>
  );
};

export default UserSettingsBtn;
