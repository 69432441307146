import axios, { AxiosResponse } from 'axios';
import { logoutUser } from './helperMethod';
import { getLocalStorageItem } from 'utils/getLocalStorageItem';

const redirectToLogin = (): void => logoutUser();

const api = axios.create();
api.interceptors.request.use((config) => {
  // pass on the headers on each request
  const { role, token: accessToken } = getLocalStorageItem('growthVal');
  config.headers = {
    'Content-Type': 'application/json',
    'x-access-token': `${accessToken}`,
    role,
  };

  return config;
});

api.interceptors.response.use((response: AxiosResponse): any => {
  const { data: { status = '' } = {} } = response || {};
  switch (status) {
    case 401:
      return redirectToLogin();

    default:
      return response;
  }
});

export default api;
