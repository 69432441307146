import { Title, StyledParagraph, OptionToSkipSection, CheckBox, Label, EffectOFSkipping } from './styles';
import { useDCFContext } from 'context/DCFContext/DCFContext';

const SkipDCF = (): JSX.Element => {
  const { setSkipFinancialForecast, skipFinancialForecast } = useDCFContext();
  return (
    <>
      <Title> Financial forecasts</Title>
      <StyledParagraph>
        Please fill-in the information below. <br />
        The better your forecasts are - the more accurate your valuation will be.
      </StyledParagraph>
      <OptionToSkipSection>
        <CheckBox
          type="checkbox"
          onClick={() => {
            setSkipFinancialForecast((prevState: boolean) => !prevState);
          }}
          checked={skipFinancialForecast}
        />{' '}
        <Label>I want to skip the financial forecasts section</Label>
      </OptionToSkipSection>
      <EffectOFSkipping>
        If you skip this section - we won't be able to calculate your valuation using VC and DCF methods.
      </EffectOFSkipping>
    </>
  );
};

export default SkipDCF;
