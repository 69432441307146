import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 70px);
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: block;
    margin-top: 200px;
    min-height: auto;
  }
`;

export const Centerlize = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 86%;
  margin: 0 auto;
  max-width: 1496px;
  && {
    ${(props) => props.theme.breakpoints.down('sm')} {
      width: 90%;
    }
  }
`;

export const BoldSpan = styled.span`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  logoBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 40,
    width: 90,
    height: 90,
    [theme.breakpoints.down('xs')]: {
      width: 75,
      height: 75,
      marginBottom: 30,
    },
  },
  logoImg: {
    width: '100%',
    height: '100%',
  },
  titleBox: {
    marginBottom: 25,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
  title: {
    lineHeight: 1.5,
    textAlign: 'center',
    fontSize: '24px',
  },
  textContainer: {
    maxWidth: 360,
    marginBottom: 27,
  },
}));
