import React, { useEffect, useState, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import CardColumnSection from 'components/organisms/CardColumsSection';
import { Box, Card, Typography } from '@material-ui/core';
import { commaSeparatedNumber } from 'utils/helperMethod';
import { RiskReportProps, ColumnsData } from './types';
import { useStyles } from './styles';

const RiskReport: React.FC<RiskReportProps> = ({ currency, riskReportDetails }) => {
  const classes = useStyles();
  const [riskValues, setRiskValues] = useState<ColumnsData[] | any>();

  const { riskFactorWeightedAmount } = riskReportDetails;

  useEffect(() => {
    if (isEmpty(riskReportDetails)) {
      return;
    }
    const { riskFactorValuations } = riskReportDetails;
    const riskData = riskFactorValuations.map((item) => ({ title: item.question, value: item.score }));
    setRiskValues(riskData);
  }, [riskReportDetails]);

  const getTotalValueCount = useMemo(() => {
    if (isEmpty(riskReportDetails)) {
      return null;
    }
    const { riskFactorValuations } = riskReportDetails;
    const count = riskFactorValuations.reduce(function (acc, obj) {
      return +acc + +obj.score;
    }, 0);

    return count;
  }, [riskReportDetails]);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" className={classes.scoreCardValue}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Risk factor summation method
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography variant="subtitle2" className={classes.cardSubText}>
            The company valuation according to
            <br /> the Risk factor summation method is&nbsp;
            <span className={classes.greenText}>
              {currency}
              {commaSeparatedNumber(Number(riskFactorWeightedAmount))}
            </span>
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={3} className={classes.scoreCardsSection}>
        <Card className={`${classes.reportCard} ${classes.cardContainer}`}>
          <Box className={classes.cardTextBox}>
            <Typography variant="body1" className={classes.cardSubText}>
              <b>Risk Factor Summation method</b> uses a baseline valuation of the companies according to the location,
              industry, and stage of your company, which is then adjusted for 12 risk types by the risk levels which are
              estimated by the company’s management.
            </Typography>
            <Box mt={4}>
              <Typography variant="subtitle1" className={classes.pointHeading}>
                Method pros
              </Typography>
              <Box mt={1} />
              <Box display="flex">
                <span className={classes.bullet}>•</span>
                <Typography variant="body1" className={classes.pointText}>
                  Can be a good basis for your negotiations with the investor and discussing risk mitigation strategies
                  that the company has
                </Typography>
              </Box>
            </Box>
            <Box mt={2}>
              <Typography variant="subtitle1" className={classes.pointHeading}>
                Method cons
              </Typography>
              <Box mt={1} />
              <Box display="flex">
                <span className={classes.bullet}>•</span>
                <Typography variant="body1" className={classes.pointText}>
                  Highly subjective
                </Typography>
              </Box>
            </Box>
            <Box mt={5} />
            <Box display="flex" justifyContent="center">
              <Card className={`${classes.noteSection} ${classes.riskNote}`}>
                <Typography variant="body1" className={classes.notesText}>
                  Although original method has a fixed adjustment value (such as {currency}500,000), our algorithm uses
                  a different value depending on our estimates of valuations in your region, industry, stage of funding
                  or valuations provided by you in Valuation Parameters
                </Typography>
              </Card>
            </Box>
          </Box>
        </Card>
        <Card className={`${classes.reportCard} ${classes.cardContainer}`}>
          <Box display="flex" flexDirection="column" className={classes.cardTextBox}>
            <Box mt={3}>
              <CardColumnSection
                firstTitle="Risk Factor"
                secondTitle="Risk Score"
                data={riskValues || []}
                totalValue={getTotalValueCount}
                mobileWidthFirstCol="65%"
                mobileWidthSecondCol="34%"
                widthFirstCol="70%"
                widthSecondCol="29%"
                isLastRowGreen
                isCenterlizeValue
              />
            </Box>
          </Box>
          <Box display="flex" className={classes.scoreSection}>
            <Typography variant="body1" className={classes.scores}>
              Assigned factors:&nbsp;
              <span>
                <b>-2</b> Very high risk
              </span>
              &nbsp;&nbsp;
              <span>
                <b>-1</b> High risk
              </span>
              &nbsp;&nbsp;
              <span>
                <b>0</b> Average risk
              </span>
              &nbsp;&nbsp;
              <span>
                <b>1</b> Low risk
              </span>
              &nbsp;&nbsp;
              <span>
                <b>2</b> Very low risk
              </span>
            </Typography>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default RiskReport;
