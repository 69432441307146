import styled from 'styled-components';
import customTheme from 'theme/customTheme';

export const NavbarWrapper = styled.section`
  padding: 30px 0;
  margin: auto 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      margin: auto 20px;
      padding: 24px 0;
    }
  }
`;

export const LoginButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50px;
  padding: 12px 20px;
  margin-left: 15px;
  font-family: 'Mulish';
  background-color: ${({ theme }) => theme.palette.primary.main};
  &:hover {
    background-color: ${(props) => props.theme.colors.DarkBlack};
  }
  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      height: 36px;

      padding: 8px 15px;
      margin-left: 0px;
      font-size: 13px;
    }
  }
`;
export const SpanWithMargin = styled.span`
  margin-right: 15px;
  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      display: none;
    }
  }
`;
