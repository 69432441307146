import NavContainer from 'components/layout/NavContainer';
import DashboardContent from 'components/organisms/DashboardContent';
import dashboardCopy from './dashboardCopy.json';

const DashBoard: React.FC = () => (
  <>
    <NavContainer>
      <DashboardContent {...dashboardCopy} />
    </NavContainer>
  </>
);

export default DashBoard;
