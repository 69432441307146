import { Box, Typography } from '@material-ui/core';
import customTheme from 'theme/customTheme';
import Button from 'components/molecules/button';
import Modal from 'components/molecules/Modal';
import { useStyles } from '../styles';

interface PropTypes {
  onClose: () => void;
  onAccept: () => void;
  open: boolean;
}

const DeleteUserModal: React.FC<PropTypes> = ({ onClose, onAccept, open }) => {
  const classes = useStyles();

  return (
    <Modal onClose={onClose} open={open}>
      <Box className={classes.modalContent}>
        <Box display="flex" justifyContent="center">
          <Typography className={classes.modalText}>
            Are you sure you want to delete your account &amp; data?
            <br />
            This cannot be undone.
          </Typography>
        </Box>
        <Box className={classes.modalActionPanel}>
          <Box className={classes.cancelBtn}>
            <Button marginTop="20px" bgColor={customTheme.colors.LightBlack} onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <Button marginTop="20px" bgColor={customTheme.colors.error} onClick={onAccept}>
            Yes, delete my account &amp; data
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteUserModal;
