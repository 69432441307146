import { LogoWrapper } from './styles';

interface GrowthValLogoProps {
  logo: string;
  logoStyle?: unknown;
}
function GrowthValLogo({ logo, logoStyle }: GrowthValLogoProps): JSX.Element {
  return (
    <LogoWrapper logoStyle={logoStyle}>
      <img src={logo} alt="growthvalLogo" />
    </LogoWrapper>
  );
}

export default GrowthValLogo;
