import { Link } from 'react-router-dom';
import { routesObj } from 'routes/routes';
import classnames from 'classnames';
import customTheme from 'theme/customTheme';
import { ReactComponent as VCEquationImage } from 'assets/equation-vc.svg';
import { ReactComponent as WarningIcon } from 'assets/warning-icon-grey.svg';
import { commaSeparatedNumber } from 'utils/helperMethod';
import { useStyles } from '../styles';
import { vcReportProps, VcTableItemType } from '../types';
import copy from './copy.json';

const VcReport: React.FC<vcReportProps & { companyValuationId?: number; parentCompanyValuationId: number }> = ({
  vcReportDetails: {
    companyYear5Amount,
    exitYear,
    investmentSize,
    investorShare,
    medianMultiple,
    medianName,
    postMoneyValuation,
    preMoneyValuation,
  },
  currency,
  companyValuationId,
  skipFinancialForecast,
  parentCompanyValuationId,
}) => {
  const classes = useStyles();
  const isVcAvailable = !skipFinancialForecast;

  const topTableData = ((isAvailable: boolean): VcTableItemType[] => [
    {
      title: 'The multiple chosen is:',
      value: isAvailable ? medianName : '-',
    },
    {
      title: `Company’s ${medianName} in year 5:`,
      value: isAvailable ? `${currency}${commaSeparatedNumber(Number(companyYear5Amount))}` : '-',
    },
    {
      title: `${medianName} * Multiple:`,
      value: isAvailable ? `${medianMultiple}x` : '-',
    },
    {
      title: 'Exit Year:',
      value: isAvailable ? exitYear : '-',
    },
  ])(isVcAvailable);

  const bottomTableData = ((isAvailable: boolean): VcTableItemType[] => [
    {
      title: 'Post-Money Valuation',
      value: isAvailable ? `${currency}${commaSeparatedNumber(Number(postMoneyValuation))}` : '-',
    },
    {
      title: 'Investment size',
      value: isAvailable ? `${currency}${commaSeparatedNumber(Number(investmentSize))}` : '-',
    },
    {
      title: 'Pre-Money Valuation',
      value: isAvailable ? `${currency}${commaSeparatedNumber(Number(preMoneyValuation))}` : '-',
    },
    {
      title: 'Investor’s share',
      value: isAvailable ? `${investorShare}%` : '-',
    },
  ])(isVcAvailable);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2
          style={{
            fontFamily: 'Prompt',
            fontWeight: 700,
            fontStyle: 'normal',
            color: '#171C1B',
            fontSize: '32px',
            lineHeight: '43px',
            wordBreak: 'break-word',
          }}
        >
          VC method
        </h2>
        <div style={{ marginTop: '16px' }}>
          {isVcAvailable ? (
            <p
              style={{
                fontFamily: 'Mulish',
                fontWeight: 700,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '16px',
                lineHeight: '21px',
                wordBreak: 'break-word',
              }}
            >
              The company valuation according to the VC method is&nbsp;
              <span style={{ color: customTheme.colors.MediumSpringGreen }}>
                {currency}
                {commaSeparatedNumber(Number(preMoneyValuation))}
              </span>
            </p>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <WarningIcon style={{ minWidth: '19px', marginRight: '10px' }} />
              <p>
                <b>
                  To see the valuation according to the VC method, please{' '}
                  <Link
                    to={{
                      pathname: `${routesObj.questionaries.path}`,
                      state: { companyValuationId, parentCompanyValuationId },
                    }}
                  >
                    <span className={classes.greenText}>complete the Financial Forecasts</span>
                  </Link>
                  section in the questionnaire.
                </b>
              </p>
            </div>
          )}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
        <div
          style={{
            overflow: 'hidden',
            boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
            borderRadius: '4px',
            color: 'rgba(0, 0, 0, 0.87)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: '#fff',
            width: '49%',
            height: 'auto',
            minHeight: '538px',
          }}
          className="tilesBoxShadow"
        >
          <div style={{ padding: '25px 35px 17px 35px' }}>
            <p
              style={{
                fontFamily: 'Mulish',
                fontWeight: 700,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '16px',
                lineHeight: '21px',
                wordBreak: 'break-word',
              }}
            >
              <b>The VC Method</b> calculates a start-up's value by:
            </p>
            <div style={{ marginTop: '24px' }} />
            {copy.descriptionList.map((listItemText, index) => (
              <div style={{ display: 'flex' }} key={index}>
                <span style={{ paddingRight: 10 }}>{++index}.</span>
                <p
                  style={{
                    fontFamily: 'Mulish',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    color: '#171C1B',
                    fontSize: '15px',
                    lineHeight: '21px',
                  }}
                >
                  {listItemText}
                </p>
              </div>
            ))}
            <div style={{ marginTop: '24px' }} />
            <p
              style={{
                fontFamily: 'Mulish',
                fontWeight: 400,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '16px',
                lineHeight: '21px',
                wordBreak: 'break-word',
              }}
            >
              Growthval calculates the valuation by using P/Revenues, EV/EBITDA, P/E multiples and the result is the
              median value of 3.
            </p>
            <div style={{ marginTop: '24px' }} />
            {topTableData.map(({ title, value }, index) => {
              return (
                <div style={{ display: 'flex' }} key={index}>
                  <div style={{ width: '60%', padding: '0 18px' }}>
                    <p>
                      <b>{title}</b>
                    </p>
                  </div>
                  <div style={{ width: '60%', padding: '0 18px' }}>{value}</div>
                </div>
              );
            })}
            <div style={{ marginTop: '24px' }} />
            {bottomTableData.map(({ title, value }, index) => {
              const rightItemClass = classnames(classes.vcDataItem, {
                [classes.topLeftTile]: index === 0,
                [classes.bottomLeftTile]: index === (bottomTableData || []).length - 1,
              });

              const leftItemClass = classnames(classes.vcDataItem, {
                [classes.topRightTile]: index === 0,
                [classes.bottomRightTile]: index === (bottomTableData || []).length - 1,
              });

              return (
                <div style={{ display: 'flex' }} key={index}>
                  <div style={{ width: '60%', padding: '8px 18px' }} className={rightItemClass}>
                    <b>{title}</b>
                  </div>
                  <div style={{ width: '40%', padding: '8px 18px' }} className={leftItemClass}>
                    {value}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div style={{ width: '49%', height: 'auto', display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              overflow: 'hidden',
              boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
              borderRadius: '4px',
              color: 'rgba(0, 0, 0, 0.87)',
              transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              marginBottom: '24px',
              background: customTheme.colors.MediumSpringGreen,
            }}
            className="tilesBoxShadow"
          >
            <div style={{ padding: '25px 35px 17px 35px', display: 'flex', justifyContent: 'center' }}>
              <VCEquationImage />
            </div>
            <div style={{ marginTop: '16px' }} />
          </div>
          <div
            style={{
              overflow: 'hidden',
              boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
              borderRadius: '4px',
              color: 'rgba(0, 0, 0, 0.87)',
              transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              backgroundColor: '#fff',
              paddingBottom: '12px',
            }}
            className="tilesBoxShadow"
          >
            <div style={{ padding: '25px 35px 17px 35px' }}>
              <h3
                style={{
                  fontFamily: 'Prompt',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '18px',
                  lineHeight: '21px',
                  wordBreak: 'break-word',
                }}
              >
                Method pros
              </h3>
              <div style={{ marginTop: '8px' }} />
              {copy.pros.map((prosText, index) => (
                <div style={{ display: 'flex' }} key={index}>
                  <span style={{ paddingRight: 10 }}>•</span>
                  <p
                    style={{
                      fontFamily: 'Mulish',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      color: '#171C1B',
                      fontSize: '15px',
                      lineHeight: '21px',
                    }}
                  >
                    {prosText}
                  </p>
                </div>
              ))}
              <div style={{ marginTop: '32px' }}>
                <h3
                  style={{
                    fontFamily: 'Prompt',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    color: '#171C1B',
                    fontSize: '18px',
                    lineHeight: '21px',
                    wordBreak: 'break-word',
                  }}
                >
                  Method cons
                </h3>
                <div style={{ marginTop: '8px' }} />
                {copy.cons.map((consText, index) => (
                  <div style={{ display: 'flex' }} key={index}>
                    <span style={{ paddingRight: 10 }}>•</span>
                    <p
                      style={{
                        fontFamily: 'Mulish',
                        fontWeight: 400,
                        fontStyle: 'normal',
                        color: '#171C1B',
                        fontSize: '15px',
                        lineHeight: '21px',
                      }}
                    >
                      {consText}
                    </p>
                  </div>
                ))}
              </div>
              <div style={{ marginTop: '24px' }} />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div
                  style={{
                    overflow: 'hidden',
                    boxShadow: 'none',
                    borderRadius: '4px',
                    color: 'rgba(0, 0, 0, 0.87)',
                    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    backgroundColor: '#fff',
                    background: customTheme.colors.skyBlue,
                    width: '95%',
                    padding: 12,
                  }}
                >
                  <p
                    style={{
                      fontFamily: 'Mulish',
                      fontWeight: 400,
                      fontStyle: 'normal',
                      color: '#171C1B',
                      fontSize: '14px',
                      lineHeight: '21px',
                    }}
                  >
                    Data Source for default multiples:&nbsp;
                    <span style={{ color: customTheme.colors.MediumSpringGreen }}>
                      <b>Damodaran database</b>
                    </span>
                    <br />
                    You can also select the multiple in settings - if you have accurate data on comparable companies
                    transaction multiples.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VcReport;
