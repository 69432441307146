import classnames from 'classnames';
import { Box, Typography, Divider } from '@material-ui/core';
import { useStyles } from '../styles';

interface PropTypes {
  title: string;
  large?: boolean;
  children: React.ReactNode;
}

const SettingsSection: React.FC<PropTypes> = ({ title, large, children }) => {
  const classes = useStyles();
  const headerClass = classnames(classes.header, classes.marginBottom, {
    [classes.largeHeader]: !!large,
  });

  return (
    <>
      <Box component="section" className={classes.marginBottom}>
        <Typography variant="subtitle1" className={headerClass}>
          {title}
        </Typography>
        {children}
      </Box>
      <Divider className={classes.marginBottom} />
    </>
  );
};

export default SettingsSection;
