import { useState, useEffect } from 'react';
import FormInput from 'components/molecules/FormInput/FormInput';
import MuiRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import useRegistration from './useRegistration';
import FormError from 'components/molecules/formError/FormError';
import FormTitle from 'components/molecules/Title/Title';
import { termsAndConditionUrl } from 'utils/constants';

import {
  FormWrapper,
  FormControlLabel,
  BusinessType,
  GridContainer,
  DisplayOnMobile,
  DisplayOnDesktop,
  DivWithMargin,
  InputWrapper,
  NameWrapper,
  StyledCheckBox,
  TermAndCondition,
  ContinueButton,
} from './styles';

const RegistrationForm = (): JSX.Element => {
  const [hasAcceptTerms, setHasAcceptTerms] = useState(false);

  const { formValues, handleChange, handleSubmit, formError, errorMessage, registrating, setFormValues } =
    useRegistration();

  const { firstName, lastName, password, email } = formValues;

  useEffect(() => {
    setFormValues({ ...formValues, businessType: 'startup' });
  }, []);

  useEffect(() => {
    return () => {
      document.removeEventListener('click', handleSubmit);
    };
  }, []);

  return (
    <>
      <FormWrapper>
        <FormTitle titleStyle={{ marginBottom: '24px' }}>Create your account</FormTitle>

        <BusinessType>
          <DisplayOnMobile> I am </DisplayOnMobile>
          <RadioGroup onChange={handleChange} defaultValue="startup">
            <GridContainer container>
              <DisplayOnDesktop> I am a</DisplayOnDesktop>
              <FormControlLabel control={<MuiRadio />} label="Startup" value="startup" />
              <FormControlLabel control={<MuiRadio />} label="Investor" value="investor" />
              <FormControlLabel control={<MuiRadio />} label="Others" value="others" />
            </GridContainer>
            <FormError errorMsg={formError.businessTypeError} />
          </RadioGroup>
        </BusinessType>

        <NameWrapper>
          <div>
            <FormInput
              label="First name:"
              type="text"
              placeholder="First name"
              name="firstName"
              isError={formError.firstNameError.length > 0}
              value={firstName}
              onChange={handleChange}
            />

            <FormError errorMsg={formError.firstNameError} />
          </div>
          <DivWithMargin>
            <FormInput
              label="Last name:"
              type="text"
              placeholder="Last name"
              name="lastName"
              isError={formError.lastNameError.length > 0}
              value={lastName}
              onChange={handleChange}
            />
            <FormError errorMsg={formError.lastNameError} />
          </DivWithMargin>
        </NameWrapper>

        <InputWrapper>
          <FormInput
            label="Email:"
            type="text"
            placeholder="name@company.com"
            name="email"
            isError={formError.emailError.length > 0}
            value={email}
            onChange={handleChange}
          />
          <FormError errorMsg={formError.emailError} />
        </InputWrapper>

        <InputWrapper>
          <FormInput
            label="Password:"
            type="password"
            name="password"
            placeholder="••••••••"
            isError={formError.passwordError.length > 0}
            placeholderStyle={{ 'font-weight': '900', fontSize: '16px', ' letter-spacing': '2px' }}
            value={password}
            onChange={handleChange}
          />
          <FormError errorMsg={formError.passwordError || errorMessage} />
        </InputWrapper>

        <TermAndCondition onClick={() => setHasAcceptTerms((prev) => !prev)}>
          <StyledCheckBox id="termsAndCondition" />
          <label htmlFor="termsAndCondition">
            I agree to the{' '}
            <a href="https://growthval.com/terms-conditions" target="_blank" style={{ cursor: 'not-allowed' }}>
              {' '}
              Terms & Conditions{' '}
            </a>
            <span> and </span>
            <a href={termsAndConditionUrl} target="_blank">
              {' '}
              Privacy Policy{' '}
            </a>
          </label>
        </TermAndCondition>

        <ContinueButton disabled={!hasAcceptTerms || registrating} onClick={handleSubmit}>
          {registrating ? 'progress...' : 'Continue'}
        </ContinueButton>
      </FormWrapper>
    </>
  );
};

export default RegistrationForm;
