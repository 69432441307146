import { useReducer } from 'react';
import { omit } from 'lodash';
import { ReportData, ReportDetailsType } from './types';

type ReportActionType = {
  type: string;
  payload: ReportData;
};

export type StoreType = {
  reportDetails: ReportDetailsType;
  companyValuationId: number | null;
  parentCompanyValuationId: number | null;
  paidReport: boolean | null;
  skipFinancialForecast: boolean | null;
};

const SET_DATA = 'SET_DATA';
const initialState: StoreType = {
  reportDetails: {} as ReportDetailsType,
  companyValuationId: null,
  parentCompanyValuationId: null,
  paidReport: null,
  skipFinancialForecast: null,
};

const reducer = (state: StoreType, { type, payload }: ReportActionType) => {
  switch (type) {
    case SET_DATA: {
      const newReportDetails = omit(payload, ['companyValuationId', 'paidReport']);
      return {
        reportDetails: newReportDetails,
        companyValuationId: payload.companyValuationId,
        paidReport: payload.paidReport,
        parentCompanyValuationId: payload.parentCompanyValuationId,
        skipFinancialForecast: payload.skipFinancialForecast,
      };
    }
    default:
      return state;
  }
};

const useValuationData = (): any[] => {
  const [valuationData, dispatch] = useReducer(reducer, initialState);
  const setValuationData = (payload: ReportData): void => dispatch({ type: SET_DATA, payload } as ReportActionType);

  return [valuationData, setValuationData];
};

export default useValuationData;
