import * as yup from 'yup';

export const userFormSchema = yup.object().shape({
  firstName: yup.string().required('Please enter your name'),
  lastName: yup.string().required('Please enter your last name'),
  companyName: yup.string(), // company name is ignored
  email: yup.string(), // email is ignored
});

export const passwordSchema = yup.object({
  oldPassword: yup.string().required('Please enter your old password'),
  newPassword: yup
    .string()
    .min(8, 'Password has to be at least ${min} characters long')
    .required('Please enter new password')
    .when('oldPassword', {
      is: (password: string): boolean => !!password,
      then: yup.string().notOneOf([yup.ref('oldPassword')], 'New password needs to be different'),
    }),
  confirmPassword: yup
    .string()
    .required('Please confirm new password')
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});
