import { UserAddMoreBox, InputField, DeleteIcon, inputFieldStyle } from './styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useState, ChangeEvent } from 'react';
import { useStyles } from './styles';

const AddMoreField = ({ addNewItemFromInputField, setShowCustomInputField }: any) => {
  const [inputValue, setInputValue] = useState('');
  const classes = useStyles();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value.trim());
  };

  return (
    <>
      <UserAddMoreBox>
        <InputField placeholder="Add New field..." type="text" style={inputFieldStyle} onChange={handleChange} />
        <AddCircleIcon
          className={classes.addIcon}
          onClick={() => {
            if (inputValue === '') {
              return;
            }
            addNewItemFromInputField(inputValue);
            setShowCustomInputField((prevState: boolean) => !prevState);
          }}
        />
        <DeleteIcon
          onClick={() => {
            setShowCustomInputField((prevState: boolean) => !prevState);
          }}
        />
      </UserAddMoreBox>
    </>
  );
};

export default AddMoreField;
