import { useState } from 'react';
import classnames from 'classnames';
import { Formik, Form } from 'formik';
import Payment from 'components/templates/payment';
import usePaymentModal from 'components/templates/payment/usePaymentModal';
import { Box, Typography } from '@material-ui/core';
import NavContainer from 'components/layout/NavContainer';
import Loading from 'components/layout/Loading';
import useLoadingContext from 'components/layout/Loading/useLoadingContext';
import CustomInput from 'components/molecules/FormInput';
import Button from 'components/molecules/button';
import { Wrapper } from 'style/CommonStyle';
import { Section, Row, SubmitSection, DeleteUserModal } from './components';
import { Error } from 'style/CommonStyle';
import customTheme from 'theme/customTheme';
import { UserDataType, PasswordDataType } from './types';
import { useMessage } from './helpers';
import { Container, Centerlize, useStyles } from './styles';
import { userFormSchema, passwordSchema } from './validation';
import { userDataApi, passwordApi, settingsApi } from './api';
import { useEffect } from 'react';

const supportEmail = 'hello@growthval.com';

const Settings: React.FC = () => {
  const classes = useStyles();
  const { setPaymentModal, isSuccessModalOpen } = usePaymentModal();
  const [userMsg, setUserErrorMsg, setUserSuccessMsg] = useMessage();
  const [pswdMsg, setPswdErrorMsg, setPswdSuccessMsg] = useMessage();
  const [showDeleteModal, setDeleteModal] = useState<boolean>(false);
  const { setLoading } = useLoadingContext();
  const [{ subscriptionPlan, user }, setSettingsData] = useState<{ [key: string]: { [key: string]: unknown } }>({
    subscriptionPlan: {},
    user: {},
  });

  useEffect(() => {
    // refresh settings after payment is done
    if (isSuccessModalOpen === false) {
      settingsApi.get({
        onRequest: () => setLoading(true),
        onSuccess: (data) => setSettingsData(data),
        onFinally: () => setLoading(false),
      });
    }
  }, [isSuccessModalOpen]);

  const initialUserData: any = {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
    companyName: user.companyName || '',
  };

  const initialPasswordData: any = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const submitUserData = (values: UserDataType): Promise<void> =>
    userDataApi.put(values, {
      onSuccess: () => setUserSuccessMsg('Changes saved'),
      onFailure: setUserErrorMsg,
    });

  const submitNewPassword = (values: PasswordDataType): Promise<void> =>
    passwordApi.put(values, {
      onSuccess: () => setPswdSuccessMsg('Your password has been changed'),
      onFailure: setPswdErrorMsg,
    });

  const deleteUser = () => userDataApi.delete();
  const contactSupport = () => window.location.assign(`mailto:${supportEmail}`);

  const statusText = ((status) => {
    switch (status) {
      case 'active':
        return 'Active';
      case 'expired':
        return 'Expired';
      case 'none':
        return 'No subscription';
      default:
        return '';
    }
  })(subscriptionPlan.status);

  const subscriptionStatusClass = ((status) =>
    classnames(classes.boldText, {
      [classes.error]: status === 'expired',
      [classes.success]: status === 'active',
    }))(subscriptionPlan.status);

  const validFrom = (subscriptionPlan.validFrom || '') as string;
  const validTo = (subscriptionPlan.validTo || '') as string;

  return (
    <>
      <Loading>
        <NavContainer>
          <Wrapper>
            <Container>
              <Centerlize>
                <Section large title="Settings">
                  <Formik initialValues={initialUserData} onSubmit={submitUserData} validationSchema={userFormSchema}>
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        <Box className={classes.marginBottom}>
                          <Row label="First name">
                            <Box className={classes.inputContainer}>
                              <CustomInput
                                isFullWidth
                                marginTop="5px"
                                name="firstName"
                                type="text"
                                value={values.firstName}
                                handleOnChange={(value) => setFieldValue('firstName', value, false)}
                                bgColor={customTheme.colors.white}
                                helpText={errors.firstName}
                              />
                            </Box>
                          </Row>
                          <Row label="Last name">
                            <Box className={classes.inputContainer}>
                              <CustomInput
                                isFullWidth
                                marginTop="5px"
                                name="lastName"
                                type="text"
                                value={values.lastName}
                                handleOnChange={(value) => setFieldValue('lastName', value, false)}
                                bgColor={customTheme.colors.white}
                                helpText={errors.lastName}
                              />
                            </Box>
                          </Row>
                          <Row label="Email">
                            <Box className={classes.inputContainer}>
                              <CustomInput
                                isFullWidth
                                marginTop="5px"
                                name="email"
                                type="text"
                                placeholder={values.email}
                                bgColor={customTheme.colors.Gray95}
                                readOnly
                              />
                            </Box>
                          </Row>
                          <Row label="Company name">
                            <Box className={classes.inputContainer}>
                              <CustomInput
                                isFullWidth
                                disableTrimming
                                marginTop="5px"
                                name="companyName"
                                type="text"
                                value={values.companyName}
                                handleOnChange={(value) => setFieldValue('companyName', value, false)}
                                bgColor={customTheme.colors.white}
                                helpText={errors.companyName}
                              />
                            </Box>
                          </Row>
                        </Box>
                        <SubmitSection message={userMsg.text} type={userMsg.type}>
                          <Button marginTop="0" type="submit">
                            Save changes
                          </Button>
                        </SubmitSection>
                      </Form>
                    )}
                  </Formik>
                </Section>

                <Section title="Change password">
                  <Formik
                    initialValues={initialPasswordData}
                    onSubmit={submitNewPassword}
                    validationSchema={passwordSchema}
                  >
                    {({ values, setFieldValue, errors }) => {
                      return (
                        <Form>
                          <Box className={classes.marginBottom}>
                            <Row label="Current password">
                              <Box className={classes.inputContainer}>
                                <CustomInput
                                  isFullWidth
                                  marginTop="5px"
                                  name="oldPassword"
                                  type="password"
                                  placeholder="•••••••"
                                  value={values.oldPassword}
                                  handleOnChange={(value) => setFieldValue('oldPassword', value, false)}
                                  bgColor={customTheme.colors.white}
                                />
                                {!!errors.oldPassword && <Error>{errors.oldPassword}</Error>}
                              </Box>
                            </Row>
                            <Row label="New password">
                              <Box className={classes.inputContainer}>
                                <CustomInput
                                  isFullWidth
                                  marginTop="5px"
                                  name="newPassword"
                                  type="password"
                                  placeholder="•••••••"
                                  value={values.newPassword}
                                  handleOnChange={(value) => setFieldValue('newPassword', value, false)}
                                  bgColor={customTheme.colors.white}
                                />
                                {!!errors.newPassword && <Error>{errors.newPassword}</Error>}
                              </Box>
                            </Row>
                            <Row label="Confirm password">
                              <Box className={classes.inputContainer}>
                                <CustomInput
                                  isFullWidth
                                  marginTop="5px"
                                  name="confirmPassword"
                                  type="password"
                                  placeholder="•••••••"
                                  value={values.confirmPassword}
                                  handleOnChange={(value) => setFieldValue('confirmPassword', value, false)}
                                  bgColor={customTheme.colors.white}
                                />
                                {!!errors.confirmPassword && <Error>{errors.confirmPassword}</Error>}
                              </Box>
                            </Row>
                          </Box>
                          <SubmitSection message={pswdMsg.text} type={pswdMsg.type}>
                            <Button marginTop="0" type="submit">
                              Change password
                            </Button>
                          </SubmitSection>
                        </Form>
                      );
                    }}
                  </Formik>
                </Section>

                <Section title="Status of your subscription">
                  <>
                    <Row unresponsive label="Status">
                      <Typography className={subscriptionStatusClass}>{statusText}</Typography>
                    </Row>
                    {subscriptionPlan.status !== 'none' && (
                      <>
                        <Row unresponsive label="Started on">
                          <Typography className={classes.boldText}>{validFrom}</Typography>
                        </Row>
                        <Row unresponsive label="Ends on">
                          <Typography className={classes.boldText}>{validTo}</Typography>
                        </Row>
                      </>
                    )}
                    <Button
                      marginTop="30px"
                      isDisable={subscriptionPlan.status === 'active'}
                      bgColor={
                        subscriptionPlan.status === 'active'
                          ? customTheme.colors.LigthGray
                          : customTheme.colors.primaryColor
                      }
                      onClick={() => setPaymentModal(true)}
                    >
                      {subscriptionPlan.status === 'none' ? 'Activate subscription' : 'Reactivate subscription'}
                    </Button>
                  </>
                </Section>

                <Section title="Contact support">
                  <Button marginTop="0" onClick={contactSupport}>
                    Contact support
                  </Button>
                </Section>

                <Section title="Delete your account & data">
                  <Button marginTop="0" bgColor={customTheme.colors.error} onClick={() => setDeleteModal(true)}>
                    Delete your account &amp; data
                  </Button>
                </Section>
              </Centerlize>
            </Container>
          </Wrapper>
        </NavContainer>
        <DeleteUserModal onAccept={deleteUser} onClose={() => setDeleteModal(false)} open={showDeleteModal} />
      </Loading>
      <Payment />
    </>
  );
};

export default Settings;
