import classnames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from '../styles';

interface PropTypes {
  label: string;
  children: React.ReactNode;
  unresponsive?: boolean;
}

const Row: React.FC<PropTypes> = ({ label, unresponsive = false, children }) => {
  const classes = useStyles();
  const rowClass = classnames(classes.row, { [classes.responsiveRow]: !unresponsive });
  const leftBoxClass = classnames(classes.leftBox, { [classes.responsiveBox]: !unresponsive });
  const rightBoxClass = classnames(classes.rightBox, { [classes.responsiveBox]: !unresponsive });
  return (
    <Box className={rowClass}>
      <Box className={leftBoxClass}>
        <Typography className={classes.boldText}>{label}</Typography>
      </Box>
      <Box className={rightBoxClass}>{children}</Box>
    </Box>
  );
};

export default Row;
