import { Box, Typography } from '@material-ui/core';
import { getAmountString } from 'utils/helperMethod';
import { useStyles } from '../styles';

interface PropTypes {
  valuationRange: [number, number];
  weightedValuation: number;
  currency: string;
  companyName: string;
}

const Summary: React.FC<PropTypes> = ({
  valuationRange = [0, 0],
  weightedValuation = 0,
  currency = '',
  companyName = '',
}) => {
  const classes = useStyles();
  const [fromRange, toRange] = valuationRange;
  return (
    <Box className={`${classes.greenBox} ${classes.shadowContainer}`}>
      <Box className={classes.summaryItem}>
        <Typography variant="subtitle1" className={`${classes.greenBoxText} ${classes.summaryTitle}`}>
          {`THE VALUATION RANGE OF ${companyName.toUpperCase()}`}
        </Typography>
        <Typography variant="subtitle1" className={`${classes.greenBoxText} ${classes.summaryValue}`}>
          {getAmountString(fromRange, currency)} - {getAmountString(toRange, currency)}
        </Typography>
      </Box>
      <Box className={classes.summaryItem}>
        <Typography variant="subtitle1" className={`${classes.greenBoxText} ${classes.summaryTitle}`}>
          {`THE WEIGHTED VALUATION OF ${companyName.toUpperCase()}`}
        </Typography>
        <Typography variant="subtitle1" className={`${classes.greenBoxText} ${classes.summaryValue}`}>
          {getAmountString(weightedValuation, currency)}
        </Typography>
      </Box>
    </Box>
  );
};

export default Summary;
