import React from 'react';
import isEmpty from 'lodash/isEmpty';
import CompanyOverview from './CompanyOverview';
import FinancialReport from './FinancialReport';
import ScoreCardReport from './ScoreCardReport';
import BerkusReport from './BerkusReport';
import RiskReport from './RiskReport';
import VcReport from './VCReport';
import ValuationSummary from './ValuationSummary';
import DCFReport from './DCFReport';
import Disclaimer from 'components/molecules/Disclaimer';
import CoverSection from 'components/organisms/CoverSection';
import useFetchPdfReport from './useFetchPdfReport';
import { PdfReportProps } from './types';

const PdfReport: React.FC<PdfReportProps> = ({ match }) => {
  const pdfReportData = useFetchPdfReport(match?.params.userId, match?.params.token);
  if (isEmpty(pdfReportData)) return <></>;

  const {
    companyValuationId,
    parentCompanyValuationId,
    companyOverview,
    scorecardReport: scoreCardDetails = {},
    berkusReport: berkusDetails = {},
    riskFactorReport: riskReportDetails = {},
    vcReport: vcReportDetails = {},
    valuationSummaryReport: valuationSummaryDetails = {},
    financialReport: financialDetails = {},
    companyName = '',
    reportGeneratedDate = '',
    skipFinancialForecast,
    dcfReport = {},
  } = pdfReportData;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '35px 0 35px 0', background: '#fff' }}>
      <div style={{ width: '90%', margin: '0 auto', maxWidth: ' 1496px' }}>
        <div id="element-to-print">
          <div className="pagebreak">
            <CoverSection name={companyName} date={reportGeneratedDate} />
          </div>
          <div className="pagebreak">
            <div style={{ marginTop: '30px' }}>
              <CompanyOverview details={companyOverview} />
            </div>
          </div>
          <div className="pagebreak">
            <div style={{ marginTop: '27px' }}>
              <FinancialReport financialDetails={financialDetails} currency={companyOverview.currencySymbol} />
            </div>
          </div>
          <div className="pagebreak">
            <div style={{ marginTop: '27px' }}>
              <ValuationSummary
                valuationSummaryDetails={valuationSummaryDetails}
                currency={companyOverview.currencySymbol}
                companyName={companyOverview.companyName}
              />
            </div>
          </div>
          <div className="pagebreak">
            <div style={{ marginTop: '27px' }}>
              <ScoreCardReport scoreCardDetails={scoreCardDetails} currency={companyOverview.currencySymbol} />
            </div>
          </div>
          <div className="pagebreak">
            <div style={{ marginTop: '48px' }}>
              <BerkusReport
                berkusDetails={berkusDetails}
                currency={companyOverview.currencySymbol}
                companyName={companyOverview.companyName}
              />
            </div>
          </div>
          <div className="pagebreak">
            <div style={{ marginTop: '48px' }}>
              <RiskReport riskReportDetails={riskReportDetails} currency={companyOverview.currencySymbol} />
            </div>
          </div>
          <div className="pagebreak">
            <div style={{ marginTop: '48px' }}>
              <VcReport
                vcReportDetails={vcReportDetails}
                currency={companyOverview.currencySymbol}
                skipFinancialForecast={skipFinancialForecast}
                parentCompanyValuationId={parentCompanyValuationId}
              />
            </div>
          </div>
          <div className="pagebreak">
            <div style={{ marginTop: '48px' }}>
              <DCFReport
                dcfReport={dcfReport}
                currencySymbol={companyOverview.currencySymbol}
                companyValuationId={companyValuationId}
                skipFinancialForecast={skipFinancialForecast}
                parentCompanyValuationId={parentCompanyValuationId}
              />
            </div>
          </div>
          <div className="pagebreak">
            <div style={{ marginTop: '48px' }}>
              <Disclaimer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfReport;
