import { makeStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  bgColor?: string;
  fontSize?: string;
  textColor?: string;
  marginTop?: string;
  hoverColor?: string;
}

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  formButton: {
    background: ({ bgColor }) => bgColor || '#00CAA6',
    padding: '12px 23px',
    marginTop: ({ marginTop }) => marginTop,
    fontFamily: 'Mulish',
    fontSize: ({ fontSize }) => fontSize || '16px',
    lineHeight: '21px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: ({ textColor }) => textColor || '#FFFFFF',
    '&:hover': {
      background: ({ hoverColor }) => hoverColor,
    },
    '&:disabled': {
      color: '#ffffff',
    },
  },
}));
