import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  sideNavBar: {
    width: '13%',
    position: 'fixed',
    zIndex: 2,
    top: '32%',
    left: '72px',
    background: 'transparent',
    overflowX: 'hidden',
    padding: '28px 0',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  linkText: {
    fontSize: '14px',
    lineHeight: '21px',
  },
  sideBarLinks: {
    padding: '10px 20px',
  },
}));
