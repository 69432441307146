import { useState } from 'react';
import LoadingContext from './LoadingContext';

interface PropTypes {
  children: React.ReactElement;
}

const LoadingProvider: React.FC<PropTypes> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  return <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>;
};

export default LoadingProvider;
