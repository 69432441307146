import { useState } from 'react';
import moment from 'moment';
import { MessageHelperType, SettingsResponseType, SubscriptionPlanType } from './types';

export const useMessage = (
  timeout = 2000,
): [MessageHelperType, (newMessage: string) => void, (newMessage: string) => void] => {
  const [message, setMessage] = useState<MessageHelperType>({
    type: 'ERROR',
    text: '',
  });

  const handleMessage =
    (type: 'ERROR' | 'SUCCESS' = 'ERROR'): ((newMessage: string) => void) =>
    (newMessage = ''): void => {
      setMessage({
        type: type,
        text: newMessage,
      });
      setTimeout(() => {
        setMessage({
          type: type,
          text: '',
        });
      }, timeout);
    };

  const setErrorMessage = handleMessage('ERROR');
  const setSuccessMessage = handleMessage('SUCCESS');

  return [message, setErrorMessage, setSuccessMessage];
};

// stuff needed to handle settings response...

const formatSubscriptionDate = (date = ''): string => {
  if (!date) return '';

  const momentDate = moment(date);
  return [momentDate.format('DD MMMM YYYY'), 'at', momentDate.format('HH:mm')].join(' ');
};

const handleSubscriptionPlan = (subscriptionPlan: SubscriptionPlanType): SubscriptionPlanType => {
  const validFrom = formatSubscriptionDate(subscriptionPlan.validFrom);
  const validTo = formatSubscriptionDate(subscriptionPlan.validTo);

  const newSubscriptionPlan = {
    ...subscriptionPlan,
    validFrom,
    validTo,
  };

  return newSubscriptionPlan;
};

export const handleSettingsResponse = ({
  subscriptionPlan = {},
  ...rest
}: SettingsResponseType = {}): SettingsResponseType => {
  const newSubscription = handleSubscriptionPlan(subscriptionPlan);
  return {
    ...rest,
    subscriptionPlan: newSubscription,
  };
};
