import styled, { css } from 'styled-components';

export const TitleBox = styled.h2<{ titleStyle: any }>`
  font-family: 'Prompt', sans-serif;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: #171c1b;
  ${(props) =>
    css`
      ${props.titleStyle}
    `}

  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      font-size: 26px;
    }
  }
`;
