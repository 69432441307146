import styled from 'styled-components';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import customTheme from 'theme/customTheme';
import Slider from '@material-ui/core/Slider';

export const QuestionSection = styled.section`
  display: 'flex';
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 35px 0 35px 0;
  margin-bottom: 75px;
`;

export const CenterlizeContent = styled.div`
  width: 60%;
  margin: 0 auto;
  max-width: 1496px;
  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      width: 90%;
    }
  }
`;

export const Symbol = styled.div`
  background: ${(props) => props.theme.colors.MediumSpringGreen};
  border-radius: 50px 0 0 50px;
  width: 43px;
  height: 58px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 15px 25px;
`;

export const Currency = styled.span`
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  font-size: 16px;
  line-height: 30px;
  font-family: Mulish;
  font-weight: bold;
`;

export const InfoIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  select: {
    width: '100%',
    borderRadius: '47px',
    background: customTheme.colors.white,
    padding: '0px 23px',
  },
  divider: {
    margin: '0 13px 0 5px',
    width: '3px',
    height: '30px',
    background: customTheme.colors.MediumSpringGreen,
  },
  questionText: {
    fontSize: '15px',
  },
  heading: {
    fontSize: '36px',
    lineHeight: '48px',
    textAlign: 'left',
  },
  subTitle: {
    fontSize: '16px',
    lineHeight: '25px',
  },
  questionHeading: {
    fontSize: '21px',
    lineHeight: '28px',
  },
  horizontalDivider: {
    marginTop: '7px',
  },
  riskLabels: {
    fontSize: '12px',
    lineHeight: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
  },
  dropdownText: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  generalContainer: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  questionTextSection: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  fieldSection: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '15px',
    },
  },
  confirmationHeading: {
    fontSize: '16px',
    textAlign: 'center',
    lineHeight: '20px',
  },
  confirmationText: {
    fontSize: '13px',
    lineHeight: '9px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      lineHeight: '12px',
    },
  },
  sliderContainer: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginTop: '40px',
    },
  },
  sliderSection: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 16,
      marginRight: 16,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginLeft: 0,
    },
  },
  sliderTitle: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  sliderLabels: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 16px 20px 44%',
    [theme.breakpoints.down('md')]: {
      padding: '0 0 20px 0',
    },
  },
  sliderLabelTitle: {
    position: 'relative',
    '&:nth-child(2)': {
      right: '16px',
    },
    '&:nth-child(4)': {
      left: '16px',
    },
  },
  valuationBtn: {
    padding: '10px 26px',
    '&:hover': {
      background: customTheme.colors.MediumSpringGreen,
    },
    '& h6': {
      color: customTheme.colors.white,
      fontSize: '16px',
      lineHeight: '21px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '13px 53px',
      '& h6': {
        fontSize: '14px',
        lineHeight: '16px',
      },
    },
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  valuationCTA: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      marginBottom: 2,
      '& span:nth-child(2)': {
        marginLeft: '-35px',
        marginRight: '10px',
      },
    },
  },
}));

export const RiskSlider = withStyles({
  rail: {
    width: '100%',
    height: 8,
    borderRadius: 23,
  },
  track: {
    height: 8,
    borderRadius: 23,
  },
  thumb: {
    width: 18,
    height: 18,
    border: `3px solid ${customTheme.colors.white}`,
  },
})(Slider);

export const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  && {
    ${(props) => props.theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: center;
    }
  }
`;
