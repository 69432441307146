import { Box, Typography } from '@material-ui/core';
import CustomButton from 'components/molecules/button';
import { Edit as EditIcon, Note as NoteIcon } from '@material-ui/icons';
import customTheme from 'theme/customTheme';
import { useStyles } from './styles';

interface PropTypes {
  onEdit?: () => void;
  onDownload?: () => void;
  pdfUrl?: string;
}

const ValuationHeader: React.FC<PropTypes> = ({ onEdit, onDownload, pdfUrl }) => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between" className={classes.reportSection}>
      <Box>
        <Typography variant="subtitle1" className={classes.heading}>
          Valuation is Complete
        </Typography>
        <Box mt={1} />
        <Typography variant="body1" className={classes.subHeading}>
          This is the valuation of your startup:
        </Typography>
      </Box>
      <Box className={classes.buttonsContainer}>
        <Box className={classes.editButton}>
          <CustomButton
            isDisable={!onEdit}
            onClick={onEdit}
            bgColor={!onEdit ? customTheme.colors.LigthGray : customTheme.colors.DarkBlack}
            startIcon={<EditIcon />}
            hoverColor={customTheme.colors.MediumSpringGreen}
          >
            Edit answers &amp; valuation parameters
          </CustomButton>
        </Box>
        <Box className={classes.pdfBtn}>
          {pdfUrl ? (
            <a href={`${process.env.REACT_APP_AWS_URL}/${pdfUrl}`} rel="noreferrer" download>
              <CustomButton
                isDisable={!onDownload}
                onClick={onDownload}
                bgColor={!onDownload ? customTheme.colors.LigthGray : customTheme.colors.MediumSpringGreen}
                startIcon={<NoteIcon />}
              >
                Download report
              </CustomButton>
            </a>
          ) : (
            <CustomButton
              isDisable={!onDownload}
              onClick={onDownload}
              bgColor={!onDownload ? customTheme.colors.LigthGray : customTheme.colors.MediumSpringGreen}
              startIcon={<NoteIcon />}
            >
              Download the report
            </CustomButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ValuationHeader;
