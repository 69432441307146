import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Box, Card, Typography } from '@material-ui/core';
import CardColumnSection from 'components/organisms/CardColumsSection';
import { commaSeparatedNumber } from 'utils/helperMethod';
import customTheme from 'theme/customTheme';
import { BerkusReportProps, ColumnsData } from './types';
import { useStyles } from './styles';

const BerkusReport: React.FC<BerkusReportProps> = ({ currency, berkusDetails, companyName }) => {
  const classes = useStyles();
  const [berkusValues, setBerkusValues] = useState<ColumnsData[] | any>();

  useEffect(() => {
    if (isEmpty(berkusDetails)) {
      return;
    }
    const berkusData = berkusDetails.berkusCategoriesValuation.map((item) => ({
      title: item.name,
      value: `${currency}${commaSeparatedNumber(Number(item.categoryWeightedAmount))}`,
    }));

    setBerkusValues(berkusData);
  }, [berkusDetails]);

  const getTotalValueCount = () => {
    if (isEmpty(berkusDetails)) {
      return null;
    }

    const count = berkusDetails.berkusCategoriesValuation.reduce(function (acc, obj) {
      return +acc + +obj.categoryWeightedAmount;
    }, 0);

    if (count && typeof count !== 'number') return '';
    if (count && typeof count === 'number') {
      return commaSeparatedNumber(count);
    }
  };

  const { berkusWeightedAmount } = berkusDetails;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <h2
            style={{
              fontFamily: 'Prompt',
              fontWeight: 700,
              fontStyle: 'normal',
              color: '#171C1B',
              fontSize: '32px',
              lineHeight: '43px',
              wordBreak: 'break-word',
            }}
          >
            Berkus method
          </h2>
        </div>
        <div style={{ marginTop: '16px' }}>
          <p
            style={{
              fontFamily: 'Mulish',
              fontWeight: 700,
              fontStyle: 'normal',
              color: '#171C1B',
              fontSize: '16px',
              lineHeight: '21px',
              wordBreak: 'break-word',
            }}
          >
            The company valuation according to the Berkus method is&nbsp;
            <span style={{ color: customTheme.colors.MediumSpringGreen }}>
              {currency}
              {commaSeparatedNumber(Number(berkusWeightedAmount))}
            </span>
          </p>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
        <div
          style={{
            overflow: 'hidden',
            boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
            borderRadius: '4px',
            color: 'rgba(0, 0, 0, 0.87)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: '#fff',
            width: '49%',
            height: 'auto',
            minHeight: '538px',
          }}
          className="tilesBoxShadow"
        >
          <div style={{ padding: '25px 35px 17px 35px' }}>
            <p
              style={{
                fontFamily: 'Mulish',
                fontWeight: 700,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '16px',
                lineHeight: '21px',
                wordBreak: 'break-word',
              }}
            >
              This method was developed by Dave Berkus to value companies at pre-revenue stage.
            </p>
            <div style={{ marginTop: '24px' }} />
            <p
              style={{
                fontFamily: 'Mulish',
                fontWeight: 400,
                fontStyle: 'normal',
                color: '#171C1B',
                fontSize: '16px',
                lineHeight: '21px',
                wordBreak: 'break-word',
              }}
            >
              It is based on assigning a value in 5 areas to reduce risks of the startups:
            </p>
            <div style={{ marginTop: '24px' }} />
            <div style={{ display: 'flex' }}>
              <span style={{ paddingRight: 10 }}>•</span>
              <p
                style={{
                  fontFamily: 'Mulish',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '15px',
                  lineHeight: '21px',
                }}
              >
                Sound Idea (basic value)
              </p>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ paddingRight: 10 }}>•</span>
              <p
                style={{
                  fontFamily: 'Mulish',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '15px',
                  lineHeight: '21px',
                }}
              >
                Prototype (reducing technology risk)
              </p>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ paddingRight: 10 }}>•</span>
              <p
                style={{
                  fontFamily: 'Mulish',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '15px',
                  lineHeight: '21px',
                }}
              >
                Quality Management Team (reducing execution risk)
              </p>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ paddingRight: 10 }}>•</span>
              <p
                style={{
                  fontFamily: 'Mulish',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '15px',
                  lineHeight: '21px',
                }}
              >
                Strategic Relationships (reducing market risk)
              </p>
            </div>
            <div style={{ display: 'flex' }}>
              <span style={{ paddingRight: 10 }}>•</span>
              <p
                style={{
                  fontFamily: 'Mulish',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '15px',
                  lineHeight: '21px',
                }}
              >
                Product Rollout and Sales (reducing production risk)
              </p>
            </div>
            <div style={{ marginTop: '32px' }}>
              <h2
                style={{
                  fontFamily: 'Prompt',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '18px',
                  lineHeight: '21px',
                  wordBreak: 'break-word',
                }}
              >
                Method pros
              </h2>
              <div style={{ marginTop: '8px' }} />
              <div style={{ display: 'flex' }}>
                <span style={{ paddingRight: 10 }}>•</span>
                <p
                  style={{
                    fontFamily: 'Mulish',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    color: '#171C1B',
                    fontSize: '15px',
                    lineHeight: '21px',
                  }}
                >
                  Useful method to value pre-revenue startups.
                </p>
              </div>
              <div style={{ display: 'flex' }}>
                <span style={{ paddingRight: 10 }}>•</span>
                <p
                  style={{
                    fontFamily: 'Mulish',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    color: '#171C1B',
                    fontSize: '15px',
                    lineHeight: '21px',
                  }}
                >
                  Can be a used to justify your startup valuation during negotiations.
                </p>
              </div>
              <div style={{ display: 'flex' }}>
                <span style={{ paddingRight: 10 }}>•</span>
                <p
                  style={{
                    fontFamily: 'Mulish',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    color: '#171C1B',
                    fontSize: '15px',
                    lineHeight: '21px',
                  }}
                >
                  Identifies important factors that affect a startup
                </p>
              </div>
            </div>
            <div style={{ marginTop: '16px' }}>
              <h2
                style={{
                  fontFamily: 'Prompt',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '18px',
                  lineHeight: '21px',
                  wordBreak: 'break-word',
                }}
              >
                Method cons
              </h2>
              <div style={{ marginTop: '8px' }} />
              <div style={{ display: 'flex' }}>
                <span style={{ paddingRight: 10 }}>•</span>
                <p
                  style={{
                    fontFamily: 'Mulish',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    color: '#171C1B',
                    fontSize: '15px',
                    lineHeight: '21px',
                  }}
                >
                  Level of subjectivity
                </p>
              </div>
              <div style={{ display: 'flex' }}>
                <span style={{ paddingRight: 10 }}>•</span>
                <p
                  style={{
                    fontFamily: 'Mulish',
                    fontWeight: 400,
                    fontStyle: 'normal',
                    color: '#171C1B',
                    fontSize: '15px',
                    lineHeight: '21px',
                  }}
                >
                  Doesn’t include weights which are important for your sector
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            overflow: 'hidden',
            boxShadow: 'rgba(0, 0, 0, 0.08) 0px 0px 25px',
            borderRadius: '4px',
            color: 'rgba(0, 0, 0, 0.87)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundColor: '#fff',
            width: '49%',
            height: 'auto',
            minHeight: '538px',
          }}
          className="tilesBoxShadow"
        >
          <div style={{ padding: '25px 35px 17px 35px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginTop: '24px' }}>
              <CardColumnSection
                firstTitle={companyName}
                secondTitle="Value Allocated"
                data={berkusValues || []}
                totalValue={`${currency}${getTotalValueCount()}`}
                mobileWidthFirstCol="55%"
                mobileWidthSecondCol="44%"
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                overflow: 'hidden',
                boxShadow: 'none',
                borderRadius: '4px',
                color: 'rgba(0, 0, 0, 0.87)',
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                backgroundColor: '#fff',
                background: customTheme.colors.skyBlue,
                width: '85%',
                padding: 12,
              }}
            >
              <p
                style={{
                  fontFamily: 'Mulish',
                  fontWeight: 400,
                  fontStyle: 'normal',
                  color: '#171C1B',
                  fontSize: '14px',
                  lineHeight: '21px',
                }}
              >
                We ask a set of questions under each category to allocate value to the startup. Maximum valuation that
                can be assigned in each area is <b>{currency}500,000</b>, the startup’s valuation cannot be higher
                than&nbsp;
                <b>{currency}2,500,000</b>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BerkusReport;
