import styled from 'styled-components';

export const FormWrapper = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const MarginAuto = styled.div`
  display: flex;
  justify-content: center;
`;
export const FormContainer = styled.section`
  width: 392px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.08);
  padding: 30px;
  margin: 30px 0;
  border-radius: 8px;

  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      max-width: 335px;
      padding: 30px 10px;
    }
  }
`;

export const InputWrapper = styled.div`
  margin: 20px 0;
  position: relative;
`;

export const ForgetPassword = styled.p`
  font-size: 13px;
  font-weight: 400;
  position: absolute;
  top: 2px;
  right: 0px;
  font-family: 'Mulish';
  & a {
    color: #555555;
  }
`;

export const NotMemberSignUp = styled.div`
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.2px;
  margin-top: 30px;
  font-family: 'Mulish';
  font-size: 14px;
  & a {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
