import { commaSeparatedNumber } from 'utils/helperMethod';

export const firstLetterCapital = (data: string): string => {
  return data.charAt(0).toUpperCase() + data.slice(1);
};

export const pieChartColorPalette = [
  '#00CAA6',
  '#171C1B',
  '#ADFFF0',
  '#7293A0',
  '#45B79C',
  '#46606A',
  '#5E8E96',
  '#ABDBD2',
  '#ABDBD2',
  '#0E7C7B',
];

export const getColorPalette = (length: number): Array<string> => {
  const palette = [];
  for (let i = 0; i <= length; i++) {
    palette.push(pieChartColorPalette[i]);
  }
  return palette;
};

export const verticalBarConfig = (currency: string) => {
  return {
    indexAxis: 'x',
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: window.matchMedia('(max-width: 767px)').matches ? '6px' : '11px',
            family: 'Mulish',
            weight: window.matchMedia('(max-width: 767px)').matches ? 600 : 400,
          },
        },
      },
      y: {
        ticks: {
          callback: function (value: number) {
            return `${currency}${commaSeparatedNumber(Number(value))}`;
          },
        },
      },
    },
  };
};

export const pieChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const FinancialBarConfig = (currency: string) => {
  const isMobile = window.matchMedia('(max-width: 767px)').matches;
  return {
    indexAxis: 'x',
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        position: 'left',
        ticks: {
          callback: function (value: number) {
            return `${currency}${commaSeparatedNumber(Number(value))}`;
          },
          font: {
            size: isMobile ? '7px' : '11px',
            family: 'Mulish',
            weight: isMobile ? 600 : 400,
          },
        },
      },
      y1: {
        position: 'right',
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value: number) {
            return `${value}%`;
          },
          font: {
            size: isMobile ? '7px' : '11px',
            family: 'Mulish',
            weight: isMobile ? 600 : 400,
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: isMobile ? '6px' : '11px',
            family: 'Mulish',
            weight: isMobile ? 600 : 400,
          },
        },
      },
    },
  };
};
