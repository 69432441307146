import styled from 'styled-components';

export const MainWrapper = styled.section`
  display: flex;
  width: 100vw;
  height: 100vh;
`;
export const ConfirmationBox = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px 20px 20px;

  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      padding: 20px 0px;
    }
  }

  && {
    ${(props) => props.theme.breakpoints.down('sm')} {
      padding: 0;
    }
  }
`;

export const CardMedia = styled.img`
  height: 75px;
  width: 75px;
  margin-bottom: 20px;
`;

export const Title = styled.h3`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  color: ${(props) => props.theme.colors.DarkBlack};
  font-family: ${(props) => props.theme.fontFamily.secondaryFont};
  line-height: 32px;
`;

export const StyledParagraph = styled.p`
  max-width: 300px;
  margin-bottom: 30px;
  color: ${(props) => props.theme.colors.DarkBlack};
  font-size: 16px;
  font-weight: 400;
`;

export const SeeFullReportButton = styled.button`
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.primaryBorderRadius};
  width: 40%;
  height: 46px;

  background-color: ${(props) => props.theme.colors.primaryColor};

  &:active {
    border: 2px solid ${(props) => props.theme.colors.white};
    transform: translateY(-1px);
  }
  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      width: 200px;
    }
  }
`;
