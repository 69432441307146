import React from 'react';
import { Box, Typography, Card } from '@material-ui/core';
import CardContent from 'components/organisms/CardContent';
import { commaSeparatedNumber } from 'utils/helperMethod';
import { useStyles } from './styles';
import { CompanyOverivewProps } from './types';
import { firstLetterCapital } from './helper';
import { isEmpty } from 'lodash';

const CompanyOverview: React.FC<CompanyOverivewProps> = ({ details }) => {
  const classes = useStyles();

  if (isEmpty(details)) {
    return <></>;
  }
  const {
    companyName,
    companyWebsite,
    yearFounded,
    industry,
    location,
    numberOfFounders,
    numberOfEmployees,
    numberOfAdvisors,
    businessType,
    targetMarket,
    businessStage,
  } = details;
  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          Company overview
        </Typography>
      </Box>
      <Box mt={2}>
        <Card className={classes.cardHeader}>
          <Typography variant="subtitle1" className={classes.linkHeading}>
            {firstLetterCapital(companyName)}
          </Typography>
          {companyWebsite && (
            <a
              href={
                companyWebsite.indexOf('http://') === -1 && companyWebsite.indexOf('https://') === -1
                  ? `//${companyWebsite}`
                  : companyWebsite
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography className={classes.linkText} variant="body1">
                {companyWebsite}
              </Typography>
            </a>
          )}
        </Card>
      </Box>
      <Box display="flex" mt={2} justifyContent="space-between" className={classes.cardContent}>
        <Box width="32%" className={classes.card}>
          <CardContent
            data={[
              {
                title: 'LOCATION',
                text: location,
              },
              {
                title: 'YEAR FOUNDED',
                text: yearFounded,
              },
              {
                title: 'INDUSTRY',
                text: industry,
              },
            ]}
          />
        </Box>
        <Box width="32%" className={classes.card}>
          <CardContent
            data={[
              {
                title: 'TYPE OF BUSINESS',
                text: businessType,
              },
              {
                title: 'TARGET MARKET',
                text: commaSeparatedNumber(Number(targetMarket)),
              },
              {
                title: 'STAGE OF BUSINESS',
                text: businessStage,
              },
            ]}
          />
        </Box>
        <Box width="32%" className={classes.card}>
          <CardContent
            data={[
              {
                title: 'NUMBER OF FOUNDERS',
                text: numberOfFounders,
              },
              {
                title: 'NUMBER OF EMPLOYEES',
                text: numberOfEmployees,
              },
              {
                title: 'NUMBER OF ADVISORS',
                text: numberOfAdvisors,
              },
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyOverview;
