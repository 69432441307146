import React, { useState, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ReactTooltip from 'react-tooltip';
import QuestionMark from 'components/layout/QuestionMark';
import { Select, MenuItem } from '@material-ui/core';
import CustomInput from 'components/molecules/FormInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import theme from 'theme/customTheme';
import { Error } from 'style/CommonStyle';
import { formatInputWithCommas, handleCommaNumberChange } from 'utils/helperMethod';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles, Symbol, Currency } from './styles';
import { Countries, Industries, SimpleQuestionTypes, Choices } from './types';

interface GeneralQuestionProps {
  handleCurrency: (location: string | number, inputRef: any) => void;
  currencySymbol: string;
  countries: Countries[];
  industries: Industries[];
  generalQuestions: { [key: string]: SimpleQuestionTypes[] };
  setFieldValue: (field: string, data: string | number | any[], validate: boolean) => any;
  errors: any;
  handleBlur: (e: any) => any;
  touched: any;
  values: any;
}

const GeneralQuestions: React.FC<GeneralQuestionProps> = ({
  handleCurrency,
  currencySymbol,
  countries,
  industries,
  generalQuestions,
  setFieldValue,
  errors,
  handleBlur,
  touched,
  values,
}) => {
  const classes = useStyles();
  const [notAllowNegative, setNotAllowNegative] = useState<boolean>(false);
  const inputRefs = useRef<HTMLInputElement>(null);

  const notAllowNegativeValue = (event: any) => {
    if (event.key === '-') {
      event.preventDefault();
      setNotAllowNegative(false);
      return false;
    }
    setNotAllowNegative(true);
    return true;
  };

  return (
    <>
      <Typography variant="subtitle1" className={classes.questionHeading}>
        General questions
      </Typography>
      <Divider className={classes.horizontalDivider} />
      <Box display="flex" flexDirection="column" mt={2}>
        {generalQuestions &&
          Object.entries(generalQuestions).map(([key, item]) => {
            if (isEmpty(item)) return <></>;
            if (item) {
              return item.map(
                ({
                  questionNo,
                  slug,
                  question,
                  fieldType,
                  fieldSymbol,
                  readOnly,
                  placeholder,
                  choices,
                  tooltip,
                  disableTrimming,
                }) => {
                  const isQuestion = question === 'Currency';
                  const isLocation = question === 'Location';
                  const isIndustry = question === 'Industry';
                  const showSymbol = fieldSymbol === 'currency' || fieldSymbol === 'percentage';
                  const isError = !isEmpty(errors) && errors.general[slug] && touched.general[slug];
                  const selectDefaultText = isLocation ? 'Choose country' : 'Choose answer';
                  const inputValue = !isEmpty(values) && !isEmpty(values.general) ? values.general[slug] : '';
                  let fieldValue = '';
                  if (!isEmpty(values) && !isEmpty(values.general)) {
                    fieldValue = !isQuestion ? inputValue || '' : handleCurrency(values.general['location'], inputRefs);
                  }
                  return (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      mt={2}
                      className={classes.generalContainer}
                    >
                      <Box
                        display="flex"
                        width="52%"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                        mt={2}
                        className={classes.questionTextSection}
                      >
                        <Box width="5%">
                          <Typography variant="body1" className={classes.questionText}>
                            {questionNo}
                          </Typography>
                        </Box>
                        <Divider orientation="vertical" className={classes.divider} flexItem />
                        <Box width="100%" display="flex">
                          <Box>
                            <Typography variant="subtitle2" className={classes.questionText}>
                              {question}
                            </Typography>
                          </Box>
                          {tooltip !== null && (
                            <>
                              <Box ml={1} mt={1}>
                                <QuestionMark
                                  bgFillColor="#CCCCCC"
                                  symbolFillColor="#ffffff"
                                  data-tip="hover on me will keep the tooltip"
                                  data-for={`tool-tip-${questionNo}`}
                                />
                              </Box>
                              <ReactTooltip delayHide={1000} effect="solid" id={`tool-tip-${questionNo}`} />
                            </>
                          )}
                        </Box>
                      </Box>
                      <Box display="flex" width="40%" flexDirection="column" className={classes.fieldSection}>
                        {fieldType === 'text' && (
                          <CustomInput
                            type={fieldType}
                            name={`general.${slug}`}
                            padding={showSymbol ? '13px 0px 13px 54px' : '13px 13px 13px 29px'}
                            marginTop="0"
                            value={fieldValue}
                            bgColor={isQuestion ? theme.colors.Gray95 : theme.colors.white}
                            ref={isQuestion ? inputRefs : null}
                            disableTrimming={disableTrimming}
                            inputProps={
                              showSymbol && (
                                <InputAdornment position="start">
                                  <Symbol>
                                    <Currency>{fieldSymbol === 'currency' ? currencySymbol || '£' : '%'}</Currency>
                                  </Symbol>
                                </InputAdornment>
                              )
                            }
                            placeholder={placeholder}
                            isFullWidth={true}
                            readOnly={readOnly}
                            handleOnChange={(data: string | number) => {
                              if (!isQuestion) {
                                setFieldValue(`general.${slug}`, data, false);
                              }
                            }}
                            helpText={isError ? errors.general[slug] : ''}
                            handleBlur={handleBlur}
                          />
                        )}
                        {fieldType === 'number' && (
                          <CustomInput
                            type="text"
                            name={`general.${slug}`}
                            padding={showSymbol ? '13px 0px 13px 54px' : '13px 13px 13px 29px'}
                            marginTop="0"
                            value={
                              fieldSymbol !== 'percentage' && slug !== 'yearFounded'
                                ? formatInputWithCommas(fieldValue)
                                : fieldValue
                            }
                            bgColor={isQuestion ? theme.colors.Gray95 : theme.colors.white}
                            ref={isQuestion ? inputRefs : null}
                            disableTrimming={disableTrimming}
                            inputProps={
                              showSymbol && (
                                <InputAdornment position="start">
                                  <Symbol>
                                    <Currency>{fieldSymbol === 'currency' ? currencySymbol || '£' : '%'}</Currency>
                                  </Symbol>
                                </InputAdornment>
                              )
                            }
                            placeholder={placeholder}
                            isFullWidth={true}
                            readOnly={readOnly}
                            handleOnChange={(data: string) => {
                              if (!isQuestion) {
                                if (fieldSymbol !== 'percentage' && slug !== 'yearFounded') {
                                  handleCommaNumberChange(data, (newData) => {
                                    setFieldValue(`general.${slug}`, newData, false);
                                  });
                                }

                                if (fieldSymbol !== 'currency' && /^$|^\d*$/.test(data)) {
                                  setFieldValue(`general.${slug}`, data, false);
                                }
                              }
                            }}
                            helpText={isError ? errors.general[slug] : ''}
                            handleBlur={handleBlur}
                            onKeyPress={(e) => {
                              if (slug !== 'revenue_last_year') {
                                notAllowNegativeValue(e);
                              }
                            }}
                          />
                        )}
                        {fieldType === 'dropdown' && (
                          <>
                            <Select
                              error={isError}
                              variant="outlined"
                              name={`general.${slug}`}
                              className={classes.select}
                              IconComponent={ExpandMoreIcon}
                              value={
                                (!isEmpty(values) && !isEmpty(values.general) && values.general[slug]) ||
                                selectDefaultText
                              }
                              onChange={(e: any) => {
                                if (isLocation) {
                                  handleCurrency(e.target.value, inputRefs);
                                }
                                setFieldValue(`general.${slug}`, e.target.value, false);
                              }}
                              onBlur={handleBlur}
                            >
                              <MenuItem value={isLocation ? 'Choose country' : 'Choose answer'}>
                                <Typography variant="body2" className={classes.dropdownText}>
                                  {isLocation ? 'Choose country' : 'Choose answer'}
                                </Typography>
                              </MenuItem>
                              {isLocation &&
                                countries.length > 0 &&
                                countries.map(({ value, text }) => {
                                  return <MenuItem value={value}>{text}</MenuItem>;
                                })}
                              {isIndustry &&
                                industries.length > 0 &&
                                industries.map(({ value, text }) => {
                                  return <MenuItem value={value}>{text}</MenuItem>;
                                })}
                              {!isLocation &&
                                !isIndustry &&
                                choices.length > 0 &&
                                choices.map((item: Choices) => {
                                  return <MenuItem value={item.value}>{item.text}</MenuItem>;
                                })}
                            </Select>
                            {isError && <Error>{errors.general[slug]}</Error>}
                          </>
                        )}
                      </Box>
                    </Box>
                  );
                },
              );
            }
          })}
      </Box>
    </>
  );
};

export default GeneralQuestions;
