import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import CustomButton from 'components/molecules/button';
import CenterlizeBox from 'components/organisms/CenterlizedBox';
import { CardDetails, OuterBox, SubTitle, Title, LinkPath } from 'style/CommonStyle';
import { EmailConfirmation, ResendEmailVerificationLink, VerifyUserAccount } from 'utils/ApiUrls';
import EmailValidationForm from 'components/organisms/EmailValidationForm';
import {
  AccountVerificationProps,
  ResponseType,
  ErrorResponseType,
  ResendLinkResponse,
  VerifyResponseType,
  MixNullBoolean,
} from './types';

const AccountVerification: React.FC<AccountVerificationProps> = (value) => {
  const [isVerified, setVerified] = useState<MixNullBoolean>(null);
  const [isAlreadyVerified, setAccountAlreadyVerified] = useState<MixNullBoolean>(null);
  const [userEmail, setUserEmail] = useState<string>('');
  const [userId, setUserId] = useState<number | null>(null);
  const [confirmationCode, setConfirmationCode] = useState<string | null>(null);
  const [showConfirmationMsg, setShowConfirmationMsg] = useState<MixNullBoolean>(null);

  useEffect(() => {
    const verificationToken = value?.match?.params?.verificationToken;
    setConfirmationCode(verificationToken);
    const checkVerificationToken = (token: string) => {
      axios
        .get<AxiosResponse>(EmailConfirmation(token))
        .then((res: ResponseType) => {
          const {
            data: {
              status,
              data: { userId },
            },
          } = res;
          if (status === 200 && userId) {
            setUserId(userId);
            verifiedUser(userId, token);
          }
        })
        .catch((error: ErrorResponseType) => {
          const {
            response: {
              data: { status, error: responseError },
            },
          } = error;
          if (status === 401 && responseError?.details) {
            setVerified(false);
            setUserEmail(responseError?.details?.email);
            setUserId(responseError?.details?.userId);
          }
          if (status === 409) {
            setAccountAlreadyVerified(true);
          }
        });
    };
    checkVerificationToken(verificationToken);
  }, [value]);

  const verifiedUser = (id: number, token: string) => {
    if (id) {
      axios.post<AxiosResponse>(VerifyUserAccount(id), { confirmationCode: token }).then((res: VerifyResponseType) => {
        if (res.status === 204) {
          setVerified(true);
        }
      });
    }
  };

  const renderLayout = useCallback(() => {
    const submitForm = (e: any) => {
      e.preventDefault();
      if (userId) {
        axios
          .post<AxiosResponse>(ResendEmailVerificationLink(userId), {
            confirmationCode,
          })
          .then((res: ResendLinkResponse) => {
            if (res.status === 204) {
              setShowConfirmationMsg(true);
              setVerified(null);
            }
          });
      }
    };

    if (isVerified === null && showConfirmationMsg === null && isAlreadyVerified === null) {
      return (
        <CardDetails>
          <OuterBox>
            <SubTitle>...Loading</SubTitle>
          </OuterBox>
        </CardDetails>
      );
    }

    if (isAlreadyVerified && isVerified === null && showConfirmationMsg === null) {
      return (
        <CardDetails>
          <Title>Account Confirmed</Title>
          <SubTitle>Your account has already been confirmed.</SubTitle>
          <SubTitle>Please click on the button below to Log In.</SubTitle>
          <Link to="/">
            <CustomButton type="button">Go to Login </CustomButton>
          </Link>
        </CardDetails>
      );
    }

    if (isVerified && showConfirmationMsg === null && isAlreadyVerified === null) {
      return (
        <OuterBox>
          <CardDetails>
            <Title>Your account is confirmed</Title>
            <SubTitle>
              Please
              <Link to="/">
                <LinkPath> Sign in </LinkPath>
              </Link>{' '}
              now!
            </SubTitle>
          </CardDetails>
        </OuterBox>
      );
    }
    if (!isVerified && showConfirmationMsg === null && isAlreadyVerified === null) {
      return (
        <EmailValidationForm
          title="Link Expired"
          subTitle="Enter your email address and we will send you a link to complete your registration"
          submitForm={submitForm}
          userEmail={userEmail}
          btnText="Send me the link"
          readOnly
        />
      );
    }
    if (isVerified === null && showConfirmationMsg && isAlreadyVerified === null) {
      return (
        <CardDetails>
          <Title>Check your email</Title>
          <SubTitle>Please check your email to confirm your account.</SubTitle>
          <SubTitle>If you haven't received our email in 15 minutes, please check your spam folder.</SubTitle>
        </CardDetails>
      );
    }
  }, [isVerified, userEmail, showConfirmationMsg, confirmationCode, userId, isAlreadyVerified]);

  return (
    <>
      <CenterlizeBox>{renderLayout()}</CenterlizeBox>
    </>
  );
};

export default AccountVerification;
