import React from 'react';
import { Box, Card, Divider, Typography } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { useStyles } from './styles';

type Elements = {
  title: string;
  text: string | number;
};

type PropTypes = {
  data: Elements[];
};

const CardContent: React.FC<PropTypes> = ({ data }) => {
  const classes = useStyles();
  return (
    <Card className={classes.cardContainer}>
      {!isEmpty(data) &&
        data.map(({ title, text }) => {
          return (
            <Box className={classes.content}>
              <Typography variant="caption" className={classes.title}>
                {title}
              </Typography>
              <Box mt={1} />
              <Typography variant="subtitle1" className={classes.text}>
                {text}
              </Typography>
              <Divider />
            </Box>
          );
        })}
    </Card>
  );
};

export default CardContent;
