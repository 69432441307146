import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import InputBase from '@material-ui/core/InputBase';
import { Error } from 'style/CommonStyle';
import { useStyles } from './styles';

interface InputProps {
  type: string;
  value?: string | number;
  placeholder?: string;
  borderColor?: string;
  readOnly?: boolean;
  handleOnChange?: (e: any, keyCode?: number) => void;
  helpText?: string;
  inputProps?: any;
  padding?: string;
  name?: string;
  bgColor?: string;
  marginTop?: string;
  isFullWidth?: boolean;
  borderRadius?: string;
  width?: string;
  height?: string;
  ref?: any;
  disableTrimming?: boolean;
  handleBlur?: (e: any) => any;
  onKeyPress?: (e: any) => any;
  mobileWidth?: string;
}

const defaultProps = {
  readOnly: false,
  value: '',
  handleOnChange: () => false,
  helpText: '',
  inputProps: null,
  padding: '11px 16px',
  bgColor: 'transparent',
  marginTop: '12px',
  isFullWidth: false,
  name: '',
  ref: null,
  handleBlur: () => false,
  onKeyPress: () => false,
  borderRadius: '50px',
  width: 'none',
  height: 'none',
  mobileWidth: '',
};

const InputText: React.FC<InputProps> = forwardRef(
  (
    {
      type,
      value,
      placeholder,
      helpText,
      readOnly,
      handleOnChange,
      disableTrimming,
      inputProps,
      isFullWidth,
      name,
      handleBlur,
      onKeyPress,
      ...styles
    },
    ref,
  ) => {
    const classes = useStyles(styles);
    const [fieldValue, setFieldValue] = useState<string | number>('');
    const isError = (helpText && helpText.length > 0) || false;
    const handleChange = ({ target }: React.FormEvent) => {
      const value = disableTrimming ? (target as HTMLInputElement).value : (target as HTMLInputElement).value.trim();
      return handleOnChange && handleOnChange(value);
    };

    useImperativeHandle(ref, () => ({
      onChange(inputValue: string | number) {
        setFieldValue(inputValue);
      },
    }));

    useEffect(() => {
      if (value || value === '') setFieldValue(value);
    }, [value]);

    return (
      <>
        <InputBase
          error={isError}
          name={name}
          type={type}
          onChange={handleChange}
          value={fieldValue}
          placeholder={placeholder}
          className={classes.inputField}
          startAdornment={inputProps}
          readOnly={readOnly}
          fullWidth={isFullWidth}
          inputRef={ref}
          onBlur={handleBlur}
          onKeyPress={onKeyPress}
          onKeyDown={(event) => {
            if ((type === 'number' && event.key === 'e') || event.key === 'ArrowDown') {
              event?.preventDefault();
            }
            return true;
          }}
        />
        {isError && <Error>{helpText}</Error>}
      </>
    );
  },
);

InputText.defaultProps = defaultProps;

export default InputText;
