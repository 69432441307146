import api from 'utils/api';
import { omit } from 'lodash';
import { users, changePassword, settings } from 'utils/ApiUrls';
import { getLocalStorageItem } from 'utils/getLocalStorageItem';
import { RequestHandles, UserDataType, PasswordDataType } from './types';
import { handleSettingsResponse } from './helpers';
import { logoutUser } from 'utils/helperMethod';

export const userDataApi = {
  put: async (
    data: UserDataType,
    {
      onRequest = () => null,
      onSuccess = () => null,
      onFailure = () => null,
      onFinally = () => null,
    }: RequestHandles = {},
  ): Promise<void> => {
    const requestBody = omit(data, 'email');
    onRequest();

    try {
      await api.put(users, requestBody);
      const oldData = getLocalStorageItem('growthVal') || {};
      localStorage.setItem('growthVal', JSON.stringify({ ...oldData, ...data }));
      onSuccess(data);
    } catch ({ response: { data: { error: { message } = {} } = {} } = {} }) {
      onFailure(message || 'Oops, something went wrong');
    } finally {
      onFinally();
    }
  },

  delete: async ({
    onRequest = () => null,
    onFailure = () => null,
    onFinally = () => null,
  }: RequestHandles = {}): Promise<void> => {
    onRequest();

    try {
      await api.delete(users);
      logoutUser();
    } catch ({ response: { data: { error: { message = '' } = {} } = {} } = {} }) {
      onFailure(message || 'Oops, something went wrong');
    } finally {
      onFinally();
    }
  },
};

export const passwordApi = {
  put: async (
    data: PasswordDataType,
    {
      onRequest = () => null,
      onSuccess = () => null,
      onFailure = () => null,
      onFinally = () => null,
    }: RequestHandles = {},
  ): Promise<void> => {
    onRequest();
    const requestBody = omit(data, 'confirmPassword');

    try {
      const response = await api.put(changePassword, requestBody);
      onSuccess(response);
    } catch ({ response: { data: { error: { message = '' } = {} } = {} } = {} }) {
      onFailure(message || 'Oops, something went wrong');
    } finally {
      onFinally();
    }
  },
};

export const settingsApi = {
  get: async ({
    onRequest = () => null,
    onSuccess = () => null,
    onFailure = () => null,
    onFinally = () => null,
  }: RequestHandles = {}): Promise<void> => {
    onRequest();
    try {
      const { data: { data: responseData = {} } = {} } = (await api.get(settings)) || {};
      const data = handleSettingsResponse(responseData);
      onSuccess(data);
    } catch ({ response: { data: { error: { message = '' } = {} } = {} } = {} }) {
      onFailure(message || 'Oops, something went wrong');
    } finally {
      onFinally();
    }
  },
};
