import { formateNumber } from './DCFReportHelper';
import { Table, TableRow, cellStyleA } from './styles';
import TableCell from './reportTableCell';
interface LeftCardTableProps {
  dcfReport: any;
  currencySymbol?: string;
}
const LeftCardTable = ({ dcfReport, currencySymbol }: LeftCardTableProps): JSX.Element => {
  const tableCellData = [
    {
      id: 1,
      cellLabel: 'Discount rate',
      cellData: formateNumber(dcfReport.discountRatePercentage),
    },
    {
      id: 2,
      cellLabel: 'Long-term growth rate',
      cellData: formateNumber(dcfReport.growthRatePercentage),
    },
    {
      id: 3,
      cellLabel: 'Sum of discounted FCF (Years 1 to 5)',
      cellData: formateNumber(dcfReport.sumDiscountedFCF, currencySymbol),
    },
    {
      id: 4,
      cellLabel: 'Discounted FCF in Terminal Period',
      cellData: formateNumber(dcfReport.discountedFCFInTerminalPeriod, currencySymbol),
    },
    {
      id: 5,
      cellLabel: 'Enterprise Value',
      cellData: formateNumber(dcfReport.enterpriseValue, currencySymbol),
    },
    {
      id: 6,
      cellLabel: 'Plus Cash',
      cellData: formateNumber(dcfReport.cash, currencySymbol),
    },
    {
      id: 7,
      cellLabel: 'Less Borrowings',
      cellData: formateNumber(dcfReport.borrowings, currencySymbol),
    },
    {
      id: 8,
      cellLabel: 'Equity Value',
      cellData: formateNumber(dcfReport.equityValue, currencySymbol),
    },
  ];

  return (
    <Table className="tilesBoxShadow">
      {tableCellData.map((item) => (
        <TableRow addExtraMargin={item.id == 2}>
          <TableCell
            cellData={item.cellLabel}
            style={item.id == 1 ? { ...cellStyleA, 'border-radius': '5px 0px 0px 0px' } : cellStyleA}
            key={item.id}
            addBg={item.id === 8}
            borderRadius={item.id === 8 ? '0px 0px 0px 5px' : ''}
            fontWeight={item.id === 8 ? 700 : 400}
          />
          <TableCell
            cellData={item.cellData}
            style={item.id == 1 ? { ...cellStyleA, 'border-radius': '0px 5px 0px 0px' } : cellStyleA}
            key={item.id}
            borderRadius={item.id === 8 ? '0px 0px 5px 0px' : ''}
            addBg={item.id === 8}
            fontWeight={item.id === 8 ? 700 : 400}
          />
        </TableRow>
      ))}
    </Table>
  );
};

export default LeftCardTable;
