import { makeStyles, Theme } from '@material-ui/core/styles';
import customTheme from 'theme/customTheme';

interface StyleProps {
  marginTop: number;
}

const loaderSize = 100;

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: ({ marginTop }) => `calc(100vh - ${marginTop}px)`,
    width: '100vw',
  },

  loader: {
    width: loaderSize,
    height: loaderSize,
    borderRadius: loaderSize / 2,
    border: `${loaderSize / 9}px solid ${customTheme.colors.primaryColor}`,
    borderTopColor: 'transparent',
    animation: '$spin 1s linear infinite',
  },

  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
}));
