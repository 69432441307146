import { useState } from 'react';
import UserSettingsBtn from 'components/molecules/UserSettingsBtn';
import { Typography, Drawer } from '@material-ui/core';
import { StyledOptionLink, StyledOptionBtn, useStyles } from '../styles';
import { NavLinkType } from '../types';

interface PropTypes {
  options: NavLinkType[];
  userName: string;
}

const UserSettings: React.FC<PropTypes> = ({ options, userName }) => {
  const [isDrawerOpen, setDrawer] = useState<boolean>(false);
  const classes = useStyles();

  return (
    <>
      <UserSettingsBtn
        open={isDrawerOpen}
        onClick={() => setDrawer((state) => !state)}
        userName={userName || 'User Profile'}
      />
      <Drawer
        anchor="top"
        open={isDrawerOpen}
        onClick={() => setDrawer(false)}
        onClose={() => setDrawer(false)}
        style={{ zIndex: 'inherit' }}
        ModalProps={{
          BackdropProps: { invisible: true },
        }}
        classes={{
          paper: classes.optionsDrawerPaper,
        }}
      >
        {options.map(({ onClick = () => null, to = '', text = '', id }) => {
          if (to) {
            return (
              <StyledOptionLink
                key={id}
                to={to}
                onClick={() => {
                  onClick();
                }}
              >
                <Typography>{text}</Typography>
              </StyledOptionLink>
            );
          }

          return (
            <StyledOptionBtn onClick={onClick} key={id}>
              <Typography>{text}</Typography>
            </StyledOptionBtn>
          );
        })}
      </Drawer>
    </>
  );
};

export default UserSettings;
