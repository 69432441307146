import styled, { css } from 'styled-components';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';
import { Grid } from '@material-ui/core';

export const FormWrapper = styled.form`
  max-width: 391px;
  height: 510px;

  && {
    ${(props) => props.theme.breakpoints.down('md')} {
      max-width: 335px;
      height: fit-content;
      margin: 10px auto;
    }
  }
`;

export const SuccessMsg = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  margin: 0px !important;

  & > span:nth-child(1) {
    width: 18px !important;
    height: 18px !important;
    margin-right: 10px;
  }
  & > span:nth-child(2) {
    font-size: 14px;
    font-weight: 400;
    color: #171c1b;
    margin-right: 20px;
    padding: 0px !important;
  }
`;

export const BusinessType = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 31px;
`;
export const GridContainer = styled(Grid)`
  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      margin: 10px 0;
    }
  }
`;
export const ShearedStyle = styled.span`
  margin-right: 20px;
  font-weight: 700;
`;
export const DisplayOnMobile = styled(ShearedStyle)`
  display: none;
  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      display: block;
      margin-bottom: 5px;
    }
  }
`;
export const DisplayOnDesktop = styled(ShearedStyle)`
  display: block;
  && {
    ${(props) => props.theme.breakpoints.down('xs')} {
      display: none;
    }
  }
`;

export const InputWrapper = styled.div`
  margin: 20px 0;
`;
export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 19px 0;
`;
export const DivWithMargin = styled.div`
  margin-left: 20px;
`;

export const StyledCheckBox = styled(MuiCheckbox)`
  width: 20px;
  height: 20px;
  margin-right: 8px !important;
  color: ${({ theme }) => theme.palette.primary.main} !important;
`;

export const TermAndCondition = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: 'Mulish';
  color: #171c1b;
  font-weight: 400;
  & > span {
    margin: 0 3px;
  }
  > a {
    text-decoration: underline;
    margin: 0 2px;
  }

  > a:nth-child(1) {
    cursor: not-allowed;
  }
  > a:nth-child(2) {
    cursor: pointer;
  }
`;

export const ContinueButton = styled.button<{ disabled: boolean }>`
  outline: none;
  border: none;
  border-radius: 50px;
  color: #fff;
  margin-top: 24px;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  height: 46px;
  cursor: pointer;
  font-family: 'Mulish';
  background-color: ${({ theme }) => theme.palette.primary.main};
  &:hover {
    background-color: ${(props) => props.theme.colors.DarkBlack};
  }
  &:active {
    border: 2px solid #fff;
    transform: translateY(-1px);
  }
  ${({ disabled }) =>
    disabled
      ? css`
          color: ${({ theme }) => theme.colors.lighterGray};
          cursor: not-allowed;
        `
      : ''};
`;
