import React from 'react';
import { commaSeparatedNumber } from 'utils/helperMethod';
import { useStyles } from './styles';
import { CompanyOverivewProps } from './types';
import { firstLetterCapital } from './helper';
import { isEmpty } from 'lodash';
import customTheme from 'theme/customTheme';
import CardContent from './CardContent';

const CompanyOverview: React.FC<CompanyOverivewProps> = ({ details }) => {
  if (isEmpty(details)) {
    return <></>;
  }
  const {
    companyName,
    companyWebsite,
    yearFounded,
    industry,
    location,
    numberOfFounders,
    numberOfEmployees,
    numberOfAdvisors,
    businessType,
    targetMarket,
    businessStage,
  } = details;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <h2
          style={{
            fontSize: '32px',
            lineHeight: '43px',
            wordBreak: 'break-word',
            color: customTheme.colors.DarkBlack,
            fontStyle: 'normal',
            fontFamily: 'Prompt',
            fontWeight: 700,
          }}
        >
          Company overview
        </h2>
      </div>
      <div style={{ marginTop: '20px' }}>
        <div
          style={{
            background: customTheme.colors.DarkBlack,
            padding: '23px 20px',
            overflow: 'hidden',
            boxShadow: 'none',
            borderRadius: '4px',
          }}
        >
          <h3
            style={{
              fontSize: '32px',
              lineHeight: '43px',
              wordBreak: 'break-word',
              fontStyle: 'normal',
              fontFamily: 'Prompt',
              fontWeight: 700,
              color: customTheme.colors.white,
            }}
          >
            {firstLetterCapital(companyName)}
          </h3>
          {companyWebsite && (
            <a
              href={
                companyWebsite.indexOf('http://') === -1 && companyWebsite.indexOf('https://') === -1
                  ? `//${companyWebsite}`
                  : companyWebsite
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <h4
                style={{
                  fontSize: '16px',
                  lineHeight: '21px',
                  color: customTheme.colors.MediumSpringGreen,
                  wordBreak: 'break-word',
                  fontStyle: 'normal',
                  fontFamily: 'Mulish',
                  fontWeight: 400,
                }}
              >
                {companyWebsite}
              </h4>
            </a>
          )}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
        <div style={{ width: '32%' }}>
          <CardContent
            data={[
              {
                title: 'LOCATION',
                text: location,
              },
              {
                title: 'YEAR FOUNDED',
                text: yearFounded,
              },
              {
                title: 'INDUSTRY',
                text: industry,
              },
            ]}
          />
        </div>
        <div style={{ width: '32%' }}>
          <CardContent
            data={[
              {
                title: 'TYPE OF BUSINESS',
                text: businessType,
              },
              {
                title: 'TARGET MARKET',
                text: commaSeparatedNumber(Number(targetMarket)),
              },
              {
                title: 'STAGE OF BUSINESS',
                text: businessStage,
              },
            ]}
          />
        </div>
        <div style={{ width: '32%' }}>
          <CardContent
            data={[
              {
                title: 'NUMBER OF FOUNDERS',
                text: numberOfFounders,
              },
              {
                title: 'NUMBER OF EMPLOYEES',
                text: numberOfEmployees,
              },
              {
                title: 'NUMBER OF ADVISORS',
                text: numberOfAdvisors,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyOverview;
