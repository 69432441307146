import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

type SvgProps = {
  bgFillColor: string;
  symbolFillColor: string;
};

const QuestionMark: React.FC<SvgProps> = ({ bgFillColor, symbolFillColor, ...rest }) => {
  return (
    <SvgIcon {...rest}>
      <circle cx="10.5" cy="10.5" r="10.5" fill={bgFillColor} />
      <path
        d="M8.931 6.67175C9.443 6.35975 10.039 6.20375 10.719 6.20375C11.215 6.20375 11.655 6.28775 12.039 6.45575C12.423 6.62375 12.719 6.85575 12.927 7.15175C13.143 7.44775 13.251 7.79175 13.251 8.18375C13.251 8.60775 13.151 8.97175 12.951 9.27575C12.759 9.57975 12.467 9.93175 12.075 10.3317C11.747 10.6677 11.499 10.9557 11.331 11.1957C11.163 11.4277 11.059 11.6917 11.019 11.9877L10.971 12.3237H10.083V11.8317C10.083 11.3837 10.163 11.0077 10.323 10.7037C10.483 10.3917 10.715 10.0557 11.019 9.69575C11.251 9.42375 11.423 9.18775 11.535 8.98775C11.655 8.78775 11.715 8.56375 11.715 8.31575C11.715 8.03575 11.615 7.81975 11.415 7.66775C11.223 7.50775 10.955 7.42775 10.611 7.42775C10.051 7.42775 9.491 7.59975 8.931 7.94375V6.67175ZM9.699 13.1637H11.391V14.8438H9.699V13.1637Z"
        fill={symbolFillColor}
      />
    </SvgIcon>
  );
};

export default QuestionMark;
